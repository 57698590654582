<template>
<div class="usage-detail__mobile-nav-container">
  <ModalUsagePickAView v-if="navSelectModalVisible" :closeModal="hideNavSelectModal" :isDashboard="false"/>
  <div class="usage-detail__mobile-nav-container__left-column">
      <a href="/account/usage-new" class="usage-detail__mobile-nav-container__left-column__back-arrow-wrapper">
        <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-left" />
        </svg>
        <span>Back</span>
      </a>
  </div>
  <div class="usage-detail__mobile-nav-container__right-column">
    <button
    v-if="selectedButtonOption"
    @click="showNavSelectModal"
    class="usage-detail-mobile__top-nav-button">
      <img :src="getImgUrl()" />
      <span class="usage-detail-mobile__top-nav-button__label">{{getOptionLabel()}}</span>
      <i class="usage-detail-mobile__down-caret"></i>
    </button>
  </div>
</div>
</template>
<script>
import ModalUsagePickAView from "../ModalUsagePickAView.vue";

export default {
  name: "UsageDetailMobileNav",
  components: {
    ModalUsagePickAView
  },
  mounted() {
    this.setMatchingSelectedButtonOption(this.buttonOptions, {routerPath: this.$route.path});
  },
  methods: {
    showNavSelectModal() {
      this.navSelectModalVisible = true;
    },
    hideNavSelectModal() {
      this.navSelectModalVisible = false;
    },
    getOptionLabel() {
      return this.selectedButtonOption.label;
    },
    getImgUrl() {
      return `${this.iconUrlBase}${this.selectedButtonOption.icon}-green.svg`;
    },
    setMatchingSelectedButtonOption(buttonOptions, buttonOption) {
      this.selectedButtonOption = buttonOptions.find(obj => buttonOption.routerPath.startsWith(obj.routerPath));
    },
    onOptionSelected(buttonOption) {
      this.$router.push(buttonOption.routerPath);
      this.hideNavSelectModal();
      this.setMatchingSelectedButtonOption(this.buttonOptions, buttonOption);
    }
  },
  data() {
    return {
      navSelectModalVisible: false,
      selectedButtonOption: undefined,
      buttonOptions: [
        {
          label: 'Graph',
          routerPath: '/graph',
          icon: 'usage-visual-graph'
        },
        {
          label: 'Table',
          routerPath: '/table',
          icon: 'usage-visual-table'
        },
        {
          label: 'Time / Day',
          routerPath: '/time-of-day',
          icon: 'usage-visual-time-of-day'
        },
        {
          label: 'Seasonal',
          routerPath: '/seasonal',
          icon: 'usage-visual-seasonal'
        },
        // {
        //   label: 'Heat / Cool',
        //   routerPath: '/heating-cooling',
        //   icon: 'usage-visual-heating-cooling'
        // },
        {
          label: 'Performance',
          routerPath: '/performance',
          icon: 'usage-visual-performance'
        },
        {
          label: 'Compare',
          routerPath: '/compare',
          icon: 'usage-visual-compare'
        }
      ],
      iconUrlBase: '/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/'
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>