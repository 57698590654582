var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "my-account__full-width-section my-account__section-with-bottom-border"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
        },
        [
          _c("h2", { staticClass: "gds-display-1 gds-space-stack-m" }, [
            _vm._v("Account Nickname")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-close-button-combo" }, [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = true
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Edit")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && !_vm.pendingSave
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = false
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Close")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.editing
        ? _c("div", [
            _vm.currentAccount.nickname
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-l my-account__sub-section-bottom"
                  },
                  [_vm._v(_vm._s(_vm.currentAccount.nickname))]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.currentAccount.nickname
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-l my-account__sub-section-bottom gds-text-grey"
                  },
                  [_vm._v("None set")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Save()
                }
              }
            },
            [
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-l" },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Nickname")
                      ]),
                      _vm._v(" "),
                      _c("the-mask", {
                        staticClass: "gds-input-field__input",
                        attrs: {
                          maxlength: "30",
                          type: "text",
                          placeholder: "Type Here...",
                          tokens: _vm.allowedCharacters,
                          mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"
                        },
                        on: {
                          input: function($event) {
                            _vm.touched = true
                          }
                        },
                        model: {
                          value: _vm.editedNickname,
                          callback: function($$v) {
                            _vm.editedNickname = $$v
                          },
                          expression: "editedNickname"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-round gds-space-stack-m",
                  class: { "gds-loading": _vm.pendingSave },
                  attrs: {
                    type: "submit",
                    disabled:
                      _vm.pendingSave || !_vm.touched || _vm.errors.any()
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Save")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorSave
                ? _c(
                    "div",
                    { staticClass: "form-message-box gds-space-stack-m" },
                    [
                      _vm._v(
                        "There was an error updating your paperless billing."
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }