<template>
  <div class="make-payment-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Bill Pay" state="error"
      phonetext="To make a payment by phone call us at" closepath="/make-payment" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 -->
      <section id="pay-slat-1" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="bill-pay-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Payment Amount</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Payment amount: {{ paymentAmount | currency }}</div>
                <button @click="gotoPage(1)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 1" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <widget-scheduled-payments class="gds-space-stack-xl" />
              <banner-one-action v-if="showPastDueWizardBanner" bannerColor="blue"
                message="Part of your balance is past due. We offer no-interest installment plans to make it easier to get current."
                linkText="See Plan Options" url="/past-due-wizard"></banner-one-action>
              <div class="bill-pay-slat__inner-row gds-space-stack-l bill-pay__account-box">
                <div class="bill-pay__amount-flex-container gds-flex-container gds-flex-container--top">
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Current balance</div>
                    <div>
                      <div v-if="amountDue > 0 && pastDueBalance > 0">
                        <div class="gds-body-normal gds-space-stack-s">Total balance: {{ amountDue |
                            currency
                        }}</div>
                        <div class="gds-body-normal gds-space-stack-s">Due now: {{ pastDueBalance |
                            currency
                        }}</div>
                      </div>
                      <div v-if="amountDue > 0 && pastDueBalance > 0 && pastDueBalance !== amountDue"
                        class="gds-body-normal">Due
                        {{ currentAccount.amountDueDate | dateFull }}: {{ amountDue - pastDueBalance | currency }}</div>
                      <div v-if="amountDue > 0 && pastDueBalance <= 0"
                        class="gds-body-normal">{{amountDue | currency}} is due {{currentAccount.amountDueDate | dateFull}}.</div>
                      <div v-if="amountDue <= 0" class="gds-body-normal">All paid up, you do not owe any money at this
                        time.</div>
                    </div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Service Address</div>
                    <div class="gds-body-normal gds-linebreaks">{{ currentAccount.address | addressFormat }}</div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Account Number</div>
                    <div class="gds-body-normal">{{ currentAccount.accountNumber }}</div>
                  </div>
                  <div v-if="accounts.length > 1" @click="chooseAccount()" class="inline">
                    <button class="gds-button gds-text-button choose-account-button">
                      <svg class="eicproduct__edit-icon-inline gds-icon">
                        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                      </svg>
                      <span v-if="accounts.length > 1">Switch Account</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="bill-pay-slap__inner-row gds-space-stack-l">
                <form data-vv-scope="page1" @submit.prevent>
                  <fieldset v-show="permitFutureDate"
                    class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding">
                    <label class="gds-radio">
                      <input v-model="isScheduled" :value="false" type="radio" name="is-scheduled-group" />
                      <span class="gds-radio__faux"></span>
                      <span class="gds-radio__label">
                        <span class="gds-font-demi">Pay Immediately</span>
                      </span>
                    </label>
                    <label class="gds-radio">
                      <input v-model="isScheduled" :value="true" type="radio" name="is-scheduled-group" />
                      <span class="gds-radio__faux"></span>
                      <span class="gds-radio__label">
                        <span class="gds-font-demi">Schedule for the future</span>
                      </span>
                    </label>
                  </fieldset>
                  <div class="gds-flex-container gds-flex-container--left">
                    <fieldset class="gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Payment amount</span>
                        <div class="gds-position--relative">
                          <span class="gds-input-field__prefix gds-text">$</span>
                          <money v-model="paymentAmount" v-validate="`required|min_value:${paymentMeta.minACH}|max_value:${this.maxPaymentAmount}`"
                            name="payment amount" key="payment amount"
                            class="bill-pay-input-field__input gds-input-field__input" autofocus ref="focuser1"></money>
                        </div>
                      </label>
                    </fieldset>
                    <fieldset v-show="isScheduled" class="gds-fieldset">
                      <span class="gds-input-field__label">Payment date</span>
                      <div class="my-account-billing-report__filtration-date-box">
                        <div @click="$refs.datePicker.showCalendar()"
                          class="my-account-billing-report__filtration-date-icon">
                          <svg class="gds-icon">
                            <use
                              xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                          </svg>
                        </div>
                        <datepicker ref="datePicker" v-model="scheduledDate" v-validate="isScheduled ? 'required' : ''"
                          name="payment date" key="payment date" format="MMMM d, yyyy" :disabled-dates="disabledDates"
                          input-class="export-data__date my-account-billing-report__filtration-date-text" />
                      </div>
                    </fieldset>
                  </div>
                  <div class="bill-pay__fieldset--wide-hint">
                    <div v-if="errors.first(`payment amount`, 'page1')"
                              class="validation-error">{{
                              `The payment amount entered is greater than the maximum allowed amount of $${this.maxPaymentAmount}. If a larger payment amount needs to be made, please make multiple payments.`
                              }}</div>
                    <div v-if="errors.first('payment date', 'page1')" class="validation-error">{{ errors.first("payment date", "page1")}}</div>
                    <div v-if="paymentAmount < 10" class="form-input-hint form-input-hint--blue">Card payments have a
                      $10.00 minimum. If you’d like to be able to pay using a credit, debit, or ATM card, please adjust
                      the payment amount.</div>
                    <div v-if="paymentAmount > 0 && paymentAmount > amountDue"
                      class="form-input-hint form-input-hint--blue">The payment amount you entered is more than what is
                      due at this time. Any overpayment will be automatically credited on the next bill we issue you.
                    </div>
                  </div>
                </form>
                <button @click="finishPage('page1', 2)" class="gds-button gds-compact gds-space-stack-l">Next</button>
                <a v-if="!isRecurringPaymentsEnrolled" href="/account/settings#autopay" class="gds-display-0 gds-link gds-link--bold">Setup or Manage
                  Recurring Auto Payments</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 -->
      <section id="pay-slat-2" class="payment-method bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="bill-pay-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Payment Method</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{ selectedWallet ? selectedWallet.accountNickName : ""
                }}</div>
                <button @click="gotoPage(2)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <banner-one-action v-if="tooManyCCPayments" bannerColor="blue"
                message="Card payments are disabled because two or more payments have already been processed this month, or are pending or scheduled. GMP limits card payments to 2 per month to keep electronic payments free for all customers. To make an additional payment this month, please use a bank account, which has no payment limits."
                :linkText="'Info'" @buttonFunction="showModalCCPaymentInfo"></banner-one-action>
              <ModalCCPaymentInfo :visible="ShowModalCCPaymentInfo" :close="closeModal" />
              <div v-if="!tooManyCCPayments && paymentAmount < paymentMeta.minCC"
                class="form-message-box form-message-box--blue gds-space-stack-l">Card payments are disabled because the
                payment amount is below the minimum of {{ paymentMeta.minCC | currency }}. If you'd like to pay using a
                debit or credit card, please edit your payment amount.</div>
              <div v-if="!tooManyCCPayments && paymentAmount > paymentMeta.maxCC"
                class="form-message-box form-message-box--blue gds-space-stack-l">Card payments are disabled because the
                payment amount is above the maximum of {{ paymentMeta.maxCC | currency }}. If you'd like to pay using a
                debit or credit card, please edit your payment amount.</div>

              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-body-small gds-space-stack-l">Please select your payment method.</div>
                <form class="bill-pay-login__form" @submit.prevent role="radiogroup">
                  <fieldset class="gds-fieldset gds-space-inset-s">
                    <wallet-selector v-for="wallet of userWallets" :key="wallet.walletId" :value="wallet"
                      v-model="selectedWallet"
                      :disabled="(wallet.paymentType === 'CC' || wallet.paymentType === 'ATM') && (tooManyCCPayments || paymentAmount < paymentMeta.minCC || paymentAmount > paymentMeta.maxCC)"
                      :isNew="wallet.isNew" />
                  </fieldset>
                </form>
                <div v-if="!isRecurringPaymentsEnrolled">
                  <div class="gds-body-small gds-space-stack-l">Do you want to turn on Recurring Auto Payments?</div>
                  <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding">
                    <label class="gds-radio">
                      <input ref="recurring-focuser" v-model="enrollInRecurringPayments" :value="false"  type="radio" name="enroll-in-recurring" />
                      <span class="gds-radio__faux"></span>
                      <span class="gds-radio__label bill-pay-recurring-enroll__radio-label">
                        <span class="gds-font-demi">No. Only make a one-time payment.</span>
                      </span>
                    </label>
                    <label class="gds-radio">
                      <input v-model="enrollInRecurringPayments" :value="true" type="radio" name="enroll-in-recurring"/>
                      <span class="gds-radio__faux"></span>
                      <span class="gds-radio__label bill-pay-recurring-enroll__radio-label">
                        <span class="gds-font-demi">Yes. Turn on Recurring Auto Payments with this payment method.</span>
                      </span>
                    </label>
                  </fieldset>
                </div>
                <button @click="finishPage('page2', 3)"
                  class="bill-pay__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 -->
      <section id="pay-slat-3" class="payment-details bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="bill-pay-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Payment Details</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{ selectedWallet | walletSlug }}</div>
                <button @click="gotoPage(3)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <!-- SLAT 3 NEW CC INPUT -->
              <div v-if="selectedWallet && selectedWallet.isNew === 'credit'">
                <form data-vv-scope="page3NewCC" @submit.prevent>
                  <div class="bill-pay-slat__inner-row gds-space-stack-l">Please provide your card information.</div>
                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Name on Card</span>
                      <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                        key="name on card" class="bill-pay-input-field__input gds-input-field__input" type="text"
                        placeholder="Name" maxlength="45" autocomplete="cc-name" ref="focuser3" />
                      <div v-if="errors.first('name on card', 'page3NewCC')" class="validation-error">{{
                          errors.first("name on card", "page3NewCC")
                      }}</div>
                    </label>
                  </fieldset>
                  <div
                    class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-s">
                    <fieldset class="bill-pay-slat__card-number gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Card Number</span>
                        <div class="gds-position--relative">
                          <span class="bill-pay__credit-card-prefix gds-input-field__prefix gds-text">
                            <svg class="bill-pay__icon gds-icon">
                              <use
                                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                            </svg>
                          </span>
                          <input v-model="creditNumber" v-mask="'################'"
                            v-validate="(selectedWallet.lastFourDigitDebitAccount ? '' : 'required|') + 'numeric|min:15|max:16'"
                            name="card number" key="card number" class="gds-input-field__input" type="tel"
                            :placeholder="selectedWallet.lastFourDigitDebitAccount ? ('******' + selectedWallet.lastFourDigitDebitAccount) : ''"
                            maxlength="16" autocomplete="cc-number" />
                        </div>
                        <div v-if="errors.first('card number', 'page3NewCC')" class="validation-error">{{
                            errors.first("card number", "page3NewCC")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Expiration</span>
                        <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                          v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                          key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                          autocomplete="cc-exp" />
                        <div v-if="errors.first('expiration date', 'page3NewCC')" class="validation-error">{{
                            errors.first("expiration date", "page3NewCC")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">CVV</span>
                        <input v-model="tempCVV" v-mask="'####'" v-validate="'required|numeric|min:3|max:4'" name="CVV"
                          key="CVV" class="gds-input-field__input" type="tel" maxlength="4" autocomplete="cc-csc" />
                        <div v-if="errors.first('CVV', 'page3NewCC')" class="validation-error">{{ errors.first("CVV",
                            "page3NewCC")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Billing ZIP Code</span>
                        <input v-model="debitZip" v-mask="'#####'" v-validate="'required|numeric|min:5|max:5'"
                          name="billing zip" key="billing zip" class="bill-pay-slat__small-input gds-input-field__input"
                          type="tel" maxlength="5" />
                      </label>
                      <div v-if="errors.first('billing zip', 'page3NewCC')" class="validation-error">{{
                          errors.first("billing zip", "page3NewCC")
                      }}</div>
                    </fieldset>
                  </div>
                  <fieldset v-if="selectedWallet.isNew && !enrollInRecurringPayments" class="gds-fieldset gds-space-stack-l">
                    <label class="gds-checkbox gds-checkbox-thin">
                      <input v-model="savePayment" type="checkbox"/>
                      <span class="gds-checkbox__faux"></span>
                      <span class="gds-checkbox__label gds-display-00 gds-text-grey">Save this card for future
                        use.</span>
                    </label>
                  </fieldset>
                  <fieldset v-if="savePayment || enrollInRecurringPayments" class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Payment Method Nickname</span>
                      <input v-model="paymentName" v-validate="'required'" name="payment method name"
                        key="payment method name" class="bill-pay-input-field__input gds-input-field__input" type="text"
                        maxlength="50" />
                      <div v-if="errors.first('payment method name', 'page3NewCC')" class="validation-error">{{
                          errors.first("payment method name", "page3NewCC")
                      }}</div>
                    </label>
                  </fieldset>
                </form>
                <button @click="finishPage('page3NewCC', 4)" class="gds-button gds-compact">Next</button>
              </div>

              <!-- SLAT 3 NEW ATM INPUT -->
              <div v-if="selectedWallet && selectedWallet.isNew === 'atm'">
                <form data-vv-scope="page3NewATM" @submit.prevent>
                  <div class="bill-pay-slat__inner-row gds-space-stack-l">Please provide your card information.</div>
                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Name on Card</span>
                      <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                        key="name on card" class="bill-pay-input-field__input gds-input-field__input" type="text"
                        placeholder="Name" maxlength="45" autocomplete="cc-name" ref="focuser3" />
                      <div v-if="errors.first('name on card', 'page3NewATM')" class="validation-error">{{
                          errors.first("name on card", "page3NewATM")
                      }}</div>
                    </label>
                  </fieldset>
                  <div
                    class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-s">
                    <fieldset class="bill-pay-slat__card-number gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Card Number</span>
                        <div class="gds-position--relative">
                          <span class="bill-pay__credit-card-prefix gds-input-field__prefix gds-text">
                            <svg class="bill-pay__icon gds-icon">
                              <use
                                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                            </svg>
                          </span>
                          <input v-model="creditNumber" v-mask="'################'"
                            v-validate="(selectedWallet.lastFourDigitDebitAccount ? '' : 'required|') + 'numeric|min:15|max:16'"
                            name="card number" key="card number" class="gds-input-field__input" type="tel"
                            :placeholder="selectedWallet.lastFourDigitDebitAccount ? ('******' + selectedWallet.lastFourDigitDebitAccount) : ''"
                            maxlength="16" autocomplete="cc-number" />
                        </div>
                        <div v-if="errors.first('card number', 'page3NewATM')" class="validation-error">{{
                            errors.first("card number", "page3NewATM")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Expiration</span>
                        <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                          v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                          key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                          autocomplete="cc-exp" />
                        <div v-if="errors.first('expiration date', 'page3NewATM')" class="validation-error">{{
                            errors.first("expiration date", "page3NewATM")
                        }}</div>
                      </label>
                    </fieldset>
                  </div>
                  <fieldset v-if="selectedWallet.isNew  && !enrollInRecurringPayments" class="gds-fieldset gds-space-stack-l">
                    <label class="gds-checkbox gds-checkbox-thin">
                      <input v-model="savePayment" type="checkbox"/>
                      <span class="gds-checkbox__faux"></span>
                      <span class="gds-checkbox__label gds-display-00 gds-text-grey">Save this card for future
                        use.</span>
                    </label>
                  </fieldset>
                  <fieldset v-if="savePayment || enrollInRecurringPayments" class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Payment Method Nickname</span>
                      <input v-model="paymentName" v-validate="'required'" name="payment method name"
                        key="payment method name" class="bill-pay-input-field__input gds-input-field__input" type="text"
                        maxlength="50" />
                      <div v-if="errors.first('payment method name', 'page3NewATM')" class="validation-error">{{
                          errors.first("payment method name", "page3NewATM")
                      }}</div>
                    </label>
                  </fieldset>
                </form>
                <button @click="finishPage('page3NewATM', 4)" class="gds-button gds-compact">Next</button>
              </div>

              <!-- SLAT 3 NEW ACH INPUT -->
              <div v-if="selectedWallet && selectedWallet.isNew === 'ach'">
                <form data-vv-scope="page3NewACH" @submit.prevent>
                  <div class="bill-pay-slat__inner-row gds-space-stack-l">Please provide your bank account information.
                  </div>
                  <div class="bill-pay-slat__inner-row gds-space-stack-l">
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Type</span>
                        <select v-model="selectedWallet.transType" v-validate="'required'" name="account type"
                          key="account type"
                          class="bill-pay-input-field__input gds-input-field bill-pay-input-field__select"
                          id="account-type">
                          <option value="SAVINGS">Savings</option>
                          <option value="CHECKING">Checking</option>
                          <option value="MONEY_MARKET">Money Market</option>
                        </select>
                        <div v-if="errors.first('account type', 'page3NewACH')" class="validation-error">{{
                            errors.first("account type", "page3NewACH")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Number</span>
                        <input v-model="bankNumber" v-mask="'######################'"
                          v-validate="'required|numeric|min:5|max:22'" name="account number" key="account number"
                          class="bill-pay-input-field__input gds-input-field__input" type="tel" maxlength="22"
                          ref="focuser3" />
                        <div v-if="errors.first('account number', 'page3NewACH')" class="validation-error">{{
                            errors.first("account number", "page3NewACH")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Routing Number</span>
                        <input v-model="selectedWallet.debitRouting" v-mask="'#########'"
                          v-validate="'required|numeric|min:9'" name="routing number" key="routing number"
                          class="bill-pay-input-field__input gds-input-field__input" type="tel" maxlength="9" />
                        <div v-if="errors.first('routing number', 'page3NewACH')" class="validation-error">{{
                            errors.first("routing number", "page3NewACH")
                        }}</div>
                      </label>
                    </fieldset>
                  </div>
                  <fieldset v-if="selectedWallet.isNew  && !enrollInRecurringPayments" class="gds-fieldset gds-space-stack-l">
                    <label class="gds-checkbox gds-checkbox-thin">
                      <input v-model="savePayment" type="checkbox"/>
                      <span class="gds-checkbox__faux"></span>
                      <span class="gds-checkbox__label gds-display-00 gds-text-grey">Save this bank account for future
                        use.</span>
                    </label>
                  </fieldset>
                  <fieldset v-if="savePayment || enrollInRecurringPayments" class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Payment Method Nickname</span>
                      <input v-model="paymentName" v-validate="'required'" name="payment method name"
                        key="payment method name" class="bill-pay-input-field__input gds-input-field__input" type="text"
                        maxlength="50" />
                      <div v-if="errors.first('payment method name', 'page3NewACH')" class="validation-error">{{
                          errors.first("payment method name", "page3NewACH")
                      }}</div>
                    </label>
                  </fieldset>
                </form>
                <button @click="finishPage('page3NewACH', 4)" class="gds-button gds-compact">Next</button>
              </div>

              <!-- SLAT 3 SHOW SAVED CC, LINK TO EDIT CARD INFO -->
              <div
                v-if="selectedWallet && !selectedWallet.isNew && selectedWallet.paymentType === 'CC' && !editClicked">
                <form data-vv-scope="page3VerifyCC" @submit.prevent>
                  <div
                    class="bill-pay-slat__inner-row gds-flex-container gds-flex-container--top gds-flex-container--space-between gds-flex-container--wrap">
                    <div class="bill-pay__existing-payment-method--left">
                      <div class="bill-pay__existing-payment-description gds-space-stack-l">For security, please enter
                        the CVV and billing ZIP Code associated with this card.</div>
                      <fieldset class="bill-pay-slat__cvv gds-fieldset gds-space-stack-m">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">CVV</span>
                          <input v-model="tempCVV" v-mask="'####'" v-validate="'required|numeric|min:3|max:4'"
                            name="CVV" key="CVV" class="bill-pay-slat__small-input gds-input-field__input" type="tel"
                            maxlength="4" autocomplete="cc-csc" ref="focuser3" />
                          <div v-if="errors.first('CVV', 'page3VerifyCC')" class="validation-error">{{
                              errors.first("CVV", "page3VerifyCC")
                          }}</div>
                        </label>
                      </fieldset>

                      <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-space-stack-m">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Billing ZIP Code</span>
                          <input v-model="debitZip" v-mask="'#####'" v-validate="'required|numeric|min:5|max:5'"
                            name="billing zip" key="billing zip"
                            class="bill-pay-slat__small-input gds-input-field__input" type="tel" maxlength="5" />
                        </label>
                        <div v-if="errors.first('billing zip', 'page3VerifyCC')" class="validation-error">{{
                            errors.first("billing zip", "page3VerifyCC")
                        }}</div>
                      </fieldset>
                    </div>

                    <div class="bill-pay__existing-payment-method--right gds-space-stack-l">
                      <div class="bill-pay__existing-payment-method-box gds-flex-container gds-flex-container--top">
                        <svg class="bill-pay__method-box-icon bill-pay__icon gds-icon">
                          <use
                            xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                        </svg>
                        <div>
                          <div>{{ selectedWallet.accountNickName }}</div>
                          <div>{{ selectedWallet | walletSlug }}</div>
                        </div>
                      </div>
                      <div class="bill-pay__edit-card-details">
                        <button @click="editClicked = true; slatFocus(3)"
                          class="clickable gds-button gds-text-button gds-text-button--small-text">
                          <svg class="bill-pay__edit-icon-inline gds-icon">
                            <use
                              xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                          </svg>
                          Edit Card Details
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--/end flex container -->
                </form>
                <button @click="finishPage('page3VerifyCC', 4)" class="gds-button gds-compact">Next</button>
              </div>

              <!-- SLAT 3 EDIT SCREEN FOR EXISTING CC  -->
              <div v-if="selectedWallet && !selectedWallet.isNew && selectedWallet.paymentType === 'CC' && editClicked">
                <form data-vv-scope="page3OldCC" @submit.prevent>
                  <div v-if="!selectedWallet.isExpired"
                    class="bill-pay__existing-payment-description gds-space-stack-l">Edit the details for your saved
                    card.</div>
                  <div v-if="selectedWallet.isExpired" class="form-message-box gds-space-stack-l">This payment method
                    has expired, please update the payment details or remove the payment method.</div>
                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Nickname</span>
                      <input v-model="selectedWallet.accountNickName" v-validate="'required|max:45'"
                        name="account nickname" key="account nickname" class="gds-input-field__input" type="text"
                        placeholder="Account Nickname" maxlength="45" />
                    </label>
                  </fieldset>

                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Name on Card</span>
                      <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                        key="name on card" class="gds-input-field__input" type="text" placeholder="Name" maxlength="45"
                        autocomplete="cc-name" ref="focuser3" />
                      <div v-if="errors.first('name on card', 'page3OldCC')" class="validation-error">{{
                          errors.first("name on card", "page3OldCC")
                      }}</div>
                    </label>
                  </fieldset>
                  <div
                    class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-l">
                    <fieldset class="bill-pay-slat__card-number gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Card Number</span>
                        <div class="bill-pay__credit-card-number-last-four gds-flex-container gds-flex-container--left">
                          <span class="bill-pay__credit-card-prefix gds-text">
                            <svg class="bill-pay__icon gds-icon">
                              <use
                                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                            </svg>
                          </span>
                          <span class="disabled-cardnumber">************{{ selectedWallet.debitAccount }}</span>
                        </div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Expiration Date</span>
                        <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                          v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                          key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                          autocomplete="cc-exp" />
                        <div v-if="errors.first('expiration date', 'page3OldCC')" class="validation-error">{{
                            errors.first("expiration date", "page3OldCC")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">CVV</span>
                        <input v-model="tempCVV" v-mask="'####'" v-validate="'required|numeric|min:3|max:4'" name="CVV"
                          key="CVV" class="gds-input-field__input" type="tel" maxlength="4" autocomplete="cc-csc" />
                        <div v-if="errors.first('CVV', 'page3OldCC')" class="validation-error">{{ errors.first("CVV",
                            "page3OldCC")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Billing ZIP Code</span>
                        <input v-model="debitZip" v-mask="'#####'" v-validate="'required|numeric|min:5|max:5'"
                          name="billing zip" key="billing zip" class="bill-pay-slat__small-input gds-input-field__input"
                          type="tel" maxlength="5" />
                      </label>
                      <div v-if="errors.first('billing zip', 'page3OldCC')" class="validation-error">{{
                          errors.first("billing zip", "page3OldCC")
                      }}</div>
                    </fieldset>
                  </div>
                </form>
                <div v-if="editCCError" class="form-message-box gds-space-stack-l">{{ editCCError }}</div>
                <button @click="CancelOrBack()"
                  class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel Editing</button>
                <button @click="saveOldCredit()" :disabled="pendingSaveOldCredit"
                  :class="{ 'gds-loading': pendingSaveOldCredit }" type="submit"
                  class="bill-pay__paired-button gds-button gds-compact">
                  <span class="gds-button__text">Save and Continue</span>
                </button>
              </div>

              <!-- SLAT 3 EDIT SCREEN FOR EXISTING ATM  -->
              <div v-if="selectedWallet && !selectedWallet.isNew && selectedWallet.paymentType === 'ATM'">
                <form data-vv-scope="page3OldATM" @submit.prevent>
                  <div class="bill-pay__existing-payment-description gds-space-stack-l">Edit the details for your saved
                    ATM card.</div>
                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Payment method nickname</span>
                      <input v-model="selectedWallet.accountNickName" v-validate="'required|max:45'"
                        name="account nickname" key="account nickname" class="gds-input-field__input" type="text"
                        placeholder="Account Nickname" maxlength="45" />
                    </label>
                  </fieldset>

                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Name on Card</span>
                      <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                        key="name on card" class="gds-input-field__input" type="text" placeholder="Name" maxlength="45"
                        autocomplete="cc-name" ref="focuser3" />
                      <div v-if="errors.first('name on card', 'page3OldATM')" class="validation-error">{{
                          errors.first("name on card", "page3OldATM")
                      }}</div>
                    </label>
                  </fieldset>
                  <div
                    class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-l">
                    <fieldset class="bill-pay-slat__card-number gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Card Number</span>
                        <div class="bill-pay__credit-card-number-last-four gds-flex-container gds-flex-container--left">
                          <span class="bill-pay__credit-card-prefix gds-text">
                            <svg class="bill-pay__icon gds-icon">
                              <use
                                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                            </svg>
                          </span>
                          <span class="disabled-cardnumber">************{{ selectedWallet.debitAccount }}</span>
                        </div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Expiration Date</span>
                        <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                          v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                          key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                          autocomplete="cc-exp" />
                        <div v-if="errors.first('expiration date', 'page3OldATM')" class="validation-error">{{
                            errors.first("expiration date", "page3OldATM")
                        }}</div>
                      </label>
                    </fieldset>
                  </div>
                </form>
                <div v-if="editATMError" class="form-message-box gds-space-stack-l">{{ editATMError }}</div>
                <button @click="gotoPage(4)" class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel
                  Editing</button>
                <button @click="saveOldATM()" :disabled="pendingSaveOldATM"
                  :class="{ 'gds-loading': pendingSaveOldATM }" type="submit"
                  class="bill-pay__paired-button gds-button gds-compact">
                  <span class="gds-button__text">Save and Continue</span>
                </button>
              </div>

              <!-- EDIT SCREEN FOR EXISTING ACH ACCOUNT  -->
              <div v-if="selectedWallet && !selectedWallet.isNew && selectedWallet.paymentType === 'ACH'">
                <form data-vv-scope="page3OldACH" @submit.prevent>
                  <div v-if="!selectedWallet.isDebitAccountUnauthorized"
                    class="bill-pay__existing-payment-description gds-space-stack-l">Edit the details for your saved
                    bank account.</div>
                  <div v-if="selectedWallet.isDebitAccountUnauthorized" class="form-message-box gds-space-stack-l">
                    Please re-enter the information for this bank account.</div>
                  <div class="bill-pay-slat__inner-row gds-space-stack-l">
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Payment Method Nickname</span>
                        <input v-model="selectedWallet.accountNickName" v-validate="'required|max:45'"
                          name="account nickname" key="account nickname" class="gds-input-field__input" type="text"
                          placeholder="Account Nickname" maxlength="45" />
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Type</span>
                        <select v-model="selectedWallet.transType" v-validate="'required'" name="account type"
                          key="account type" class="bill-pay-input-field__select gds-input-field" id="account-type">
                          <option value="SAVINGS">Savings</option>
                          <option value="CHECKING">Checking</option>
                          <option value="MONEY_MARKET">Money Market</option>
                        </select>
                        <div v-if="errors.first('account type', 'page3OldACH')" class="validation-error">{{
                            errors.first("account type", "page3OldACH")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Number</span>
                        <input v-model="selectedWallet.debitAccount" v-mask="'######################'"
                          v-validate="'required|numeric|min:5|max:22'" name="account number" key="account number"
                          :placeholder="selectedWallet.debitAccount ? ('******' + selectedWallet.debitAccount) : ''"
                          class="gds-input-field__input" type="text" maxlength="22" ref="focuser3" />
                        <div v-if="errors.first('account number', 'page3OldACH')" class="validation-error">{{
                            errors.first("account number", "page3OldACH")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Routing Number</span>
                        <input v-model="selectedWallet.debitRouting" v-mask="'#########'"
                          v-validate="'required|numeric|min:9'" name="routing number" key="routing number"
                          class="gds-input-field__input" type="text" maxlength="9" />
                        <div v-if="errors.first('routing number', 'page3OldACH')" class="validation-error">{{
                            errors.first("routing number", "page3OldACH")
                        }}</div>
                      </label>
                    </fieldset>
                  </div>
                </form>
                <div v-if="editACHError" class="form-message-box gds-space-stack-l">{{ editACHError }}</div>
                <button @click="CancelOrBack()"
                  class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel Editing</button>
                <button @click="saveOldACH()" :disabled="pendingSaveOldACH"
                  :class="{ 'gds-loading': pendingSaveOldACH }" type="submit"
                  class="bill-pay__paired-button gds-button gds-compact">
                  <span class="gds-button__text">Save and Continue</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 -->
      <section id="pay-slat-4" class="review-submit bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div class="bill-pay-slat__number-with-circle">
                <span>4</span>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Review &amp; Submit</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage < 4" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s">GMP Account</div>
                <div class="gds-body-small gds-space-stack-l">
                  #{{ currentAccount.accountNumber }}
                  <br />
                  {{ currentAccount.address | addressFormat({ separator: ", " }) }}
                </div>

                <div v-if="selectedWallet" class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Payment Method</div>
                  <div class="gds-body-small gds-space-stack-s">{{ (selectedWallet.isNew && this.savePayment) ?
                      this.paymentName : selectedWallet.accountNickName
                  }}</div>
                  <div class="gds-body-small gds-space-stack-s">{{ selectedWallet | walletSlug }}</div>
                </div>

                <div class="gds-display-00 gds-space-stack-s">Amount</div>
                <div class="gds-body-small gds-space-stack-l">{{ paymentAmount | currency }}</div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Payment Date</div>
                  <div v-if="!permitFutureDate" class="gds-body-small gds-space-stack-l">
                    Payment will be processed immediately, but may take up to 2 days to appear in your account.
                    <br />Scheduling the payment for a future date is not available for this account at this time.
                  </div>
                  <div v-else-if="permitFutureDate && isScheduled && scheduledDate"
                    class="gds-body-small gds-space-stack-l">{{ scheduledDate | dateFull }}</div>
                  <div v-else class="gds-body-small gds-space-stack-l">{{ todayDate | dateFull }}</div>
                </div>
                <div v-if="!isRecurringPaymentsEnrolled" class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Enroll in Recurring Auto Payments?</div>
                  <div v-if="enrollInRecurringPayments" class="gds-body-small gds-space-stack-l">Yes. Future balances will be paid 5 days before the due date using the selected payment method.</div>
                  <div v-else class="gds-body-small gds-space-stack-l">Not Enrolled <a @click="turnOnRecurring" class="cursorPointer">Change</a></div>
                </div>
                <div class="gds-body-small gds-space-stack-l">
                  <form data-vv-scope="page4" @submit.prevent>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Payment Confirmation Email Address</span>
                        <input v-model="altEmailAddress" name="email address" key="email address"
                          v-validate="'required|email|max:74'" maxlength="74" class="gds-input-field__input"
                          type="email" ref="focuser4" />
                      </label>
                      <div v-if="errors.first('email address', 'page4')" class="validation-error">{{ errors.first("email address", "page4") }}</div>
                    </fieldset>
                  </form>
                </div>
                <div class="gds-body-small gds-space-stack-l">
                  By submitting a payment you agree to the
                  <a href="https://www.speedpay.com/terms" target="_blank">Payment Terms &amp; Conditions.</a>
                </div>
                <div v-if="makePaymentError" class="form-message-box gds-space-stack-l">{{ makePaymentError }}</div>
                <button @click="submitPayment()" :disabled="pendingSubmitPayment"
                  :class="{ 'gds-loading': pendingSubmitPayment }" type="submit" class="gds-button gds-compact">
                  <span class="gds-button__text">Submit Payment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- #main -->
</template>

<script>
import { ErrorBag } from "vee-validate";
import { format, addMonths, addDays } from "date-fns";
import Datepicker from "vuejs-datepicker";
import GMPAPI from "../../services/gmpapi";
import { GetCardType, ToFullExpirationDate } from "../../utilities";
import WalletSelector from "../generic/WalletSelector";
import { DumpError } from "../../utilities";
import BannerOneAction from "../BannerOneAction";
import WidgetScheduledPayments from "../myaccount/billingpayment/WidgetScheduledPayments.vue";
import { GMPPhoneNumber } from "../../environment";
import ModalCCPaymentInfo from "./ModalCCPaymentInfo.vue";

export default {
  name: "BillPayFlowLoggedIn",
  components: {
    Datepicker,
    WalletSelector,
    WidgetScheduledPayments,
    BannerOneAction,
    ModalCCPaymentInfo
  },
  data() {
    return {
      userWallets: [],
      selectedWallet: undefined,
      savePayment: true,
      paymentName: "",
      editClicked: false,
      isKeyboarding: false,

      showPastDueWizardBanner: undefined,

      paymentAmount: 0,
      // Default to tomorrow
      scheduledDate: addDays(new Date(), 1),

      // Keep these separate since they use the same field
      creditNumber: "",
      bankNumber: "",

      tempCVV: "",
      debitZip: undefined,
      tokenizedCard: undefined,

      todayDate: new Date(),
      firstValidCard: format(addMonths(new Date(), -1), "MM/yy"),

      paymentMeta: {
        minACH: 1,
        maxACH: 99999.99,
        minCC: 10,
        maxCC: 2500
      },

      maxPaymentAmount: undefined,

      tooManyCCPayments: false,
      permitFutureDate: false,
      maxFutureDate: undefined,
      isScheduled: false,

      billPaySuccess: undefined,
      isRecurringPaymentsEnrolled: false,
      enrollInRecurringPayments: false,


      editACHError: undefined,
      editATMError: undefined,
      editCCError: undefined,
      makePaymentError: undefined,

      pendingSubmitPayment: false,
      pendingSaveOldCredit: false,
      pendingSaveOldATM: false,
      pendingSaveOldACH: false,

      loadState: undefined,
      statusLoading: undefined,
      altEmailAddress: undefined,
      isSixtyDaysPastDue: undefined,
      pastDueBalance: 0,

      ShowModalCCPaymentInfo: false,
      comingFromRecurringChange: false,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentUser() {
      return this.$store.state.user;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    pastDue() {
      return new Date(this.currentAccount.amountDueDate) < new Date();
    },
    amountDue() {
      return this.currentAccount.amountDue;
    },
    disabledDates() {
      return {
        // Everything today and before
        to: new Date(),
        // Everything after API mandated max
        from: this.maxFutureDate,
      };
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },
  async mounted() {
    const paymentMeta = await GMPAPI.GetPaymentMeta();
    if (paymentMeta && paymentMeta.minCC) {
      this.paymentMeta = paymentMeta;
    }

    this.statusLoading = true;
    this.pendingPayments = 0;
    this.scheduledPayments = 0;
    const accountNumber = this.currentAccount.accountNumber;
    const status = await GMPAPI.GetAccountStatus(accountNumber);
    if (status && (status.pastDue30Balance || status.pastDue60Balance)) {
      this.pastDueBalance = status.pastDue30Balance + status.pastDue60Balance;
      this.isSixtyDaysPastDue = status.pastDue60;
    }
    this.statusLoading = undefined;

    if (this.amountDue > 0) {
      this.paymentAmount = this.amountDue;
    } else {
      this.paymentAmount = 0;
    }

    const accountDetails = this.$store.state.user.userinfo;
    if (this.isAdmin) {
      this.altEmailAddress = "";
    } else {
      this.altEmailAddress = accountDetails.email;
    }

    if (this.currentAccount.isStopped === true) {
      this.maxPaymentAmount = this.amountDue;
    } else {
      this.maxPaymentAmount = this.paymentMeta.maxACH;
    }

    // Kick off async fetches
    try {
      this.loadState = undefined;
      await Promise.all([
        this.handlePastDueWizardBannerDisplay(),
        this.fetchUserWallets(),
        this.fetchCCPayments(),
        this.fetchScheduleAllowed(),
        this.fetchRecurringStatus()
      ]);
      this.loadState = "complete";
    } catch (err) {
      DumpError("Billpay loggedin load error", err);
      this.loadState = "error";
    }
  },
  methods: {
    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/pay/" + page });
      if (!this.comingFromRecurringChange) {
        this.slatFocus(page);
      }
    },
    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        const focuser = this.$refs["focuser" + page];
        if (focuser && focuser.$el) {
          if (focuser.$el.focus) focuser.$el.focus();
        } else if (focuser && Array.isArray(focuser) && focuser.length >= 1) {
          // Page 2 has array of wallets
          if (focuser[0].focus) focuser[0].focus();
        } else if (focuser) {
          if (focuser.focus) focuser.focus();
        }
      }, 100);
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      this.comingFromRecurringChange = false;
      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            // If under $10 (or too many CC payments), select an ACH wallet
            if (
              this.selectedWallet &&
              (this.selectedWallet.paymentType === "CC" || this.selectedWallet.paymentType === "ATM") &&
              (this.tooManyCCPayments ||
                this.paymentAmount < this.paymentMeta.minCC ||
                this.paymentAmount > this.paymentMeta.maxCC)
            ) {
              if (this.userWallets) {
                const firstACH = this.userWallets.find(
                  item => item.paymentType === "ACH"
                );
                if (firstACH) {
                  this.selectedWallet = firstACH;
                }
              }
            }
            break;
          case "page3VerifyCC":
            this.selectedWallet.cvv = this.tempCVV;
            this.selectedWallet.zip = this.debitZip;
            this.tempCVV = "";
            break;
          case "page3NewCC":
            // First make sure the nickname is unique
            if (this.selectedNicknameIsDuplicate()) {
              // Duplicate nickname, bail!
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "payment method name", msg: "The payment method nickname entered is already in use by another payment method. Please change the nickname.", scope: "page3NewCC" });
              return;
            }
            // If they've entered or altered the CC number, validate and tokenize
            if (this.creditNumber) {
              const cardType = GetCardType(this.creditNumber);
              if (!cardType) {
                // Unknown card type, bail!
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page3NewCC" });
                return;
              }
              this.selectedWallet.cardType = cardType;
              try {
                this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
                  this.paymentMeta.tokenUrl,
                  this.creditNumber
                );
                this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
                this.creditNumber = "";
              } catch (err) {
                // Bad response from tokenizer
                DumpError("New CC next tokenize error", err);
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page3NewCC" });
                return;
              }
              this.selectedWallet.cvv = this.tempCVV;
              this.selectedWallet.zip = this.debitZip;
              this.tempCVV = "";
            }
            break;
          case "page3NewATM":
            // First make sure the nickname is unique
            if (this.selectedNicknameIsDuplicate()) {
              // Duplicate nickname, bail!
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "payment method name", msg: "The payment method nickname entered is already in use by another payment method. Please change the nickname.", scope: "page3NewATM" });
              return;
            }
            // If they've entered or altered the CC number, validate and tokenize
            if (this.creditNumber) {
              const cardType = GetCardType(this.creditNumber);
              if (!cardType) {
                // Unknown card type, bail!
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page3NewATM" });
                return;
              }
              this.selectedWallet.cardType = cardType;
              try {
                this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
                  this.paymentMeta.tokenUrl,
                  this.creditNumber
                );
                this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
                this.creditNumber = "";
              } catch (err) {
                // Bad response from tokenizer
                DumpError("New CC next tokenize error", err);
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page3NewATM" });
                return;
              }
            }
            break;
          case "page3NewACH":
            // First make sure the nickname is unique
            if (this.selectedNicknameIsDuplicate()) {
              // Duplicate nickname, bail!
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "payment method name", msg: "The payment method nickname entered is already in use by another payment method. Please change the nickname.", scope: "page3NewACH" });
              return;
            }
        }
        // If old ACH or ATM, skip step 3
        if (
          nextPage === 3 &&
          (this.selectedWallet.paymentType === "ACH" || this.selectedWallet.paymentType === "ATM") &&
          !this.selectedWallet.isNew && !this.selectedWallet.isExpired && !this.selectedWallet.isDebitAccountUnauthorized
        ) {
          nextPage = 4;
        }
        if (this.selectedWallet.isExpired || this.selectedWallet.isDebitAccountUnauthorized) {
          this.editClicked = true;
          // Clear bank numbers
          if (this.selectedWallet.isDebitAccountUnauthorized && this.selectedWallet.debitRouting) {
            this.selectedWallet.debitAccount = undefined;
            this.selectedWallet.debitRouting = undefined;
          }
        } else {
          this.editClicked = false;
        }
        this.makePaymentError = undefined;
        this.gotoPage(nextPage);
      }
    },
    CancelOrBack() {
      // If this is an invalid payment method, go back to page 2 on cancel
      if (this.selectedWallet.isExpired || this.selectedWallet.isDebitAccountUnauthorized) {
        this.gotoPage(2);
      } else {
        if (this.selectedWallet.paymentType === "ACH") {
          // Bank cancel goes to page 4
          this.gotoPage(4);
        } else {
          // Credit cancel goes to same page
          this.editClicked = false;
          this.slatFocus(3);
        }
      }
    },
    selectedNicknameIsDuplicate() {
      const matcher = this.paymentName.toLowerCase();
      for (const wallet of this.userWallets) {
        if (wallet.accountNickName.toLowerCase() === matcher) return true;
      }
      return false;
    },
    async fetchUserWallets() {
      const wallets = await GMPAPI.GetWallets(this.currentAccount.accountNumber, true);
      // Add "new" options
      wallets.push({
        isNew: "credit",
        paymentType: "CC",
        accountNickName: "New Credit / Debit Card",
        transType: "CARD",
      });
      wallets.push({
        isNew: "atm",
        paymentType: "ATM",
        accountNickName: "New ATM Card",
        transType: "CARD",
      });
      wallets.push({
        isNew: "ach",
        paymentType: "ACH",
        accountNickName: "New Bank Account",
        transType: "CHECKING",
      });
      this.userWallets = wallets;
      // Initialize selected to first
      this.selectedWallet = wallets[0];
    },
    async fetchScheduleAllowed() {
      try {
        const allowed = await GMPAPI.GetScheduleAllowed(this.currentAccount.accountNumber);
        this.permitFutureDate = allowed.permitFutureDate;
        this.maxFutureDate = new Date(allowed.maxFutureDate);
      } catch {
        // If we error, treat it as not allowed
        this.permitFutureDate = false;
        this.maxFutureDate = undefined;
      }
    },
    async fetchCCPayments() {
      let payments = [];
      payments = await GMPAPI.GetBillingHistory(this.currentAccount.accountNumber, 30);
      const ccpays = payments.filter(item => (item.paymentType === "CC" || item.paymentType === "ATM") && (item.status === "PROCESSED" || item.status === "PENDING" || item.status === "SCHEDULED"));
      if (ccpays.length >= 2) {
        // 2 or more cc/atm payments this month, disable CC pay
        this.tooManyCCPayments = true;
      } else {
        this.tooManyCCPayments = false;
      }
    },
    async fetchRecurringStatus() {
      const recurringPaymentStatus = await GMPAPI.GetRecurring(this.currentAccount.accountNumber);
      if (recurringPaymentStatus.length === 0) {
        const legacyRecurringPaymentInfo = await this.GetBillingStatus();
        if (legacyRecurringPaymentInfo.legacyAutoPayEnrolled) {
          this.isRecurringPaymentsEnrolled = true;
          return;
        }
      } else {
        this.isRecurringPaymentsEnrolled = true;
        return;
      }
    },
    async GetBillingStatus() {
      const billingStatus = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      return billingStatus;
    },
    async saveOldCredit() {
      this.editCCError = undefined;
      if (await this.$validator.validateAll("page3OldCC")) {
        // If they've entered or altered the CC number, validate and tokenize
        // Currently this should never run since it's disabled
        if (this.creditNumber) {
          const cardType = GetCardType(this.creditNumber);
          if (!cardType) {
            // Unknown card type, bail!
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page3OldCC" });
            return;
          }
          this.selectedWallet.cardType = cardType;
          try {
            this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
              this.paymentMeta.tokenUrl,
              this.creditNumber
            );
            this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
            this.creditNumber = "";
          } catch (err) {
            // Bad response from tokenizer
            DumpError("Save credit tokenize error", err);
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page3OldCC" });
            return;
          }
        }
        this.selectedWallet.cvv = this.tempCVV;
        this.tempCVV = "";
        this.pendingSaveOldCredit = true;
        try {
          const wallet = this.selectedWallet;
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitAccount: this.tokenizedCard || ("*" + wallet.debitAccount),
            expirationDate: ToFullExpirationDate(wallet.expirationDate),
            cvv: wallet.cvv,
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            cardType: wallet.cardType,
            accountNickName: wallet.accountNickName,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.debitZip || this.currentAccount.mailingAddress.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          };
          await GMPAPI.UpdateWallet(
            this.currentAccount.accountNumber,
            walletRecord,
            wallet.walletId
          );
          this.editClicked = false;
          this.gotoPage(4);
        } catch (err) {
          DumpError("Save credit error", err);
          if (err.response && err.response.data) {
            if (err.response.data.message) {
              console.error(err.response.data.message);
            }
            const code = err.response.data.code;
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            // Take specific actions on certain error codes here
            switch (code) {
              case 1001:
                this.errors.add({ field: "card number", msg: "That card was declined.", scope: "page3OldCC" });
                break;
              // CVV
              case 18033:
                this.errors.add({ field: "CVV", msg: "The CVV was incorrect.", scope: "page3OldCC" });
                break;
              // Expiration date
              case 18015:
              case 18016:
                this.errors.add({ field: "expiration date", msg: "The expiration date was incorrect.", scope: "page3OldCC" });
                break;
              default:
                // Not an error we expected, so show the big red generic message
                this.editCCError = "There was an unexpected error saving your card.";
            }
          } else {
            this.editCCError = "There was an unexpected error saving your card.";
          }
        } finally {
          this.pendingSaveOldCredit = false;
        }
      }
    },
    async saveOldATM() {
      this.editATMError = undefined;
      if (await this.$validator.validateAll("page3OldATM")) {
        // If they've entered or altered the CC number, validate and tokenize
        // Currently this should never run since it's disabled
        if (this.creditNumber) {
          const cardType = GetCardType(this.creditNumber);
          if (!cardType) {
            // Unknown card type, bail!
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page3OldATM" });
            return;
          }
          this.selectedWallet.cardType = cardType;
          try {
            this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
              this.paymentMeta.tokenUrl,
              this.creditNumber
            );
            this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
            this.creditNumber = "";
          } catch (err) {
            // Bad response from tokenizer
            DumpError("Save credit tokenize error", err);
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page3OldATM" });
            return;
          }
        }
        this.pendingSaveOldATM = true;
        try {
          const wallet = this.selectedWallet;
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitAccount: this.tokenizedCard || ("*" + wallet.debitAccount),
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            cardType: wallet.cardType,
            accountNickName: wallet.accountNickName,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          };
          await GMPAPI.UpdateWallet(
            this.currentAccount.accountNumber,
            walletRecord,
            wallet.walletId
          );
          this.editClicked = false;
          this.gotoPage(4);
        } catch (err) {
          DumpError("Save atm error", err);
          if (err.response && err.response.data) {
            if (err.response.data.message) {
              console.error(err.response.data.message);
            }
            const code = err.response.data.code;
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            // Take specific actions on certain error codes here
            switch (code) {
              case 1001:
                this.errors.add({ field: "card number", msg: "That card was declined.", scope: "page3OldATM" });
                break;
              default:
                // Not an error we expected, so show the big red generic message
                this.editATMError = "There was an unexpected error saving your card.";
            }
          } else {
            this.editATMError = "There was an unexpected error saving your card.";
          }
        } finally {
          this.pendingSaveOldATM = false;
        }
      }
    },
    async saveOldACH() {
      this.editACHError = undefined;
      if (await this.$validator.validateAll("page3OldACH")) {
        this.pendingSaveOldACH = true;
        try {
          const wallet = this.selectedWallet;
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitRouting: wallet.debitRouting,
            debitAccount: wallet.debitAccount,
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          };
          this.selectedWallet.lastFourDigitDebitAccount = this.selectedWallet.debitAccount.slice(-4);
          await GMPAPI.UpdateWallet(
            this.currentAccount.accountNumber,
            walletRecord,
            wallet.walletId
          );
          this.gotoPage(4);
        } catch (err) {
          DumpError("Save ACH error", err);
          if (err.response && err.response.data) {
            if (err.response.data.message) {
              console.error(err.response.data.message);
            }
            const code = err.response.data.code;
            // Make sure we have a fresh ErrorBag for handling special cases
            if (!this.errors) this.errors = new ErrorBag();
            // Take specific actions on certain error codes here
            switch (code) {
              // Account number
              case 18055:
              case 4533006:
                this.errors.add({ field: "account number", msg: "The account number was incorrect.", scope: "page3OldACH" });
                break;
              // Routing number
              case 18056:
              case 4533005:
                this.errors.add({ field: "routing number", msg: "The routing number was incorrect.", scope: "page3OldACH" });
                break;
              case 4533003:
              case 4533004:
                this.errors.add({ field: "account number", msg: `There is a problem with this account number, please select another account or call ${GMPPhoneNumber || '1-(888)-835-4672'} Monday through Friday, 9am - 6pm.`, scope: "page3OldACH" });
                break;
              default:
                // Not an error we expected, so show the big red generic message
                this.editACHError = "There was an unexpected error saving your bank account.";
            }
          } else {
            this.editACHError = "There was an unexpected error saving your bank account.";
          }
        } finally {
          // Either way, turn off the loading indicator
          this.pendingSaveOldACH = false;
        }
      }
    },
    async setRecurring() {
      // Set Recurring Payments
      const userinfo = this.$store.state.user.userinfo;
      const customerInformation = {
        // debitName: this.selectedWallet.debitName || userinfo.fullName,
        debitFirstName: userinfo.givenName,
        debitLastName: userinfo.surname,
        // Account
        creditFirstname: userinfo.givenName,
        creditLastname: userinfo.surname,
        phone: userinfo.phone,
        // Populate all location fields from account, should be required but ignored
        debitAddress1: this.currentAccount.address.street1,
        debitCity: this.currentAccount.address.city,
        debitState: this.currentAccount.address.state,
        debitZip: this.debitZip || this.currentAccount.address.zip,
        creditCity: this.currentAccount.address.city,
        creditState: this.currentAccount.address.state,
        creditZip: this.currentAccount.address.zip,
      };
      let debitInformation;
      if (this.selectedWallet.paymentType === "CC") {
        debitInformation = { cvv: this.selectedWallet.cvv };
      }
      try {
        await GMPAPI.SetRecurring(this.currentAccount.accountNumber, this.selectedWallet.walletId, this.altEmailAddress ? this.altEmailAddress : accountDetails.email, customerInformation, debitInformation);
        this.recurringPaymentSuccess = true;
      } catch (error) {
        this.recurringPaymentSuccess = false;
      }
    },
    async submitPayment() {
      if (!await this.$validator.validateAll("page4")) return;
      this.makePaymentError = undefined;
      this.pendingSubmitPayment = true;
      try {
        const accountDetails = this.$store.state.user.userinfo;
        const wallet = this.selectedWallet;
        if (this.savePayment && wallet.isNew) {
          const walletRecord = {
            paymentType: wallet.paymentType,
            debitRouting: wallet.debitRouting,
            debitAccount: wallet.isNew === "credit" || wallet.isNew === "atm" ? this.tokenizedCard : this.bankNumber,
            expirationDate: wallet.expirationDate ? ToFullExpirationDate(wallet.expirationDate) : undefined,
            cvv: wallet.cvv || "000",
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            cardType: wallet.cardType,
            accountNickName: wallet.isNew ? this.paymentName : this.accountNickName,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.debitZip || this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          };
          const walletId = await GMPAPI.CreateWallet(
            this.currentAccount.accountNumber,
            walletRecord
          );
          this.selectedWallet.walletId = walletId;
        }

        const paymentRecord = {
          customerInformation: {
            // Payment
            // If no debitName (saved payment), fill in account name to satisfy GMP api
            debitName: wallet.debitName || accountDetails.fullName,
            // Account
            creditFirstname: accountDetails.givenName,
            creditLastname: accountDetails.surname,
            phone: accountDetails.phone,
            creditAccount: this.currentAccount.accountNumber,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.debitZip || this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          },
          debitInformation: {
            paymentType: wallet.paymentType,
            debitRouting: wallet.debitRouting,
            // If new credit/atm, tokenized. If new bank, bankNumber. If old, * + last four digits
            debitAccount: wallet.isNew ? ((wallet.isNew === "credit" || wallet.isNew === "atm") ? this.tokenizedCard : this.bankNumber) : "*" + wallet.debitAccount,
            // Note, speedpay docs say they want MM/YYYY but GMP api expects full ISO
            // Only convert if we have the shorthand input date, otherwise leave alone
            expDate:
              wallet.expirationDate && wallet.expirationDate.length < 8
                ? ToFullExpirationDate(wallet.expirationDate)
                : wallet.expirationDate,
            cvv: wallet.cvv || "000",
            // NOTE alternate casing of transType
            transtype: wallet.transType,
            // If CC can process as ATM, do it
            skipCcOverAtm: false,
            // Pull existing wallet if not new
            retrieveWallet: !!wallet.walletId,
          },
          paymentInformation: {
            emailAddress: this.altEmailAddress ? this.altEmailAddress : accountDetails.email,
            paymentAmount: this.paymentAmount,
          },
        };

        if (this.permitFutureDate && this.isScheduled && this.scheduledDate) {
          paymentRecord.paymentInformation.scheduledPaymentDate = this.scheduledDate.toISOString();
        }
        const paymentResult = await GMPAPI.MakePayment(this.currentAccount.accountNumber, paymentRecord);
        this.$store.commit("setPaymentAmount", this.paymentAmount);
        this.$store.commit("setConfirmationNumber", paymentResult.paymentId);
        this.$store.commit("setFuturePayment", (this.permitFutureDate && this.isScheduled && this.scheduledDate) ? this.scheduledDate.toISOString() : undefined);
        // Update account data (pending payments)
        await this.$store.dispatch("FetchUserInfo");
        if (!this.makePaymentError && this.enrollInRecurringPayments) {
          try {
            if (this.selectedWallet.walletId) {
              await this.setRecurring();
            } else {
              this.recurringPaymentSuccess = false;
            }

            // Route here with props to say recurring has been set up
            // this.$router.push({ path: "/complete" });
            this.$router.push({
              name: "complete", params: {
                enrollInRecurringPayments: this.enrollInRecurringPayments,
                isRecurringPayments: true,
                recurringPaymentSuccess: this.recurringPaymentSuccess,
                currentAccount: this.currentAccount
              }
            });
          } catch (error) {
            // Route here with props to say recurring has failed but payment was successful
            this.$router.push({
              name: "complete", params: {
                enrollInRecurringPayments: this.enrollInRecurringPayments,
                isRecurringPayments: true,
                recurringPaymentSuccess: false,
                currentAccount: this.currentAccount
              }
            });
          }
        } else {
          this.$router.push({
            name: "complete", params: {
              enrollInRecurringPayments: false,
              isRecurringPayments: false,
              currentAccount: this.currentAccount
            }
          });
        }
      } catch (err) {
        DumpError("Submit payment loggedin error", err);
        if (err.response && err.response.data) {
          if (err.response.data.message) {
            console.error(err.response.data.message);
          }
          const code = err.response.data.code;
          // Make sure we have a fresh ErrorBag for handling special cases
          if (!this.errors) this.errors = new ErrorBag();
          // Take specific actions on certain error codes here
          switch (code) {
            // CVV
            case 1006:
              this.errors.add({ field: "CVV", msg: "The CVV was incorrect.", scope: "page3NewCC" });
              this.errors.add({ field: "CVV", msg: "The CVV was incorrect.", scope: "page3OldCC" });
              this.gotoPage(3);
              break;
            // Expiration date
            case 1012:
              if (this.selectedWallet.isNew) {
                this.errors.add({ field: "expiration date", msg: "The expiration date was incorrect.", scope: "page3NewCC" });
                this.gotoPage(3);
              } else {
                this.editClicked = true;
                this.errors.add({ field: "expiration date", msg: "The expiration date was incorrect.", scope: "page3OldCC" });
                this.gotoPage(3);
              }
              break;
            // CC Mod 10
            case 3342:
            case 18077:
              this.errors.add({ field: "card number", msg: "The card number was incorrect.", scope: "page3NewCC" });
              this.errors.add({ field: "card number", msg: "The card number was incorrect.", scope: "page3NewATM" });
              this.gotoPage(3);
              break;
            // Routing number
            case 2517:
            case 4330:
            case 4533005:
              this.errors.add({ field: "routing number", msg: "The routing number was incorrect.", scope: "page3NewACH" });
              this.gotoPage(3);
              break;
            // Account number
            case 2518:
            case 4533006:
              this.errors.add({ field: "account number", msg: "The account number was incorrect.", scope: "page3NewACH" });
              this.gotoPage(3);
              break;
            case 4533003:
            case 4533004:
              if (this.selectedWallet.isNew) {
                this.errors.add({ field: "account number", msg: `There is a problem with this account, please select another account or call ${GMPPhoneNumber || '1-(888)-835-4672'} Monday through Friday, 9am - 6pm.`, scope: "page3NewACH" });
                this.gotoPage(3);
              } else {
                this.makePaymentError = `There is a problem with this account number or routing number, please select another account or call ${GMPPhoneNumber || '1-(888)-835-4672'} Monday through Friday, 9am - 6pm.`;
              }
              break;
            // The following are non-fatal errors, but don't redirect to a particular field

            // Declined
            case 1001:
            // ATM Declined
            case 9002:
              this.makePaymentError = "The card was declined.";
              break;
            // Duplicate
            case 3307:
              this.makePaymentError = "This payment appears to be a duplicate! You recently paid the exact same amount on this account. To prevent you from accidentally paying twice, it has NOT been processed. If you’d still like to make a payment on this account, change the amount and submit it again.";
              break;
            // More than 2 payments / month
            case 3803:
              this.makePaymentError = "GMP allows two credit card payments per account per month. If you would like to make additional payments on your account this month, please use your checking account.";
              break;

            // Any other error code gets redirected to the "fatal" error page
            default:
              this.$router.push({
              name: "fatalerror", params: {
                code: code,
                isRecurringPayments: this.enrollInRecurringPayments,
              }
            });
              break;
          }
        } else {
          this.$router.push({
              name: "fatalerror", params: {
                code: code,
                isRecurringPayments: this.enrollInRecurringPayments,
              }
          });
        }
      } finally {
        // Either way, turn off the loading indicator
        this.pendingSubmitPayment = false;
      }
    },
    async handlePastDueWizardBannerDisplay() {
      if (this.isSixtyDaysPastDue) {
        const paymentArrangementStatus = await GMPAPI.GetPaymentArrangement(this.currentAccount.accountNumber);
        this.showPastDueWizardBanner = paymentArrangementStatus.isEligible;
      } else {
        this.showPastDueWizardBanner = false;
      }
    },
    showModalCCPaymentInfo() {
      this.ShowModalCCPaymentInfo = true;
    },
    closeModal() {
      this.ShowModalCCPaymentInfo = false;
    },
    turnOnRecurring() {
      this.enrollInRecurringPayments = true;
      this.comingFromRecurringChange = true;
      this.gotoPage(2);
      setTimeout(() => {
        window.scrollTo({ top: this.$refs["recurring-focuser"].offsetTop, behavior: 'smooth' });
      }, 100);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
