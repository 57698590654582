<template>
  <span>
    <button :disabled="disabled" @click="goBack()" class="date-navigation__button">
      <svg class="gds-icon black">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonBack",
  props: {
    goBack: Function,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>