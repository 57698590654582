var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__table-container" }, [
    _vm.loadState === undefined
      ? _c("div", { staticClass: "table-loading" })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "empty"
      ? _c("div", { staticClass: "usage-detail__error-container" }, [
          _vm._v("No daily historical usage data available.\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState == "error"
      ? _c("div", { staticClass: "usage-detail__error-container" }, [
          _vm._v(
            "An unexpected error occurred trying to read\n    daily historical usage data, please try again\n    later."
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c(
          "div",
          { staticClass: "usage-detail-table__hourly-container" },
          [
            _c("div", { staticClass: "usage-detail__hourly-heading-row" }, [
              _c("div", { class: _vm.usageHeadingCellClass }, [_vm._v("Time")]),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent
                ? _c("div", { class: _vm.usageHeadingCellClass }, [
                    _vm._v("Net (kWh)")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: _vm.usageHeadingCellClass }, [
                _vm._v("Usage (kWh)")
              ]),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent
                ? _c("div", { class: _vm.usageHeadingCellClass }, [
                    _vm._v("Generation (kWh)")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: _vm.usageHeadingCellClass }, [
                _vm._v("Temperature")
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.usageHeadingCellClass }, [
                _vm._v("Conditions")
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.hourlyUsageData, function(usage) {
              return _c("div", { key: usage.date.getTime() }, [
                _c("div", { staticClass: "usage-detail__hourly-table-row" }, [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.usageTableCellClass,
                        "usage-detail__hourly-time"
                      ]
                    },
                    [_vm._v(" " + _vm._s(_vm.convertToTimeRange(usage.date)))]
                  ),
                  _vm._v(" "),
                  _vm.hasGenerationRecordsPresent &&
                  !_vm.hasGenerationReturned(usage)
                    ? _c("div", {
                        staticClass: "usage-detail__empty-net-generation-cell"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasGenerationRecordsPresent &&
                  _vm.hasGenerationReturned(usage)
                    ? _c("div", { class: _vm.usageTableCellClass }, [
                        _c(
                          "span",
                          {
                            class: [
                              _vm.returnedGenerationClass,
                              "usage-detail__returned-amount"
                            ]
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.generationReturned(usage.returnedGeneration)
                              ) + " "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasTou(usage) || _vm.hasConsumption(usage)
                    ? _c(
                        "div",
                        { class: _vm.usageTableCellClass },
                        [
                          _c("usage-bar", {
                            attrs: {
                              barType: _vm.determineBarType(usage),
                              consumedAmount: _vm.roundAmountSingleDecimal(
                                usage.consumedTotal
                              ),
                              offPeakAmount: _vm.roundAmountSingleDecimal(
                                usage.offPeak
                              ),
                              onPeakAmount: _vm.roundAmountSingleDecimal(
                                usage.onPeak
                              ),
                              showTouDetails: false,
                              parentContainerSize: _vm.maxContainerSize,
                              usageDate: usage.date,
                              maxUsageValue: _vm.maxUsageValue()
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasGenerationRecordsPresent && !_vm.hasGeneration(usage)
                    ? _c("div", {
                        staticClass:
                          "usage-detail__empty-hourly-generation-cell"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasGeneration(usage)
                    ? _c(
                        "div",
                        { class: _vm.usageTableCellClass },
                        [
                          _c("usage-bar", {
                            attrs: {
                              barType: "generation",
                              generationAmount: usage.generation,
                              parentContainerSize: _vm.maxContainerSize,
                              maxUsageValue: _vm.maxUsageValue()
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { class: _vm.usageTableCellClass }, [
                    _vm._v(
                      _vm._s(
                        _vm.getTemperature(usage).avg
                          ? _vm.getTemperature(usage).avg + "\u00B0F"
                          : ""
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.usageTableCellClass }, [
                    usage.weatherIcon && usage.weatherIcon != ""
                      ? _c("img", {
                          staticClass: "usage-detail__weather-image",
                          attrs: {
                            src:
                              _vm.weatherImagePath + usage.weatherIconImageUrl,
                            alt: "Weather Icon"
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ])
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }