var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass:
          "date-navigation__button date-navigation__button__calendar__text-added",
        style: { width: _vm.buttonWidth || _vm.defaultButtonWidth },
        attrs: { disabled: _vm.disabled, title: "Go to today" },
        on: {
          click: function($event) {
            return _vm.goToToday()
          }
        }
      },
      [
        _c("img", {
          staticClass: "back-to-today-icon enabled",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-back-to-today.svg"
          }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "back-to-today-icon disabled",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-back-to-today-disabled.svg"
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "date-navigation__button__calendar__label" },
          [_vm._v("Go To Today")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }