<template>
  <div>
    <div v-if="incidentStatus !== 'Resolved'" class="gds-space-stack-m"> {{ formatEtr(incident.estimatedRestoredAt, incident.etrEarliest, incident.etrLatest)}}</div>
    <div v-else-if="incident.restoredAt && incidentStatus === 'Resolved'" class="gds-space-stack-m">Power was restored on {{incident.restoredAt | dateRestored}}.</div>
    <div v-else class="gds-space-stack-m">{{ etrText }}</div>
    <div class="pi-graph-bkgr gds-space-stack-s">
      <div v-if="incidentStatus === 'Resolved'" class="pi-section pi-section-full">
        <div slot="" class="pi-section pi-section-half">
          <div class="pi-section-checkmark"></div>
        </div>
        <div class="pi-section-checkmark"></div>
      </div>
      <div v-if="incidentStatus !== 'Resolved'" class="pi-section pi-section-half">
        <div class="pi-section-checkmark"></div>
      </div>
    </div>

    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <div>
        <div class="gds-font-size-s gds-font-demi gds-space-stack-xs">Reported</div>
        <div class="gds-font-size-m gds-font-book gds-text-grey">{{incident.createdAt | dateRestored}}</div>
      </div>
      <div>
        <div :class="{ active: incidentStatus === 'Resolved' }" class="pi-graph__restored gds-font-size-s gds-font-demi gds-space-stack-xs">Restored</div>
        <div v-if="incident.restoredAt" class="gds-font-size-m gds-font-book gds-text-grey">{{incident.restoredAt | dateRestored}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetOutageStatusLabel, FormatCustomerEtr } from "../../utilities";
import { isFeatureEnabled, getConfigValue } from "../../services/featureflags";
import { format, isSameDay, parseISO} from "date-fns";

export default {
  name: "IncidentProgress",
  components: {
  },
  props: ["incident"],
  computed: {
    incidentStatus() {
      return GetOutageStatusLabel(this.incident.status.code);
    },
    isSameDayEstimatedEtr() {
      return this.incident && 
            this.incident.etrEarliest &&
            this.incident.etrEarliest != null &&
            this.incident.etrLatest &&
            this.incident.etrLatest != null &&
            isSameDay(parseISO(this.incident.etrEarliest), parseISO(this.incident.etrLatest));
    },
  },
  data() {
    return {
      useAlternateEtrText: false,
      etrText: undefined,
      etrDefaultText: 'We are assessing this outage to determine an estimated restoration time.',
    };
  },
  async mounted() {
    try {
      this.useAlternateEtrText = await isFeatureEnabled('FF_UseAlternateEtrText', false);
      if (this.useAlternateEtrText) {
        this.etrText = await getConfigValue('TXT_AlternateEtrText', this.etrDefaultText);
      } else {
        this.etrText = this.etrDefaultText;
      }
      
    } catch (err) {
      this.etrText = this.etrDefaultText;
    }
  },
  methods: {
    formatEtr(etr, etrEarly, etrLate) {
      return FormatCustomerEtr(etr, etrEarly, etrLate, this.etrText);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>