<template>
  <div class="subpage-dashboards subpage-overview--dashboard subpage-dashboards--left-spacer">
    <widget-scheduled-payments />
    <section class="my-account__full-width-section">
      <div class="my-account__overview-double-card-wrap gds-flex-container">
        <div class="my-account__blue-card-wrap">
          <card-pay-bill></card-pay-bill>
        </div>
        <card-payment-plan></card-payment-plan>
      </div>
    </section>

    <section id="history" ref="history" class="my-account__full-width-section">
      <h2 class="gds-display-1 gds-space-stack-l">Bill &amp; Payment History</h2>
      <widget-payment-history />
    </section>
  </div>
</template>

<script>
import CardPayBill from "../../CardPayBill";
import CardPaymentPlan from "../../CardPaymentPlan";
import WidgetPaymentHistory from "./WidgetPaymentHistory";
import WidgetScheduledPayments from "./WidgetScheduledPayments.vue";

export default {
  name: "SubpageOverview",
  components: {
    CardPayBill,
    CardPaymentPlan,
    WidgetPaymentHistory,
    WidgetScheduledPayments,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  async mounted() {
    if (this.$route.hash === "#history") {
      await this.$nextTick();
      this.$refs.history.scrollIntoView(true);
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>