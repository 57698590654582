var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "subpage-dashboards subpage-report--dashboard subpage-dashboards--left-spacer"
    },
    [
      !_vm.billingStats && !_vm.isAdminLite
        ? _c("div", { staticClass: "account-billingStats-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.billingStats && !_vm.isAdminLite
        ? _c(
            "section",
            {
              staticClass: "my-account__full-width-section gds-space-stack-xl"
            },
            [
              _c(
                "h2",
                {
                  staticClass: "gds-display-1 gds-space-stack-l",
                  attrs: { id: "yearly-totals" }
                },
                [_vm._v("Yearly Totals")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "my-account-billing-report__row gds-flex-container gds-flex-container--left"
                },
                [
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__data-label",
                          attrs: { id: "current-year-bills" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.billingStats.thisYear.year) +
                              "\n          Bills\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby":
                              "yearly-totals current-year-bills"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.thisYear.billTotal,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__data-label",
                          attrs: { id: "current-year-payments" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.billingStats.thisYear.year) +
                              "\n          Payments\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby":
                              "yearly-totals current-year-payments"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.thisYear.paymentTotal,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__data-label",
                          attrs: { id: "previous-year-bills" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.billingStats.lastYear.year) +
                              "\n          Bills\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby":
                              "yearly-totals previous-year-bills"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.lastYear.billTotal,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__data-label",
                          attrs: { id: "previous-year-payments" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.billingStats.lastYear.year) +
                              "\n          Payments\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby":
                              "yearly-totals previous-year-payments"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.lastYear.paymentTotal,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.billingStats
        ? _c(
            "section",
            {
              staticClass: "my-account__full-width-section gds-space-stack-xl"
            },
            [
              _c(
                "h2",
                {
                  staticClass: "gds-display-1 gds-space-stack-l",
                  attrs: { id: "seasonal-averages" }
                },
                [_vm._v("Seasonal Averages")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "my-account-billing-report__row gds-flex-container gds-flex-container--left"
                },
                [
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby": "seasonal-averages winter-avg"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.winterAverage,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby": "seasonal-averages spring-avg"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.springAverage,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby": "seasonal-averages summer-avg"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.summerAverage,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-account-billing-report__col3" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "my-account-billing-report__large-data",
                          attrs: {
                            "aria-labelledby": "seasonal-averages fall-avg"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.billingStats.fallAverage,
                                true
                              )
                            )
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.FF_CustomBillReport
        ? _c(
            "section",
            {
              staticClass: "my-account__full-width-section gds-space-stack-xl"
            },
            [
              _c("h2", { staticClass: "gds-display-1 gds-space-stack-l" }, [
                _vm._v("Detailed Report")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "my-account-billing-report__data-tables-wrapper"
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n        View and customize a spreadsheet of all bill charges, quantities, and rates for the dates you choose. The\n        detailed report will open in a new window.\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "gds-button gds-round gds-space-stack-l",
                      attrs: {
                        href:
                          _vm.EnvironmentConstants.BillReportURL +
                          "/?accountNumber=" +
                          _vm.currentAccount.accountNumber,
                        target: "_blank"
                      }
                    },
                    [_vm._v("Launch Detailed Report")]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.FF_CustomBillReport
        ? _c(
            "section",
            {
              staticClass: "my-account__full-width-section gds-space-stack-xl"
            },
            [
              _vm.apiError
                ? _c("flow-error", {
                    attrs: {
                      name: "Bill Report",
                      state: "error",
                      phonetext: "For assistance call us at",
                      showBackground: false
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.apiError
                ? _c("div", [
                    _c(
                      "h2",
                      { staticClass: "gds-display-1 gds-space-stack-l" },
                      [_vm._v("Generate Detailed Report")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-account-billing-report__detailed-report-wrapper"
                      },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-space-stack-l" }, [
                          _c("label", [
                            _c(
                              "h3",
                              {
                                staticClass: "gds-display-0 gds-space-stack-m"
                              },
                              [_vm._v("Report Timeframe")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reportTimeFrame,
                                    expression: "reportTimeFrame"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "report time frame",
                                staticClass:
                                  "eicproduct-input-field-l__select gds-input-field",
                                attrs: {
                                  disabled:
                                    _vm.pendingRates || _vm.pendingReport,
                                  name: "report time frame",
                                  autofocus: ""
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.reportTimeFrame = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.handleReportTimeframeChange
                                  ]
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "" },
                                    domProps: { value: undefined }
                                  },
                                  [_vm._v("Select timeframe for report")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { domProps: { value: "12-months" } },
                                  [_vm._v("Past 12 Months")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { domProps: { value: "24-months" } },
                                  [_vm._v("Past 24 Months")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { domProps: { value: "custom-range" } },
                                  [_vm._v("Select Range")]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.showDatePicker
                          ? _c("div", [
                              _c("div", { staticClass: "gds-space-stack-l" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "gds-input-field",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "gds-input-field__label" },
                                      [_vm._v("Start Date")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "export-data__filtration-date-box"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "my-account-billing-report__filtration-date-icon"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              { staticClass: "gds-icon" },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("datepicker", {
                                          key: "bill report start date",
                                          ref: "startDatePicker",
                                          attrs: {
                                            name: "bill report start date",
                                            "v-validate": _vm.showDatePicker
                                              ? "required"
                                              : null,
                                            typeable: true,
                                            placeholder: "Select Date...",
                                            "disabled-dates":
                                              _vm.disableFutureDates,
                                            "minimum-view": "month",
                                            "maximum-view": "year",
                                            "input-class":
                                              "gds-font-size-l export-data__date"
                                          },
                                          on: {
                                            input:
                                              _vm.handleBillDetailsReportStartDateChange
                                          },
                                          model: {
                                            value:
                                              _vm.billDetailsReportStartDate,
                                            callback: function($$v) {
                                              _vm.billDetailsReportStartDate = $$v
                                            },
                                            expression:
                                              "billDetailsReportStartDate"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.first("bill report start date")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "rebate-validation-error"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.errors.first(
                                                    "bill report start date"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "gds-space-stack-l" }, [
                                _vm.billDetailsReportStartDate
                                  ? _c(
                                      "label",
                                      {
                                        staticClass:
                                          "gds-input-field gds-space-stack-l",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-input-field__label"
                                          },
                                          [_vm._v("End Date")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "export-data__filtration-date-box"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "my-account-billing-report__filtration-date-icon"
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  { staticClass: "gds-icon" },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("datepicker", {
                                              key: "bill report end date",
                                              ref: "endDatePicker",
                                              attrs: {
                                                name: "bill report end date",
                                                "v-validate": _vm.showDatePicker
                                                  ? "required"
                                                  : null,
                                                typeable: true,
                                                placeholder: "Select Date...",
                                                "disabled-dates": {
                                                  to: _vm.addMonths(
                                                    new Date(
                                                      _vm.billDetailsReportStartDate
                                                    ),
                                                    1
                                                  )
                                                },
                                                "minimum-view": "month",
                                                "maximum-view": "year",
                                                "input-class":
                                                  "gds-font-size-l export-data__date"
                                              },
                                              on: {
                                                input:
                                                  _vm.handleCustomRangeBillingRates
                                              },
                                              model: {
                                                value:
                                                  _vm.billDetailsReportEndDate,
                                                callback: function($$v) {
                                                  _vm.billDetailsReportEndDate = $$v
                                                },
                                                expression:
                                                  "billDetailsReportEndDate"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.errors.first("bill report end date")
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rebate-validation-error"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "bill report end date"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.showReportContents &&
                                  !_vm.selectedRangeError,
                                expression:
                                  "showReportContents && !selectedRangeError"
                              }
                            ],
                            staticClass: "gds-space-stack-xl"
                          },
                          [
                            _c("label", [
                              _c(
                                "h3",
                                {
                                  staticClass: "gds-display-0 gds-space-stack-m"
                                },
                                [_vm._v("Report Contents")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reportContent,
                                      expression: "reportContent"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass:
                                    "eicproduct-input-field-l__select gds-input-field",
                                  class: {
                                    "gds-loading":
                                      _vm.pendingRates || _vm.pendingReport
                                  },
                                  attrs: {
                                    disabled:
                                      _vm.pendingRates || _vm.pendingReport,
                                    name: "report time frame",
                                    autofocus: ""
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.reportContent = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.handleReportContentChange
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: undefined }
                                    },
                                    [_vm._v("Select contents of report")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { domProps: { value: "full-report" } },
                                    [
                                      _vm._v(
                                        "All Rates, Charges, and Quantities"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { domProps: { value: "custom-report" } },
                                    [_vm._v("Custom Report")]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showCustomReportContent && _vm.reportTimeFrame
                      ? _c(
                          "section",
                          {
                            staticClass:
                              "my-account__full-width-section gds-space-stack-xl"
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "gds-display-0 gds-space-stack-m"
                              },
                              [_vm._v("Values to Include")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-items-flex-container gds-space-stack-xl"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "global-login-panel__form-helper my-account-billing-report__custom-report-items"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "gds-checkbox gds-checkbox-thin"
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "checkbox",
                                            value: "quantity"
                                          },
                                          on: {
                                            click: _vm.handleValueSelection
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "gds-checkbox__faux"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-font-size-m gds-text-grey"
                                          },
                                          [_vm._v("Billed Quantity (kWh, kW)")]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "global-login-panel__form-helper my-account-billing-report__custom-report-items"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "gds-checkbox gds-checkbox-thin"
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "checkbox",
                                            value: "amount"
                                          },
                                          on: {
                                            click: _vm.handleValueSelection
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "gds-checkbox__faux"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-font-size-m gds-text-grey"
                                          },
                                          [_vm._v("Billed Amount ($)")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "h3",
                              {
                                staticClass: "gds-display-0 gds-space-stack-m"
                              },
                              [_vm._v("Rates, Products & Programs to Include")]
                            ),
                            _vm._v(" "),
                            _vm.billingRates
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "report-items-flex-container"
                                  },
                                  _vm._l(_vm.billingRates, function(
                                    billingRate
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: billingRate,
                                        staticClass:
                                          "global-login-panel__form-helper my-account-billing-report__custom-report-items"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-checkbox gds-checkbox-thin"
                                          },
                                          [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: { value: billingRate },
                                              on: {
                                                click:
                                                  _vm.handleRateCodeSelection
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "gds-checkbox__faux"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-font-size-m gds-text-grey"
                                              },
                                              [_vm._v(_vm._s(billingRate))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.billingRates
                              ? _c("div", [
                                  _vm.pendingRates
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass:
                                            "eicproduct__file-uploader-loader--svg",
                                          attrs: {
                                            src:
                                              "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.pendingRates
                                    ? _c("div", [
                                        _vm._v(
                                          "Please select a valid timeframe."
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.showFileViewer && !_vm.selectedRangeError
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "my-account-billing-report__custom-report-button",
                            class: {
                              "gds-loading":
                                _vm.pendingReport || _vm.pendingRates
                            },
                            attrs: {
                              disabled:
                                _vm.pendingRates ||
                                _vm.pendingReport ||
                                !_vm.reportTimeFrame ||
                                !_vm.reportContent ||
                                !_vm.selectedRates.length ||
                                !_vm.selectedValues.length
                            },
                            on: { click: _vm.runReport }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.pendingReport || _vm.pendingRates
                                    ? " "
                                    : "Run Report"
                                ) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedRangeError
                      ? _c(
                          "div",
                          {
                            staticClass: "my-account__custom-report-container"
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account-billing-report__custom-report-error-text"
                              },
                              [
                                _vm._v(
                                  "\n          No Data Found for the selected range. Please select a valid date range.\n        "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFileViewer
                      ? _c(
                          "div",
                          {
                            staticClass: "my-account__custom-report-container"
                          },
                          [
                            _vm._m(6),
                            _vm._v(" "),
                            !_vm.viewReportError
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-account__custom-report-flex-item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-display-0 gds-space-stack-l gds-align--text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Your detailed report for " +
                                            _vm._s(
                                              _vm._f("dateFull")(
                                                this.billDetailsReportStartDate
                                              )
                                            ) +
                                            "-" +
                                            _vm._s(
                                              _vm._f("dateFull")(
                                                this.billDetailsReportEndDate
                                              )
                                            ) +
                                            " is ready.\n          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-account__custom-report-button-container"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-align--text-center my-account__custom-report-button",
                                            on: { click: _vm.viewReport }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "gds-card__button gds-button gds-round gds-secondary"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.pendingReport
                                                      ? null
                                                      : "View"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-align--text-center my-account__custom-report-button",
                                            on: {
                                              click: function($event) {
                                                return _vm.downloadReport()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "gds-card__button gds-button gds-round gds-secondary",
                                                class: {
                                                  "gds-loading":
                                                    _vm.pendingReport
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.pendingReport
                                                        ? null
                                                        : "Download CSV"
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.viewReportError
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-account__custom-report-flex-item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-account-billing-report__custom-report-error-text gds-space-stack-l"
                                      },
                                      [
                                        _vm._v(
                                          "\n            There was an error getting your report. Please try again.\n          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-align--text-center my-account__custom-report-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.RefreshData()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "gds-card__button gds-button gds-round gds-secondary"
                                          },
                                          [
                                            _vm._v(
                                              "\n              Go Back\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "my-account-billing-report__data-label",
        attrs: { id: "winter-avg" }
      },
      [
        _vm._v("\n          Winter\n          "),
        _c("span", { staticClass: "gds-font-book" }, [
          _vm._v("(Dec, Jan, Feb)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "my-account-billing-report__data-label",
        attrs: { id: "spring-avg" }
      },
      [
        _vm._v("\n          Spring\n          "),
        _c("span", { staticClass: "gds-font-book" }, [
          _vm._v("(Mar, Apr, May)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "my-account-billing-report__data-label",
        attrs: { id: "summer-avg" }
      },
      [
        _vm._v("\n          Summer\n          "),
        _c("span", { staticClass: "gds-font-book" }, [
          _vm._v("(Jun, July, Aug)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "my-account-billing-report__data-label",
        attrs: { id: "fall-avg" }
      },
      [
        _vm._v("\n          Fall\n          "),
        _c("span", { staticClass: "gds-font-book" }, [
          _vm._v("(Sept, Oct, Nov)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-l" }, [
      _c("p", [
        _vm._v(
          "\n            Generate a spreadsheet of all bill charges, quantities, and rates for the dates you choose. After you run\n            the\n            report you can download or view it in your browser.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "my-account__custom-report__icon-light-bulb my-account__custom-report-flex-item gds-flex-container"
      },
      [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/icon-light-bulb.svg",
            alt: "View or download report"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "my-account__custom-report__icon-light-bulb my-account__custom-report-flex-item"
      },
      [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/icon-light-bulb.svg",
            alt: "View or download report"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }