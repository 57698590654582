<template>
  <div ref="usageTableContainer" class="usage-detail__component-container">
    <UsageDetailControls
      class="usage-detail__table-nav-controls"
      :selectedDate="monthYearSelected"
      :datePickerDisabledDates="disabledDates"
      :selectedDateSubText="selectedDateSubText"
      :selectedDateWidth="selectedDateWidth"
      :showDateBackwardsButton="showDateBackwardsButton" 
      :showDateForwardsButton="showDateForwardsButton"
      :showJumpToDateButton="showJumpToDateButton" 
      :showGoToTodayButton="showGoToTodayButton"
      jumpToDateButtonWidth="9.5rem"
      goToTodayButtonWidth="9rem"
      :showViewOptionsButton="false"
      :onDateBackwardsClicked="onDateBackwardsClicked" 
      :onDateForwardsClicked="onDateForwardsClicked"
      :onSelectedDateChanged="onSelectedDateChanged"
      :onGoToTodayClicked="onGoToTodayClicked"
      :onOptionSelectedTableSort="sortUsageData"
      :onOptionSelectedCalendarMonthsBillingPeriod="onOptionCalendarTypeSelected"
      :onViewOptionsClicked="onViewOptionsClicked"
      :datePickerDateType="'month'" 
      :selectConfigTableSort="selectConfigTableSort"
      :selectConfigCalendarMonthsBillingPeriod="selectConfigCalendarMonthsBillingPeriod"
      :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
      :disableDateNavigationButtonForward="disableDateNavigationButtonForward"
      :disableAllDateNavControls="!loadState  || disableAllDateNavControls"
    />
    <div v-if="!loadState" class="table-loading"></div>
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>

    <div v-if="loadState === 'complete' || loadState === 'unavailable'" class="usage-detail__daily-table">
      <section v-if="loadState === 'complete'" class="usage-detail__table-container">
        <div class="usage-detail__table-rows-wrapper">
          <div class="usage-detail__table-row">
            <div :class="usageHeadingCellClass">Date</div>
            <div v-if="isDesktopView" :class="usageHeadingCellClass">Day of Week</div>
            <div v-if="hasGenerationRecordsPresent" :class="usageHeadingCellClass">Net (kWh)</div>
            <div :class="usageHeadingCellClass">Usage (kWh)</div>
            <div v-if="hasGenerationRecordsPresent" :class="usageHeadingCellClass">Generation (kWh)</div>
            <div v-if="hasSnapshotData" :class="usageHeadingCellClass">Highest Use Time of Day</div>
            <div v-if="!hasSnapshotData" :class="usageTableEmptyCellClass"></div>
            <div v-if="isDesktopView" :class="usageHeadingCellClass">Low</div>
            <div v-if="isDesktopView" :class="usageHeadingCellClass">High</div>
            <div :class="usageHeadingCellClass">Conditions</div>
          </div>
          <div v-for="usage of dailyUsageData" :key="usage.date.getTime()" class="usage-detail__highlight-row">
            <usage-detail-table-row
              :dailyUsagePeriodValues="usage.dailyUsagePeriodValues"
              :expandedRow="findDate(usage.date)"
              :usage="usage"
              :hasGenerationRecordsPresent="hasGenerationRecordsPresent"
              :maxUsageValue="maxUsageValue"
              @toggle-hourly="toggleHourlyUsage">
            </usage-detail-table-row>
            <usage-detail-hourly-table v-if="findDate(usage.date) != -1" :barType="determineBarType(usage)"
              :usageDate="usage.date" :hasGenerationRecordsPresent="hasGenerationRecordsPresent" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { endOfMonth, isEqual, parseISO, startOfDay, isSameDay, addMinutes } from "date-fns";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls";
import ButtonViewOptions from "../../../generic/ButtonViewOptions";
import HighestUsageMicroSparkbar from "../highestusagemicrosparkbar/HighestUsageMicroSparkbar";
import UsageBar from "../usageshared/UsageBar";
import UsageDetailHourlyTable from "./UsageDetailHourlyTable";
import UsageDetailTableRow from "./UsageDetailTableRow";
import UsageTableUtil from "../../../mixins/UsageTableUtil";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import { GetComponentStatus } from '../../../../services/statuspage';
import GMPAPI from "../../../../services/gmpapi";


export default {
  name: "UsageDetailTable",
  mixins: [
    MediaQueryMixin,
    UsageTableUtil,
    UsageDetailBaseComponentMixin
  ],
  components: {
    UsageDetailControls,
    ButtonViewOptions,
    HighestUsageMicroSparkbar,
    UsageBar,
    UsageDetailHourlyTable,
    UsageDetailTableRow
  },
  data() {
    return {
      dailyUsageData: undefined,
      hasGenerationRecordsPresent: undefined,
      hourlyUsageSelections: [],
      hourlyUsageLoadState: undefined,
      hasSnapshotData: false,
      usageHeadingCellClass: undefined,
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined,
      usageAmountSectionWidth: 0,
      usageContainerSizePercent: 1,
      maxUsageValue: undefined,
      disableDateNavigationButtonForward: undefined,
      timeOfDayMap: [
        { "hour0to3": "12am-4am" },
        { "hour4to7": "4am-8am" },
        { "hour8to11": "8am-12pm" },
        { "hour12to15": "12pm-4pm" },
        { "hour16to19": "4pm-8pm" },
        { "hour20to23": "8pm-12pm" }],
      selectConfigTableSort: {
        width: undefined,
        options: [
          {
            label: 'Newest First',
            value: 'newest-first'
          },
          {
            label: 'Oldest First',
            value: 'oldest-first'
          }
        ]
      },
      selectedTableSortValue: 'newest-first',
      selectedDateWidth: undefined
    }
  },
  watch: {
    currentAccount() {
      this.init();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isMobileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    }
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 
      
      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods.periods);
      await this.init();
      window.addEventListener("resize", this.setControlWidths );
      
    } catch (err) {
      DumpError("Usage detail table refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.setControlWidths );
  },
  methods: {
    async init() {
      const currentDate = new Date();
      this.selectedEndDate = endOfMonth(currentDate);
      this.disableDateNavigationButtonForward = true;
      this.adjustDatesForCalendarMonth();
      this.setControlWidths();
    },

    async refreshData(accountNumber, startDate, endDate) {
      this.resetData();
      try {
        const response = await this.getPeriodUsageData(accountNumber, "daily", startDate, endDate);
        if (response && response.intervals[0] && response.intervals[0].values && response.intervals[0].values.length > 0) {
          const dailyUsageData = response.intervals[0].values;
          const dailyUsageWithWeatherIcon = dailyUsageData.map(item => {
            if (item && (item.temperature || item.darkskyTemperature)) {
              return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon) };
            }
            return { ...item, weatherIconImageUrl: "" };
          });
          this.dailyUsageData = dailyUsageWithWeatherIcon;
          this.loadState = 'complete';
        } else {
          this.loadState = 'empty';
          return;
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        return;
      }

      this.hasGenerationRecordsPresent = this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION);
      this.dailyUsageData = this.filterRowsGreaterThanDate(this.dailyUsageData, new Date());

      this.maxUsageValue = this.findMaxUsageValue(this.dailyUsageData);
      this.sortUsageTable(this.selectedTableSortValue, this.dailyUsageData);
      await this.updateSnapshotData(accountNumber, startDate, endDate);

      this.setStyles();
    },

    resetData() {
      this.loadState = undefined;
      this.hasSnapshotData = false;
      this.dailyUsageData = [];
      this.dailyUsageSnapshotData = null;
    },

    filterRowsGreaterThanDate(array, date) {
      return array.filter((row) => row.date < date);
    },

    sortUsageTable(sortOrder, arrayToSort) {
      return arrayToSort.sort((a, b) => {
        if (sortOrder === 'oldest-first') {
          return a.date - b.date;
        }
        return b.date - a.date;
      });
    },

    async getAccountUsageSnapshot(accountNumber, startDate, endDate, expand) {
      return await GMPAPI.GetAccountUsageSnapshot(accountNumber, ToServerDateTruncate(startDate), ToServerDateTruncate(endDate), expand);
    },

    async updateSnapshotData(accountNumber, startDate, endDate) {
      try {
        this.dailyUsageSnapshotData = await this.getAccountUsageSnapshot(accountNumber, startDate, endDate, 'daily');
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        this.hasSnapshotData = false;
        return;
      }

      if (this.dailyUsageSnapshotData && this.dailyUsageSnapshotData.dailyConsumption && 
          this.dailyUsageSnapshotData.dailyConsumption.length > 0) {
        this.hasSnapshotData = true;
        this.appendTimeOfUseData(this.dailyUsageData, this.dailyUsageSnapshotData.dailyConsumption);
      } else {
        this.hasSnapshotData = false;
      }
    },
    
    appendTimeOfUseData(dailyUsageData, usageSnapshotData) {
      // Add additional dailyUsagePeriodValues and highestUsagePeriodOfDay properties from usage snapshot data to dailyUsage array
      dailyUsageData.forEach(dailyUsage => {
        const snapshotTimeOfUse = usageSnapshotData.find(item => {
          const parsedItemDateUTC = parseISO(item.date);
          const parsedItemDateLocal = addMinutes(parsedItemDateUTC, parsedItemDateUTC.getTimezoneOffset());

          return isSameDay(startOfDay(parsedItemDateLocal), dailyUsage.date);
        });

        if (snapshotTimeOfUse) {
          const highestUsagePeriodOfDay = this.findSnapshotHourWithHighestUsage(snapshotTimeOfUse.values);
          dailyUsage.highestUsagePeriodOfDay = this.findUsagePeriodDisplayText(highestUsagePeriodOfDay);
          dailyUsage.dailyUsagePeriodValues = this.getSnapshotTimePeriodValues(snapshotTimeOfUse.values);
        }
      })
    },

    findSnapshotHourWithHighestUsage(dailyTimeOfDayUsageValues) {
      const maxHourProperty = Object.keys(dailyTimeOfDayUsageValues).reduce((maxKey, currentKey) => {
        if (currentKey.startsWith("hour") && dailyTimeOfDayUsageValues[currentKey] > dailyTimeOfDayUsageValues[maxKey]) {
          return currentKey;
        }
        return maxKey;
      });
      return maxHourProperty;
    },

    findUsagePeriodDisplayText(hour) {
      const match = this.timeOfDayMap.find(obj => Object.keys(obj)[0] === hour);
      return match ? Object.values(match)[0] : null;
    },

    getSnapshotTimePeriodValues(data) {
      return Object.keys(data)
        .filter(key => key.startsWith("hour"))
        .map(key => data[key]);
    },

    setStyles() {
      if (this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION)) {
        this.usageHeadingCellClass = 'usage-detail__generation-heading-cell';
        this.usageTableCellClass = 'usage-detail__generation-cell';
        this.usageTableEmptyCellClass = 'usage-detail__empty-generation-cell'
      } else {
        this.usageHeadingCellClass = 'usage-detail__heading-cell';
        this.usageTableCellClass = 'usage-detail__flat-rate-cell';
        this.usageTableEmptyCellClass = 'usage-detail__empty-flat-rate-cell'
      }
    },

    handleDailyUsageRead(value) {
      this.hourlyUsageLoadState = value;
    },

    getUniqueUsageTypes(usageData) {
      const uniqueProperties = new Set();

      usageData.forEach(obj => {
        if (obj.hasOwnProperty(this.GENERATION)) {
          uniqueProperties.add(this.GENERATION);
        }
        if (obj.hasOwnProperty(this.CONSUMPTION)) {
          uniqueProperties.add(this.CONSUMPTION);
        }
        if (obj.hasOwnProperty(this.ON_PEAK)) {
          uniqueProperties.add(this.ON_PEAK);
        }
      });

      return Array.from(uniqueProperties);
    },

    sortUsageData(sortOrder) {
      if (sortOrder === 'oldest-first') {
        this.selectedTableSortValue = 'oldest-first';
      }
      else {
        this.selectedTableSortValue = 'newest-first';
      }
      this.dailyUsageData = this.sortUsageTable(sortOrder, this.dailyUsageData);
    },

    toggleHourlyUsage(usageDate) {
      document.querySelector(".usage-detail__table-rows-wrapper").scrollLeft = 0;
      const index = this.findDate(usageDate);
      if (index != -1) {
        this.hourlyUsageSelections.splice(index, 1);
      } else {
        this.hourlyUsageSelections.push(usageDate);
      }
    },

    findDate(usageDate) {
      const isFoundIndex = this.hourlyUsageSelections.findIndex(item => {
        return isEqual(startOfDay(item), startOfDay(usageDate));
      });
      return isFoundIndex;
    },
    setControlWidths() {
      if (window.innerWidth < 950) {
        this.selectedDateWidth = '85px';
      } else if (window.innerWidth < 1000) {
        this.selectedDateWidth = '120px';
      } else if (window.innerWidth < 1175) {
        this.selectConfigCalendarMonthsBillingPeriod.width = '160px'
        this.selectConfigTableSort.width = '135px';
      } else if (window.innerWidth < 1375) {
        this.selectedDateWidth = '150px';
      } else {
        this.selectedDateWidth = '310px';
      }
      this.selectConfigCalendarMonthsBillingPeriod = JSON.parse(JSON.stringify(this.selectConfigCalendarMonthsBillingPeriod));
      this.selectConfigTableSort = JSON.parse(JSON.stringify(this.selectConfigTableSort));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>