<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail">
      <div class="modal-usage-pick-a-view__header">
        <span class="modal-usage-pick-a-view__title">Graph Layers</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="modal-usage-pick-a-view__modal-body">
          <div class="modal-usage-pick-a-view__options-body">
            <div class="modal-usage-pick-a-view__options-body__button-options">
              <div v-for="layer of layers" @click="updateSelected(layer)" ref="layers" :key="layer.name"
                class="modal-usage-pick-a-view__nav-select-button">
                <div class="usage-detail__usage-graph-modal-circle">
                  <svg class="gds-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                  </svg>
                </div>
                <span class="modal-usage-pick-a-view__nav-select-button__label">{{ layer.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
const WHITE = '#ffffff';

export default {
  name: "ModalGraphLayers",
  props: {
    layers: Array,
    closeModal: Function,
    isDashboard: Boolean
  },
  mounted() {
    this.layers.forEach((layer, i) => {
      this.updateCheckIconStyling(layer, i);
    });
  },
  methods: {
    updateSelected(layer) {
      let layers = this.layers;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].name === layer.name) {
          layer.selected = !layer.selected;
          this.updateCheckIconStyling(layer, i)
          break;
        }
      }
      this.layers = layers;
      this.$emit("update-selected", this.layers);
      return;
    },
    updateCheckIconStyling(layer, layerIndex) {
      // Change check mark background color
      this.$refs['layers'][layerIndex].firstChild.style.border = `2px solid ${layer.flatColor}`
      if (layer.selected) {
        this.$refs['layers'][layerIndex].firstChild.style.backgroundColor = layer.flatColor;
        return;
      }
      this.$refs['layers'][layerIndex].firstChild.style.backgroundColor = WHITE;
      return
    }
  }
};
</script>

<style scoped></style>