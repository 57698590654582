<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close()" type="button" class="close bill-pay__close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <FocusLock>
        <div class="eicproducts__modal-box--body">
          <div
            class="gds-space-stack-ml"
          >The cost of electronic transactions were previously allowed only with a $4.95 convenience fee. Our regulators agreed to allow free transactions for all customers if higher cost credit card transactions were limited to two a month. It was agreed that if additional transactions were required, a customer could use the lower cost method of a bank transaction.</div>
          <button @click="close('informational')" type="button" class="gds-button">Okay</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalInformational",
  props: {
    visible: Boolean,
    close: Function,
  },
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>