var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-usage--dashboard" },
    [
      _vm.testDriveUsage
        ? _c("banner-dual-action", {
            staticClass: "gds-flex-container",
            attrs: {
              bannerColor: "blue",
              message:
                "You are trying out our new usage section. We would love to hear what you think. To provide feedback please click the following link:",
              messageTwo: "or you can use this link to",
              linkOneText: "Provide Feedback",
              linkTwoText: "Switch Back to the Old Usage View"
            },
            on: {
              linkOneClicked: _vm.showProvideFeedBackModal,
              linkTwoClicked: _vm.goToLegacyUsage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h1", { staticClass: "my-account__title gds-space-stack-l" }, [
        _vm._v("Usage")
      ]),
      _vm._v(" "),
      _vm.isMobbileView || _vm.isTabletView
        ? _c(
            "div",
            {
              staticClass: "usage-dashboard-pick-a-view-container",
              on: { click: _vm.showPickAViewModal }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "15",
                    height: "15",
                    viewBox: "0 0 15 15",
                    fill: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z",
                      fill: "#00704A"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "usage-dashboard-pick-a-view-container__label" },
                [_vm._v("Usage Dashboard")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "usage-dashboard-pick-a-view-container__link" },
                [_vm._v("Pick a View")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pickAViewModalVisible
        ? _c("ModalUsagePickAView", {
            attrs: { closeModal: _vm.hidePickAViewModal, isDashboard: true }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard-widget-border usage-dashboard-margin-bottom-1rem "
        },
        [
          _vm.bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 &&
          (!_vm.FF_NetMeteredDashboard ||
            (_vm.FF_NetMeteredDashboard && !_vm.isNetMetered))
            ? _c("widget-current-usage", {
                on: { "change-options-event": _vm.handleOptionChangeEvent }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 &&
          _vm.FF_NetMeteredDashboard && _vm.isNetMetered
            ? _c("widget-net-metering", {
                attrs: {
                  loadstate: _vm.loadstate,
                  billingSummary: _vm.billingSummary
                },
                on: { "change-options-event": _vm.handleOptionChangeEvent }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "usage-dashboard-two-columns" }, [
        _c(
          "div",
          { staticClass: "usage-dashboard-widget-border" },
          [_c("widget-daily-usage-history")],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard-widget-usage-trend-wrapper usage-dashboard-widget-border usage-dashboard-grid-short--height-auto usage-dashboard-margin-bottom-1rem"
            },
            [_c("widget-usage-trend")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard-widget-time-of-day-wrapper usage-dashboard-widget-border usage-dashboard-grid-short"
            },
            [_c("widget-time-of-day")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gds-space-stack-l" },
        [_c("UsageVisualizationAndTools")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gds-space-stack-l" },
        [_c("usage-notification")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gds-space-stack-l" }, [_c("usage-export")], 1),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("modal-floating-feedback", {
        attrs: { visible: _vm.showFeedback },
        on: {
          close: function($event) {
            _vm.showFeedback = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }