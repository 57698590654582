var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "usage-dashboard__widget-usage-trend gds-position--relative"
    },
    [
      _c("div", { staticClass: "usage-dashboard__headings--in-boxes" }, [
        _vm._v("\n    Usage Trend\n    \n    "),
        _vm.subtextKwh && _vm.subtext
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "usage-dashboard__widget-usage-trend--subtextKwh"
                },
                [_vm._v(_vm._s(_vm.subtextKwh))]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "usage-dashboard__widget-usage-trend--subtext" },
                [_vm._v(_vm._s(_vm.subtext))]
              ),
              _vm._v(" "),
              _vm.selectedBarMonth
                ? _c(
                    "span",
                    {
                      staticClass:
                        "usage-dashboard__widget-usage-trend--subtext"
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/account/usage-new/detail/graph" +
                              "?startDate=" +
                              _vm.selectedBarMonth +
                              "&resolution=daily"
                          }
                        },
                        [_vm._v("View")]
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("a", {
        staticClass: "usage-dashboard__circle-arrow-right",
        attrs: { href: "/account/usage-new/detail/graph" }
      }),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c("div", {
              ref: "widgetUsageTrend",
              staticClass: "gds-position--absolute",
              attrs: { id: "usage-dashboard__widget-usage-trend--chart" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "my-account__usage-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "unavailable"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("Usage information is not available for this account.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("An unexpected error occurred, please try again later.")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }