<template>
  <div v-if="visible" class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="export-data__modal-box--header">
          <button @click="Close()" type="button" class="close bill-pay__close">
            <svg class="gds-icon close-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <div class="export-data__modal-box--headline">Usage Download</div>
        </header>
        <div class="export-data__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l gds-flex-container gds-flex-container--space-between">
            <div class="gds-font-demi">Select a Format</div>
            <div class="gds-flex-container gds-flex-container--right">
              <button
                @click="dataFormat = 'xml'"
                type="button"
                :class="{ 'export-data__active': dataFormat === 'xml' }"
                class="export-data__data-format-selection green-xml-icon gds-button gds-secondary gds-compact gds-space-inline-s"
              ></button>
              <button
                @click="dataFormat = 'csv'"
                type="button"
                :class="{ 'export-data__active': dataFormat === 'csv' }"
                class="export-data__data-format-selection gds-button gds-secondary gds-compact"
              >CSV</button>
            </div>
          </div>

          <div class="gds-font-demi gds-space-stack-s">Date Range</div>
          <div class="gds-font-size-s gds-space-stack-s">Maximum range: 15 days</div>
          <div class="export-data__modal-box--message gds-space-stack-s gds-flex-container gds-flex-container--space-between">
            <fieldset class="export-data__fieldset gds-fieldset">
              <div class="gds-input-field__label">From</div>
              <div class="export-data__filtration-date-box">
                <div @click="$refs.startPicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                  <svg class="gds-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                  </svg>
                </div>
                <datepicker ref="startPicker" v-model="startDate" format="MMMM d, yyyy" :disabled-dates="{from: lastPossibleDate}" input-class="gds-font-size-s export-data__date" />
              </div>
            </fieldset>

            <fieldset class="export-data__fieldset gds-fieldset">
              <div class="gds-input-field__label">To</div>
              <div class="export-data__filtration-date-box">
                <div @click="$refs.endPicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                  <svg class="gds-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                  </svg>
                </div>
                <datepicker ref="endPicker" v-model="endDate" format="MMMM d, yyyy" :disabled-dates="{from: lastPossibleDate}" input-class="gds-font-size-s export-data__date" />
              </div>
            </fieldset>
          </div>
          <div v-if="rangeDays < 1" class="validation-error">Start date must be before end date.</div>
          <div v-if="rangeDays > 15" class="validation-error">Maximum export range is 15 days.</div>

          <button
            @click="Save()"
            :disabled="rangeDays < 1 || rangeDays > 15 || pendingDownload"
            :class="{ 'gds-loading': pendingDownload }"
            type="button"
            class="gds-button gds-compact gds-space-stack-l"
          >
            <span class="gds-button__text">Go</span>
          </button>
          <div v-if="errorDownload" class="form-message-box gds-space-stack-m">{{errorDownload}}</div>
          <div class="export-data__xml-green-button-description gds-font-size-s">
            <span class="export-data__xml-green-button-description--icon green-xml-icon"></span> Selecting the green button option for your usage download format provides a Green Button Initiative compliant XML dataset.
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

import GMPAPI from '../../../services/gmpapi';
import { ToServerDateTruncate, DumpError } from '../../../utilities';
import { format, differenceInCalendarDays, addDays } from 'date-fns';

export default {
  name: "ModalExportData",
  props: ["visible"],
  components: {
    Datepicker,
  },
  data() {
    return {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      dataFormat: "csv",

      lastPossibleDate: addDays(new Date(), -1),

      pendingDownload: false,
      errorDownload: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    rangeDays() {
      return differenceInCalendarDays(this.endDate, this.startDate) + 1;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.pendingDownload = true;
      try {
        const filename = "UsageData_" + format(this.startDate, "yyyy-MM-dd") + "_" + this.rangeDays + "Days." + this.dataFormat;
        await GMPAPI.DownloadUsageExport(this.currentAccount.accountNumber, filename, ToServerDateTruncate(this.startDate), ToServerDateTruncate(addDays(this.endDate, 1)), this.dataFormat);
        this.Close();
      } catch (err) {
        DumpError("Export data error", err);
        this.errorDownload = "There was an unexpected error exporting your data.";
      } finally {
        this.pendingDownload = false;
      }
    },
    Close() {
      this.$emit("complete");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>