<template>
  <div class="top-card-display__single-card top-card-display__single-card--outages-near-you">
    <div class="top-banner-card gds-card ">
      <figure class="gds-card__image-container gds-aspect-silver">
        <div class="gds-card__image">
          <!-- TODO: needs real header image -->
          <img src="/wp-content/themes/gmptwentynineteen/assets/images/webchat-card-header.jpg" alt="Web Chat" class="gds-card__image" />
        </div>
      </figure>
      <div class="gds-card__contents">
        <p class="gds-display-0 gds-space-stack-s">Need help? Chat with our team to get answers to your questions.</p>
        <button v-if="queueOnline" @click="StartWebchat()" type="button" class="gds-button gds-card__button gds-align--block-center gds-round gds-invert gds-button-full">Start Chat</button>
        <button v-if="!queueOnline" disabled type="button" class="gds-button gds-card__button gds-align--block-center gds-round gds-secondary gds-button-full">Chat is offline</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ShowChat, QueueStatus } from "../../../services/webchat";
import { DefaultChatQueue } from "../../../environment";

export default {
  name: "CardWebChat",
  data() {
    return {
      queueStatus: undefined,
    };
  },
  computed: {
    queueOnline() {
      return this.queueStatus && this.queueStatus.IsOpen;
    },
    queueMode() {
      return this.queueStatus && this.queueStatus.QueueModeName;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.queueStatus = undefined;
      try {
        this.queueStatus = await QueueStatus(DefaultChatQueue);
        console.log("Chat queue " + DefaultChatQueue + " is in " + this.queueMode + " mode");
      } catch (err) {
        this.queueStatus = { IsOpen: false };
      }
    },
    StartWebchat() {
      ShowChat(DefaultChatQueue);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>