var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "my-account__full-width-section my-account__section-with-bottom-border"
    },
    [
      _vm.loadState !== "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                  },
                  [
                    _c(
                      "h2",
                      { staticClass: "gds-display-1 gds-space-inline-m" },
                      [_vm._v("Paperless Billing")]
                    ),
                    _vm._v(" "),
                    _vm.loadState === undefined
                      ? _c("div", { staticClass: "editor-loading" })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "fake-edit-link" }, [_vm._v("Edit")])
              ]
            ),
            _vm._v(" "),
            _vm.loadState === "error"
              ? _c("div", { staticClass: "editor-error" }, [
                  _vm._v("Unable to load this section. Please try again later.")
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                  },
                  [
                    _c(
                      "h2",
                      {
                        ref: "scrolltarget",
                        staticClass: "gds-display-1 gds-space-inline-m"
                      },
                      [_vm._v("Paperless Billing")]
                    ),
                    _vm._v(" "),
                    !_vm.editing && _vm.billingStatus
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account__on-off-text gds-flex-container gds-flex-container--left"
                              },
                              [
                                _c("IndicatorOnOff", {
                                  attrs: {
                                    value:
                                      _vm.billingStatus.billRouteInformation
                                        .isEBill
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "edit-close-button-combo" }, [
                  !_vm.editing
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editing = true
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Edit")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editing && !_vm.pendingSave
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Close")]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.editing && _vm.billingStatus
              ? _c("div", [
                  !_vm.billingStatus.billRouteInformation.isEBill
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "my-account__sub-section-bottom gds-text-grey"
                        },
                        [_vm._v("You are receiving paper bills in the mail.")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.billingStatus.billRouteInformation.isEBill
                    ? _c(
                        "div",
                        { staticClass: "my-account__sub-section-bottom" },
                        [
                          _vm._v(
                            "Sending your bill to " +
                              _vm._s(
                                _vm.billingStatus.billRouteInformation
                                  .emailAddress
                              )
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && _vm.paperlessSettings
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.Save()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-account__header-button-pairing gds-flex-container gds-flex-container--left"
                      },
                      [
                        _c(
                          "fieldset",
                          { staticClass: "gds-fieldset gds-space-inline-m" },
                          [
                            _c("label", { staticClass: "gds-switch" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.paperlessSettings.billRouteInformation
                                        .isEBill,
                                    expression:
                                      "paperlessSettings.billRouteInformation.isEBill"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.paperlessSettings.billRouteInformation
                                      .isEBill
                                  )
                                    ? _vm._i(
                                        _vm.paperlessSettings
                                          .billRouteInformation.isEBill,
                                        null
                                      ) > -1
                                    : _vm.paperlessSettings.billRouteInformation
                                        .isEBill
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a =
                                          _vm.paperlessSettings
                                            .billRouteInformation.isEBill,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.paperlessSettings
                                                .billRouteInformation,
                                              "isEBill",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.paperlessSettings
                                                .billRouteInformation,
                                              "isEBill",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.paperlessSettings
                                            .billRouteInformation,
                                          "isEBill",
                                          $$c
                                        )
                                      }
                                    },
                                    function($event) {
                                      _vm.touched = true
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-switch__faux" })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm.paperlessSettings.billRouteInformation.isEBill
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _vm._v("Paperless billing is turned on.")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.paperlessSettings.billRouteInformation.isEBill
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _vm._v("Paperless billing is turned off.")
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.paperlessSettings.billRouteInformation.isEBill
                      ? _c(
                          "fieldset",
                          { staticClass: "gds-fieldset gds-space-stack-m" },
                          [
                            _c("label", { staticClass: "gds-input-field" }, [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Where to send your paper bill")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.paperlessSettings.billRouteInformation
                                        .emailAddress,
                                    expression:
                                      "paperlessSettings.billRouteInformation.emailAddress"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email|max:74",
                                    expression: "'required|email|max:74'"
                                  }
                                ],
                                key: "email",
                                staticClass: "gds-input-field__input",
                                attrs: {
                                  maxlength: "74",
                                  name: "email",
                                  type: "email",
                                  placeholder: "johndoe@example.com"
                                },
                                domProps: {
                                  value:
                                    _vm.paperlessSettings.billRouteInformation
                                      .emailAddress
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.paperlessSettings
                                          .billRouteInformation,
                                        "emailAddress",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      _vm.touched = true
                                    }
                                  ]
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.errors.first("email")
                              ? _c("div", { staticClass: "validation-error" }, [
                                  _vm._v(_vm._s(_vm.errors.first("email")))
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gds-button gds-round gds-space-stack-m",
                        class: { "gds-loading": _vm.pendingSave },
                        attrs: {
                          type: "submit",
                          disabled:
                            _vm.pendingSave || !_vm.touched || _vm.errors.any()
                        }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Save")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errorSave
                      ? _c(
                          "div",
                          { staticClass: "form-message-box gds-space-stack-m" },
                          [
                            _vm._v(
                              "There was an error updating your paperless billing."
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }