var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-dashboard__widget-net-metering" }, [
    _vm.loadstate === "complete"
      ? _c(
          "div",
          [
            _vm.isDesktopView
              ? _c("WidgetNetMeteringDesktop", {
                  attrs: { billingSummary: _vm.billingSummary },
                  on: { "change-options-event": _vm.handleOptionChangeEvent }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isMobileView || _vm.isTabletView
              ? _c("WidgetNetMeteringMobile", {
                  attrs: { billingSummary: _vm.billingSummary },
                  on: { "change-options-event": _vm.handleOptionChangeEvent }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.loadstate
      ? _c("div", {
          staticClass:
            "usage-dashboard__widget-current-usage my-account__usage-loading"
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadstate === "unavailable"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v("Usage information is not available for this account.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadstate === "error"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v("An unexpected error occurred, please try again later.")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }