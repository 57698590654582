var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Information for the specified incident",
              state: "error"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            [
              _c("outage-breadcrumbs", { attrs: { incident: _vm.incident } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "town-incident__row gds-flex-container",
                  class: { collapsed: _vm.detailCollapsed }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "town-incident__column--details incident-details"
                    },
                    [
                      _vm.incident.isPlanned && _vm.FF_PlannedOutage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--left gds-space-stack-m"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "planned-outage__top-label-and-icon gds-font-size-l"
                                },
                                [_vm._v("Planned Outage")]
                              )
                            ]
                          )
                        : !_vm.incident.isPlanned &&
                          _vm.incidentStatus === "Resolved"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--left gds-space-stack-m"
                            },
                            [
                              _c("div", { staticClass: "outage-no-icon" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "gds-font-size-l" }, [
                                _vm._v("Resolved Outage Incident")
                              ])
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--left gds-space-stack-m"
                            },
                            [
                              _c("div", { staticClass: "outage-yes-icon" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "gds-font-size-l" }, [
                                _vm._v("Active Incident")
                              ])
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "town-incident__street-name gds-text--capitalize gds-font-size-xxl"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.incident.street
                                  ? _vm.incident.street.toLowerCase()
                                  : ""
                              ) +
                              " " +
                              _vm._s(_vm.incident.counter) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.incident.town) +
                              ", VT\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.messagesExist && _vm.FF_OutagesMessaging
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "town-incident__single-incident-wrapper"
                            },
                            [
                              _c("h5", [_vm._v("Messages")]),
                              _vm._v(" "),
                              !_vm.expandMessageList
                                ? _c(
                                    "section",
                                    {
                                      staticClass:
                                        "outage-messages__detail-button",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoMessageDetail(
                                            _vm.messagesArray[0].messageId,
                                            _vm.incidentid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "outage-messages__flex-heading"
                                        },
                                        [
                                          _c("span", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getPreviewText(
                                                    _vm.messagesArray[0].title,
                                                    15
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "gds-text-grey" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatMessageTime(
                                                    _vm.messagesArray[0].sendAt
                                                      ? _vm.messagesArray[0]
                                                          .sendAt
                                                      : _vm.messagesArray[0]
                                                          .createdAt
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPreviewText(
                                              _vm.messagesArray[0].fullMessage,
                                              30
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.messagesArray, function(message) {
                                return _c("div", { key: message.messageId }, [
                                  _vm.expandMessageList
                                    ? _c(
                                        "section",
                                        {
                                          staticClass:
                                            "outage-messages__detail-button",
                                          on: {
                                            click: function($event) {
                                              return _vm.gotoMessageDetail(
                                                message.messageId,
                                                _vm.incidentid
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "outage-messages__flex-heading"
                                            },
                                            [
                                              _c("span", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getPreviewText(
                                                        message.title,
                                                        15
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "gds-text-grey"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatMessageTime(
                                                        message.sendAt
                                                          ? message.sendAt
                                                          : message.createdAt
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getPreviewText(
                                                  message.fullMessage,
                                                  30
                                                )
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              }),
                              _vm._v(" "),
                              _vm.messagesArray.length > 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "outage-messages__collapsible-menu",
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleViewMessages()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.expandMessageList
                                            ? "Hide All"
                                            : "View All (" +
                                                _vm.messagesArray.length +
                                                ")"
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "town-incident__single-incident-wrapper gds-font-size-l gds-font-demi"
                        },
                        [
                          _c("incident-progress", {
                            attrs: { incident: _vm.incident }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.myAffectedAccount
                        ? _c(
                            "div",
                            { staticClass: "town-incident__affected-account" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-font-size-l gds-font-demi gds-space-stack-m"
                                },
                                [_vm._v("Your affected location")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-flex-container gds-flex-container--left"
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "town-incident__column--details--home-icon",
                                    attrs: {
                                      src:
                                        "/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-alert.svg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-linebreaks gds-font-size-m gds-font-demi"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("addressFormat")(
                                            _vm.myAffectedAccount.address,
                                            {
                                              separator: "\n"
                                            }
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.affectedTowns
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "town-incident__affected-towns gds-font-size-l gds-font-demi"
                            },
                            [
                              _vm._v(
                                "\n          This " +
                                  _vm._s(
                                    _vm.incident.isPlanned &&
                                      _vm.FF_PlannedOutage
                                      ? "planned outage"
                                      : "incident"
                                  ) +
                                  "\n          " +
                                  _vm._s(
                                    _vm.incidentStatus === "Resolved"
                                      ? "affected"
                                      : "is affecting"
                                  ) +
                                  "\n          " +
                                  _vm._s(_vm.incident.customerCount) +
                                  " customer" +
                                  _vm._s(
                                    _vm.incident.customerCount > 1 ? "s" : ""
                                  ) +
                                  "\n          " +
                                  _vm._s(
                                    _vm.myAffectedAccount
                                      ? "(including you)"
                                      : ""
                                  ) +
                                  "\n          in " +
                                  _vm._s(
                                    _vm.affectedTowns.length > 1
                                      ? _vm.affectedTowns.length + " towns"
                                      : _vm.incident.town
                                  ) +
                                  ".\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.affectedTowns && _vm.affectedTowns.length >= 1
                        ? _c(
                            "div",
                            _vm._l(_vm.affectedTowns, function(town) {
                              return _c(
                                "div",
                                {
                                  key: town.name,
                                  staticClass:
                                    "town-incident__affected-towns-list"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-flex-container gds-flex-container--space-between"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Town",
                                              params: { townname: town.slug }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(town.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "gds-font-size-m" },
                                        [
                                          _vm._v(
                                            _vm._s(town.customers) +
                                              " customer" +
                                              _vm._s(
                                                town.customers > 1 ? "s" : ""
                                              )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.incidentStatus === "Resolved"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "resolved-outage-info-box gds-flex-container gds-flex-container--top"
                            },
                            [
                              _c("img", {
                                staticClass: "resolved-incident-icon",
                                attrs: {
                                  src:
                                    "/wp-content/themes/gmptwentynineteen/assets/images/icon-incident-orange.svg"
                                }
                              }),
                              _vm._v(" "),
                              _vm._m(0)
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "town-incident__column--map gds-position--relative"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "incident-row-collapse-toggle incident-page",
                          class: { collapsed: _vm.detailCollapsed },
                          attrs: {
                            title:
                              (_vm.detailCollapsed ? "View" : "Close") +
                              " Incident Details"
                          },
                          on: { click: _vm.ToggleCollapsed }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "incident-row-collapse-toggle--text"
                            },
                            [_vm._v("View Incident Details")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("gmp-map", {
                        ref: "map",
                        attrs: { kind: "incident", incidentId: _vm.incidentid },
                        on: {
                          centered: function($event) {
                            _vm.isCentered = $event
                          },
                          update: _vm.onMapUpdate
                        }
                      }),
                      _vm._v(" "),
                      !_vm.isCentered
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "recenter-button incident-recenter gds-button gds-round gds-secondary",
                              class: { collapsed: _vm.detailCollapsed },
                              attrs: { id: "recenter-button" },
                              on: {
                                click: function($event) {
                                  return _vm.Recenter()
                                }
                              }
                            },
                            [_vm._v("Back to incident")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        { staticClass: "gds-font-demi gds-font-size-l gds-space-stack-s" },
        [_vm._v("Power Still Out?")]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If your power is still out please check your circuit breaker. Sometimes the power is restored, but\n              requires restarting the breaker to come back on. If this still does not resolve the issue, please report\n              another outage."
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "report_outage_btn gds-button gds-round gds-secondary gds-compact",
          attrs: { href: "/report-outage" }
        },
        [
          _c("span", { staticClass: "gds-button__text" }, [
            _vm._v("Report Outage")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }