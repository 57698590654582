<template>
  <span>
    <button
    :disabled="disabled"
    @click="buttonClicked()"
    class="view-options-button"
    v-bind:style="{ width: buttonWidth }">
      <div>{{buttonText}}</div>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButton",
  props: {
    disabled: Boolean,
    buttonText: String,
    buttonWidth: String,
    buttonClicked: Function
  }
};
</script>

<style scoped>
</style>