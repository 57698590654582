import { forEach } from '../functions';
import mustache from 'mustache/mustache.min.js';
import configureSearchInput from '../helpers/configure-search-input';

const hideClass = 'gds-visibility--hide';

const KEY_CODE_MAP = {
  TAB: 9,
  ENTER: 13,
  ESC: 27
};

const templateStringTown = `
  <div class="lookup-tool-results__town">
    <div class="lookup-tool-results__top">
      <h1 class="lookup-tool-results__heading">{{post_title}}</h1>
      <button class="gds-button-circle gds-secondary" aria-label="close" data-action="designer-search--close"></button>
    </div>
    {{&offices}}
  </div>
  `;

const templateStringOffice = `
  <div class="lookup-tool-results__office">
    {{#office_name}}
      <h2 class="lookup-tool-results__subheading--service-center">{{office_name}}</h2>
    {{/office_name}}
    {{#office_address}}
      <p>{{&office_address}}</p>
    {{/office_address}}
    <h2 class="lookup-tool-results__subheading--post-type-name">Designers</h2>
    <div class="lookup-tool-results__result-values">
      {{&designers}}
    </div>
  </div>
  `;

const templateStringDesigner = `
  <div class="lookup-tool-results__result-value">
    {{#thumb}}
      <div class="lookup-tool-results__result-value--image-container">
        <img class="" src="{{.}}" />
      </div>
    {{/thumb}}
    <div class="lookup-tool-results__result-value--info">
      {{#title}}
        <h3 class="lookup-tool-results__result-value--title">{{title}}</h3>
      {{/title}}
      {{#phone}}
        <p class="lookup-tool-results__result-value--phone">{{&phone}}</p>
      {{/phone}}
      {{#email}}
        <a class="lookup-tool-results__result-value--email" href="mailto:{{email}}" target="_blank">{{email}}</a>
      {{/email}}
      {{#towns}}
        <p class="lookup-tool-results__result-value--town">Communities served in whole or part: {{towns}}</p>
      {{/towns}}
    </div>
  </div>
  `;

const onDesignerSearchSelection = function (feedback) {
  feedback.event.preventDefault();

  const params = {
    action: 'gmp_get_designers',
    town_id: feedback.selection.value.id
  }

  const url = site.url + '/wp-admin/admin-ajax.php?' + jQuery.param(params);

  jQuery.get(url, [], function (response) {
    let offices = "";
    forEach(response.offices, function (i, office) {
      let designers = "";
      forEach(office.designers, function (i, designer) {
        designers = designers + mustache.render(templateStringDesigner, designer);
      });
      office.designers = designers;
      offices = offices + mustache.render(templateStringOffice, office);
    });
    const town = response.town;
    town.offices = offices;
    jQuery('#distribution-designers .results').show();
    const resultsDiv = document.querySelector('#distribution-designers .results');
    resultsDiv.innerHTML = mustache.render(templateStringTown, town);
    jQuery('#designer-search-results-window').addClass(hideClass);
  });
};

const closeDesignerSearch = function () {
  const resultsDiv = jQuery('#distribution-designers .results');
  if (undefined == resultsDiv) return;
  resultsDiv.hide();
  jQuery('#designer-search-input').val('');
}

export default function init() {
  configureSearchInput(
    '.lookup-tool__search-form',
    '#designer-search-input',
    '#designer-search-results-window',
    false,
    ['&subtype=town', '&post_type=town'],
    onDesignerSearchSelection,
    true
  );
  jQuery('#designer-search-bar-close').hide();
  jQuery('#distribution-designers .results').hide();
  jQuery('#designer-search-input').on("input", function () {
    if (jQuery(this).val().length > 0) {
      jQuery('#designer-search-bar-close').show();
    } else {
      jQuery('#designer-search-bar-close').hide();
    }
  });
  jQuery(document).on('click', '[data-action="designer-search--close"]', function (event) {
    event.preventDefault()
    closeDesignerSearch();
  });

}
