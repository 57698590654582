<template>
  <span>
    <button :disabled="disabled" @click="showCalendar()" title="Calendar"  class="date-navigation__button date-navigation__button__calendar">
      <svg class="gds-icon calendar">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonCalendar",
  props: {
    showCalendar: Function,
    buttonLabel: String,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>