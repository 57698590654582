<template>
  <div v-if="currentAccount" class="top-card-display__single-card top-card-display__single-card--account-dashboard top-card-display__single-card--logged-in">
    <div> <!--duplicates structure of other cards -->
      <a href="/account" class="top-banner-card gds-card gds-background-forestgreen gds-invert">
        <div class="gds-card__contents">
          <h3 class="home-card__title--small-margin gds-card__title">Account Dashboard</h3>
          <p class="home-card__body--small-margin gds-card__body">View and manage your service account.</p>
          <div class="gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAccountDashboard",
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
  async mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>