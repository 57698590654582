var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    !_vm.disabled
      ? _c(
          "button",
          {
            staticClass:
              "date-navigation__button date-navigation__button__calendar date-navigation__button__calendar__text-added",
            style: { width: _vm.buttonWidth || _vm.defaultButtonWidth },
            attrs: { title: "Calendar" },
            on: {
              click: function($event) {
                return _vm.jumpToDate()
              }
            }
          },
          [
            _c("svg", { staticClass: "gds-icon calendar" }, [
              _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "date-navigation__button__calendar__label" },
              [_vm._v(_vm._s(_vm.buttonLabel || _vm.defaultButtonLabel))]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.disabled
      ? _c(
          "button",
          {
            staticClass:
              "date-navigation__button date-navigation__button__calendar date-navigation__button__calendar__text-added",
            style: { width: _vm.buttonWidth || _vm.defaultButtonWidth },
            attrs: { disabled: "", title: "Calendar" }
          },
          [
            _c("svg", { staticClass: "gds-icon calendar" }, [
              _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "date-navigation__button__calendar__label" },
              [_vm._v(_vm._s(_vm.buttonLabel || _vm.defaultButtonLabel))]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }