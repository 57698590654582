var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "export-data__modal-box" },
          [
            _c("FocusLock", [
              _c("header", { staticClass: "delete-user__modal-box--header" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "close gds-button-circle gds-icon-close-small gds-secondary",
                    on: {
                      click: function($event) {
                        return _vm.Close()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "gds-visibility--hide-visually" },
                      [_vm._v("Close")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "export-data__modal-box--headline" }, [
                  _vm._v("Are you sure you want to remove this account?")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "delete-user__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__modal-box--message gds-space-stack-l"
                  },
                  [
                    _vm._v(
                      "Removing this account only removes your online access and does not cancel your Green Mountain Power service. You can cancel your service online at the "
                    ),
                    _c("a", { attrs: { href: "/start-stop-move-service" } }, [
                      _vm._v("Start, Stop, or Move")
                    ]),
                    _vm._v(" page, or by calling us at (888) 835-4672.")
                  ]
                ),
                _vm._v(" "),
                _vm.errorRemove
                  ? _c(
                      "div",
                      { staticClass: "form-message-box gds-space-stack-m" },
                      [_vm._v(_vm._s(_vm.errorRemove))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact gds-space-stack-m",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Close()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Cancel")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m",
                    class: { "gds-loading": _vm.pendingRemove },
                    attrs: { disabled: _vm.pendingRemove, type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Save()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Yes, Remove Account")
                    ])
                  ]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }