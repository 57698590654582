<template>
  <div ref="usageHistoryContainer" class="daily-usage-history">
    <div class="usage-dashboard__headings--in-boxes">Past 7 Days</div>
    <a  href="/account/usage-new/detail/table" class="usage-dashboard__circle-arrow-right"></a>
    <div class="daily-usage-history__data-container">
      <div v-if="loadState === 'complete'">
        <div class="daily-usage-history__table-heading-row">
          <div :class="usageHeadingCellClass">Date</div>
          <div :class="usageHeadingCellClass">Usage</div>
          <div v-if="hasGenerationRecordsPresent" :class="usageHeadingCellClass">Generation</div>
          <div :class="usageHeadingCellClass">Conditions</div>
        </div>
        <div class="daily-usage-history__horizontal-line"></div>
        <div v-for="(usage, index) of dailyUsageData" :key="usage.date.getTime()">
          <Widget-Daily-Usage-History-Table-Row class="daily-usage-history__table-row"
            :usage="usage" 
            :hasGenerationRecordsPresent="hasGenerationRecordsPresent"
            :maxUsageValue="maxUsageValue">
          </Widget-Daily-Usage-History-Table-Row>
          <div v-if="index != dailyUsageData.length-1" class="daily-usage-history__horizontal-line"></div>
        </div>
      </div>
    </div>
    <div v-if="!loadState" class="daily-usage-history__spinner"></div>
    <div v-if="loadState === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadState === 'error'" class="error-message">An unexpected error occurred, please try again later.</div>
  </div>
</template>

<script>

import { subDays } from 'date-fns';
import GMPAPI from "../../../../services/gmpapi";
import { ToServerDateTruncate, DumpError } from '../../../../utilities';
import WidgetDailyUsageHistoryTableRow from './WidgetDailyUsageHistoryTableRow';
import usageTableUtil from "../../../mixins/UsageTableUtil";


export default {
  name: "WidgetDailyUsageHistory",
  mixins: [usageTableUtil],
  components: {
    WidgetDailyUsageHistoryTableRow
  },
  data() {
    return {
      dailyUsageData: undefined,
      hasGenerationRecordsPresent: undefined,
      maxUsageValue: undefined,
      loadState: undefined,
      usageAmountSectionWidth: 0
    }
  },
  watch: {
    currentAccount() {
      this.dailyUsageData;
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
      const endDate = subDays(new Date(), 1);
      const startDate = subDays(endDate, 6);
      this.refreshData(this.currentAccount.accountNumber, startDate, endDate)
  },
  watch: {
    currentAccount() {
      const endDate = subDays(new Date(), 1);
      const startDate = subDays(endDate, 6);
      this.refreshData(this.currentAccount.accountNumber, startDate, endDate);
    },
  },
  methods: {
    sortReverseChronological(usageData) {
      return usageData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    determineWeatherIcon() {
      const dailyUsageWithWeatherIcon = this.dailyUsageData.map(item => {
          if (item && (item.temperature || item.darkskyTemperature)) {
            return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon) };
          }
          return { ...item, weatherIconImageUrl: "" };
        });
        return dailyUsageWithWeatherIcon;
    },
    async refreshData(accountNumber, startDate, endDate) {
      try {
        this.resetData();
        const response = await GMPAPI.GetPeriodUsage(accountNumber, "daily", ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
        this.dailyUsageData = response.intervals[0].values;

        this.dailyUsageData = await this.determineWeatherIcon();

        this.dailyUsageData = this.sortReverseChronological(this.dailyUsageData);
        this.hasGenerationRecordsPresent = this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION);
        this.maxUsageValue = this.findMaxUsageValue(this.dailyUsageData);
        this.setStyles();
        this.loadState = 'complete';

        this.$nextTick(() => {
          window.addEventListener('resize', this.throttle(this.onResize, 500));
          this.usageAmountSectionWidth = this.$refs.usageHistoryContainer.clientWidth * .3;
        });

      } catch (err) {
        this.handleRefreshError(err);
      }
    },
    handleRefreshError(err) {
      if (err.response && err.response.status === 404) {
        this.loadState = "unavailable";
        return;
      }
      this.loadState = 'error';
      DumpError("Error getting usage data.", err);
    },
    resetData() {
      this.loadState = undefined;
      this.dailyUsageData = [];
    },
    setStyles() {
      if (this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION)) {
        this.usageHeadingCellClass = 'daily-usage-history__generation-heading-cell';
      } else {
        this.usageHeadingCellClass = 'daily-usage-history__flat-rate-heading-cell';
      }
    },
    onResize() {
      this.usageAmountSectionWidth = this.$refs.usageHistoryContainer.clientWidth * .3;
    },
    // function used to throttle onResize function above
    throttle(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>