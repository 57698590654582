<template>
  <span
  v-bind:style="{ width: spanWidth }"
  class="usage-detail__select"
  :class="{ 'usage-detail__select--button-appearance': configCopied.buttonAppearance }">
    <label class="">
      <select
      @change="onSelectChange($event)"
      v-bind:style="{ width: selectWidth }"
      class="usage-detail__select__option">
        <option
        :key="i"
        :value="option.value"
        v-for="(option, i) in configCopied.options"
        :selected="option.selected">{{option.label}}</option>
      </select>
    </label>
  </span>
</template>

<script>
const defaultWidth = '165px';

export default {
  name: "UsageDetailSelect",
  props: {
    config: Object,
    optionSelected: Function
  },
  data() {
    return {
      configCopied: this.copyObject(this.config),
      spanWidth: this.config.width || defaultWidth,
      selectWidth: this.config.width || defaultWidth
    };
  },
  mounted() {
    if (this.config.width) {
      this.setSelectWidth(this.config.width);
    }

    this.setSelectedOption(this.configCopied.options, this.configCopied.selectedValue);
  },
  watch: {
    config(val) {
      this.configCopied = this.copyObject(val);
      this.spanWidth = this.configCopied.width || defaultWidth;
      this.setSelectWidth(this.configCopied.width);
      this.setSelectedOption(this.configCopied.options, this.configCopied.selectedValue);
    }
  },
  methods: {
    onSelectChange($event) {
       this.optionSelected($event.target.value);
    },
    copyObject(objectToCopy) {
     return JSON.parse(JSON.stringify(objectToCopy));
    },
    setSelectWidth(originalWidth) {
      this.selectWidth = (parseInt(originalWidth) - 20) + 'px';
    },
    getMatchingOption(targetArray, selectedValue) {
      let foundElement = undefined;
      targetArray.forEach(e => {if (e.value === selectedValue) foundElement = e});
      return foundElement;
    },
    setSelectedOption(options, selectedValue) {
      if (selectedValue === undefined) {return; }

      const matchingOption = this.getMatchingOption(options, selectedValue);

      if (!matchingOption) {return; }

      options.forEach(option => option.selected = false);
      matchingOption.selected = true;
    }
  }
};
</script>

<style scoped>
</style>