<template>
  <div v-if="visible" class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="delete-user__modal-box--header">
          <button @click="Close()" class="close gds-button-circle gds-icon-close-small gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
          </button>
          <div class="export-data__modal-box--headline">Are you sure you want to remove this account?</div>
        </header>
        <div class="delete-user__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l">Removing this account only removes your online access and does not cancel your Green Mountain Power service. You can cancel your service online at the <a
            href="/start-stop-move-service"
          >Start, Stop, or Move</a> page, or by calling us at (888) 835-4672.</div>
          <div v-if="errorRemove" class="form-message-box gds-space-stack-m">{{errorRemove}}</div>
          <button @click="Close()" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
            <span class="gds-button__text">Cancel</span>
          </button>
          <button @click="Save()" :disabled="pendingRemove" :class="{ 'gds-loading': pendingRemove }" type="button" class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m">
            <span class="gds-button__text">Yes, Remove Account</span>
          </button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';
import { GMPPhoneNumber } from '../../../environment';

export default {
  name: "ModalRemoveAccount",
  props: ["visible"],
  components: {
  },
  data() {
    return {
      pendingRemove: false,
      errorRemove: undefined,
      GMPPhoneNumber,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.pendingRemove = true;
      try {
        // Remove it
        await GMPAPI.RemoveUserAccount(this.userInfo.username, this.currentAccount.accountNumber);
        // Get new accounts
        await this.$store.dispatch("FetchUserInfo");
        this.Close();
        window.location.href = "/account";
      } catch (err) {
        DumpError("Remove account error", err);
        this.errorRemove = "There was an unexpected error removing your account.";
      } finally {
        this.pendingRemove = false;
      }
    },
    Close() {
      this.$emit("complete");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>