var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--outages-near-you"
    },
    [
      _vm.currentAccount
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "top-banner-card gds-card top-banner-card--logged-in card-box-shadow",
                class: { "gds-loading": _vm.loadState === undefined }
              },
              [
                _vm.loadState === "complete"
                  ? _c(
                      "figure",
                      {
                        staticClass:
                          "gds-card__image-container gds-aspect-silver"
                      },
                      [
                        _c("div", { staticClass: "gds-card__image" }, [
                          _c("img", {
                            staticClass: "gds-card__image",
                            attrs: {
                              src: _vm.outageSummary.screenshotUrl,
                              alt: "The Green Mountain Power Outage Center"
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "gds-card__contents" }, [
                  _c("div", [
                    _c(
                      "p",
                      { staticClass: "gds-display-0 gds-space-stack-s" },
                      [_vm._v("Outages in Your Town")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "card--outages-near-you__caption gds-card__caption"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressTownState")(
                              _vm.currentAccount.address
                            )
                          )
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.loadState === "complete" && _vm.townOutages !== undefined
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "card--outages-near-you__number card__data-container--large-text gds-align--text-center"
                        },
                        [_vm._v(_vm._s(_vm.townOutages))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loadState === "error"
                    ? _c("div", { staticClass: "card-error" }, [
                        _vm._v("\n          Unable to load,\n          "),
                        _c("br"),
                        _vm._v("please try again later.\n        ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loadState !== undefined
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "gds-card__button gds-button gds-secondary gds-round gds-align--block-center gds-button-full",
                          attrs: { href: "/outages" }
                        },
                        [_vm._v("View Outage Map")]
                      )
                    : _vm._e()
                ])
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }