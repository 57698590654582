var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "usage-detail__select",
      class: {
        "usage-detail__select--button-appearance":
          _vm.configCopied.buttonAppearance
      },
      style: { width: _vm.spanWidth }
    },
    [
      _c("label", {}, [
        _c(
          "select",
          {
            staticClass: "usage-detail__select__option",
            style: { width: _vm.selectWidth },
            on: {
              change: function($event) {
                return _vm.onSelectChange($event)
              }
            }
          },
          _vm._l(_vm.configCopied.options, function(option, i) {
            return _c(
              "option",
              {
                key: i,
                domProps: { value: option.value, selected: option.selected }
              },
              [_vm._v(_vm._s(option.label))]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }