var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "my-account__sidebar__nav" },
    [
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: { active: _vm.subPage === "" },
          attrs: { to: "/" }
        },
        [_vm._v("User Settings")]
      ),
      _vm._v(" "),
      !_vm.isAdmin
        ? _c(
            "router-link",
            {
              staticClass: "my-account__sidebar__nav-item",
              class: { active: _vm.subPage === "/accounts" },
              attrs: { to: "/accounts" }
            },
            [_vm._v("Add / Manage Accounts")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "my-account__sidebar__nav-item",
          attrs: { href: "/account", id: "arrow-after-link" }
        },
        [_vm._v("My Account Dashboard")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }