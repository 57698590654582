var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    _c(
      "section",
      { staticClass: "my-account__login-form-wrap" },
      [
        _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
          _vm._v("My Account")
        ]),
        _vm._v(" "),
        _vm.loadState === undefined
          ? _c("div", { staticClass: "table-loading" })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "error"
          ? _c("flow-error", {
              attrs: {
                name: "Logging in",
                state: "error",
                phonetext:
                  "To report an outage or make a payment by phone call us at"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "maintenance"
          ? _c("flow-error", {
              attrs: {
                name: "Logging in",
                state: "maintenance",
                phonetext:
                  "To report an outage or make a payment by phone call us at"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "complete"
          ? _c("div", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.Login()
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    {
                      staticClass: "gds-fieldset gds-space-stack-m",
                      class: { "gds-has-error": _vm.errorlogin }
                    },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Username or Email")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.username,
                              expression: "username"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "gds-input-field__input",
                          attrs: {
                            id: "username",
                            type: "text",
                            spellcheck: "false",
                            autocapitalize: "none",
                            autocomplete: "username",
                            required: "",
                            "aria-required": "true"
                          },
                          domProps: { value: _vm.username },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.username = $event.target.value
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    {
                      staticClass: "gds-fieldset gds-space-stack-m",
                      class: { "gds-has-error": _vm.errorlogin }
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Password")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            attrs: { autocomplete: "current-password" },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "global-login-panel__form-helper" },
                        [
                          _c(
                            "label",
                            { staticClass: "gds-checkbox gds-checkbox-thin" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.stayloggedin,
                                    expression: "stayloggedin"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "stay-logged-in"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.stayloggedin)
                                    ? _vm._i(_vm.stayloggedin, null) > -1
                                    : _vm.stayloggedin
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.stayloggedin,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.stayloggedin = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.stayloggedin = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.stayloggedin = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-checkbox__faux" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gds-checkbox__label gds-display-00 gds-text-grey"
                                },
                                [_vm._v("Stay Logged In")]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(1)
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-space-stack-l" }, [
                    _vm.errorlogin
                      ? _c(
                          "div",
                          {
                            staticClass: "gds-helper-text-error",
                            attrs: { id: "submission_error" }
                          },
                          [_vm._v(_vm._s(_vm.errorlogin))]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-space-stack-l" }, [
                    _c(
                      "button",
                      {
                        staticClass: "gds-button",
                        class: { "gds-loading": _vm.pendingLogin },
                        attrs: { disabled: _vm.pendingLogin, type: "submit" }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Log In")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(2)
                ]
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-font-size-l gds-space-stack-m" }, [
      _vm._v(
        "\n        Log in to view your account information. Don’t have an account?\n        "
      ),
      _c("a", { attrs: { href: "/account/create" } }, [
        _vm._v("Create an account")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-login-panel__form-helper" }, [
      _c(
        "a",
        {
          staticClass: "gds-display-00 gds-link gds-link--bold",
          attrs: { href: "/account/forgot" }
        },
        [_vm._v("Forgot Password?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-panel__form-helpers" }, [
      _c(
        "p",
        {
          staticClass:
            "gds-align--text-center gds-body-small gds-link gds-link--bold"
        },
        [
          _vm._v("\n            Don't have an account?\n            "),
          _c("a", { attrs: { href: "/account/create" } }, [
            _vm._v("Get Started")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }