var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showable
    ? _c(
        "div",
        { staticClass: "widget-floating-helper" },
        [
          _c(
            "div",
            {
              staticClass: "need-help-button gds-font-demi",
              attrs: { tabindex: "0" },
              on: {
                click: function($event) {
                  return _vm.ToggleExpanded()
                }
              }
            },
            [_vm._v("Need Help?")]
          ),
          _vm._v(" "),
          _vm.expanded
            ? _c("div", { staticClass: "helper-window" }, [
                _c("div", { staticClass: "header-row gds-space-stack-m" }, [
                  !_vm.loggedInUser && _vm.FF_GlobalChatFlag
                    ? _c("div", { staticClass: "helper-window__intro" }, [
                        _vm._v(
                          "Need help? Please log in to chat with our team."
                        )
                      ])
                    : !_vm.chatOnline
                    ? _c("div", { staticClass: "helper-window__intro" }, [
                        _vm._v(
                          "Need help? Here are ways to get answers to your questions."
                        )
                      ])
                    : _c("div", { staticClass: "helper-window__intro" }, [
                        _vm._v(
                          "Need help? Chat with our team to get answers to your questions."
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "helper-window__close-button",
                      on: {
                        click: function($event) {
                          _vm.expanded = false
                        }
                      }
                    },
                    [
                      _c("svg", { staticClass: "gds-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href":
                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                          }
                        })
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.FF_GlobalChatFlag
                  ? _c("div", { staticClass: "webchat-button-wrap" }, [
                      !_vm.loggedInUser
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "gds-button gds-round webchat-button gds-space-stack-m",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.KickToLogin()
                                }
                              }
                            },
                            [_vm._v("Log In to Chat")]
                          )
                        : _vm.chatLoaded && _vm.chatOnline
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "gds-button gds-round webchat-button gds-space-stack-m",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.StartWebchat()
                                }
                              }
                            },
                            [_vm._v("Start Chat")]
                          )
                        : _vm.chatLoaded && !_vm.chatOnline
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "gds-button gds-round gds-secondary gds-space-stack-m webchat-button gds-space-stack-m",
                              attrs: { disabled: "", type: "button" }
                            },
                            [_vm._v("Chat is offline")]
                          )
                        : _c("button", {
                            staticClass:
                              "gds-button gds-round gds-secondary gds-space-stack-m webchat-button gds-loading gds-space-stack-m",
                            attrs: { disabled: "", type: "button" }
                          })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "call-us-row" }, [
                  _vm._v("\n      Call us at\n      "),
                  _c(
                    "a",
                    {
                      staticClass: "gds-nobreak gds-visibility--show-mobile",
                      attrs: { href: "tel:" + _vm.GMPPhoneNumber }
                    },
                    [_vm._v(_vm._s(_vm.GMPPhoneNumber))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "gds-nobreak gds-visibility--show-desktop" },
                    [_vm._v(_vm._s(_vm.GMPPhoneNumber))]
                  )
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm.loggedInUser
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "gds-link gds-link--bold",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function($event) {
                              return _vm.ShowFeedback()
                            }
                          }
                        },
                        [_vm._v("Provide Website Feedback")]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("modal-floating-feedback", {
            attrs: { visible: _vm.showFeedback },
            on: {
              close: function($event) {
                _vm.showFeedback = false
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m" }, [
      _c(
        "a",
        { staticClass: "gds-link gds-link--bold", attrs: { href: "/contact" } },
        [_vm._v("More ways to Contact Us")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m" }, [
      _c(
        "a",
        {
          staticClass: "gds-link gds-link--bold",
          attrs: { href: "/help-center" }
        },
        [_vm._v("View Help Center")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }