var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "export-data__modal-box" },
          [
            _c("FocusLock", [
              _c("header", { staticClass: "delete-user__modal-box--header" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "close gds-button-circle gds-icon-close-small gds-secondary",
                    on: {
                      click: function($event) {
                        return _vm.Close()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "gds-visibility--hide-visually" },
                      [_vm._v("Close")]
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.completedRemove
                  ? _c(
                      "div",
                      { staticClass: "export-data__modal-box--headline" },
                      [_vm._v("Are you sure you want to cancel this payment?")]
                    )
                  : _c(
                      "div",
                      { staticClass: "export-data__modal-box--headline" },
                      [_vm._v("Payment cancelled successfully")]
                    )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "delete-user__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__modal-box--message gds-space-stack-l"
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Your payment of " +
                          _vm._s(
                            _vm._f("currency")(_vm.payment.totalPaymentAmount)
                          ) +
                          " scheduled for " +
                          _vm._s(
                            _vm._f("dateFull")(_vm.payment.scheduledPaymentDate)
                          ) +
                          " " +
                          _vm._s(_vm.payment.methodSlug)
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.completedRemove
                      ? _c("span", [_vm._v("will be cancelled.")])
                      : _c("span", [_vm._v("was cancelled.")])
                  ]
                ),
                _vm._v(" "),
                !_vm.completedRemove
                  ? _c("div", [
                      _vm.errorRemove
                        ? _c(
                            "div",
                            {
                              staticClass: "form-message-box gds-space-stack-m"
                            },
                            [_vm._v(_vm._s(_vm.errorRemove))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-secondary gds-compact gds-space-stack-m",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.Close()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Cancel")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m",
                          class: { "gds-loading": _vm.pendingRemove },
                          attrs: {
                            disabled: _vm.pendingRemove,
                            type: "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.Save()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Yes, Cancel Payment")
                          ])
                        ]
                      )
                    ])
                  : _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-primary gds-compact gds-space-stack-m",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.Close()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Close")
                          ])
                        ]
                      )
                    ])
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }