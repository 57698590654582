var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "usage-detail__container" },
    [
      _vm.testDriveUsage
        ? _c("banner-dual-action", {
            staticClass: "gds-flex-container",
            attrs: {
              bannerColor: "blue",
              message:
                "You are trying out our new usage section. We would love to hear what you think: ",
              messageTwo: "or you can: ",
              linkOneText: "Provide Feedback",
              linkTwoText: "Switch Back to the Old Usage View"
            },
            on: {
              linkOneClicked: _vm.showProvideFeedBackModal,
              linkTwoClicked: _vm.goToLegacyUsage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobbileView || _vm.isTabletView
        ? _c("UsageDetailMobileNav")
        : _vm._e(),
      _vm._v(" "),
      _vm.isDesktopView
        ? _c("div", { staticClass: "usage-detail__nav-container" }, [
            _c("a", {
              staticClass: "usage-detail__usage-detail-nav-back-button",
              attrs: { href: "/account/usage-new" }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "usage-detail__router-link-container" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/graph" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Graph")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/table" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Table")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/time-of-day" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Time Of Day")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/seasonal" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Seasonal")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/performance" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Performance")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/compare" }
                  },
                  [
                    _c("nav", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Compare")]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("modal-floating-feedback", {
        attrs: { visible: _vm.showFeedback },
        on: {
          close: function($event) {
            _vm.showFeedback = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }