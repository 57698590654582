var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box no-scroll-modal" },
          [
            _c(
              "button",
              {
                staticClass: "close bill-pay__close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("FocusLock", [
              _c("div", { staticClass: "eicproducts__modal-box--body" }, [
                _c("div", { staticClass: "gds-space-stack-ml" }, [
                  _vm._v(
                    "The cost of electronic transactions were previously allowed only with a $4.95 convenience fee. Our regulators agreed to allow free transactions for all customers if higher cost credit card transactions were limited to two a month. It was agreed that if additional transactions were required, a customer could use the lower cost method of a bank transaction."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.close("informational")
                      }
                    }
                  },
                  [_vm._v("Okay")]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }