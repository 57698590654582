<template>
  <section class="my-account__full-width-section my-account__section-with-bottom-border">
    <!-- Loading and error display -->
    <div v-if="loadState !== 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top gds-space-stack-m">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 class="gds-display-1 gds-space-stack-m">Phones</h2>
          <div v-if="loadState === undefined" class="editor-loading" />
        </div>
        <div class="fake-edit-link">Edit</div>
      </div>
      <div v-if="loadState === 'error'" class="editor-error">Unable to load this section. Please try again later.</div>
    </div>
    <!-- Main display when loading complete -->
    <div v-if="loadState === 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <h2 class="gds-display-1 gds-space-stack-m">Phones</h2>

        <div class="edit-close-button-combo">
          <button v-if="!editing" @click="BeginEdit()" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Edit</span>
          </button>
          <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Close</span>
          </button>
        </div>
      </div>
      <!-- Display -->
      <div v-if="!editing && phoneNumbers" class="my-account__sub-section-bottom">
        <div v-for="phone of phoneNumbers" :key="phone.number" class="my-account-settings__phone-display gds-flex-container ">
          <div class="my-account-settings__phone-type gds-font-size-l">{{phone.type | phoneType | titlecase}}</div>
          <div class="my-account-settings__phone-type--number gds-font-size-l">{{phone.number | phone}}</div>
        </div>
      </div>
      <!-- Edit -->
      <form v-if="editing" @submit.prevent="Save()" class="gds-space-stack-l">
        <div v-for="(phone, index) of editedPhoneNumbers" :key="index">
          <div class="gds-flex-container gds-flex-container--left gds-space-stack-s">
            <fieldset class="gds-fieldset gds-space-stack-s gds-space-inline-default">
              <label class="gds-input-field">
                <select v-model="phone.type" @change="touched = true" class="bill-pay-input-field__select gds-input-field">
                  <option value="CELL">Cell</option>
                  <option value="HOME">Home</option>
                  <option value="WORK">Work</option>
                  <option value="BUS">Business</option>
                </select>
              </label>
            </fieldset>
            <fieldset class="gds-fieldset gds-space-stack-s gds-space-inline-default">
              <label class="gds-input-field">
                <the-mask
                  v-model="phone.number"
                  :mask="'+1 (###) ###-####'"
                  @input.native="touched = true"
                  v-validate="'required|min:10'"
                  data-vv-validate-on="input"
                  :name="'phone' + index"
                  :key="'phone' + index"
                  class="gds-input-field__input"
                  type="tel"
                  placeholder="Your phone number"
                />
              </label>
            </fieldset>
            <button
              v-if="editedPhoneNumbers.length > 1"
              @click="RemovePhone(index)"
              type="button"
              class="my-account__settings--detail-remove-btn gds-button red gds-text-button"
            >Remove</button>
          </div>
          <div v-if="errors.first('phone' + index)" class="validation-error">Complete phone number required</div>
        </div>
        <button @click="AddPhone()" type="button" class="gds-button gds-round gds-secondary">Add New</button>
        <button type="submit" :disabled="pendingSave || !touched || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round">
          <span class="gds-button__text">Save</span>
        </button>
        <div v-if="errorSave" class="form-message-box gds-space-inset-default">There was an error updating your phone numbers.</div>
        <div v-if="noPhoneNumberError" class="form-message-box gds-space-inset-default">At least one phone number is required.</div>
      </form>
    </div>
  </section>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";

export default {
  name: "EditorPhones",
  data() {
    return {
      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: false,
      noPhoneNumberError: false,

      phoneNumbers: undefined,
      personId: undefined,
      editedPhoneNumbers: undefined,

      loadState: undefined,
    };
  },
  components: {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  filters: {
    phoneType: function (value) {
      if (value === "BUS") return "BUSINESS";
      return value;
    },
  },
  methods: {
    async RefreshStatus() {
      this.phoneNumbers = undefined;
      this.editedPhoneNumbers = undefined;
      this.editing = false;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        const info = await GMPAPI.GetPhoneNumbers(this.currentAccount.accountNumber)
        this.phoneNumbers = info.phones;
        this.personId = info.personId;
        this.loadState = "complete";
      } catch (err) {
        DumpError("Phone number editor refresh error", err);
        if (
          err.response &&
          err.response.data &&
          err.response.data.code === 81) {
          this.loadState = "complete";
        } else {
          this.loadState = "error";
        }
      }
    },
    BeginEdit() {
      // Copy current value to editor
      const editedPhoneNumbers = [];
      if (this.phoneNumbers) {
        for (const phone of this.phoneNumbers) {
          editedPhoneNumbers.push({ number: phone.number, type: phone.type });
        }
      }
      this.editedPhoneNumbers = editedPhoneNumbers;
      this.editing = true;
      this.touched = false;
      this.pendingSave = false;
      this.errorSave = false;
      this.noPhoneNumberError = false;
    },
    async RemovePhone(index) {
      this.touched = true;
      this.editedPhoneNumbers.splice(index, 1);
    },
    async AddPhone() {
      this.touched = true;
      this.editedPhoneNumbers.push({ type: "CELL", number: "" });
    },
    async Save() {
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        this.errorSave = undefined;
        this.noPhoneNumberError = undefined;
        try {
          const sequenced = this.editedPhoneNumbers.map((item, index) => ({ ...item, sequence: (index + 1).toString() }));
          await GMPAPI.UpdatePhoneNumbers(this.currentAccount.accountNumber, this.personId, sequenced);
          this.editing = false;
          await this.RefreshStatus();
        } catch (err) {
          DumpError("Update phones error", err);
          if (
            err.response &&
            err.response.data &&
            err.response.data.field === "phones") {
            this.noPhoneNumberError = true;
          } else {
            this.errorSave = true;
          }
        } finally {
          this.pendingSave = false;
        }
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>