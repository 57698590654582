var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.incidentStatus !== "Resolved"
      ? _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.formatEtr(
                  _vm.incident.estimatedRestoredAt,
                  _vm.incident.etrEarliest,
                  _vm.incident.etrLatest
                )
              )
          )
        ])
      : _vm.incident.restoredAt && _vm.incidentStatus === "Resolved"
      ? _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(
            "Power was restored on " +
              _vm._s(_vm._f("dateRestored")(_vm.incident.restoredAt)) +
              "."
          )
        ])
      : _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(_vm._s(_vm.etrText))
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "pi-graph-bkgr gds-space-stack-s" }, [
      _vm.incidentStatus === "Resolved"
        ? _c("div", { staticClass: "pi-section pi-section-full" }, [
            _c(
              "div",
              {
                staticClass: "pi-section pi-section-half",
                attrs: { slot: "" },
                slot: "default"
              },
              [_c("div", { staticClass: "pi-section-checkmark" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pi-section-checkmark" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.incidentStatus !== "Resolved"
        ? _c("div", { staticClass: "pi-section pi-section-half" }, [
            _c("div", { staticClass: "pi-section-checkmark" })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
      },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "gds-font-size-s gds-font-demi gds-space-stack-xs" },
            [_vm._v("Reported")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-font-size-m gds-font-book gds-text-grey" },
            [_vm._v(_vm._s(_vm._f("dateRestored")(_vm.incident.createdAt)))]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "pi-graph__restored gds-font-size-s gds-font-demi gds-space-stack-xs",
              class: { active: _vm.incidentStatus === "Resolved" }
            },
            [_vm._v("Restored")]
          ),
          _vm._v(" "),
          _vm.incident.restoredAt
            ? _c(
                "div",
                { staticClass: "gds-font-size-m gds-font-book gds-text-grey" },
                [
                  _vm._v(
                    _vm._s(_vm._f("dateRestored")(_vm.incident.restoredAt))
                  )
                ]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }