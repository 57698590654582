<template>
  <div v-if="visible" class="gmp-modal">
    <div class="outage-map-legend" v-click-outside="Close">
      <button @click="Close()" type="button" class="close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <label class="outage-map-legend__title">Map Legend</label>
      <div>
        <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--wrap">
          <div class="outage-map-legend__col first">
            <div class="legend-txt-wrap">
              <div class="gds-font-demi gds-font-size-l gds-space-stack-m">Towns</div>
              <p class="gds-font-light gds-font-size-s gds-text-grey">Towns are shaded according to how many customers have power out.</p>
            </div>
            <div class="legend-img-wrap">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/towns-yellow-orange-red.svg" alt="Town example" class="legend-img" />
            </div>
          </div>
          <div class="outage-map-legend__col">
            <div class="legend-txt-wrap">
              <div class="gds-font-demi gds-font-size-l gds-space-stack-m">Outage Incidents</div>
              <p class="gds-font-light gds-font-size-s gds-text-grey">Outage incidents are shown as dots and may have estimated affected areas shown as shapes.</p>
            </div>
            <div class="legend-img-wrap">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/map-legend-incident.svg" alt="Outage incident example" class="legend-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalMapLegend",
  props: ["visible"],
  components: {
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
    Close() {
      this.$emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>