<template>
  <section
    v-if="budgetStatus && (budgetStatus.isEnrolled || budgetStatus.isEligible)"
    class="my-account__full-width-section my-account__section-with-bottom-border"
  >
    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
        <h2 class="gds-display-1 gds-space-inline-m">Budget Billing</h2>
        <div v-if="!editing" class="gds-flex-container gds-flex-container--left">
          <div class="my-account__on-off-text gds-flex-container gds-flex-container--left gds-flex-container--top">
            <IndicatorOnOff :value="budgetStatus.isEnrolled"></IndicatorOnOff>
          </div>
        </div>
      </div>

      <div class="edit-close-button-combo">
        <button v-if="!editing && (accountStatus && !(accountStatus.pastDue30 || accountStatus.pastDue60))" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Edit</span>
        </button>
        <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Close</span>
        </button>
      </div>
    </div>
    <div v-if="showWarning" class="budget-billing-payarrange-warning-message gds-space-stack-l">{{ warningMessage }}</div>

    <!-- Budget Billing Display -->
    <div v-if="!editing">
      <div v-if="budgetStatus.isEnrolled" class="my-account__sub-section-bottom my-account__budget-billing-text">
        You are enrolled in Budget Billing.
        <span v-if="budgetStatus.totalBudgetAmount">Your monthly bill is {{budgetStatus.totalBudgetAmount | currency}}.</span>
        <div v-if="newestBudgetLetter" class="gds-space-top-m"><a class="gds-link gds-link--bold cursorPointer" :href="newestBudgetLetter.url" target="_blank">View your most recent budget letter</a></div>
      </div>
      <div v-else-if="(accountStatus && (accountStatus.pastDue30 || accountStatus.pastDue60))" class="my-account__budget-billing-text  my-account__sub-section-bottom gds-text-grey">
        Budget Billing enrollment is complicated for accounts with a past due balance. To understand your options and monthly payments,
        <span v-if="(accountStatus && accountStatus.pastDue60)">visit the <a href="/past-due-wizard" class="gds-link gds-link--bold">Past Due Quick Setup</a>,</span>
        call us at {{GMPPhoneNumber}}, or email us at <a :href="'mailto:' + GMPEmail" class="gds-link gds-link--bold">{{GMPEmail}}</a>.
      </div>
      <div
        v-else-if="budgetStatus.isEligible"
        class="my-account__budget-billing-text my-account__sub-section-bottom gds-text-grey"
      >Enroll in budget billing and get consistent monthly bills of {{budgetStatus.totalBudgetAmount | currency}}</div>
      <div v-else-if="budgetStatus.ineligibleReason" class="my-account__budget-billing-text  gds-space-stack-m gds-text-grey">{{budgetStatus.ineligibleReason}}</div>
      <div v-else class="my-account__budget-billing-text  gds-space-stack-m gds-text-grey">You are ineligible for Budget Billing</div>
    </div>
    <!-- Budget Billing Edit -->
    <div v-if="editing">
      <div v-if="budgetStatus.isEnrolled">
        <div class="my-account__sub-section-bottom">
          You are enrolled in Budget Billing. To turn off Budget Billing please contact customer service at
          <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
        </div>
      </div>
      <form v-if="!budgetStatus.isEnrolled" @submit.prevent="TurnOnBudget()">
        <div class="my-account__header-button-pairing gds-flex-container gds-flex-container--left">
          <fieldset class="gds-fieldset gds-space-inline-m">
            <label class="gds-switch">
              <input v-model="budgetEnrolled" type="checkbox" />
              <span class="gds-switch__faux"></span>
            </label>
          </fieldset>
          <div>
            <div v-if="!budgetEnrolled" class="gds-font-size-l gds-space-stack-m">You are not enrolled in budget billing.</div>
            <div v-if="budgetEnrolled" class="gds-font-size-l gds-space-stack-m">You will be enrolled in budget billing. Click save to confirm.</div>
          </div>
        </div>
        <div class="gds-font-size-l gds-font-demi gds-space-stack-m">If you enroll in budget billing, your monthly bill will be {{budgetStatus.totalBudgetAmount | currency}}.</div>

        <div class="my-account__description--limit-width gds-space-stack-m">
          Budget billing is not a discount. Your consistent monthly payment is based on your average usage and is subject to change.
          If you are a new customer or have net metering, we do not recommend going on a budget. We track your actual balance and review your account on a regular basis.
          If you stop Budget Billing, your next bill will include an adjustment to true up your account with your actual balance.
        </div>
        <button type="submit" :disabled="pendingSave || !budgetEnrolled" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
          <span class="gds-button__text">Save</span>
        </button>
        <div v-if="errorSave" class="form-message-box gds-space-stack-m">There was an error enabling Budget Billing.</div>
      </form>
    </div>
  </section>
</template>

<script>
import IndicatorOnOff from "../../generic/IndicatorOnOff";
import GMPAPI from "../../../services/gmpapi";
import { GMPPhoneNumber, GMPEmail } from "../../../environment"
import { DumpError } from "../../../utilities";

export default {
  name: "EditorBudgetBilling",
  data() {
    return {
      editing: false,
      pendingSave: false,
      errorSave: false,

      maxAmountDueForWarning: 1200,
      warningMessage: "Because this account has a balance greater than $1,200.00 please consider calling customer service at (888) 835-4672 before enrolling in budget billing so that we can best help you.",

      budgetStatus: undefined,
      accountStatus: undefined,
      // Always starts false, because you can't edit unless you're not enrolled
      budgetEnrolled: false,
      budgetLetters: [],
      newestBudgetLetter: undefined,

      GMPPhoneNumber,
      GMPEmail,
    };
  },
  components: {
    IndicatorOnOff,
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    showWarning() {
      if (this.currentAccount.amountDue > this.maxAmountDueForWarning) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.budgetStatus = undefined;
      this.accountStatus = undefined;
      if (!this.currentAccount) {
        return;
      }
      this.getBudgetLetters();
      try {
        const [budget, account] = await Promise.all([
          GMPAPI.GetBudgetStatus(this.currentAccount.accountNumber),
          GMPAPI.GetAccountStatus(this.currentAccount.accountNumber),
        ]);
        this.budgetStatus = budget;
        this.accountStatus = account;
      } catch (err) {
        DumpError("Budget billing editor refresh error", err);
        // Note that we do not have an error state for this card because if not fully loaded, it is just invisible
      }
    },
    async TurnOnBudget() {
      this.pendingSave = true;
      this.errorSave = undefined;
      try {
        await GMPAPI.CreateBudgetBilling(this.currentAccount.accountNumber);
        await this.RefreshStatus();
        this.editing = false;
      } catch (err) {
        DumpError("Create budget error", err);
        this.errorSave = true;
      } finally {
        this.pendingSave = false;
      }
    },
    async getBudgetLetters() {
      this.newestBudgetLetter = undefined;
      let letters = this.currentAccount.letters;
      if (!letters) { return; }
      
      let budgetLetterTypes = ["BU74-5D", "BUDQTR", "BU73-14D", "BU73-5D", "BUFR", "YRBUDROLL", "YRBUDSYNC"];
      for (let i = 0; i < letters.length; i++) {
        if (budgetLetterTypes.includes(letters[i].type)) {
          if (!this.newestBudgetLetter) {
            this.newestBudgetLetter = letters[i];
          }
          if (new Date(letters[i].printedAt).getTime() > new Date(this.newestBudgetLetter.printedAt).getTime()) {
            this.newestBudgetLetter = letters[i];
          }
        }
      }
    }
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      this.editing = false;
      this.errorSave = false;
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>