<template>
  <span>
  <button @click="viewOptions()" title="Options" class="view-options-button">
    <svg class="gds-icon view-options">
      <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-settings" />
    </svg>
    <div>{{ viewOptionsLabel }}</div>
  </button>
  </span>
</template>

<script>
export default {
  name: "ButtonViewOptions",
  props: {
    viewOptions: Function,
    viewOptionsLabel: {
      type: String,
      default: "View Options"
    },
  }
};
</script>

<style scoped>
</style>