<template>
  <div v-if="showable" class="widget-floating-helper">
    <div @click="ToggleExpanded()" class="need-help-button gds-font-demi" tabindex="0">Need Help?</div>
    <div v-if="expanded" class="helper-window">
      <div class="header-row gds-space-stack-m">
        <div v-if="!loggedInUser && FF_GlobalChatFlag" class="helper-window__intro">Need help? Please log in to chat with our team.</div>
        <div v-else-if="!chatOnline" class="helper-window__intro">Need help? Here are ways to get answers to your questions.</div>
        <div v-else class="helper-window__intro">Need help? Chat with our team to get answers to your questions.</div>
        <div @click="expanded = false" class="helper-window__close-button">
          <svg class="gds-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </div>
      </div>

      <div v-if="FF_GlobalChatFlag" class="webchat-button-wrap">
        <!-- Not logged in - button kicks to transit login -->
        <button v-if="!loggedInUser" @click="KickToLogin()" type="button" class="gds-button gds-round webchat-button gds-space-stack-m">Log In to Chat</button>
        <!-- Logged in, loaded, chat online - button loads chat -->
        <button v-else-if="chatLoaded && chatOnline" @click="StartWebchat()" type="button" class="gds-button gds-round webchat-button gds-space-stack-m">Start Chat</button>
        <!-- Chat isn't online, disable -->
        <button
          v-else-if="chatLoaded && !chatOnline"
          disabled
          type="button"
          class="gds-button gds-round gds-secondary gds-space-stack-m webchat-button gds-space-stack-m"
        >Chat is offline</button>
        <!-- Chat is loading -->
        <button v-else disabled type="button" class="gds-button gds-round gds-secondary gds-space-stack-m webchat-button gds-loading gds-space-stack-m"></button>
      </div>

      <div class="call-us-row">
        Call us at
        <a :href="'tel:' + GMPPhoneNumber" class="gds-nobreak gds-visibility--show-mobile">{{ GMPPhoneNumber }}</a>
        <span class="gds-nobreak gds-visibility--show-desktop">{{GMPPhoneNumber}}</span>
      </div>
      <div class="gds-space-stack-m">
        <a href="/contact" class="gds-link gds-link--bold">More ways to Contact Us</a>
      </div>
      <div class="gds-space-stack-m">
        <a href="/help-center" class="gds-link gds-link--bold">View Help Center</a>
      </div>
      <!-- NOTE: currently, only logged-in users are allowed to submit feedback -->
      <div v-if="loggedInUser">
        <a @click="ShowFeedback()" href="javascript:void(0);" class="gds-link gds-link--bold">Provide Website Feedback</a>
      </div>
    </div>
    <modal-floating-feedback :visible="showFeedback" @close="showFeedback = false" />
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../environment";
import { ShowChat, QueueStatus } from "../services/webchat";
import { DefaultChatQueue } from "../environment";
import { DumpError } from "../utilities";
import ModalFloatingFeedback from "./ModalFloatingFeedback.vue";
import { isFeatureEnabled } from "../services/featureflags"

export default {
  name: "WidgetFloatingHelper",
  components: {
    ModalFloatingFeedback,
  },
  data() {
    return {
      showable: false,
      expanded: false,
      chatLoaded: false,
      chatOnline: false,
      showFeedback: false,
      GMPPhoneNumber,
      FF_GlobalChatFlag: undefined,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    // When loaded, check if we're allowed to show the widget
    // Note that, if for a routed page/component, this must cover the entire routing tree of the page
    // We can't detect when the route changes, because this component lives outside of any page's router
    let path = window.location.pathname;
    // Remove trailing /
    if (path.endsWith("/")) {
      path = path.substring(0, path.length - 1);
    }
    if (path.startsWith("/outages")) {
      this.showable = false;
    } else {
      this.showable = true;
      this.getConfigs();
    }
  },
  methods: {
    async getConfigs() {
      this.FF_GlobalChatFlag = await isFeatureEnabled('FF_GlobalChatFlag', false);
    },
    ToggleExpanded() {
      this.expanded = !this.expanded;
      if (this.loggedInUser && this.expanded && !this.chatLoaded && this.chatLoaded !== undefined) {
        this.RefreshStatus();
      }
    },
    KickToLogin() {
      window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
    },
    async RefreshStatus() {
      let queueStatus = undefined;
      this.chatLoaded = undefined; // Set to undefined while loading
      try {
        queueStatus = await QueueStatus(DefaultChatQueue);
      } catch (err) {
        DumpError("Error getting queue status", err);
      } finally {
        this.chatLoaded = true;
        this.chatOnline = queueStatus && queueStatus.IsOpen;
      }
    },
    StartWebchat() {
      try {
        ShowChat(DefaultChatQueue);
        // When opening chat, hide the popup and the button too
        this.expanded = false;
      } catch (err) {
        DumpError("Error starting webchat", err);
        this.chatOnline = false;
      }
    },
    ShowFeedback() {
      this.showFeedback = true;
      this.expanded = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
