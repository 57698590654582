var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "usage-dashboard__widget-net-metering",
      staticStyle: { position: "relative" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard__widget-net-metering--mobile-heading-container"
        },
        [
          _vm.FF_NetMeteredDashboard && _vm.currentAccount.solarNetMeter
            ? _c("widget-switcher", {
                attrs: {
                  currentWidget: "Net Metering",
                  options: _vm.widgetOptions
                },
                on: { "handle-option-click": _vm.handleOptionClick }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("a", {
            staticClass:
              "usage-dashboard__widget-net-metering--circle-arrow-right",
            attrs: { href: "/account/usage-new/detail/performance" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard__widget-current-usage--mobile-text--current-net-usage"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-current-usage--mobile-text--large"
            },
            [_vm._v("Current Net Usage")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-current-usage--text--largest"
            },
            [_vm._v(_vm._s(_vm.currentNetUsage) + " kWh")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard__widget-net-metering--mobile-flex-container"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-net-metering--mobile-column-left"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-net-metering--mobile-generation-returned"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--mobile-text--large black"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.generationReturnedToGrid) + " kWh\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--text--grey-small"
                    },
                    [_vm._v("Generation Returned to Grid")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-net-metering--total-usage"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--mobile-text--large black"
                    },
                    [_vm._v(_vm._s(_vm.energyFromGrid) + " kWh")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--text--grey-small"
                    },
                    [_vm._v("Energy from Grid")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-net-metering--mobile-column-right"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-net-metering--mobile-right-column-item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--mobile-text--large green"
                    },
                    [_vm._v(_vm._s(_vm.totalGeneration) + " kWh")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--text--grey-small"
                    },
                    [_vm._v("Total Generation")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-net-metering--mobile-right-column-item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--mobile-text--large black"
                    },
                    [_vm._v(_vm._s(_vm.generationToHome) + " kWh")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--text--grey-small"
                    },
                    [_vm._v("Generation to Home")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-net-metering--mobile-right-column-item"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--mobile-text--large orange"
                    },
                    [_vm._v(_vm._s(_vm.totalUsage) + " kWh")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-dashboard__widget-net-metering--text--grey-small"
                    },
                    [_vm._v("Total Usage")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-net-metering--battery-saver-note-mobile"
            },
            [
              _vm._v(
                "* Your usage data may be slightly off if you have a connected battery back-up."
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass:
            "usage-dashboard__widget-net-metering--mobile-resizable-svg",
          attrs: {
            width: _vm.svgWidth,
            height: _vm.svgHeight,
            viewBox: "0 0 342 336",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M166.537 293.271C164.773 293.271 163.342 294.716 163.342 296.498C163.342 296.576 163.348 296.652 163.354 296.729H163.342V304.458H169.732V296.729H169.721C169.726 296.652 169.732 296.576 169.732 296.498C169.732 294.716 168.302 293.271 166.537 293.271Z",
              fill: "#FD872E"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "154.822",
              y: "271.757",
              width: "4.68626",
              height: "4.30281",
              fill: "#22395B"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M190.608 288.108H148.857L150.561 275.63H190.608V288.108Z",
              fill: "#22395B"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M192.738 271.972L178.04 286.817L179.105 288.108L192.738 274.339L206.371 288.108L207.436 287.032L192.738 271.972Z",
              fill: "#22395B"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M192.738 274.339L179.531 288.108H179.957V304.458H205.518V288.108H205.944L192.738 274.339Z",
              fill: "white",
              stroke: "#202020",
              "stroke-opacity": "0.1"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M187.625 300.156H197.85V298.865H187.625V300.156Z",
              fill: "#FD872E"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M176.762 288.108L175.687 291.3L174.285 295.896L172.289 304.458H179.531V288.108L176.762 288.108Z",
              fill: "#231F20",
              "fill-opacity": "0.1"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M192.525 286.602C193.819 286.602 194.868 285.542 194.868 284.235C194.868 282.928 193.819 281.869 192.525 281.869C191.231 281.869 190.182 282.928 190.182 284.235C190.182 285.542 191.231 286.602 192.525 286.602Z",
              fill: "#BAE0F5"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M188.478 298.865H196.998V290.259H188.478V298.865Z",
              fill: "#BAE0F5"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M154.822 295.853H159.508V291.12H154.822V295.853Z",
              fill: "#BAE0F5"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M171.863 295.853H176.549V291.12H171.863V295.853Z",
              fill: "#BAE0F5"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass:
            "usage-dashboard__widget-net-metering--mobile-resizable-svg",
          attrs: {
            width: _vm.svgWidth,
            height: _vm.svgHeight,
            viewBox: "0 0 342 336",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M158.652 152L157.65 154.603L155.714 153.95L155.128 155.833L152.34 155.079L153.127 157.749L151.16 158.31L151.842 160.163L149.123 161.122L151.399 162.839L150.152 164.4L151.842 165.515L150.231 167.821L153.127 167.929L153.076 169.893L155.128 169.845L155.241 172.617L157.65 171.075L158.815 172.693L160.446 171.499L162.239 173.678L163.241 171.075L165.177 171.728L165.763 169.845L168.552 170.599L167.765 167.929L169.731 167.368L169.049 165.515L171.768 164.556L169.492 162.839L170.739 161.278L169.049 160.163L170.66 157.857L167.765 157.749L167.815 155.785L165.763 155.833L165.65 153.061L163.241 154.603L162.076 152.985L160.446 154.179L158.652 152Z",
              fill: "#F8BF97"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M166.004 162.839C166.004 165.778 163.515 168.16 160.446 168.16C157.376 168.16 154.887 165.778 154.887 162.839C154.887 159.9 157.376 157.518 160.446 157.518C163.515 157.518 166.004 159.9 166.004 162.839Z",
              fill: "#FD872E"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M193.287 187.253H147.691L161.429 165.091H207.025L193.287 187.253Z",
              fill: "#2C60B3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M176.377 165.091H175.245L162.267 187.21L162.346 187.253H163.375L176.377 165.091Z",
              fill: "white"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M192.56 165.091H191.428L178.458 187.211L178.537 187.253H179.565L192.56 165.091Z",
              fill: "white"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M193.287 187.253H147.691L161.429 165.091C161.429 165.091 166.4 168.824 175.325 174.757C179.884 177.788 193.287 187.253 193.287 187.253Z",
              fill: "white",
              "fill-opacity": "0.1"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M147 189.758H194.389L209 166.131",
              stroke: "#B8BBBF",
              "stroke-width": "1.5"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass:
            "usage-dashboard__widget-net-metering--mobile-resizable-svg",
          attrs: {
            width: _vm.svgWidth,
            height: _vm.svgHeight,
            viewBox: "0 0 342 336",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d: "M166 255V255C166 246.716 159.284 240 151 240L121 240",
              stroke: "#A7A7A7",
              "stroke-width": "3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M166 197V197C166 203.075 161.075 208 155 208L124 208",
              stroke: "#A7A7A7",
              "stroke-width": "3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M187 197L187 254C187 254.552 186.552 255 186 255V255",
              stroke: "#A7A7A7",
              "stroke-width": "3"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M186.5 263L179 253L194 253L186.5 263Z",
              fill: "#A7A7A7"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M165.5 263L158 253L173 253L165.5 263Z",
              fill: "#A7A7A7"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M121.5 208L131.5 200.5L131.5 215.5L121.5 208Z",
              fill: "#A7A7A7"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }