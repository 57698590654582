<template>
  <div class="outage-header gds-flex-container">
    <div class="outage-header__left gds-flex-container gds-flex-container--left">
      <div v-if="updated" class="gds-flex-container gds-flex-container--column outage-header__status">
        <div class="gds-font-demi gds-font-size-l">Outage Center</div>
        <div class="gds-font-light gds-font-size-s gds-text-lightgrey">Updated at {{ updated | timeOnly }}</div>
      </div>
      <div v-if="summary" class="outage-header__data-column outage-header__border-right">
        <div class="gds-font-size-xxl gds-font-medium">{{ summary.customerCount.toLocaleString() }}</div>
        <div class="gds-font-demi gds-font-size-s">Customers Out</div>
      </div>
      <div v-if="summary" class="outage-header__data-column outage-header__border-right">
        <div class="gds-font-size-xxl gds-font-medium">{{ summary.eventCount }}</div>
        <div class="gds-font-demi gds-font-size-s">Active Incidents</div>
      </div>
      <div v-if="summary" class="outage-header__data-column">
        <div class="gds-font-size-xxl gds-font-medium">{{ summary.townCount }}</div>
        <div class="gds-font-demi gds-font-size-s">Towns Affected</div>
      </div>
    </div>
    <!-- /outage-header__left -->
    <div class="outage-header__right gds-flex-container">
      <div v-if="!currentAccount" class="outage-header__login">
        <div class="outage-header__login--button-wrap">
          <button @click="ShowLogin" type="button" class="gds-button gds-text-button">Log in</button>
          to view your details
        </div>
      </div>
      <!-- Null indicates personalData is loading -->
      <div v-else-if="personalData === null" class="outage-header__login">
        <button class="outage-header__login-locations-button gds-button gds-compact gds-secondary my-status gds-loading"
          style="width: 182px"></button>
      </div>
      <div v-else-if="mergedData && mergedData.length > 0" class="outage-header__login outage-my-status-popout">
        <button @click="showMyStatus = !showMyStatus" type="button" :class="{ 'caret-down': !showMyStatus }"
          class="outage-header__login-locations-button gds-button gds-compact gds-secondary my-status caret caret-white">My
          Power Status</button>

        <div v-if="showMyStatus" class="outage-my-status" v-click-outside="CloseMyStatus">
          <div class="outage-my-status__inner">
            <div v-for="account of mergedData" :key="account.accountNumber" class="outage-my-account">
              <div v-if="account.photoUrl"
                :style="{ backgroundImage: 'url(' + GetResizedAccountPhoto(account.photoUrl) + ')' }"
                class="small-round-img" />
              <div v-if="!account.photoUrl" class="small-round-img" />
              <div class="outage-my-account__detail">
                <!-- Note - not all accts have a nickname - ie 8 garden st in testwallet -->
                <div v-if="account.nickname" class="nickname">{{ account.nickname }}</div>
                <div class="address gds-linebreaks">{{ account.address | addressFormat({
                    separator: "\n", skipFirst:
                      false
                  })
                }}</div>
                <div v-if="account.outageReported || (account.incidents && account.incidents.length > 0)"
                  class="outage-status gds-flex-container gds-flex-container--left">
                  <div class="outage-header__alert-icon active">
                    <img
                      src="/wp-content/themes/gmptwentynineteen/assets/images/icon-small-orange-alert-with-shadow.svg" />
                  </div>
                  <div>Outage Reported</div>
                </div>

                <div v-else class="outage-status gds-flex-container gds-flex-container--left">
                  <div class="outage-header__alert-icon inactive">
                    <img
                      src="/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-with-shadow.svg" />
                  </div>
                  <div>No Outages Reported</div>
                </div>

                <div v-if="account.plannedOutages && account.plannedOutages.length"
                  class="outage-status gds-flex-container gds-flex-container--left">
                  <div class="outage-header__alert-icon planned">
                    <img src="/wp-content/themes/gmptwentynineteen/assets/images/planned-outage-info-icon.svg" />
                  </div>
                  <div>Planned Outage</div>
                </div>
              </div>
              <div class="outage-status-link">
                <div v-if="account.incidents && account.incidents.length > 0">
                  <router-link :to="'/incident/' + account.incidents[0].id"
                    class="gds-button gds-round gds-secondary gds-compact">
                    <span class="gds-button__text">View Details</span>
                  </router-link>
                </div>
              </div>
            </div>
            <!--/outage-my-account -->
          </div>
          <!-- /outage-my-account__inner -->
        </div>
      </div>
      <!--/outage-my-status-popout-->

      <div class="outage-header__report">
        <a href="/report-outage"
          class="report_outage_btn outage-header__report-button gds-button gds-orange gds-compact">Report Outage</a>
      </div>
    </div>
    <!-- /outage-header__right -->
  </div>
</template>

<script>
import { GetResizedAccountPhoto } from "../../utilities";
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";
import { EnvironmentConstants } from "../../environment";
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "OutageHeader",
  components: {
  },
  props: ["updated", "personalData"],
  data() {
    return {
      GetResizedAccountPhoto,
      summary: undefined,
      showMyStatus: false,
      mergedData: undefined,
      refreshTimer: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    this.RefreshSummary();
    this.MergePersonalData();
  },
  // If this component is destroyed, stop the refresh timer
  destroyed() {
    clearTimeout(this.refreshTimer);
  },
  methods: {
    /** Update summary from the API, and schedule a timed refresh */
    async RefreshSummary() {
      try {
        this.summary = await GMPAPI.GetIncidentsSummary();
      } catch (err) {
        DumpError("Couldn't get incidents", err);
      } finally {
        // Let's do this again periodically
        this.refreshTimer = setTimeout(() => { this.RefreshSummary() }, EnvironmentConstants.OutageRefreshIntervalMS);
      }
    },
    ShowLogin() {
      this.$store.commit("setLoginDrawerVisible", true);
    },
    CloseMyStatus(event) {
      // Ignore clicks on the button which toggles it anyways. This is only a problem on iOS
      if (event && event.target && event.target.classList && event.target.classList.contains("outage-header__login-locations-button")) return;
      this.showMyStatus = false;
    },
    // Attach account images if available
    MergePersonalData() {
      if (this.personalData) {
        const mergedData = [];
        const fullAccounts = this.$store.state.user.accounts;
        for (const account of this.personalData) {
          const fullAccount = fullAccounts.find(item => item.accountNumber === account.accountNumber);
          if (fullAccount) {
            account.photoUrl = fullAccount.photoUrl;
          }
          mergedData.push(account);
        }
        this.mergedData = mergedData;
      }
    },
  },
  watch: {
    async personalData() {
      this.MergePersonalData();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>