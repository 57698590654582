<template>
  <section class="my-account__full-width-section my-account__section-with-bottom-border">
    <!-- Loading and error display -->
    <div v-if="loadState !== 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 class="gds-display-1 gds-space-stack-m">Mailing Address</h2>
          <div v-if="loadState === undefined" class="editor-loading" />
        </div>
        <div class="fake-edit-link">Edit</div>
      </div>
      <div v-if="loadState === 'error'" class="editor-error">Unable to load this section. Please try again later.</div>
    </div>
    <!-- Main display when loading complete -->
    <div v-if="loadState === 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <h2 class="gds-display-1 gds-space-stack-m">Mailing Address</h2>

        <div class="edit-close-button-combo">
          <button v-if="!editing && mailingAddress" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Edit</span>
          </button>
          <button v-if="editing && !pendingVerify && !pendingUpdate" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Close</span>
          </button>
        </div>
      </div>
      <!-- Display -->
      <div v-if="!editing && mailingAddress" class="my-account__static-address-box my-account__sub-section-bottom">
        <div class="gds-linebreaks">{{mailingAddress | addressFormat({ mailing: true })}}</div>
      </div>
      <!-- Edit -->
      <div v-if="editing">
        <inline-mailing-address ref="inlineAddress" :address="mailingAddress" @save="InlineSaved" @touched="FormTouch" />
        <div v-if="errorVerify" class="form-message-box gds-space-stack-l">{{errorVerify}}</div>
        <div v-if="errorUpdate" class="form-message-box gds-space-stack-l">{{errorUpdate}}</div>
        <button
          @click="Verify()"
          :disabled="pendingVerify || pendingUpdate || !formTouched"
          :class="{ 'gds-loading': pendingVerify || pendingUpdate }"
          type="button"
          class="gds-button gds-round gds-space-stack-l"
        >
          <span class="gds-button__text">Save</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { DumpError, GetMailingAddressFromVerified } from "../../../utilities";
import InlineMailingAddress from "./InlineMailingAddress";

export default {
  name: "EditorMailingAddress",
  data() {
    return {
      editing: false,
      formTouched: false,

      pendingVerify: false,
      errorVerify: undefined,
      pendingUpdate: false,
      errorUpdate: undefined,

      loadState: undefined,

      mailingAddress: undefined,
    };
  },
  components: {
    InlineMailingAddress,
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.mailingAddress = undefined;
      this.editedAddress = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        this.mailingAddress = await GMPAPI.GetMailingAddress(this.currentAccount.accountNumber)
        this.loadState = "complete";
      } catch (err) {
        DumpError("Mailing address editor refresh error", err);
        this.loadState = "error";
      }
    },
    async FormTouch(touched) {
      this.formTouched = touched;
    },
    async Verify() {
      this.pendingVerify = true;
      this.errorVerify = undefined;
      try {
        await this.$refs.inlineAddress.Verify();
      } catch (err) {
        DumpError("Verify address error", err);
        this.errorVerify = "There was an unexpected error verifying your mailing address.";
      } finally {
        this.pendingVerify = false;
      }
    },
    /** This gets called when a final address is accepted from the inline form  */
    async InlineSaved(address) {
      this.pendingUpdate = true;
      this.errorUpdate = undefined;
      try {
        await GMPAPI.UpdateMailingAddress(this.currentAccount.accountNumber, address);
        await this.RefreshStatus();
        this.editing = false;
      } catch (err) {
        DumpError("Update address error", err);
        this.errorUpdate = "There was an unexpected error updating your mailing address.";
      } finally {
        this.pendingUpdate = false;
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>