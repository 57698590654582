<template>

  <!-- Only show when logged out and there is card data for this index -->
  <div v-if="myCard && !currentAccount" class="top-card-display__single-card homepage__marketing-card">
    <a :href="myCard.link && myCard.link.url" :target="myCard.link && myCard.link.target" :title="myCard.link && myCard.link.title" class="top-banner-card gds-card gds-background-green gds-invert">
      <figure class="gds-card__image-container" v-if="myCard.image">
        <div class="gds-card__image">
          <img :src="myCard.image && myCard.image.sizes && myCard.image.sizes.medium" :alt="myCard.image && myCard.image.alt" />
        </div>
      </figure>
      <div class="gds-card__contents">
        <h3 class="gds-card__title home-card__title--small-margin">{{myCard.title}}</h3>
        <p v-if="!myCard.image" class="gds-card__body home-card__body--small-margin">{{myCard.body}}</p>
        <div class="gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"></div>
      </div>
    </a>
  </div>
</template>

<script>
import { MarketingCards } from "../environment";

export default {
  name: "CardMarketing",
  props: ["cardIndex"],
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    myCard() {
      if (MarketingCards && MarketingCards.length >= this.cardIndex) {
        return MarketingCards[this.cardIndex];
      } else {
        return undefined;
      }
    },
  },
  async mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
