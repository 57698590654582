<template>
  <div v-if="visible" class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="delete-user__modal-box--header">
          <button @click="Close()" class="close gds-button-circle gds-icon-close-small gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
          </button>
          <div class="export-data__modal-box--headline">Are you sure you want to remove your online access?</div>
        </header>
        <div class="delete-user__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l">
            Deleting your user account only removes your online access and does not cancel your Green Mountain Power service.
            You can cancel your service online at the
            <a
              href="/start-stop-move-service"
            >Start, Stop, or Move</a>
            page, or by calling us at
            <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
          </div>
          <div v-if="errorDelete" class="form-message-box gds-space-stack-m">{{errorDelete}}</div>
          <button @click="Close()" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
            <span class="gds-button__text">Cancel</span>
          </button>
          <button
            @click="DeleteMe()"
            :disabled="pendingDelete"
            :class="{ 'gds-loading': pendingDelete }"
            type="button"
            class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m"
          >
            <span class="gds-button__text">Yes, Delete User Account</span>
          </button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../services/gmpapi';
import { DumpError } from '../../utilities';
import { GMPPhoneNumber } from '../../environment';

export default {
  name: "ModalDeleteUser",
  props: ["visible"],
  components: {
  },
  data() {
    return {
      pendingDelete: false,
      errorDelete: undefined,

      GMPPhoneNumber,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async DeleteMe() {
      this.pendingDelete = true;
      try {
        await GMPAPI.DeleteUsername(this.userInfo.username);
        await this.$store.dispatch("LogOut");
        window.location = "/";
      } catch (err) {
        DumpError("Delete user error", err);
        this.errorDelete = "There was an unexpected error deleting your user account.";
      } finally {
        this.pendingDelete = false;
      }
    },
    Close() {
      this.$emit("complete");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>