<template>
  <section class="my-account__full-width-section my-account__section-with-bottom-border">
    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <h2 class="gds-display-1 gds-space-stack-m">Account Nickname</h2>

      <div class="edit-close-button-combo">
        <button v-if="!editing" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Edit</span>
        </button>
        <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Close</span>
        </button>
      </div>
    </div>
    <!-- Display -->
    <div v-if="!editing">
      <div v-if="currentAccount.nickname" class="gds-font-size-l my-account__sub-section-bottom">{{currentAccount.nickname}}</div>
      <div v-if="!currentAccount.nickname" class="gds-font-size-l my-account__sub-section-bottom gds-text-grey">None set</div>
    </div>
    <!-- Edit -->
    <form v-if="editing" @submit.prevent="Save()">
      <fieldset class="gds-fieldset gds-space-stack-l">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Nickname</span>
          <the-mask
            v-model="editedNickname"
            @input="touched = true"
            maxlength="30"
            class="gds-input-field__input"
            type="text"
            placeholder="Type Here..."
            :tokens="allowedCharacters"
            :mask="'CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'"
          />
        </label>
      </fieldset>
      <button type="submit" :disabled="pendingSave || !touched || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
        <span class="gds-button__text">Save</span>
      </button>
      <div v-if="errorSave" class="form-message-box gds-space-stack-m">There was an error updating your paperless billing.</div>
    </form>
  </section>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";

export default {
  name: "EditorAccountNickname",
  data() {
    return {
      allowedCharacters: {
        "C": {
          pattern: /[0-9a-zA-Z !@#$%^&*()_+~,./?;:'"-=`|ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },
      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: false,

      editedNickname: undefined,
    };
  },
  components: {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      if (this.currentAccount) {
        // Copy account nickname over to temporary editable
        this.editedNickname = this.currentAccount.nickname;
      }
    },
    async Save() {
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        this.errorSave = undefined;
        try {
          await GMPAPI.UpdateNickname(this.userInfo.username, this.currentAccount.accountNumber, this.editedNickname);
          await this.$store.dispatch("FetchUserInfo");
        } catch (err) {
          DumpError("Update nickname error", err);
          this.errorSave = true;
        } finally {
          this.pendingSave = false;
        }
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      this.editing = false;
      this.touched = false;
      this.errorSave = false;
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>