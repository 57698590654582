<template>
  <div class="subpage-dashboards subpage-usage--dashboard">
    <banner-dual-action
      class="gds-flex-container"
      v-if="testDriveUsage" bannerColor="blue"
      message='You are trying out our new usage section. We would love to hear what you think. To provide feedback please click the following link:'
      messageTwo="or you can use this link to"
      linkOneText="Provide Feedback"
      @linkOneClicked="showProvideFeedBackModal"
      linkTwoText="Switch Back to the Old Usage View"
      @linkTwoClicked="goToLegacyUsage"
    />
    <h1 class="my-account__title gds-space-stack-l">Usage</h1>
    <div v-if="isMobbileView || isTabletView" @click="showPickAViewModal" class="usage-dashboard-pick-a-view-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z" fill="#00704A"/>
      </svg>
      <span class="usage-dashboard-pick-a-view-container__label">Usage Dashboard</span>
      <span class="usage-dashboard-pick-a-view-container__link">Pick a View</span>
    </div>
    <ModalUsagePickAView v-if="pickAViewModalVisible" :closeModal="hidePickAViewModal" :isDashboard="true"/>
    <div class="usage-dashboard-widget-border usage-dashboard-margin-bottom-1rem ">
      <widget-current-usage 
        v-if="bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 && (!FF_NetMeteredDashboard || (FF_NetMeteredDashboard && !isNetMetered))"
        @change-options-event="handleOptionChangeEvent"/>
      <widget-net-metering :loadstate="loadstate" :billingSummary="billingSummary" v-if="bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 && (FF_NetMeteredDashboard && isNetMetered)" @change-options-event="handleOptionChangeEvent"/>
    </div>
    <div class="usage-dashboard-two-columns">
      <div class="usage-dashboard-widget-border">
          <widget-daily-usage-history />
      </div>
      <div>
        <div class="usage-dashboard-widget-usage-trend-wrapper usage-dashboard-widget-border usage-dashboard-grid-short--height-auto usage-dashboard-margin-bottom-1rem">
          <widget-usage-trend />
        </div>
        <div class="usage-dashboard-widget-time-of-day-wrapper usage-dashboard-widget-border usage-dashboard-grid-short">
          <widget-time-of-day />
        </div>
      </div>
    </div>
    <div class="gds-space-stack-l">
      <UsageVisualizationAndTools />
    </div>
    <div class="gds-space-stack-l">
      <usage-notification />
    </div>
    <div class="gds-space-stack-l">
      <usage-export />
    </div>
    <router-view></router-view>
    <modal-floating-feedback :visible="showFeedback" @close="showFeedback = false" />
  </div>
</template>

<script>
import UsageExport from "./usageexport/UsageExport.vue";
import MediaQueryMixin from "../../mixins/MediaQueryMixin";
import ModalUsagePickAView from "./ModalUsagePickAView.vue";
import WidgetTimeOfDay from "./widgettimeofday/WidgetTimeOfDay.vue";
import WidgetUsageTrend from "./widgetusagetrend/WidgetUsageTrend.vue";
import UsageNotification from "./usagenotification/UsageNotification.vue";
import UsageVisualizationAndTools from "./UsageVisualizationAndTools.vue";
import WidgetCurrentUsage from "./widgetcurrentusage/WidgetCurrentUsage.vue";
import WidgetNetMetering from "./widgetnetmetering/WidgetNetMetering.vue";
import WidgetDailyUsageHistory from "./widgetdailyhistory/WidgetDailyUsageHistory.vue";
import BannerDualAction from "../../BannerDualAction.vue"
import ModalFloatingFeedback from "../../ModalFloatingFeedback.vue";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import { isFeatureEnabled } from "../../../services/featureflags";

export default {
  name: "SubpageUsage",
  components: {
    UsageExport,
    WidgetTimeOfDay,
    WidgetUsageTrend,
    UsageNotification,
    WidgetCurrentUsage,
    WidgetNetMetering,
    WidgetDailyUsageHistory,
    ModalUsagePickAView,
    UsageVisualizationAndTools,
    BannerDualAction,
    ModalFloatingFeedback,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      pickAViewModalVisible: false,
      showFeedback: false,
      isNetMetered: undefined,
      FF_NetMeteredDashboard: undefined,
      bothFeatureFlagAndIsNetmeteredConditionsChecked: 0,
      billingSummary: undefined,
      loadstate: undefined
    };
  },
  computed: {    
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    testDriveUsage() {
      return this.$store.state.user.testDriveUsage
    },
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
  watch: {
    async currentAccount() {
      this.bothFeatureFlagAndIsNetmeteredConditionsChecked = 1;
      await this.getBillingSummary();
    }
  },
  async mounted() {
    await this.getConfigs();
    await this.getBillingSummary();
  },
  methods: {
    async getConfigs() {
      this.FF_NetMeteredDashboard = await isFeatureEnabled('FF_NetMeteredDashboard', false);
      this.bothFeatureFlagAndIsNetmeteredConditionsChecked ++;
    },
    showPickAViewModal() {
      this.pickAViewModalVisible = true;
    },
    hidePickAViewModal() {
      this.pickAViewModalVisible = false;
    },
    async goToLegacyUsage() {
      this.$store.commit("setTestDriveUsage", false);
      window.location.href = '/account/usage';

      await GMPAPI.SubmitCSSLogging(this.currentAccount.accountNumber, this.$store.state.user.userinfo.username, "SWITCH_TO_LEGACY_USAGE", "Switched back to legacy usage.", true);
    },
    showProvideFeedBackModal() {
      this.showFeedback = true;
    },
    async getBillingSummary() {
      this.loadstate = 'loading';
      this.isNetMetered = undefined;
      try {
        const summary = await GMPAPI.GetUsageSummary(this.currentAccount.accountNumber);
        this.bothFeatureFlagAndIsNetmeteredConditionsChecked ++;
        if (summary) { 
          this.loadstate = 'complete';
          this.billingSummary = summary;
          this.isNetMetered = summary.isNetMetered;
          return;
        }
        this.loadstate = 'unavailable';
        this.isNetMetered = false;
      } catch (err) {
        this.loadstate = 'error';
        DumpError("Get Billing Summary error", err);
        this.isNetMetered = false;
      }

    },
    handleOptionChangeEvent(option) {
      if (option === 'widget-net-metering') {
        this.isNetMetered = true;
        return;
      }
      this.isNetMetered = false;
    }
  },
};
</script>
