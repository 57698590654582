import { format } from "date-fns";
import Highcharts from "highcharts";

function clearChartXAxis(index) {
  document.querySelector(`g.highcharts-axis-labels.highcharts-xaxis-labels > text:nth-child(${index})`).innerHTML = '';
};

function checkDataLengthDiffernces(chartDataPrevious, chartDataCurrent) {
  if (chartDataPrevious.length > chartDataCurrent.length) {
    const diff = chartDataPrevious.length - chartDataCurrent.length;

    for (let i = 1; i  <= diff; i++) {
      clearChartXAxis(chartDataCurrent.length + i);
    }
  }
};

export function DrawChart(renderingDiv, chartDataPrevious, chartDataCurrent, previousBillingCycleDailyUsageData, currentBillingCycleDailyUsageData, xAxisLabels, chartXAxisSteps) {
  Highcharts.chart(renderingDiv, {
    chart: {
      type: 'area',
      height: 400,
      marginLeft: 10,
      marginRight: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: undefined
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
            size: 12,
            opacity: 0.5,
            attributes: {
                'stroke-width': 0,
                fill: '#EF6723',
                stroke: '#EF6723'
              }
            }
          }
        }
      },
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            },
          }
        }
      }
    },
    series: [{
      name: 'Previous',
      data: chartDataPrevious,
      marker: {
        states: {
          hover: {
            enabled: false,
          }
        }
      }
    },
    {
      name: "Current",
      data: chartDataCurrent,
      color: '#ef6723',
      fill: 'none',
      lineWidth: 3,
      marker: {
        radius: 3,
        symbol: 'circle',
        lineColor: '#fff',
        lineWidth: 1,
      },
      shadow: {
        color: '#fff',
        width: 1,
        opacity: 1,
        offsetX: 0,
        offsetY: 0
    }
    }],
    xAxis: {
      lineWidth: 0,
      tickWidth: 0,
      tickInterval: 1,
      allowDecimals: false,
      categories: xAxisLabels,
      labels: {
        step: chartXAxisSteps,
        formatter: function () {
          return this.value;
        }
      }
    },
    yAxis: {
      title: {
        text: undefined
      },
      gridLineWidth: 1,
      className: 'usage-performance-y-axis-label',
      tickInterval: undefined,
      labels: {
        enabled: true,
        align: 'left',
        useHTML: true,
        formatter: function() {  
          let formattedValue = undefined;
          formattedValue = this.isLast ? `${this.value} kWh` : this.value ;
          return `<i class='value'>${formattedValue}</i>`;
        }
      },
    },
    tooltip: {
      shared: false,
      shadow: false,
      useHTML: true,
      outside: true,
      borderWidth: 0,
      borderRadius: 0,
      valueDecimals: 2,
      backgroundColor: "transparent",
      formatter: function (tooltip) {
        if (this.series.name === "Current") {
          const index = this.point.index;
          const dayNumber = index + 1;

          const prevIndex = previousBillingCycleDailyUsageData[index];
          const currentIndex = currentBillingCycleDailyUsageData[index];

          if (!currentIndex) {
            return '';
          }

          const currentDate = format(new Date(currentIndex.date), "MMMM dd, yyyy");

          let html = `
            <div class="usage-detail__usage-performance__tooltip">
              <div class="usage-detail__usage-performance__tooltip__row">
                <div class=usage-detail__usage-performance__tooltip__column usage-detail__usage-performance__tooltip__column--left">
                  <div class="usage-detail__usage-performance__tooltip__circle usage-detail__usage-performance__tooltip__circle--current"></div>
                </div>
                <div class="usage-detail__usage-performance__tooltip__column usage-detail__usage-performance__tooltip__column--right">`;

                    if (prevIndex) {
                      html += `<div class="usage-detail__usage-performance__tooltip__text">${currentDate} - Day ${dayNumber}</div>`;
                    } else {
                      html += `<div class="usage-detail__usage-performance__tooltip__text">${currentDate}</div>`;
                    }

                  html += `<div class="usage-detail__usage-performance__tooltip__text usage-detail__usage-performance__tooltip__text--large">${Math.floor(chartDataCurrent[index])}kWh</div>
                </div>
              </div>
          `;

          if (prevIndex) {
            const prevDate = format(new Date(prevIndex.date), "MMMM dd, yyyy");

            html += `
              <div class="usage-detail__usage-performance__tooltip__row">
              <div class="usage-detail__usage-performance__tooltip__column usage-detail__usage-performance__tooltip__column--left">
                <div class="usage-detail__usage-performance__tooltip__circle usage-detail__usage-performance__tooltip__circle--previous"></div>
              </div>
              <div class="usage-detail__usage-performance__tooltip__column usage-detail__usage-performance__tooltip__column--right">
                <div class="usage-detail__usage-performance__tooltip__text">${prevDate} - Day ${dayNumber}</div>
                <div class="usage-detail__usage-performance__tooltip__text usage-detail__usage-performance__tooltip__text--large">${Math.floor(chartDataPrevious[index])}kWh</div>
              </div>
            </div>
            `
          }

          html += '</div>';

          return html;
        } else {
          return false;
        }
      }
    }
  });

  checkDataLengthDiffernces(chartDataPrevious, chartDataCurrent);
}

