<template>
  <div>
    <div v-if="showWidgetHeader" class="usage-dashboard__headings--in-boxes">Time of Day</div>
    <a v-if="showWidgetHeader" href="/account/usage-new/detail/time-of-day" class="usage-dashboard__circle-arrow-right"></a>
    <div v-if="loadState === 'complete'" :class="chartBodyClass">
      <div>
        <img class="usage-dashboard__widget-time-of-day--icon"
          :src="'/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/' + fileName"
          :alt="timeRange" />
      </div>
      <div :class="widgetTextClass">You use the most energy in the <span class="gds-text--bold">{{ timeOfDay }}</span> from
        <span class="gds-text--bold">{{ timeRange }}</span>.
      </div>
    </div>
    <div v-if="!loadState" class="my-account__usage-loading" />
    <div v-if="loadState === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadState === 'error'" class="error-message">An unexpected error occurred, please try again later.</div>
  </div>
</template>

<script>
import UsageDetailTimeOfDay from "../usagedetailtimeofday/UsageDetailTimeOfDay.vue";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";

export default {
  components: {
    UsageDetailTimeOfDay
  },
  name: "WidgetTimeOfDay",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  props: {
    showWidgetHeader: {
      type: Boolean,
      default: true
    },
    chartBodyClass: {
      type: String,
      default: "usage-dashboard__widget-time-of-day--body"
    },
    widgetTextClass: {
      type: String,
      default: "usage-dashboard__widget-time-of-day--text"
    },
    initialFetch: {
      type: Boolean,
      default: true
    },
    percentageData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      timeOfDay: undefined,
      timeRange: undefined,
      fileName: undefined
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      this.init();
    },
    percentageData() {
      this.loadState = 'complete';
      this.getHighestAverageConsumption(this.percentageData);
    }
  },
  async mounted() {
    if (this.initialFetch) {
      this.init();
    }
  },
  methods: {
    init() {
      this.selectedStartDate = new Date();
      this.adjustDatesForBillingPeriod();
    },

    async refreshData(accountNumber, startDate, endDate) {
      this.loadState = undefined;
      try {
        const data = await this.getAccountUsageSnapshot(accountNumber, startDate, endDate);
        this.getHighestAverageConsumption(data);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.loadState = "unavailable";
          return;
        }
        this.handleRefreshError(err);
        return;
      }
    },

    getHighestAverageConsumption(data) {
      if (data && data.averageConsumptionPercentage) {
        let highestPercentage = 0;
        let highestPercentageKey = "";
        for (let key in data.averageConsumptionPercentage) {
          if (data.averageConsumptionPercentage[key] > highestPercentage) {
            highestPercentage = data.averageConsumptionPercentage[key];
            highestPercentageKey = key;
          }
        }
        this.setTimeOfDayText(highestPercentageKey);
      }
    },

    setTimeOfDayText(rangeText) {
      switch (rangeText) {
        case "hour0to3":
          this.timeOfDay = 'late night'
          this.timeRange = '12am to 4am'
          this.fileName = 'usage-tod-late-night@2x.png'
          break;
        case "hour4to7":
          this.timeOfDay = 'daybreak'
          this.timeRange = '4am to 8am'
          this.fileName = 'usage-tod-daybreak@2x.png'
          break;
        case "hour8to11":
        this.timeOfDay = 'late morning'
          this.timeRange = '8am to 12pm'
          this.fileName = 'usage-tod-late-morning@2x.png'
          break;
        case "hour12to15":
          this.timeOfDay = 'afternoon'
          this.timeRange = '12pm to 4pm'
          this.fileName = 'usage-tod-afternoon@2x.png'
          break;
        case "hour16to19":
          this.timeOfDay = 'evening'
          this.timeRange = '4pm to 8pm'
          this.fileName = 'usage-tod-evening@2x.png'
          break;
        case "hour20to23":
          this.timeOfDay = 'night'
          this.timeRange = '8pm to 12am'
          this.fileName = 'usage-tod-night@2x.png'
          break;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>