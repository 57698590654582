<template>
  <div class="top-card-display__single-card top-card-display__single-card--latest-bill">
    <a
      v-if="loadState === 'complete' && latestBill && !isAdminLite"
      :href="latestBill.url"
      target="_blank"
      class="top-banner-card gds-card gds-background-blue gds-invert top-banner-card--logged-in"
    >
      <div class="gds-card__contents">
        <p class="gds-card__caption">Latest Bill</p>
        <h3 class="gds-card__title" v-if="latestBill.date">View your last bill from {{latestBill.date | dateFull}}</h3>
        <h3 class="gds-card__title" v-if="!latestBill.date">View your last bill</h3>
        <div class="gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"></div>
      </div>
    </a>
    <div v-else class="top-banner-card gds-card gds-background-blue gds-invert top-banner-card--logged-in" :class="{'gds-loading': loadState === undefined }">
      <div class="gds-card__contents">
        <p class="gds-card__caption">Latest Bill</p>
        <h3 v-if="loadState === 'complete' && !latestBill" class="gds-card__title">No bill has been recorded yet.</h3>
        <div v-if="loadState === 'error'" class="card-error card-error--trans-white">
          Unable to load,
          <br />please try again later.
        </div>
        <div v-if="loadState === 'error'" class="card-error-layout-blank">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addYears, addDays } from 'date-fns';
import GMPAPI from '../../../services/gmpapi';
import { ToServerDateTruncate, DumpError } from '../../../utilities';

export default {
  name: "CardLatestBill",
  data() {
    return {
      latestBill: undefined,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
  },
  async mounted() {
    await this.RefreshData();
  },
  methods: {
    async RefreshData() {
      this.latestBill = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        const start = addYears(new Date(), -2);
        const end = addDays(new Date(), 1);
        const allTransactions = await GMPAPI.GetAccountTransactions(this.currentAccount.accountNumber, ToServerDateTruncate(start), ToServerDateTruncate(end));
        const latestBill = allTransactions.find(item => item.type === "Bill Segment");
        this.latestBill = latestBill;
        this.loadState = "complete";
      } catch (err) {
        if (this.currentAccount.currentBillUrl !== undefined) {
          this.latestBill = {};
          this.latestBill.url = this.currentAccount.currentBillUrl;
          this.loadState = "complete";
        } else {
          DumpError("Latest bill card refresh error", err);
          this.loadState = "error";
        }
      }
    },
  },
  watch: {
    async currentAccount() {
      await this.RefreshData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>