var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outage-header gds-flex-container" }, [
    _c(
      "div",
      {
        staticClass:
          "outage-header__left gds-flex-container gds-flex-container--left"
      },
      [
        _vm.updated
          ? _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--column outage-header__status"
              },
              [
                _c("div", { staticClass: "gds-font-demi gds-font-size-l" }, [
                  _vm._v("Outage Center")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-light gds-font-size-s gds-text-lightgrey"
                  },
                  [
                    _vm._v(
                      "Updated at " + _vm._s(_vm._f("timeOnly")(_vm.updated))
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.summary
          ? _c(
              "div",
              {
                staticClass:
                  "outage-header__data-column outage-header__border-right"
              },
              [
                _c(
                  "div",
                  { staticClass: "gds-font-size-xxl gds-font-medium" },
                  [_vm._v(_vm._s(_vm.summary.customerCount.toLocaleString()))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gds-font-demi gds-font-size-s" }, [
                  _vm._v("Customers Out")
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.summary
          ? _c(
              "div",
              {
                staticClass:
                  "outage-header__data-column outage-header__border-right"
              },
              [
                _c(
                  "div",
                  { staticClass: "gds-font-size-xxl gds-font-medium" },
                  [_vm._v(_vm._s(_vm.summary.eventCount))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gds-font-demi gds-font-size-s" }, [
                  _vm._v("Active Incidents")
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.summary
          ? _c("div", { staticClass: "outage-header__data-column" }, [
              _c("div", { staticClass: "gds-font-size-xxl gds-font-medium" }, [
                _vm._v(_vm._s(_vm.summary.townCount))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-font-demi gds-font-size-s" }, [
                _vm._v("Towns Affected")
              ])
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "outage-header__right gds-flex-container" }, [
      !_vm.currentAccount
        ? _c("div", { staticClass: "outage-header__login" }, [
            _c("div", { staticClass: "outage-header__login--button-wrap" }, [
              _c(
                "button",
                {
                  staticClass: "gds-button gds-text-button",
                  attrs: { type: "button" },
                  on: { click: _vm.ShowLogin }
                },
                [_vm._v("Log in")]
              ),
              _vm._v("\n        to view your details\n      ")
            ])
          ])
        : _vm.personalData === null
        ? _c("div", { staticClass: "outage-header__login" }, [
            _c("button", {
              staticClass:
                "outage-header__login-locations-button gds-button gds-compact gds-secondary my-status gds-loading",
              staticStyle: { width: "182px" }
            })
          ])
        : _vm.mergedData && _vm.mergedData.length > 0
        ? _c(
            "div",
            { staticClass: "outage-header__login outage-my-status-popout" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "outage-header__login-locations-button gds-button gds-compact gds-secondary my-status caret caret-white",
                  class: { "caret-down": !_vm.showMyStatus },
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.showMyStatus = !_vm.showMyStatus
                    }
                  }
                },
                [_vm._v("My\n        Power Status")]
              ),
              _vm._v(" "),
              _vm.showMyStatus
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.CloseMyStatus,
                          expression: "CloseMyStatus"
                        }
                      ],
                      staticClass: "outage-my-status"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "outage-my-status__inner" },
                        _vm._l(_vm.mergedData, function(account) {
                          return _c(
                            "div",
                            {
                              key: account.accountNumber,
                              staticClass: "outage-my-account"
                            },
                            [
                              account.photoUrl
                                ? _c("div", {
                                    staticClass: "small-round-img",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        _vm.GetResizedAccountPhoto(
                                          account.photoUrl
                                        ) +
                                        ")"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !account.photoUrl
                                ? _c("div", { staticClass: "small-round-img" })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "outage-my-account__detail" },
                                [
                                  account.nickname
                                    ? _c("div", { staticClass: "nickname" }, [
                                        _vm._v(_vm._s(account.nickname))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "address gds-linebreaks" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("addressFormat")(
                                            account.address,
                                            {
                                              separator: "\n",
                                              skipFirst: false
                                            }
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  account.outageReported ||
                                  (account.incidents &&
                                    account.incidents.length > 0)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "outage-status gds-flex-container gds-flex-container--left"
                                        },
                                        [
                                          _vm._m(0, true),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("Outage Reported")])
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "outage-status gds-flex-container gds-flex-container--left"
                                        },
                                        [
                                          _vm._m(1, true),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v("No Outages Reported")
                                          ])
                                        ]
                                      ),
                                  _vm._v(" "),
                                  account.plannedOutages &&
                                  account.plannedOutages.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "outage-status gds-flex-container gds-flex-container--left"
                                        },
                                        [
                                          _vm._m(2, true),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("Planned Outage")])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "outage-status-link" }, [
                                account.incidents &&
                                account.incidents.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "gds-button gds-round gds-secondary gds-compact",
                                            attrs: {
                                              to:
                                                "/incident/" +
                                                account.incidents[0].id
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("View Details")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outage-header__alert-icon active" }, [
      _c("img", {
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/icon-small-orange-alert-with-shadow.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outage-header__alert-icon inactive" }, [
      _c("img", {
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-with-shadow.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outage-header__alert-icon planned" }, [
      _c("img", {
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/planned-outage-info-icon.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "outage-header__report" }, [
      _c(
        "a",
        {
          staticClass:
            "report_outage_btn outage-header__report-button gds-button gds-orange gds-compact",
          attrs: { href: "/report-outage" }
        },
        [_vm._v("Report Outage")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }