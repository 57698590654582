<template>
  <div class="make-payment-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Bill Pay" state="error"
      phonetext="To make a payment by phone call us at" closepath="/make-payment" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 -->
      <section id="pay-slat-1" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="bill-pay-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Payment Amount</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Payment amount: {{ paymentAmount | currency }}</div>
                <button @click="gotoPage(1)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 1" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l bill-pay__account-box">
                <div class="bill-pay__amount-flex-container gds-flex-container gds-flex-container--top">
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Current balance</div>
                    <div>
                      <div v-if="amountDue > 0 && pastDueBalance > 0">
                        <div class="gds-body-normal gds-space-stack-s">Total balance: {{ amountDue |
                            currency
                        }}</div>
                        <div class="gds-body-normal gds-space-stack-s">Due now: {{ pastDueBalance |
                            currency
                        }}</div>
                      </div>
                      <div v-if="amountDue > 0 && pastDueBalance > 0 && pastDueBalance !== amountDue"
                        class="gds-body-normal">Due
                        {{ currentAccount.amountDueDate | dateFull }}: {{ amountDue - pastDueBalance | currency }}</div>
                      <div v-if="amountDue > 0 && pastDueBalance <= 0"
                        class="gds-body-normal">{{amountDue | currency}} is due {{currentAccount.amountDueDate | dateFull}}.</div>
                      <div v-if="amountDue <= 0" class="gds-body-normal">All paid up, you do not owe any money at this
                        time.</div>
                    </div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Service Address</div>
                    <div class="gds-body-normal gds-linebreaks">{{ currentAccount.address | addressFormat }}</div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Account Number</div>
                    <div class="gds-body-normal">{{ currentAccount.accountNumber }}</div>
                  </div>
                </div>
              </div>
              <div class="bill-pay-slap__inner-row gds-space-stack-l">
                <form data-vv-scope="page1" @submit.prevent>
                  <div class="gds-flex-container gds-flex-container--left">
                    <fieldset class="gds-fieldset">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Payment amount</span>
                        <div class="gds-position--relative">
                          <span class="gds-input-field__prefix gds-text">$</span>
                          <money v-model="paymentAmount" v-validate="`required|min_value:${paymentMeta.minACH}|max_value:${this.maxPaymentAmount}`"
                            name="payment amount" key="payment amount"
                            class="bill-pay-input-field__input gds-input-field__input" autofocus ref="focuser1"></money>
                        </div>
                      </label>
                    </fieldset>
                  </div>
                  <div class="bill-pay__fieldset--wide-hint">
                    <div v-if="errors.first(`payment amount`, 'page1')"
                              class="validation-error">{{
                              `The payment amount entered is greater than the maximum allowed amount of $${this.maxPaymentAmount}. If a larger payment amount needs to be made, please make multiple payments.`
                              }}</div>
                    <div v-if="paymentAmount < 10" class="form-input-hint form-input-hint--blue">Card payments have a
                      $10.00 minimum. If you’d like to be able to pay using a credit, debit, or ATM card, please adjust
                      the payment amount.</div>
                    <div v-if="paymentAmount > 0 && paymentAmount > currentAccount.amountDue"
                      class="form-input-hint form-input-hint--blue">The payment amount you entered is more than what is
                      due at this time. Any overpayment will be automatically credited on the next bill we issue you.
                    </div>
                  </div>
                </form>
                <button @click="finishPage('page1', 2)" class="gds-button gds-compact gds-space-stack-l">Next</button>
                <div>
                  To set up or manage recurring auto payments, please
                  <a href="/account/preferences">log in</a> or
                  <a href="/account/create">create an account</a>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2  -->
      <section id="pay-slat-2" class="payment-details bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="bill-pay-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Payment Details</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{ paymentMethodSlug }}</div>
                <button @click="gotoPage(2)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <banner-one-action v-if="tooManyCCPayments" bannerColor="blue"
                message="Card payments are disabled because two or more payments have already been processed this month, or are pending or scheduled. GMP limits card payments to 2 per month to keep electronic payments free for all customers. To make an additional payment this month, please use a bank account, which has no payment limits."
                :linkText="'Info'" @buttonFunction="showModalCCPaymentInfo"></banner-one-action>
              <ModalCCPaymentInfo :visible="ShowModalCCPaymentInfo" :close="closeModal" />
              <div v-if="!tooManyCCPayments && paymentAmount < paymentMeta.minCC"
                class="form-message-box form-message-box--blue gds-space-stack-l">Card payments are disabled because the
                payment amount is below the minimum of {{ paymentMeta.minCC | currency }}. If you'd like to pay using a
                debit, credit, or ATM card, please edit your payment amount.</div>
              <div v-if="!tooManyCCPayments && paymentAmount > paymentMeta.maxCC"
                class="form-message-box form-message-box--blue gds-space-stack-l">Card payments are disabled because the
                payment amount is above the maximum of {{ paymentMeta.maxCC | currency }}. If you'd like to pay using a
                debit, credit, or ATM card, please edit your payment amount.</div>

              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                  <legend class="gds-display-0 gds-space-stack-ml">Please enter your payment details. Any information
                    you enter will only be used for the processing of this payment.</legend>
                  <label class="gds-radio">
                    <input v-model="selectedWallet.paymentType"
                      :disabled="tooManyCCPayments || paymentAmount < paymentMeta.minCC || paymentAmount > paymentMeta.maxCC"
                      value="CC" type="radio" name="my-radio-group" />
                    <span class="gds-radio__faux"></span>
                    <span class="gds-radio__label">Pay with Credit / Debit Card</span>
                  </label>
                  <label class="gds-radio">
                    <input v-model="selectedWallet.paymentType"
                      :disabled="tooManyCCPayments || paymentAmount < paymentMeta.minCC || paymentAmount > paymentMeta.maxCC"
                      value="ATM" type="radio" name="my-radio-group" />
                    <span class="gds-radio__faux"></span>
                    <span class="gds-radio__label">Pay with ATM Card</span>
                  </label>
                  <label class="gds-radio">
                    <input v-model="selectedWallet.paymentType" value="ACH" type="radio" name="my-radio-group" />
                    <span class="gds-radio__faux"></span>
                    <span class="gds-radio__label">Pay with Bank Account</span>
                  </label>
                </fieldset>
                <div v-show="selectedWallet.paymentType === 'CC'">
                  <form data-vv-scope="page2credit" @submit.prevent>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Name on Card</span>
                        <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                          key="name on card" class="bill-pay-input-field__input gds-input-field__input" type="text"
                          placeholder="Name" maxlength="45" autocomplete="cc-name" ref="focuser2" />
                        <div v-if="errors.first('name on card', 'page2credit')" class="validation-error">{{
                            errors.first("name on card", "page2credit")
                        }}</div>
                      </label>
                    </fieldset>
                    <div
                      class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-s">
                      <fieldset class="bill-pay-slat__card-number gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Card Number</span>

                          <div class="gds-position--relative">
                            <span class="bill-pay__credit-card-prefix gds-input-field__prefix gds-text">
                              <svg class="bill-pay__icon gds-icon gds-icon">
                                <use
                                  xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                              </svg>
                            </span>
                            <input v-model="creditNumber" v-mask="'################'"
                              v-validate="(selectedWallet.lastFourDigitDebitAccount ? '' : 'required|') + 'numeric|min:15|max:16'"
                              name="card number" key="card number" class="gds-input-field__input" type="tel"
                              :placeholder="selectedWallet.lastFourDigitDebitAccount ? ('******' + selectedWallet.lastFourDigitDebitAccount) : ''"
                              maxlength="16" autocomplete="cc-number" />
                          </div>
                          <div v-if="errors.first('card number', 'page2credit')" class="validation-error">{{
                              errors.first("card number", "page2credit")
                          }}</div>
                        </label>
                      </fieldset>
                      <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Expiration</span>
                          <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                            v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                            key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                            autocomplete="cc-exp" />
                          <div v-if="errors.first('expiration date', 'page2credit')" class="validation-error">{{
                              errors.first("expiration date", "page2credit")
                          }}</div>
                        </label>
                      </fieldset>
                      <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">CVV</span>
                          <input v-model="tempCVV" v-mask="'####'" v-validate="'required|numeric|min:3|max:4'"
                            name="CVV" key="CVV" class="gds-input-field__input" type="tel" maxlength="4"
                            autocomplete="cc-csc" />
                          <div v-if="errors.first('CVV', 'page2credit')" class="validation-error">{{ errors.first("CVV",
                              "page2credit")
                          }}</div>
                        </label>
                      </fieldset>
                      <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Billing ZIP Code</span>
                          <input v-model="debitZip" v-mask="'#####'" v-validate="'required|numeric|min:5|max:5'"
                            name="billing zip" key="billing zip"
                            class="bill-pay-slat__small-input gds-input-field__input" type="tel" maxlength="5" />
                        </label>
                        <div v-if="errors.first('billing zip', 'page2credit')" class="validation-error">{{
                            errors.first("billing zip", 'page2credit')
                        }}</div>
                      </fieldset>
                    </div>
                  </form>
                  <button @click="finishPage('page2credit', 3)" class="gds-button gds-compact">Next</button>
                </div>
                <div v-show="selectedWallet.paymentType === 'ATM'">
                  <form data-vv-scope="page2atm" @submit.prevent>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Name on Card</span>
                        <input v-model="selectedWallet.debitName" v-validate="'required|max:45'" name="name on card"
                          key="name on card" class="bill-pay-input-field__input gds-input-field__input" type="text"
                          placeholder="Name" maxlength="45" autocomplete="cc-name" ref="focuser2" />
                        <div v-if="errors.first('name on card', 'page2atm')" class="validation-error">{{
                            errors.first("name on card", "page2atm")
                        }}</div>
                      </label>
                    </fieldset>
                    <div
                      class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-s">
                      <fieldset class="bill-pay-slat__card-number gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Card Number</span>

                          <div class="gds-position--relative">
                            <span class="bill-pay__credit-card-prefix gds-input-field__prefix gds-text">
                              <svg class="bill-pay__icon gds-icon gds-icon">
                                <use
                                  xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                              </svg>
                            </span>
                            <input v-model="creditNumber" v-mask="'################'"
                              v-validate="(selectedWallet.lastFourDigitDebitAccount ? '' : 'required|') + 'numeric|min:15|max:16'"
                              name="card number" key="card number" class="gds-input-field__input" type="tel"
                              :placeholder="selectedWallet.lastFourDigitDebitAccount ? ('******' + selectedWallet.lastFourDigitDebitAccount) : ''"
                              maxlength="16" autocomplete="cc-number" />
                          </div>
                          <div v-if="errors.first('card number', 'page2atm')" class="validation-error">{{
                              errors.first("card number", "page2atm")
                          }}</div>
                        </label>
                      </fieldset>
                      <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Expiration</span>
                          <input v-model="selectedWallet.expirationDate" v-mask="'##/##'"
                            v-validate="'required|date_format:MM/yy|after:' + firstValidCard" name="expiration date"
                            key="expiration date" class="gds-input-field__input" type="text" placeholder="MM/YY"
                            autocomplete="cc-exp" />
                          <div v-if="errors.first('expiration date', 'page2atm')" class="validation-error">{{
                              errors.first("expiration date", "page2atm")
                          }}</div>
                        </label>
                      </fieldset>
                    </div>
                  </form>
                  <button @click="finishPage('page2atm', 3)" class="gds-button gds-compact">Next</button>
                </div>
                <div v-show="selectedWallet.paymentType === 'ACH'">
                  <form data-vv-scope="page2bank" @submit.prevent>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Type</span>
                        <select v-model="selectedWallet.transType" v-validate="'required'" name="account type"
                          key="account type"
                          class="bill-pay-input-field__input gds-input-field bill-pay-input-field__select"
                          id="account-type">
                          <option value="SAVINGS">Savings</option>
                          <option value="CHECKING">Checking</option>
                          <option value="MONEY_MARKET">Money Market</option>
                        </select>
                        <div v-if="errors.first('account type', 'page2bank')" class="validation-error">{{
                            errors.first("account type", "page2bank")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Account Number</span>
                        <input v-model="bankNumber" v-mask="'######################'"
                          v-validate="'required|numeric|min:5|max:22'" name="account number" key="account number"
                          class="bill-pay-input-field__input gds-input-field__input" type="tel" maxlength="22"
                          ref="focuser2" />
                        <div v-if="errors.first('account number', 'page2bank')" class="validation-error">{{
                            errors.first("account number", "page2bank")
                        }}</div>
                      </label>
                    </fieldset>
                    <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Routing Number</span>
                        <input v-model="selectedWallet.debitRouting" v-mask="'#########'"
                          v-validate="'required|numeric|min:9'" name="routing number" key="routing number"
                          class="bill-pay-input-field__input gds-input-field__input" type="tel" maxlength="9" />
                        <div v-if="errors.first('routing number', 'page2bank')" class="validation-error">{{
                            errors.first("routing number", "page2bank")
                        }}</div>
                      </label>
                    </fieldset>
                  </form>
                  <button @click="finishPage('page2bank', 3)" class="gds-button gds-compact">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 -->
      <section id="pay-slat-3" class="contact-information bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="bill-pay-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Contact Information</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{ emailAddress }}</div>
                <button @click="gotoPage(3)"
                  class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-body-small">Where we should send the payment confirmation email.</div>
              </div>
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <form data-vv-scope="page3" @submit.prevent>
                  <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-space-stack-s">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Email Address</span>
                      <input v-model="emailAddress" v-validate="'required|email|max:74'" maxlength="74"
                        name="email address" key="email address"
                        class="bill-pay-input-field__input gds-input-field__input" type="email" placeholder="Your email"
                        autocomplete="email" ref="focuser3" />
                      <div v-if="errors.first('email address', 'page3')" class="validation-error">{{ errors.first("email address", "page3")}}</div>
                    </label>
                  </fieldset>
                </form>
                <button @click="finishPage('page3', 4)" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 -->
      <section id="pay-slat-4" class="review-submit bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div class="bill-pay-slat__number-with-circle">
                <span>4</span>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Review &amp; Submit</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage < 4" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s">GMP Account</div>
                <div class="gds-body-small gds-space-stack-l">
                  #{{ currentAccount.accountNumber }}
                  <br />
                  {{ currentAccount.address | addressFormat({ separator: ", " }) }}
                </div>
                <div class="gds-display-00 gds-space-stack-s">Amount</div>
                <div class="gds-body-small gds-space-stack-l">{{ paymentAmount | currency }}</div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Payment Method</div>
                  <div class="gds-body-small gds-space-stack-l">{{ paymentMethodSlug }}</div>
                </div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Payment Date</div>
                  <div class="gds-body-small gds-space-stack-l">{{ todayDate | dateFull }}</div>
                </div>
                <div class="gds-body-small gds-space-stack-l">
                  By submitting a payment you agree to the
                  <a href="https://www.speedpay.com/terms" target="_blank">Payment Terms &amp; Conditions.</a>
                </div>
                <div v-if="makePaymentError" class="form-message-box gds-space-stack-l">{{ makePaymentError }}</div>
                <button @click="submitPayment()" :disabled="pendingSubmitPayment"
                  :class="{ 'gds-loading': pendingSubmitPayment }" type="submit" class="gds-button gds-compact">
                  <span class="gds-button__text">Submit Payment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- #main -->
</template>

<script>
import { ErrorBag } from "vee-validate";
import { format, addMonths } from "date-fns";

import GMPAPI from "../../services/gmpapi";
import { GetCardType, ToFullExpirationDate } from "../../utilities";
import { DumpError } from "../../utilities";
import { GMPPhoneNumber } from "../../environment"
import BannerOneAction from "../BannerOneAction.vue"
import ModalCCPaymentInfo from "./ModalCCPaymentInfo.vue"


export default {
  name: "BillPayFlowLoggedOut",
  components: {
    BannerOneAction,
    ModalCCPaymentInfo,
  },
  data() {
    return {
      paymentAmount: 0,

      selectedWallet: {
        transType: "CHECKING",
        paymentType: "CC",
      },

      // Keep these separate since they use the same field
      creditNumber: "",
      bankNumber: "",

      tempCVV: "",
      tokenizedCard: undefined,
      debitZip: this.debitZip,

      todayDate: new Date(),
      firstValidCard: format(addMonths(new Date(), -1), "MM/yy"),

      emailAddress: "",
      paymentMeta: {
        minACH: 1,
        maxACH: 99999.99,
        minCC: 10,
        maxCC: 2500
      },

      maxPaymentAmount: undefined,

      tooManyCCPayments: false,

      makePaymentError: undefined,

      pendingSubmitPayment: false,

      loadState: undefined,

      ShowModalCCPaymentInfo: false,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    paymentMethodSlug() {
      if (this.selectedWallet.paymentType === "CC") {
        return "Credit: ****" + this.selectedWallet.lastFourDigitDebitAccount;
      } else if (this.selectedWallet.paymentType === "ATM") {
        return "ATM: ****" + this.selectedWallet.lastFourDigitDebitAccount;
      } else {
        return "Account: ****" + this.bankNumber.slice(-4);
      }
    },
    pastDue() {
      return (new Date(this.currentAccount.amountDueDate) < new Date());
    },
    amountDue() {
      return this.currentAccount.amountDue;
    },
  },
  async mounted() {
    const paymentMeta = await GMPAPI.GetPaymentMeta()
    if (paymentMeta && paymentMeta.minCC) {
      this.paymentMeta = paymentMeta;
    }

    if (this.currentAccount.amountDue > 0) {
      this.paymentAmount = this.currentAccount.amountDue;
    } else {
      this.paymentAmount = 0;
    }

    const accountNumber = this.currentAccount.accountNumber;
    const status = await GMPAPI.GetAccountStatus(accountNumber);
    if (status && (status.pastDue30Balance || status.pastDue60Balance)) {
      this.pastDueBalance = status.pastDue30Balance + status.pastDue60Balance;
    }
    
    if (this.currentAccount.isStopped === true) {
      this.maxPaymentAmount = this.amountDue;
    } else {
      this.maxPaymentAmount = this.paymentMeta.maxACH;
    }

    // Kick off async fetches
    try {
      this.loadState = undefined;
      await Promise.all([
        this.fetchPaymentMeta(),
        this.fetchCCPayments(),
      ]);
      this.loadState = "complete";
    } catch (err) {
      DumpError("Billpay loggedout load error", err);
      this.loadState = "error";
    }
  },
  methods: {
    /** Go to a page number without validation */
    gotoPage(page) {
      this.$router.push({ path: "/pay/" + page });
      this.slatFocus(page);
    },
    showModalCCPaymentInfo() {
      this.ShowModalCCPaymentInfo = true;
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        const focuser = this.$refs["focuser" + page];
        if (focuser && focuser.$el) {
          if (focuser.$el.focus) focuser.$el.focus();
        } else if (focuser && Array.isArray(focuser) && focuser.length >= 1) {
          // Page 2 has array of wallets
          if (focuser[0].focus) focuser[0].focus();
        } else if (focuser) {
          if (focuser.focus) focuser.focus();
        }
      }, 100);
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            // If amount is invalid for credit, select ACH
            if (this.tooManyCCPayments || this.paymentAmount < this.paymentMeta.minCC || this.paymentAmount > this.paymentMeta.maxCC) {
              this.selectedWallet.paymentType = "ACH";
            }
            break;
          case "page2credit":
            // If they've entered or altered the CC number, validate and tokenize
            if (this.creditNumber) {
              const cardType = GetCardType(this.creditNumber);
              if (!cardType) {
                // Unknown card type, bail!
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page2credit" });
                return;
              }
              this.selectedWallet.cardType = cardType;
              this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
              try {
                this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
                  this.paymentMeta.tokenUrl,
                  this.creditNumber
                );
                this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
                this.creditNumber = "";
              } catch (err) {
                // Bad response from tokenizer
                DumpError("OTP credit next tokenize error", err);
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page2credit" });
                return;
              }
            }
            this.selectedWallet.cvv = this.tempCVV;
            this.tempCVV = ""
            break;
          case "page2atm":
            // If they've entered or altered the CC number, validate and tokenize
            if (this.creditNumber) {
              const cardType = GetCardType(this.creditNumber);
              if (!cardType) {
                // Unknown card type, bail!
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card number", scope: "page2atm" });
                return;
              }
              this.selectedWallet.cardType = cardType;
              this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
              try {
                this.tokenizedCard = await GMPAPI.TokenizeCreditCard(
                  this.paymentMeta.tokenUrl,
                  this.creditNumber
                );
                this.selectedWallet.lastFourDigitDebitAccount = this.creditNumber.slice(-4);
                this.creditNumber = "";
              } catch (err) {
                // Bad response from tokenizer
                DumpError("OTP atm next tokenize error", err);
                // Make sure we have a fresh ErrorBag for handling special cases
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "card number", msg: "Not a valid card", scope: "page2atm" });
                return;
              }
            }
            break;
        }
        this.gotoPage(nextPage);
      }
    },
    async fetchPaymentMeta() {
      const meta = await GMPAPI.GetPaymentMeta();
      this.paymentMeta = meta;
    },
    async fetchCCPayments() {
      let payments = [];
      payments = await GMPAPI.GetBillingHistory(this.currentAccount.accountNumber, 30);
      const ccpays = payments.filter(item => (item.paymentType === "CC" || item.paymentType === "ATM") && (item.status === "PROCESSED" || item.status === "PENDING"));
      if (ccpays.length >= 2) {
        // 2 or more cc/atm payments this month, disable CC pay
        this.tooManyCCPayments = true;
      } else {
        this.tooManyCCPayments = false;
      }
    },
    async submitPayment() {
      this.makePaymentError = undefined;
      this.pendingSubmitPayment = true;
      try {
        const wallet = this.selectedWallet;
        const paymentRecord = {
          customerInformation: {
            // Payment
            // If no debitName (credit card), fill in account name to satisfy GMP api
            debitName: wallet.debitName || (this.currentAccount.firstName + this.currentAccount.lastName),
            // Account
            creditFirstname: this.currentAccount.firstName,
            creditLastname: this.currentAccount.lastName,
            phone: this.currentAccount.phone,
            creditAccount: this.currentAccount.accountNumber,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.debitZip || this.currentAccount.mailingAddress.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1,
          },
          debitInformation: {
            paymentType: wallet.paymentType,
            debitRouting: wallet.debitRouting,
            debitAccount: (this.selectedWallet.paymentType === "CC" || this.selectedWallet.paymentType === "ATM") ? this.tokenizedCard : this.bankNumber,
            // Note, speedpay docs say they want MM/YYYY but GMP api expects full ISO
            // Only convert if we have the shorthand input date, otherwise leave alone
            expDate: (wallet.expirationDate && wallet.expirationDate.length < 8) ? ToFullExpirationDate(wallet.expirationDate) : wallet.expirationDate,
            cvv: wallet.cvv || "000",
            // NOTE alternate casing of transType
            transtype: wallet.transType,
            // If CC can process as ATM, do it
            skipCcOverAtm: false,
            // Pull existing wallet if not new
            retrieveWallet: !!wallet.walletId,
          },
          paymentInformation: {
            emailAddress: this.emailAddress,
            paymentAmount: this.paymentAmount,
          },
        };
        const paymentResult = await GMPAPI.MakePayment(this.currentAccount.accountNumber, paymentRecord);
        this.$store.commit("setPaymentAmount", this.paymentAmount);
        this.$store.commit("setConfirmationNumber", paymentResult.paymentId);
        // Update account data (pending payments)
        await this.$store.dispatch("FetchUserInfo");
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Submit payment OTP error", err);
        if (err.response && err.response.data) {
          if (err.response.data.message) {
            console.error(err.response.data.message);
          }
          const code = err.response.data.code;
          // Make sure we have a fresh ErrorBag for handling special cases
          if (!this.errors) this.errors = new ErrorBag();
          // Take specific actions on certain error codes here
          switch (code) {
            // CVV
            case 1006:
              this.errors.add({ field: "CVV", msg: "The CVV was incorrect.", scope: "page2credit" });
              this.gotoPage(2);
              break;
            // Expiration date
            case 1012:
              this.errors.add({ field: "expiration date", msg: "The expiration date was incorrect.", scope: "page2credit" });
              this.gotoPage(2);
              break;
            // CC Mod 10
            case 3342:
            case 18077:
              this.errors.add({ field: "card number", msg: "The card number was incorrect.", scope: "page2credit" });
              this.errors.add({ field: "card number", msg: "The card number was incorrect.", scope: "page2atm" });
              this.gotoPage(3);
              break;
            // Routing number
            case 2517:
            case 4330:
            case 4533005:
              this.errors.add({ field: "routing number", msg: "The routing number was incorrect.", scope: "page2bank" });
              this.gotoPage(2);
              break;
            // Account number
            case 2518:
            case 4533006:
              this.errors.add({ field: "account number", msg: "The account number was incorrect.", scope: "page2bank" });
              this.gotoPage(2);
              break;
            case 4533003:
            case 4533004:
              this.errors.add({ field: "account number", msg: `There is a problem with this account, please select another account or call ${GMPPhoneNumber || '1-(888)-835-4672'} Monday through Friday, 9am - 6pm.`, scope: "page2bank" });
              this.gotoPage(2);
              break;
            // The following are non-fatal errors, but don't redirect to a particular field

            // Declined
            case 1001:
            // ATM Declined
            case 9002:
              this.makePaymentError = "The card was declined.";
              break;
            // Duplicate
            case 3307:
              this.makePaymentError = "This payment appears to be a duplicate! You recently paid the exact same amount on this account. To prevent you from accidentally paying twice, it has NOT been processed. If you’d still like to make a payment on this account, change the amount and submit it again.";
              break;
            // More than 2 payments / month
            case 3803:
              this.makePaymentError = "GMP allows two credit card payments per account per month. If you would like to make additional payments on your account this month, please use the your checking account.";
              break;

            // Any other error code gets redirected to the "fatal" error page
            default:
              this.$router.push({ path: "/error/" + code });
              break;
          }
        } else {
          this.$router.push({ path: "/error/" + code });
        }
      } finally {
        // Either way, turn off the loading indicator
        this.pendingSubmitPayment = false;
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>