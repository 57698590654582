var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-account-settings--dashboard" },
    [
      _c("h1", { staticClass: "my-account__title gds-space-stack-l" }, [
        _vm._v("Account Settings")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-account__section-with-bottom-border" }, [
        _c(
          "div",
          {
            staticClass:
              "gds-flex-container gds-flex-container--left my-account__current-login"
          },
          [
            _c("div", { staticClass: "gds-font-size-l gds-space-inline-s" }, [
              _vm._v("\n        Currently signed in as "),
              _c("span", { staticClass: "gds-font-demi" }, [
                _vm._v(_vm._s(_vm.userInfo.fullName))
              ]),
              _vm._v(", " + _vm._s(_vm.userInfo.email) + "\n        "),
              _c(
                "a",
                {
                  staticClass: "gds-button__text gds-font-size-l",
                  attrs: { href: "/user-profile" }
                },
                [_vm._v("Manage User Settings")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "my-account__full-width-section gds-space-stack-l" },
        [
          _c(
            "div",
            {
              staticClass:
                "my-account__section-settings__address-account-wrap gds-flex-container gds-flex-container--top gds-flex-container--left"
            },
            [
              _c(
                "div",
                { staticClass: "my-account__settings--detail-flex-item" },
                [
                  _c("h2", { staticClass: "gds-display-1 gds-space-stack-m" }, [
                    _vm._v("Service Address")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-font-size-l gds-space-stack-l" },
                    [
                      _c("div", { staticClass: "gds-linebreaks" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressFormat")(_vm.currentAccount.address)
                          )
                        )
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-account__settings--detail-flex-item" },
                [
                  _c("h2", { staticClass: "gds-display-1 gds-space-stack-m" }, [
                    _vm._v("Service Account")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-font-size-l" }, [
                    _vm._v(
                      _vm._s(_vm.currentAccount.lastName) +
                        ", " +
                        _vm._s(_vm.currentAccount.firstName)
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-font-size-l gds-space-stack-m" },
                    [_vm._v("#" + _vm._s(_vm.currentAccount.accountNumber))]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "my-account__settings--detail-flex-item primary-account"
                },
                [
                  _vm.currentAccount.isPrimary
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "gds-flex-container gds-flex-container--left gds-flex-container--bottom"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-account__green-check-cirlce" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "my-account__white-check-icon gds-icon"
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "user-profile__account-status-label gds-font-demi gds-font-size-m gds-space-inline-l"
                            },
                            [_vm._v("Primary Account")]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("editor-account-photo"),
      _vm._v(" "),
      _c("editor-account-nickname"),
      _vm._v(" "),
      _c("editor-mailing-address"),
      _vm._v(" "),
      _c("editor-phones"),
      _vm._v(" "),
      _c("editor-paperless", {
        attrs: { billingStatus: _vm.billingStatus },
        on: {
          update: function($event) {
            return _vm.RefreshStatus()
          }
        }
      }),
      _vm._v(" "),
      _c("editor-auto-payments", {
        attrs: { billingStatus: _vm.billingStatus }
      }),
      _vm._v(" "),
      _c("editor-budget-billing"),
      _vm._v(" "),
      _vm.currentAccount.isPrimary
        ? _c("div", [
            _vm._v(
              "This account is marked as the primary account and cannot be removed. "
            ),
            _c(
              "a",
              {
                staticClass: "gds-link gds-link--bold",
                attrs: { href: "/user-profile/accounts/" }
              },
              [_vm._v("Change primary account. ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentAccount.isPrimary
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "gds-button red gds-text-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.showRemove = true
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Remove Account")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("modal-remove-account", {
        attrs: { visible: _vm.showRemove },
        on: { complete: _vm.RemoveComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }