var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-main" }, [
    _c(
      "div",
      {
        staticClass: "bill-pay-login__main gds-flex-container",
        attrs: { id: "top-target" }
      },
      [
        _c("nav", { staticClass: "bill-pay-login__nav" }, [
          _c("div", { staticClass: "bill-pay-login__nav__inner-link-wrap" }, [
            _c(
              "div",
              {
                staticClass:
                  "past-due-wizard-login__nav-item past-due-wizard-login__nav-item--mobile-button",
                on: {
                  click: function($event) {
                    return _vm.goToCreateAccount()
                  }
                }
              },
              [_vm._v("Don't have an account? Get Started")]
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        !_vm.isGuest
          ? _c("div", { staticClass: "bill-pay-login__login-type-container" }, [
              _c("section", { staticClass: "bill-pay-login__login-section" }, [
                _c(
                  "h2",
                  {
                    staticClass: "bill-pay-login__main-headline gds-display-2"
                  },
                  [_vm._v("Log in")]
                ),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "global-panel__form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.Login()
                      }
                    }
                  },
                  [
                    _c(
                      "fieldset",
                      {
                        staticClass: "gds-fieldset gds-space-stack-m",
                        class: { "gds-has-error": _vm.errorlogin }
                      },
                      [
                        _c("label", { staticClass: "gds-input-field" }, [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Username or Email")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.username,
                                expression: "username"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            key: "user name",
                            staticClass: "gds-input-field__input",
                            attrs: {
                              name: "user name",
                              type: "text",
                              spellcheck: "false",
                              autocapitalize: "none",
                              autocomplete: "username"
                            },
                            domProps: { value: _vm.username },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.username = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.first("user name")
                            ? _c("div", { staticClass: "validation-error" }, [
                                _vm._v(_vm._s(_vm.errors.first("user name")))
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "fieldset",
                      {
                        staticClass: "gds-fieldset gds-space-stack-m",
                        class: { "gds-has-error": _vm.errorlogin }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "gds-input-field" },
                          [
                            _c(
                              "span",
                              { staticClass: "gds-input-field__label" },
                              [_vm._v("Password")]
                            ),
                            _vm._v(" "),
                            _c("widget-password-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              key: "password",
                              attrs: {
                                autocomplete: "current-password",
                                name: "password"
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.first("password")
                              ? _c("div", { staticClass: "validation-error" }, [
                                  _vm._v(_vm._s(_vm.errors.first("password")))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "global-login-panel__form-helper" },
                          [
                            _c(
                              "label",
                              { staticClass: "gds-checkbox gds-checkbox-thin" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stayloggedin,
                                      expression: "stayloggedin"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "stay-logged-in"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.stayloggedin)
                                      ? _vm._i(_vm.stayloggedin, null) > -1
                                      : _vm.stayloggedin
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.stayloggedin,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.stayloggedin = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.stayloggedin = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.stayloggedin = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "gds-checkbox__faux"
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "gds-checkbox__label gds-display-00 gds-text-grey"
                                  },
                                  [_vm._v("Stay Logged In")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "gds-space-stack-l" }, [
                      _vm.errorlogin
                        ? _c(
                            "div",
                            {
                              staticClass: "gds-helper-text-error",
                              attrs: { id: "submission_error" }
                            },
                            [_vm._v(_vm._s(_vm.errorlogin))]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gds-space-stack-l" }, [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingLogin },
                          attrs: { disabled: _vm.pendingLogin, type: "submit" }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Log In")
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(3)
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "bill-pay-login__phone-address-section-desktop" },
      [
        _c("div", { staticClass: "bill-pay-login__phone-address-inner" }, [
          _c(
            "div",
            { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
            [_vm._v("Pay By Phone")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m gds-space-stack-m" }, [
            _vm._v("(844) 551-4550")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
            [_vm._v("Pay By Mail")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m" }, [
            _vm._v("\n            Green Mountain Power\n            "),
            _c("br"),
            _vm._v("P.O. Box 1611\n            "),
            _c("br"),
            _vm._v("Brattleboro, VT 05302-1611\n          ")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-panel__form-helpers" }, [
      _c("p", { staticClass: "gds-body-normal" }, [
        _vm._v(
          "\n            Log in to your online GMP account. Don’t have an account?\n            "
        ),
        _c("a", { attrs: { href: "/account/create" } }, [
          _vm._v("Get Started.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-login-panel__form-helper" }, [
      _c(
        "a",
        {
          staticClass: "gds-display-00 gds-link gds-link--bold",
          attrs: { href: "/account/forgot" }
        },
        [_vm._v("Forgot Password?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "bill-pay-login__phone-address-section-mobile" },
      [
        _c("div", { staticClass: "bill-pay-login__phone-address-inner" }, [
          _c(
            "div",
            { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
            [_vm._v("Pay By Phone")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m gds-space-stack-m" }, [
            _vm._v("(844) 551-4550")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
            [_vm._v("Pay By Mail")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m" }, [
            _vm._v("\n            Green Mountain Power\n            "),
            _c("br"),
            _vm._v("P.O. Box 1611\n            "),
            _c("br"),
            _vm._v("Brattleboro, VT 05302-1611\n          ")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }