export default {
  props: {
    billingSummary: Object,
    rateData: undefined
  },
  data() {
    return {
      svgWidth: 100,
      svgHeight: 100,
    };
  },
  computed: {
    currentNetUsage() {
      return (this.energyFromGrid - this.generationReturnedToGrid).toFixed(1);
    },
    generationReturnedToGrid() {
      if (this.billingSummary?.totalGenerationSentToGrid) {
        return this.billingSummary.totalGenerationSentToGrid.toFixed(1);  
      }
    },
    energyFromGrid() {
      if (this.billingSummary?.totalConsumedFromGrid) {
        return this.billingSummary.totalConsumedFromGrid.toFixed(1);
      }
    },
    totalGeneration() {
      if (this.billingSummary?.totalGrossGenerated) {
        return (this.billingSummary.totalGrossGenerated).toFixed(1);
      }
    },
    generationToHome() {
      if (this.billingSummary?.totalGenerationUsedByHome) {
        return this.billingSummary.totalGenerationUsedByHome.toFixed(1);
      }
    },
    totalUsage() {
      if (this.billingSummary?.totalConsumption) {
        return this.billingSummary.totalConsumption.toFixed(1);
      }
    }
  }
};
