var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l",
      attrs: { id: "change-password" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
        },
        [
          _c(
            "h2",
            {
              ref: "scrolltarget",
              staticClass: "gds-display-1 gds-space-stack-ml"
            },
            [_vm._v("Password")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "edit-close-button-combo" }, [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.BeginEdit()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Edit")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && !_vm.pendingSave
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = false
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Close")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.editing
        ? _c("div", [
            _c("div", { staticClass: "gds-font-size-xl gds-space-stack-ml" }, [
              _vm._v("••••••••••")
            ]),
            _vm._v(" "),
            _vm.updated
              ? _c(
                  "div",
                  {
                    staticClass:
                      "form-message-box form-message-box--green gds-space-stack-l"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-flex-container gds-flex-container--left"
                      },
                      [
                        _c("svg", { staticClass: "gds-icon" }, [
                          _c("use", {
                            attrs: {
                              "xlink:href":
                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Your password was updated successfully!")
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Save()
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "profile__edit-password-row gds-flex-container gds-flex-container--left"
                },
                [
                  _c(
                    "fieldset",
                    {
                      staticClass:
                        "profile__edit-password---fieldset gds-fieldset"
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("New Password")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial.continues",
                                value:
                                  "required|length:7,30|hasupperandlower|hasnumber",
                                expression:
                                  "'required|length:7,30|hasupperandlower|hasnumber'",
                                modifiers: { initial: true, continues: true }
                              }
                            ],
                            key: "password",
                            ref: "password",
                            attrs: {
                              "data-vv-validate-on": "input",
                              name: "password",
                              type: "password",
                              placeholder: "Enter new password..."
                            },
                            model: {
                              value: _vm.password1,
                              callback: function($$v) {
                                _vm.password1 = $$v
                              },
                              expression: "password1"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "create-password__requirements profile__edit-password--requirements"
                    },
                    [
                      !_vm.errors.firstByRule("password", "length")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "my-account__green-check-icon gds-icon"
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n          At least 7 characters\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.firstByRule("password", "length")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single gds-text-grey"
                            },
                            [
                              _c("span", { staticClass: "bullet-point" }, [
                                _vm._v("•")
                              ]),
                              _vm._v("At least 7 characters\n        ")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.errors.firstByRule("password", "hasupperandlower")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "my-account__green-check-icon gds-icon"
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n          One lowercase and one uppercase letter\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.firstByRule("password", "hasupperandlower")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single gds-text-grey"
                            },
                            [
                              _c("span", { staticClass: "bullet-point" }, [
                                _vm._v("•")
                              ]),
                              _vm._v(
                                "One lowercase and one uppercase letter\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.errors.firstByRule("password", "hasnumber")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "my-account__green-check-icon gds-icon"
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                    }
                                  })
                                ]
                              ),
                              _vm._v("\n          One number\n        ")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.firstByRule("password", "hasnumber")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "create-password__requirements-single gds-text-grey"
                            },
                            [
                              _c("span", { staticClass: "bullet-point" }, [
                                _vm._v("•")
                              ]),
                              _vm._v("One number\n        ")
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-m" },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Confirm New Password")
                      ]),
                      _vm._v(" "),
                      _c("widget-password-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|confirmed:password",
                            expression: "'required|confirmed:password'"
                          }
                        ],
                        key: "password confirmation",
                        attrs: {
                          "data-vv-validate-on": "input",
                          name: "password confirmation",
                          placeholder: "Re-enter new password..."
                        },
                        model: {
                          value: _vm.password2,
                          callback: function($$v) {
                            _vm.password2 = $$v
                          },
                          expression: "password2"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.errors.first("password confirmation")
                ? _c("div", { staticClass: "validation-error" }, [
                    _vm._v(_vm._s(_vm.errors.first("password confirmation")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-round gds-space-stack-m",
                  class: { "gds-loading": _vm.pendingSave },
                  attrs: {
                    type: "submit",
                    disabled: _vm.pendingSave || _vm.errors.any()
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Save")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorSave
                ? _c(
                    "div",
                    { staticClass: "form-message-box gds-space-stack-m" },
                    [_vm._v("There was an error updating your password.")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }