var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.budgetStatus &&
    (_vm.budgetStatus.isEnrolled || _vm.budgetStatus.isEligible)
    ? _c(
        "section",
        {
          staticClass:
            "my-account__full-width-section my-account__section-with-bottom-border"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                },
                [
                  _c(
                    "h2",
                    { staticClass: "gds-display-1 gds-space-inline-m" },
                    [_vm._v("Budget Billing")]
                  ),
                  _vm._v(" "),
                  !_vm.editing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "gds-flex-container gds-flex-container--left"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "my-account__on-off-text gds-flex-container gds-flex-container--left gds-flex-container--top"
                            },
                            [
                              _c("IndicatorOnOff", {
                                attrs: { value: _vm.budgetStatus.isEnrolled }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "edit-close-button-combo" }, [
                !_vm.editing &&
                _vm.accountStatus &&
                  !(_vm.accountStatus.pastDue30 || _vm.accountStatus.pastDue60)
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "gds-button gds-text-button gds-space-inline-l",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.editing = true
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "my-account__notifications--text-button gds-button__text"
                          },
                          [_vm._v("Edit")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.editing && !_vm.pendingSave
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "gds-button gds-text-button gds-space-inline-l",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.editing = false
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "my-account__notifications--text-button gds-button__text"
                          },
                          [_vm._v("Close")]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _vm.showWarning
            ? _c(
                "div",
                {
                  staticClass:
                    "budget-billing-payarrange-warning-message gds-space-stack-l"
                },
                [_vm._v(_vm._s(_vm.warningMessage))]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.editing
            ? _c("div", [
                _vm.budgetStatus.isEnrolled
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "my-account__sub-section-bottom my-account__budget-billing-text"
                      },
                      [
                        _vm._v(
                          "\n      You are enrolled in Budget Billing.\n      "
                        ),
                        _vm.budgetStatus.totalBudgetAmount
                          ? _c("span", [
                              _vm._v(
                                "Your monthly bill is " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.budgetStatus.totalBudgetAmount
                                    )
                                  ) +
                                  "."
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.newestBudgetLetter
                          ? _c("div", { staticClass: "gds-space-top-m" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "gds-link gds-link--bold cursorPointer",
                                  attrs: {
                                    href: _vm.newestBudgetLetter.url,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("View your most recent budget letter")]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm.accountStatus &&
                    (_vm.accountStatus.pastDue30 || _vm.accountStatus.pastDue60)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "my-account__budget-billing-text  my-account__sub-section-bottom gds-text-grey"
                      },
                      [
                        _vm._v(
                          "\n      Budget Billing enrollment is complicated for accounts with a past due balance. To understand your options and monthly payments,\n      "
                        ),
                        _vm.accountStatus && _vm.accountStatus.pastDue60
                          ? _c("span", [
                              _vm._v("visit the "),
                              _c(
                                "a",
                                {
                                  staticClass: "gds-link gds-link--bold",
                                  attrs: { href: "/past-due-wizard" }
                                },
                                [_vm._v("Past Due Quick Setup")]
                              ),
                              _vm._v(",")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n      call us at " +
                            _vm._s(_vm.GMPPhoneNumber) +
                            ", or email us at "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "gds-link gds-link--bold",
                            attrs: { href: "mailto:" + _vm.GMPEmail }
                          },
                          [_vm._v(_vm._s(_vm.GMPEmail))]
                        ),
                        _vm._v(".\n    ")
                      ]
                    )
                  : _vm.budgetStatus.isEligible
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "my-account__budget-billing-text my-account__sub-section-bottom gds-text-grey"
                      },
                      [
                        _vm._v(
                          "Enroll in budget billing and get consistent monthly bills of " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.budgetStatus.totalBudgetAmount
                              )
                            )
                        )
                      ]
                    )
                  : _vm.budgetStatus.ineligibleReason
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "my-account__budget-billing-text  gds-space-stack-m gds-text-grey"
                      },
                      [_vm._v(_vm._s(_vm.budgetStatus.ineligibleReason))]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "my-account__budget-billing-text  gds-space-stack-m gds-text-grey"
                      },
                      [_vm._v("You are ineligible for Budget Billing")]
                    )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.editing
            ? _c("div", [
                _vm.budgetStatus.isEnrolled
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "my-account__sub-section-bottom" },
                        [
                          _vm._v(
                            "\n        You are enrolled in Budget Billing. To turn off Budget Billing please contact customer service at\n        "
                          ),
                          _c("span", { staticClass: "gds-nobreak" }, [
                            _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.budgetStatus.isEnrolled
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.TurnOnBudget()
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "my-account__header-button-pairing gds-flex-container gds-flex-container--left"
                          },
                          [
                            _c(
                              "fieldset",
                              {
                                staticClass: "gds-fieldset gds-space-inline-m"
                              },
                              [
                                _c("label", { staticClass: "gds-switch" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.budgetEnrolled,
                                        expression: "budgetEnrolled"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.budgetEnrolled)
                                        ? _vm._i(_vm.budgetEnrolled, null) > -1
                                        : _vm.budgetEnrolled
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.budgetEnrolled,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.budgetEnrolled = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.budgetEnrolled = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.budgetEnrolled = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "gds-switch__faux"
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              !_vm.budgetEnrolled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-font-size-l gds-space-stack-m"
                                    },
                                    [
                                      _vm._v(
                                        "You are not enrolled in budget billing."
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.budgetEnrolled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-font-size-l gds-space-stack-m"
                                    },
                                    [
                                      _vm._v(
                                        "You will be enrolled in budget billing. Click save to confirm."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gds-font-size-l gds-font-demi gds-space-stack-m"
                          },
                          [
                            _vm._v(
                              "If you enroll in budget billing, your monthly bill will be " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.budgetStatus.totalBudgetAmount
                                  )
                                ) +
                                "."
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "my-account__description--limit-width gds-space-stack-m"
                          },
                          [
                            _vm._v(
                              "\n        Budget billing is not a discount. Your consistent monthly payment is based on your average usage and is subject to change.\n        If you are a new customer or have net metering, we do not recommend going on a budget. We track your actual balance and review your account on a regular basis.\n        If you stop Budget Billing, your next bill will include an adjustment to true up your account with your actual balance.\n      "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "gds-button gds-round gds-space-stack-m",
                            class: { "gds-loading": _vm.pendingSave },
                            attrs: {
                              type: "submit",
                              disabled: _vm.pendingSave || !_vm.budgetEnrolled
                            }
                          },
                          [
                            _c("span", { staticClass: "gds-button__text" }, [
                              _vm._v("Save")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errorSave
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "form-message-box gds-space-stack-m"
                              },
                              [
                                _vm._v(
                                  "There was an error enabling Budget Billing."
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }