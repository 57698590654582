<template>
  <div>
    <div v-if="isDesktopView">
      <div v-bind:class="expandedRow != -1 ? 'usage-detail__table-row-expanded' : 'usage-detail__table-row'">
        <div @click="toggleHourlyUsage(usage.date)" :class=usageTableCellClass>
          <div class="usage-detail__usage-date">{{ formatDate(usage.date, 'long') }}</div>
          <div v-if="expandedRow != -1 && hourlyUsageLoadState != 'loading'" class="usage-detail__hourly-collapse">
            Showing Hourly
            <span class="usage-detail__collapse-link">Collapse</span>
          </div>
        </div>
        <span :class="usageTableCellClass">{{ getDayOfWeek(usage.date, 'long') }}</span>
        <div v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage)"
          class="usage-detail__empty-net-generation-cell"></div>
        <div v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage)" :class=usageTableCellClass>
          <span :class="[returnedGenerationClass, 'usage-detail__returned-amount']"> {{ generationReturned(usage.returnedGeneration) }} </span>
        </div>
        <div v-if="hasTou(usage) || hasConsumption(usage)" :class=usageTableCellClass>
          <Usage-Bar 
            :barType="determineBarType(usage)" 
            :consumedAmount="usage.consumedTotal"
            :offPeakAmount="usage.offPeak" 
            :onPeakAmount="usage.onPeak"
            :showTouDetails="true"
            :maxUsageValue="maxUsageValue">
          </Usage-Bar>
        </div>
        <div v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage)"
          class="usage-detail__empty-generation-cell"></div>
        <div v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage)" :class="usageTableCellClass">
          <Usage-Bar 
            :barType="'generation'" 
            :generationAmount="usage.generation"
            :maxUsageValue="maxUsageValue">
          </Usage-Bar>
        </div>
        <div v-if="dailyUsagePeriodValues && dailyUsagePeriodValues.length > 0" :class="usageTableCellClass">
          <div v-if="usage.date && !isTodayOrYesterday(usage.date)" class="usage-detail__spark-chart-container">
            <span class="usage-detail__highest-usage-period">{{ usage.highestUsagePeriodOfDay }}</span>
            <highest-usage-micro-sparkbar :data="dailyUsagePeriodValues" class="usage-detail__spark-chart" />
          </div>
        </div>
        <span
          v-if="!hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class="usage-detail__flat-rate-cell"></span>
        <span
          v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class="usage-detail__empty-generation-tou-cell"></span>
        <span
          v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class="usage-detail__generation-cell"></span>
        <span :class="usageTableCellClass">{{ getTemperature(usage).low ? getTemperature(usage).low + '\u00B0F' : "" }}</span>
        <span :class="usageTableCellClass">{{ getTemperature(usage).high ? getTemperature(usage).high + '\u00B0F' : "" }}</span>
        <div :class="usageTableCellClass">
          <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="usage-detail__weather-image"
            :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
        </div>
      </div>
    </div>

    <div v-if="isMobileView || isTabletView">
      <div v-bind:class="expandedRow != -1 ? 'usage-detail__table-row-expanded' : 'usage-detail__table-row'">
        <div @click="toggleHourlyUsage(usage.date)" :class="usageTableCellClass">
          <div class="usage-detail__usage-date--mobile">
            <div>{{ formatDate(usage.date, 'short') }}</div>
            <div>{{ getDayOfWeek(usage.date, 'short') }}</div>
          </div>
          <div v-if="expandedRow != -1 && hourlyUsageLoadState != 'loading'" class="usage-detail__hourly-collapse">
            Showing Hourly
            <span class="usage-detail__collapse-link">Collapse</span>
          </div>
        </div>
        <div v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage)"
          class="usage-detail__empty-net-generation-cell"></div>
        <div v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage)" :class="usageTableCellClass">
          <span :class="[returnedGenerationClass, 'usage-detail__returned-amount']"> {{ generationReturned(usage.returnedGeneration) }} </span>
        </div>
        <div v-if="hasTou(usage) || hasConsumption(usage)" :class="usageTableCellClass">
          <usage-bar 
            :barType="determineBarType(usage)" 
            :consumedAmount="usage.consumedTotal"
            :offPeakAmount="usage.offPeak" 
            :onPeakAmount="usage.onPeak"
            :showTouDetails="true"
            :maxUsageValue="maxUsageValue">
          </usage-bar>
        </div>
        <div v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage)"
          class="usage-detail__empty-generation-cell"></div>
        <div v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage)" :class="usageTableCellClass">
          <usage-bar 
            :barType="'generation'" 
            :generationAmount="usage.generation"
            :maxUsageValue="maxUsageValue">
          </usage-bar>
        </div>
        <div v-if="dailyUsagePeriodValues && dailyUsagePeriodValues.length > 0" :class="usageTableCellClass">
          <div v-if="usage.date && !isTodayOrYesterday(usage.date)" class="usage-detail__spark-chart-container">
            <span class="usage-detail__highest-usage-period">{{ usage.highestUsagePeriodOfDay }}</span>
            <highest-usage-micro-sparkbar :data="dailyUsagePeriodValues" class="usage-detail__spark-chart" />
          </div>
        </div>
        <span
          v-if="!hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class=usage-detail__flat-rate-cell></span>
        <span
          v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class="usage-detail__empty-generation-tou-cell"></span>
        <span
          v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage) && (!dailyUsagePeriodValues || !dailyUsagePeriodValues.length)"
          class="usage-detail__generation-cell"></span>
        <span v-if="isDesktopView" :class="usageTableCellClass">{{ getTemperature(usage).low ? getTemperature(usage).low + '\u00B0F' : "" }}</span>
        <span v-if="isDesktopView" :class="usageTableCellClass">{{ getTemperature(usage).high ? getTemperature(usage).high + '\u00B0F' : "" }}</span>
        <div v-if="isDesktopView" :class="usageTableCellClass">
          <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="usage-detail__weather-image"
            :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
        </div>
        <div v-if="!isDesktopView" class="gds-flex-container">
          <span class="usage-detail__mobile-conditions-cell">{{ getTemperature(usage).low ? getTemperature(usage).low + '\u00B0F' : "" }}</span>
          <span class="usage-detail__mobile-conditions-cell">{{ getTemperature(usage).high ? getTemperature(usage).high  + '\u00B0F' : "" }}</span>
          <div>
            <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="usage-detail__weather-image"
              :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import HighestUsageMicroSparkbar from "../highestusagemicrosparkbar/HighestUsageMicroSparkbar";
import UsageBar from "../usageshared/UsageBar";
import usageTableUtil from "../../../mixins/UsageTableUtil";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";

export default {
  name: "UsageDetailTableRow",
  mixins: [
    usageTableUtil,
    MediaQueryMixin
  ],
  props: {
    expandedRow: Number,
    usage: Object,
    hasGenerationRecordsPresent: Boolean,
    maxUsageValue: Number,
    dailyUsagePeriodValues: Array
  },
  components: {
    HighestUsageMicroSparkbar,
    UsageBar
  },
  data() {
    return {
      hourlyUsageLoadState: undefined,
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined,
      returnedGenerationClass: undefined,
      monthSelected: undefined,
      timeOfDayMap: [
        { "hour0to3": "12am-4am" },
        { "hour4to7": "4am-8am" },
        { "hour8to11": "8am-12pm" },
        { "hour12to15": "12pm-4pm" },
        { "hour16to19": "4pm-8pm" },
        { "hour20to23": "8pm-12pm" }],
      CALENDAR_MONTH: 'calendar-month'
    }
  },
  watch: {
    currentAccount() {
      this.getDailyUsageData();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isMobileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    }
  },
  async mounted() {
    this.setStyles();
  },
  methods: {
    setStyles() {
      if (this.hasGenerationRecordsPresent) {
        this.usageTableCellClass = 'usage-detail__generation-cell';
      } else {
        this.usageTableCellClass = 'usage-detail__flat-rate-cell';
      }

      if (this.usage.returnedGeneration < 0) {
        this.returnedGenerationClass = 'usage-detail__negativeReturnedGenerationClass';
      } else {
        this.returnedGenerationClass = 'usage-detail__positiveReturnedGenerationClass';
      }
    },
    getDayOfWeek(date, length) {
      if (length === 'long') {
        return format(date, 'EEEE');
      }
      if (length === 'short') {
        return format(date, 'E');
      }

    },
    formatDate(date, length) {
      if (length === 'long') {
        return format(date, 'MMMM d, yyyy');
      }
      if (length === 'short') {
        return format(date, 'MMM d, yyyy');
      }
    },
    toggleHourlyUsage(usageDate) {
      this.$emit('toggle-hourly', usageDate);
    },
    isTodayOrYesterday(date) {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const usageDate = new Date(date);
      return usageDate.toDateString() === today.toDateString() || usageDate.toDateString() === yesterday.toDateString();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>