<template>
  <div>
    <h1 class="gds-display-1 gds-space-stack-m">Please provide documents for verification</h1>
    <div class="gds-body-small gds-space-stack-l">
      Please take photos or scans of the documents and upload by clicking on “Select File to Upload”.
      <div v-if="uploadDocumentList.length > 1">
        If some of your documents are combined in into a single file, you can
        <span>
          <label class="gds-file" tabindex="-1" ref="focuser4">
            <input
              ref="combinedFilePicker"
              @click.prevent="setSelectedDocumentType($event, -1, 'combined')"
              @change="filePicked($event)"
              type="file"
              accept="image/png, image/jpeg, application/pdf"
              tabindex="-1"/>
            <span class="greenBoldText">Upload a Combined File.</span>
          </label>
        </span> You’ll be prompted to note which document(s) you’re uploading.
      </div>
      <div class="accepted-file-types">Accepted file types: jpeg/jpg, pdf, png</div>
      <ModalEICProducts
        v-if="showW9InfoModal"
        modalName="w9Info"
        :close="closeModal"
        modalMessage="Please make sure you selected a box in item 3 and that you've signed your W9."/>
      <div v-if="showDocumentChecklist">
        <modal-document-checklist
          v-if="selectedFile"
          :visible="showDocumentChecklist"
          :uploadDocumentList="uploadDocumentList"
          :selectedFile="selectedFile" @close-modal="closeModal"
          @document-types-changed="documentTypeChanged"
          :documentTypes="getDocumentTypes()"/>
      </div>
    </div>
    <div
      v-for="(uploadDocument, i) in documentList"
      :key="uploadDocument.documentName + i">
      <div class="file-uploader">
        <div class="fileupload__number-with-circle">
          <span>{{ i + 1 }}</span>
        </div>
        <div class="fileupload-title">{{ uploadDocument.displayName }}</div>
        <label
          @keyup.enter="$refs.filePicker.click()"
          class="gds-file gds-flex-container gds-flex-container--left item"
          tabindex="-1">
          <div>
            <input
              ref="filePicker"
              tabindex="-1"
              @change="filePicked($event)"
              type="file"
              accept="image/png, image/jpeg, application/pdf"/>
          </div>
          <button
            @click.prevent="setSelectedDocumentType($event, i, uploadDocument.documentName)"
            to="/settings"
            :disabled="uploadDocument.file === null ? false : true"
            class="gds-card__button gds-button gds-round gds-secondary card__account-profile--settings">
              Select File to Upload
          </button>
        </label>
      </div>

      <div class="uploaded-files-wrap">
        <div v-if="uploadDocument.error === 'docTypeNotSupported'" class="validation-error"> .doc or .docx filetypes are
          not supported. Please upload a different file.</div>
        <div v-if="uploadDocument.error === 'zeroFileSize'" class="validation-error">The file size is zero. Please upload
          a different file.</div>
        <div v-if="!uploadDocument.error && uploadDocument.fileName" class="uploaded-files-card">
          <div class="uploaded-files">{{ uploadDocument.fileName }}</div>
          <div class="fileSize">
            {{ uploadDocument.fileSize | fileSizeFormat() }}
          </div>
          <div id="card-controls">
            <div @click="removeFile(i)">
              <svg class="gds-icon close-icon">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <hr style="color: #d8d8d8;" />
    </div>
  </div>
</template>

<script>
import ModalDocumentChecklist from "./evcharger/ModalDocumentChecklist.vue"
import ModalEICProducts from "./ModalEICProducts.vue";

export default {
  name: "FileUploader",
  components: {
    ModalDocumentChecklist,
    ModalEICProducts,
  },
  props: {
    uploadDocumentList: [],
    docTypes: [],
  },
  data() {
    return {
      selectedFile: undefined,
      documentTypes: this.docTypes,
      file: undefined,
      selectedDocumentTypes: [],
      showDocumentChecklist: false,
      documentList: this.uploadDocumentList,
      selectedDocIndex: undefined,
      showW9InfoModal: undefined,
    };
  },
  computed: {

  },
  methods: {
    setSelectedDocumentType(event, index, docName) {
      if (index !== -1) { this.documentList[index].error = undefined; }
      if (docName === 'w9') {
        event.preventDefault();
        this.showW9InfoModal = true;
        this.selectedDocumentType = docName;
        this.selectedDocumentTypes.push(docName);
        this.selectedDocIndex = index;
        return
      }
      this.selectedDocumentType = docName;
      if (docName != 'combined') {
        this.selectedDocumentTypes.push(docName);
      }
      if (index >= 0) {
        this.$refs.filePicker[index].click();
        this.selectedDocIndex = index;
      } else {
        this.$refs.filePicker[0].click();
      }
      return;
    },
    getDocumentTypes() {
      let docTypes = [];
      this.documentList.forEach(doc => {
        if (doc.file != null) {
          docTypes.push(doc);
        }
      });
      return docTypes;
    },

    checkFileError(selectedFile, documentListIndexes) {
      if (!selectedFile) {
        return;
      }

      const extension = this.checkFileExtension(selectedFile);
      const size = this.checkFileSize(selectedFile);

      if (this.isUnsupportedFileType(extension)) {
        this.setErrorForIndexes(documentListIndexes, "docTypeNotSupported");
      }

      if (size === 0) {
        this.setErrorForIndexes(documentListIndexes, "zeroFileSize");
      }
    },

    isUnsupportedFileType(extension) {
      const supportedExtensions = ['png', 'jpeg', 'jpg', 'pdf'];
      return !supportedExtensions.includes(extension.toLowerCase());
    },

    setErrorForIndexes(indexes, errorType) {
      indexes.forEach(index => {
        const document = this.documentList[index];
        if (!document.file && !document.fileName && !document.fileSize && document.error !== errorType) {
          document.error = errorType;
        }
      });
    },
    checkFileExtension(selectedFile) {
      const fileName = selectedFile.name;
      const extension = fileName.split('.').pop();
      return extension;
    },
    checkFileSize(selectedFile) {
      const fileSize = selectedFile?.size;
      return fileSize;
    },
    async filePicked(event) {
      this.selectedFile = event.target.files[0];
      if (!this.isCombinedFile()) {
        this.save(event, this.selectedDocIndex);
      } else {
        this.showDocumentChecklist = true;
      }
    },
    isCombinedFile() {
      return this.selectedDocumentType === 'combined' ? true : false;
    },
    documentTypeChanged(event) {
      event.forEach(documentType => {
        const index = this.documentList.findIndex(doc => {
          return doc.documentName === documentType.documentName;
        });
        if (this.documentList[index].error === 'zeroFileSize' || this.documentList[index].error === 'docTypeNotSupported') {
          this.documentList[index].error = undefined;
        }
      });
      this.showDocumentChecklist = false;
      this.selectedDocumentTypes = event;
      this.save(event);
    },
    async save(event, index) {
      if (!this.isCombinedFile()) {
        this.checkFileError(this.selectedFile, [this.selectedDocIndex]);
        if (this.documentList[this.selectedDocIndex].error === "zeroFileSize" || this.documentList[this.selectedDocIndex].error === "docTypeNotSupported") {
          return;
        }
        if (index >= 0) {
          this.documentList[index].file = this.selectedFile;
          this.documentList[index].fileName = this.selectedFile.name;
          this.documentList[index].fileSize = this.selectedFile.size;
        }
      } else {
        this.selectedDocumentTypes.forEach(documentType => {
          const index = this.documentList.findIndex(doc => {
            return doc.documentName === documentType.documentName;
          });
          this.checkFileError(this.selectedFile, [index]);
          if (this.documentList[index].error === "zeroFileSize" || this.documentList[index].error === "docTypeNotSupported") {
            return;
          }
          for (let i = 0; i < this.documentList.length; i++) {
            for (let j = 0; j < event.length; j++) {
              if (this.documentList[i].documentName === documentType.documentName && this.documentList[i].displayName === event[j].id) {
                this.documentList[i].error = undefined;
                this.documentList[i].file = this.selectedFile;
                this.documentList[i].fileName = this.selectedFile.name;
                this.documentList[i].fileSize = this.selectedFile.size;
              }
            }
          }
        });
      }
      this.$emit('file-list-changed', this.documentList);
    },
    removeFile(index) {
      if (index >= 0) {
        this.documentList[index].file = null;
        this.documentList[index].fileName = null;
        this.documentList[index].fileSize = null;
        this.$emit('file-list-changed', this.documentList);
      }
    },

    closeModal(modalName) {
      if (modalName === 'w9Info') {
        this.showW9InfoModal = false;
        this.$refs.filePicker[0].click();
      }
      if (this.selectedDocumentTypes) {
        const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
        this.documentTypes = array;
      }
      this.selectedFile = undefined;
      this.showDocumentChecklist = false;
    },
  },
};
</script>