var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "my-account__container gds-flex-container gds-flex-container--left"
    },
    [
      _c("div", { staticClass: "my-account__sidebar-plus-account-panel" }, [
        _c(
          "div",
          { staticClass: "my-account__sidebar" },
          [_c("widget-user-profile-nav")],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "my-account__dashboard user-profile__dashboard",
          attrs: { id: "my-account__dashboard" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "my-account__sidebar__nav--mobile gds-flex-container gds-flex-container--space-between"
            },
            [
              _c("div", { staticClass: "my-account__mobile-floating-title" }, [
                _vm._v("User Profile")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "my-account__sidebar__trigger--mobile caret caret-down",
                  on: { click: _vm.ToggleAccountMenu }
                },
                [
                  _vm.subPage === ""
                    ? _c("span", [_vm._v("User Settings")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subPage === "/accounts"
                    ? _c("span", [_vm._v("Manage Accounts")])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.showMenu
                ? _c(
                    "div",
                    {
                      staticClass: "my-account__sidebar__nav--mobile-inner-wrap"
                    },
                    [_c("widget-user-profile-nav")],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("router-view")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }