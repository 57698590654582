var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.myCard && !_vm.currentAccount
    ? _c(
        "div",
        {
          staticClass: "top-card-display__single-card homepage__marketing-card"
        },
        [
          _c(
            "a",
            {
              staticClass:
                "top-banner-card gds-card gds-background-green gds-invert",
              attrs: {
                href: _vm.myCard.link && _vm.myCard.link.url,
                target: _vm.myCard.link && _vm.myCard.link.target,
                title: _vm.myCard.link && _vm.myCard.link.title
              }
            },
            [
              _vm.myCard.image
                ? _c("figure", { staticClass: "gds-card__image-container" }, [
                    _c("div", { staticClass: "gds-card__image" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.myCard.image &&
                            _vm.myCard.image.sizes &&
                            _vm.myCard.image.sizes.medium,
                          alt: _vm.myCard.image && _vm.myCard.image.alt
                        }
                      })
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "gds-card__contents" }, [
                _c(
                  "h3",
                  {
                    staticClass:
                      "gds-card__title home-card__title--small-margin"
                  },
                  [_vm._v(_vm._s(_vm.myCard.title))]
                ),
                _vm._v(" "),
                !_vm.myCard.image
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "gds-card__body home-card__body--small-margin"
                      },
                      [_vm._v(_vm._s(_vm.myCard.body))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"
                })
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }