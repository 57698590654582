import { format, parse, isSameDay, parseISO  } from "date-fns";
import { da } from "date-fns/locale";

// // GMP API wants ISO dates but without ms
// export function ToServerDateTime(date) {
//   let parsed = date;
//   if (!(parsed instanceof Date)) {
//     parsed = new Date(date);
//   }
//   return format(parsed, "yyyy-MM-dd'T'HH:mm:ssXXX");
// }

  /** Download the given data as a file */
export function DownloadFile(filename, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent('click'));
}

export function ParseDateOnly(date) {
  return parse(date, "yyyy-MM-dd", new Date());
}
export function ParseDisconnectDate(date) {
  return parse(date, "MM/dd/yyyy", new Date());
}
// GMP API wants ISO dates but without ms
export function ToServerDateTruncate(date) {
  let parsed = date;
  if (!(parsed instanceof Date)) {
    parsed = ParseDateOnly(parsed);
  }
  return format(parsed, "yyyy-MM-dd'T00:00:00'XXX");
}

export function ToServerDateTruncateHours(date) {
  let parsed = date;
  if (!(parsed instanceof Date)) {
    parsed = ParseDateOnly(parsed);
  }
  return format(parsed, "yyyy-MM-dd");
}

export const ToFullExpirationDate = monthYearDate => {
  if (!monthYearDate) return undefined;
  const expirationDateRegex = /([0-9]{1,2})/g;
  const [month, year] = monthYearDate.match(expirationDateRegex) || [];

  return month && year ? `20${year}-${month}-01T00:00:00` : "";
};

export function InitialCaps(str) {
  if (!str) return "";
  const words = str.toLowerCase().split(" ");
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

export function GetCardType(number) {
  if (number.match(/^4[0-9]/)) {
    return "Visa";
  }
  if (number.match(/^5[1-5][0-9]/)) {
    return "MasterCard";
  }
  if (number.match(/^6(?:011|5[0-9]{2})/)) {
    return "Discover";
  }
  if (number.match(/^(34|37)/)) {
    return "AmericanExpress";
  }
  return "";
};

export function DumpError(title, err) {
  console.error(title);
  if (err && err.response) {
    if (err.response.config) {
      const config = err.response.config;
      // Hide the auth token
      if (config.headers && config.headers.Authorization) {
        config.headers.Authorization = "Removed for security";
      }
      // Remove password if present
      if (config.data && config.data.toLowerCase && config.data.toLowerCase().includes("password")) {
        config.data = "Removed for security";
      }
    }
    console.error(JSON.stringify(err.response, null, 2));
  } else {
    console.error(err);
  }
}

export function GetMailingAddressFromVerified(fields) {
  // Note - experian only works for USA so it is hardcoded
  return {
    addressLine1: fields[0].content,
    addressLine2: fields[1].content,
    addressLine3: fields[2].content,
    city: fields[3].content,
    state: fields[4].content,
    zip: fields[5].content,
    country: "USA",
  }
}

export function PrintableTransType(transType) {
  switch (transType) {
    case "SAVINGS":
      return "Savings";
    case "CHECKING":
      return "Checking";
    case "MONEY_MARKET":
      return "Money Market";
    default:
      return transType;
  }
}

/** Get an account photo url with a resize modifier */
export function GetResizedAccountPhoto(photoUrl) {
  // First rewrite to the secure cloudfront
  const secure = photoUrl.replace("http://account-photos.greenmountainpower.com.s3-website-us-east-1.amazonaws.com", "https://accountphotos.greenmountainpower.com");
  // Then add the resizer
  return secure.replace("https://accountphotos.greenmountainpower.com", "https://accountphotos.greenmountainpower.com/204x204");
}

/** Return a subset of a list of incidents that is affecting a particular town */
export function GetTownIncidents(town, incidents) {
  const townName = town.toLowerCase();
  const matchedIncidents = [];
  for (const incident of incidents) {
    const submatch = false;
    if (incident.customersByTown) {
      submatch = Object.keys(incident.customersByTown).find(item => item.toLowerCase() === townName);
    }
    if (submatch || (incident.town && townName === incident.town.toLowerCase())) {
      matchedIncidents.push(incident);
    }
  }
  return matchedIncidents;
}

export function FormatEstimatedRestoreTime(etr, etrEarly, etrLate) {
  if (etrEarly && etrLate) {
    const earlyDate = parseISO(etrEarly);
    const lateDate = parseISO(etrLate);

    if (isSameDay(earlyDate, lateDate)) {
      return `Est. Restoration expected ${getEtrDate(etrEarly)} between ${getEtrTime(etrEarly)} and ${getEtrTime(etrLate)}`;
    } else {
      return `Est. Restoration expected between ${getEtrDate(etrEarly)} ${getEtrTime(etrEarly)} and ${getEtrDate(etrLate)} ${getEtrTime(etrLate)}`;
    }
  } else if (etrLate && !etrEarly) {
    return `Est. Restoration expected by ${getEtrDate(etrLate)} ${getEtrTime(etrLate)}`;
  } else if (etr) {
    return `Est. Restoration: ${getEtrDate(etr)} ${getEtrTime(etr)}`;
  } else {
    return null;
  }
}

export function FormatCustomerEtr(etr, etrEarly, etrLate, etrText) {
  if (etrEarly && etrLate) {
    const earlyDate = parseISO(etrEarly);
    const lateDate = parseISO(etrLate);

    if (isSameDay(earlyDate, lateDate)) {
      return `We estimate that power will be restored on ${getEtrDate(etrEarly)} between ${getEtrTime(etrEarly)} and ${getEtrTime(etrLate)}`;
    } else {
      return `We estimate that power will be restored between ${getEtrDate(etrEarly)} ${getEtrTime(etrEarly)} and ${getEtrDate(etrLate)} ${getEtrTime(etrLate)}`;
    }
  } else if (etrLate && !etrEarly) {
    return `We estimate that power will be restored by ${getEtrDate(etrLate)} ${getEtrTime(etrLate)}`;
  } else if (etr) {
    return `We estimate that power will be restored by ${getEtrDate(etr)} ${getEtrTime(etr)}`;
  } else {
    return null;
  }
}

function getEtrDate(value) {
  if (!value) return "";
  let parsed = value;
  if (!(parsed instanceof Date)) {
    parsed = parseISO(parsed);
  }
  return format(parsed, "EEEE, MMM d");
}

function getEtrTime(value) {
  if (!value) return "";
  let parsed = value;
  if (!(parsed instanceof Date)) {
    parsed = parseISO(parsed);
  }
  return format(parsed, "h:mm a");
}

/** Scroll the page, turning smooth scroll temporarily off if needed */
export async function HardScroll(top, left, tick) {
  const rootstyles = getComputedStyle(document.documentElement);
  const scrollbehavior = rootstyles["scroll-behavior"];
  if (scrollbehavior === "smooth") {
    document.documentElement.style["scroll-behavior"] = "auto";
  }
  if (tick) await tick;
  window.scrollTo({ top, left, behavior: "auto" });
  if (scrollbehavior === "smooth") {
    document.documentElement.style["scroll-behavior"] = null;
  }
}

/** Exit a flow, returning to the referring page if available */
export function ReturnToSender(exclude, fallback = "/") {
  if (document.referrer) {
    const url = new URL(document.referrer);
    // If referrer is in the same flow, we don't want to return there, go to homepage
    if (url.pathname.startsWith(exclude)) {
      window.location = fallback;
    } else {
      window.location = url.pathname;
    }
  } else {
    window.location = fallback;
  }
}

export function HyphenateTown(townName) {
  if (!townName) return "";
  return townName.replace(/\s+/g, "-").replace(/\./g, "").toLowerCase();
}
export function DehyphenateTown(townSlug) {
  if (!townSlug) return "";
  return townSlug.replace(/-/g, " ");
}

export function GetOutageStatusLabel(statusCode) {
  switch (statusCode) {
    case 0:
    case 1:
    case 4:
      return "Reported";
    case 2:
    case 3:
    case 5:
    case 6:
      return "Resolved";
    default:
      return "Unknown";
  }
}

export function PrettyAccountType(account) {
  if (!account) return undefined;
  if (account.customerClass === "R") return "Residential";
  if (account.customerClass === "C") return "Commercial";
  return "Unknown";
}

export function ReplaceHTML(text) {
  text = text.replace(/(<([^>]+)>)/gi, " ");
  text = text.replace(/&#(\d+);/g, (match, code) => String.fromCharCode(code));

  return text;
}