<template>
  <div>
    <button @click="handleOptionsChange()" :class="isMobileView || isTabletView ? 'usage-dashboard__widget-net-metering--mobile-dropdown' : 'usage-dashboard__widget-net-metering--dropdown'">
      <span class="usage-dashboard__widget-net-metering--dropdown-label">{{ currentWidget }}</span>
      <i class="usage-dashboard__widget-net-metering--dropdown-down-caret"></i>
    </button>
    <div
      v-if="showOptionsMenu"
      v-click-outside="closeShowOptionsMenu"
      :class="isMobileView || isTabletView ? 'usage-dashboard__widget-net-metering__nav--mobile-inner-wrap' : 'usage-dashboard__widget-net-metering__nav--desktop-inner-wrap'">
      <nav class="usage-dashboard__widget-net-metering__nav">
        <a v-for="option in options" @click="handleOptionClick(option.value)"
          class="usage-dashboard__widget-net-metering__nav-item">{{ option.name }}</a>
      </nav>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";

export default {
  name: "WidgetSwitcher",
  mixins: [
    MediaQueryMixin,
  ],
  props: {
    currentWidget: String,
    options: {
      type: Array,
      validator: (prop) => prop.every(e => typeof e === 'object'),
    }
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge
        || this.deviceIsLarge;
    },
    isTabletView() {
      return this.deviceIsMedium;
    },
    isMobileView() {
      return this.deviceIsSmall;
    }
  },
  data() {
    return {
      showOptionsMenu: false,
    }
  },
  methods: {
    handleOptionsChange() {
      this.showOptionsMenu = true;
    },
    handleOptionClick(optionValue) {
      this.showOptionsMenu = false;
      this.$emit('handle-option-click', optionValue);
    },
    closeShowOptionsMenu() {
      this.showOptionsMenu = false;
    }
  }
};
</script>
