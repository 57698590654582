import { forEach } from '../functions';

var filter = 'residential';
var query  = '';

export default function rates() {
  var toggles = document.querySelectorAll('[data-rate-category-toggle]');
  if(toggles.length > 0) filterRates(filter);
  forEach(toggles, function(i, toggle) {
    toggle.addEventListener('click', function(event) {
      setFilter(toggle.attributes['data-rate-category-toggle'].value);
    })
  });
  var rateSearchInput = document.querySelector('.rates-subheader__search');
  if(rateSearchInput) {
    rateSearchInput.addEventListener('keyup', function(event) {
      setQuery(rateSearchInput.value);
    })
  }

  var rateDetails = document.querySelectorAll('.rate-detail');
  forEach(rateDetails, function(i, rateGroup) {
    var groupSelect =
      rateGroup.querySelector('.price-options__form-group--effective-dates select');

    function filterLegacyRateGroup() {
      var currentGroup = groupSelect.value;
      var currentType = rateGroup.querySelector(`[data-group-index="${currentGroup}"] select`).value;
      var priceTables = rateGroup.querySelectorAll('.legacy-rates');
      var typeSelects = rateGroup.querySelectorAll('[data-group-index]');
      forEach(typeSelects, function(i, typeSelect) {
        if(typeSelect.attributes['data-group-index'].value != currentGroup) {
          typeSelect.style['display'] = 'none';
        } else {
          typeSelect.style['display'] = 'flex';
          currentType = typeSelect.querySelector('select').value;
        }
      });
      forEach(priceTables, function(i, priceTable) {
        if(priceTable.attributes['data-group'].value != currentGroup || priceTable.attributes['data-index'].value != currentType) {
          priceTable.style['display'] = 'none';
        } else {
          priceTable.style['display'] = 'block';
        }
      });
      window.dispatchEvent(new Event('resize')); //@TODO: make helper function that also works for IE
    }

    if(groupSelect) { //legacy rates
      filterLegacyRateGroup(rateGroup);
      groupSelect.addEventListener('change', function(event) {
        filterLegacyRateGroup();
      });
      var typeSelects = rateGroup.querySelectorAll('[data-group-index] select');
      forEach(typeSelects, (i, typeSelect) => {
        typeSelect.addEventListener('change', function(event) {
          filterLegacyRateGroup();
        });
      });
    }

    // Lighting Rates
    var lightingSelect = rateGroup.querySelector('.price-options__form-group--lighting select');

    function filterLightingRates() {
      var currentLightingRate = lightingSelect.value;
      var priceTables = rateGroup.querySelectorAll('.lighting-rates');

      forEach(priceTables, function(i, priceTable) {

        if(priceTable.attributes['data-index'].value != currentLightingRate ) {
          priceTable.style['display'] = 'none';
        } else {
          priceTable.style['display'] = 'block';
        }
      });

      window.dispatchEvent(new Event('resize'));
    }

    if(lightingSelect) {
      filterLightingRates();
      lightingSelect.addEventListener('change', function(event) {
        filterLightingRates();
      });
    }
  });
}

function setFilter(category) {
  filter = category;
  var toggles = document.querySelectorAll('[data-rate-category-toggle]');
  forEach(toggles, function(i, t) {
    if(t.attributes['data-rate-category-toggle'].value != filter) {
      t.classList.remove("active");
    } else {
      t.classList.add("active");
    }
  });
  filterRates();
}

function setQuery(search) {
  query = search;
  document.querySelector('.rates-no-results-found').style.display = "none";
  filterRates();
}

function filterRates() {
  var rates = document.querySelectorAll('.rates .slat');
  var count = 0;
  forEach(rates, function(i, rate) {
    var value = rate.attributes['data-categories'].value;
    var name  = rate.querySelector('.slat__text-container');
    if((value.includes(filter) || value.includes('all-rates')) && (query.length == 0 || name.innerHTML.toLowerCase().includes(query.toLowerCase()))) {
      rate.style.display = "block";
      count++;
    } else {
      rate.style.display = "none";
    }
  });
  if(0 == count && rates.length > 0) {
    document.querySelector('.rates-no-results-found').style.display = "block";
  }
}
