<template>
  <div class="my-account__container gds-flex-container gds-flex-container--left">
    <div class="my-account__sidebar-plus-account-panel">
      <div class="my-account__sidebar">
        <widget-user-profile-nav />
      </div>
    </div>

    <div id="my-account__dashboard" class="my-account__dashboard user-profile__dashboard">
      <div class="my-account__sidebar__nav--mobile gds-flex-container gds-flex-container--space-between">
        <div class="my-account__mobile-floating-title">User Profile</div>
        <button @click="ToggleAccountMenu" class="my-account__sidebar__trigger--mobile caret caret-down">
          <span v-if="subPage === ''">User Settings</span>
          <span v-if="subPage === '/accounts'">Manage Accounts</span>
        </button>
        <div v-if="showMenu" class="my-account__sidebar__nav--mobile-inner-wrap">
          <widget-user-profile-nav />
        </div>
      </div>
      <!-- The router-view is where the current subpage is displayed -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import WidgetUserProfileNav from "./WidgetUserProfileNav";

export default {
  name: "PageMyAccount",
  components: {
    WidgetUserProfileNav,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    $route(to, from) {
      this.showMenu = false;
    }
  },
  computed: {
    subPage() {
      if (this.$route.matched.length) {
        return this.$route.matched[0].path;
      }
      return "";
    },
  },
  async mounted() {
  },
  methods: {
    ToggleAccountMenu() {
      this.showMenu = !this.showMenu;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>