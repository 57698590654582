import VueRouter from 'vue-router';
import PageUsageDetail from "../usage/PageUsageDetail.vue";
import UsageDetailGraph from "../usage/usagedetailgraph/UsageDetailGraph.vue";
import UsageDetailTable from "../usage/usagedetailtable/UsageDetailTable.vue";
import UsageDetailTimeOfDay from "../usage/usagedetailtimeofday/UsageDetailTimeOfDay.vue";
import UsageDetailSeasonal from "../usage/usagedetailseasonal/UsageDetailSeasonal.vue";
//import UsageDetailHeatingCooling from "../usage/usagedetailheatingcooling/UsageDetailHeatingCooling.vue";
import UsageDetailPerformance from "../usage/usagedetailperformance/UsageDetailPerformance.vue";
import UsageDetailCompare from "../usage/usagedetailcompare/UsageDetailCompare.vue";

export const UsageDetailRouterOptions = {
  mode: 'history',
  base: '/account/usage-new/detail',
  routes: [
    {
      path: '/',
      component: PageUsageDetail,
      redirect: '/graph'
    },
    {
      path: '/graph',
      component: UsageDetailGraph,
    },
    {
      path: '/table',
      component: UsageDetailTable,
    },
    {
      path: '/time-of-day',
      component: UsageDetailTimeOfDay,
    },
    {
      path: '/seasonal',
      component: UsageDetailSeasonal,
    },
    // {
    //   path: '/heating-cooling',
    //   component: UsageDetailHeatingCooling,
    // },
    {
      path: '/performance',
      component: UsageDetailPerformance,
    },
    {
      path: '/compare',
      component: UsageDetailCompare,
    },
    { path: '*', redirect: '/' },
  ],
};

export function UsageDetailRouterInit() {
  const usageDetailRouter = new VueRouter({
    mode: UsageDetailRouterOptions.mode,
    base: UsageDetailRouterOptions.base,
    routes: UsageDetailRouterOptions.routes,
  });

  usageDetailRouter.addRoutes(usageDetailRouter.options.routes);
}
