<template>
  <div class="usage-detail__usage-bar-container">
    <div ref="usageBarContainer" class="usage-detail__usage-bar-details">
      <div class="usage-detail__usage-amount-container">
        <span class="usage-detail__usage-amount"> {{ usageDisplayAmount }}</span>&nbsp;
        <span class="usage-detail__kwh-label">kWh</span>
      </div>
      <!-- <div v-if="barType === TIME_OF_USE || barType === GENERATION_AND_TIME_OF_USE" class="time-of-use-amounts-container">
        <span v-if="showTouDetails" class="usage-detail__off-on-peak-amount">off peak: {{ roundAmountSingleDecimal(offPeakAmount) }}</span>
        <span v-if="showTouDetails" class="usage-detail__off-on-peak-amount">on peak: {{ roundAmountSingleDecimal(onPeakAmount) }}</span>
      </div> -->
    </div>
    <svg v-if="barType === GENERATION || barType === CONSUMPTION || barType === GENERATION_AND_CONSUMPTION" class="usage-detail__svg-container">
      <line :class="usageLineClass" :x1="X_LINE_POSITION" :y1="Y_LINE_POSITION" :x2="usageLineEnd" :y2="Y_LINE_POSITION" />
    </svg>
    <svg v-if="barType === TIME_OF_USE || barType === GENERATION_AND_TIME_OF_USE" class="usage-detail__svg-container">
      <line v-if="offPeakAmount != undefined" class="usage-detail__off-peak-line" :x1="offPeakLineStart" :y1="Y_LINE_POSITION" :x2="offPeakLineEnd" :y2="Y_LINE_POSITION" />
      <line v-if="onPeakAmount != undefined" class="usage-detail__on-peak-line" :x1="onPeakLineStart" :y1="Y_LINE_POSITION" :x2="onPeakLineEnd" :y2="Y_LINE_POSITION" />
    </svg>
  </div>
</template>

<script>

import usageTableUtil from "../../../mixins/UsageTableUtil";

export default {
  name: "UsageBar",
  mixins: [usageTableUtil],
    props: {
      barType: String,
      consumedAmount: Number,
      generationAmount: Number,
      showTouDetails: Boolean,
      offPeakAmount: Number,
      onPeakAmount: Number,
      maxUsageValue: Number,
      usageDate: Date
  },
  data() {
    return {
      barContainerSize: undefined,
      offPeakLineStart: undefined,
      offPeakLineEnd: undefined,
      onPeakLineStart: undefined,
      onPeakLineEnd: undefined,
      usageLineEnd: undefined,
      usageLineClass: undefined,
      X_LINE_POSITION: 5,
      Y_LINE_POSITION: 10,
      BAR_WHITESPACE_PERCENT: .8,
      BAR_STYLE_CONSUMPTION: 'usage-detail__flat-usage-line',
      BAR_STYLE_GENERATION: 'usage-detail__generation-usage-line',
      BAR_STYLE_TIME_OF_USE: 'usage-detail__tou-usage-line'
    }
  },
  mounted() {
    setTimeout(()=>{
      window.addEventListener('resize', this.throttle(this.onResize, 500));
      this.barContainerSize = this.getBarContainerSize();
      this.usageLineClass = this.setLineStyleClass(this.barType);
      this.createUsageLine(this.barType);
    }, 250);
  },
  computed: {
    usageDisplayAmount() {
      if (this.barType === this.TIME_OF_USE || this.barType === this.GENERATION_AND_TIME_OF_USE) {
        return this.roundAmountSingleDecimal((this.offPeakAmount || 0) + (this.onPeakAmount || 0));
      }
      if (this.barType === this.CONSUMPTION || this.barType === this.GENERATION_AND_CONSUMPTION) {
        return this.roundAmountSingleDecimal(this.consumedAmount);
      }
      return this.roundAmountSingleDecimal(this.generationAmount);
    }
  },
  methods: {
    onResize() {
      this.barContainerSize = this.getBarContainerSize();
      this.createUsageLine(this.barType);
    },
    getBarContainerSize() {
      if (this.$refs.usageBarContainer && this.$refs.usageBarContainer != undefined) {
        return this.$refs.usageBarContainer.clientWidth * this.BAR_WHITESPACE_PERCENT;
      }
    },
    setLineStyleClass(barType) {
      if (barType === this.CONSUMPTION || barType === this.GENERATION_AND_CONSUMPTION) {
        return this.BAR_STYLE_CONSUMPTION;
      }
      if (barType === this.GENERATION) { 
        return this.BAR_STYLE_GENERATION;
      }

      return this.BAR_STYLE_TIME_OF_USE;
    },
    createUsageLine(barType) {
      if (barType === this.CONSUMPTION || barType === this.GENERATION_AND_CONSUMPTION) {
          this.usageLineEnd = this.calcUsageLineEnd(this.consumedAmount);
      } else if (barType === this.GENERATION) {
        this.usageLineEnd = this.calcUsageLineEnd(this.generationAmount);
      } else {
        this.offPeakLineStart = this.setOffPeakLineStart();
        this.offPeakLineEnd = this.calcOffPeakLineEnd(this.offPeakAmount, this.onPeakAmount);
        this.onPeakLineStart = this.setOnPeakLineStart(this.onPeakAmount);
        this.onPeakLineEnd = this.calcOnPeakLineEnd(this.offPeakAmount, this.onPeakAmount);
      }
    },
    calcUsageLineEnd(usageValue) {
      const size = (usageValue / this.maxUsageValue) * this.barContainerSize;
      return size < this.barContainerSize ? size : this.barContainerSize;
    },
    setOffPeakLineStart() {
      return this.X_LINE_POSITION;

    },
    calcOffPeakLineEnd(offPeakAmount, onPeakAmount) {
      if (onPeakAmount === undefined || onPeakAmount === 0) {
       return this.calcUsageLineEnd(offPeakAmount);
      }

      const totalLineSize = ((offPeakAmount + onPeakAmount) / this.maxUsageValue) * this.barContainerSize;
      const offPeakSizePercent = (offPeakAmount / (offPeakAmount + onPeakAmount));
      const offPeakLineEnd = totalLineSize * offPeakSizePercent;
      return offPeakLineEnd < this.barContainerSize ? offPeakLineEnd : this.barContainerSize;
    },
    setOnPeakLineStart(onPeakAmount) {
      const onPeakLineStart = onPeakAmount === undefined ? this.X_LINE_POSITION : this.offPeakLineEnd; 
      return onPeakLineStart;
    },
    calcOnPeakLineEnd(offPeakAmount, onPeakAmount) {
      if (onPeakAmount === undefined || onPeakAmount === 0) {
        return this.X_LINE_POSITION;
      }

      if (offPeakAmount === undefined || offPeakAmount === 0) {
        return this.calcUsageLineEnd(onPeakAmount);
      }
      const totalLineSize = ((offPeakAmount + onPeakAmount) / this.maxUsageValue) * this.barContainerSize;
      const onPeakLineEnd = totalLineSize;
      return onPeakLineEnd < this.barContainerSize ? onPeakLineEnd : this.barContainerSize;
    },
     // function used to throttle onResize function above
     throttle(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>