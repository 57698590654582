import Highcharts from "highcharts";
import { addDays } from "date-fns";


export function DrawChart(renderingDiv, deviceIsSmall, chartDataPrevious, chartDataCurrent, currentBillingCycleStartDate) {
  const SMALL_CHART = '50%';
  const LARGE_CHART = '40%';
  const circleType = 'circle';
  const gmpWhite = '#fff';
  const gmpOrange = '#EF6723';

  const getChartSize = () => {
    return deviceIsSmall ? SMALL_CHART : LARGE_CHART;
  }

  const getDateFromKey = (startDate, key) => {
    return addDays(new Date(startDate), key).toLocaleDateString('en-EN', { month: 'long', day: 'numeric' });
  }

  const computeUsageDifferenceByDay = (billingCycleDay) => {
    const previousBillingCycleUsageByDate = chartDataPrevious[billingCycleDay];
    const currentBillingCycleUsageByDate = chartDataCurrent[billingCycleDay];

    if (currentBillingCycleUsageByDate > previousBillingCycleUsageByDate) {
      return {
        difference: (currentBillingCycleUsageByDate - previousBillingCycleUsageByDate).toFixed(1),
        language: 'more'
      }
    } else {
      return {
        difference: (previousBillingCycleUsageByDate - currentBillingCycleUsageByDate).toFixed(1),
        language: 'less'
      }
    }
  }

  Highcharts.chart(renderingDiv, {
    chart: {
      type: 'area',
      height: getChartSize(),
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      spacingLeft: 0,
      spacingRight: 0
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: undefined
    },
    xAxis: {
      allowDecimals: false,
      lineWidth: 0,
      tickWidth: 0,
      tickInterval: 1,
      labels: {
        step: 5,
        x: -3.5,
        formatter: function () {
          return this.value;
        }
      },
    },
    yAxis: {
      labels: {
        enabled: false
      }
    },
    tooltip: {
      shared: false,
      borderWidth: 0,
      backgroundColor: "transparent",
      borderRadius: 0,
      shadow: false,
      useHTML: true,
      formatter: function (tooltip) {
        if (this.series.name === "Current") {
          const message = `By ${getDateFromKey(currentBillingCycleStartDate, this.key)}, you used <span class='strong'>${computeUsageDifferenceByDay(this.key).difference} kWh <br />${computeUsageDifferenceByDay(this.key).language}</span> than last billing period.`;
          let html = "<div class='current-usage-chart__usage-tooltip'><div>" + message + "</div>";
          html += "</div>";
          return html;
        } else {
          return false;
        }
      },
      valueDecimals: 2,
      outside: true,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: true,
            halo: {
              size: 15,
              attributes: {
                opacity: 1,
                fill: gmpWhite,
                'stroke-width': 0
              }
            }
          }
        }
      },
      area: {
        marker: {
          enabled: false,
          symbol: circleType,
          radius: 1
        }
      }
    },
    series: [
      {
        name: 'Previous',
        data: chartDataPrevious,
        marker: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      {
        name: "Current",
        type: 'line',
        lineWidth: 3,
        lineColor: gmpOrange,
        data: chartDataCurrent.map((point, index) => {
          if (index === chartDataCurrent.length - 1) {
              return {
                y: point,
                marker: {
                    enabled: true,
                    symbol: circleType,
                    fillColor: gmpOrange,
                    radius: 5,
                    lineWidth: 1,
                    lineColor: gmpWhite
                }
            };
          }

          return {
            y: point,
            marker: {
              enabled: false 
            },
          };
        })
      }
  ],
    
    responsive: {
      rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: { 
            marginLeft: 3.5,
            marginRight: 3.5
          }
        }
      }]
    }
  });
}

