<template>
  <div class="subpage-dashboards subpage-netmetering--dashboard">
    <h1 class="my-account__title gds-space-stack-l">Net Metering</h1>
    <div v-if="loadState === undefined" class="table-loading" />
    <div class="usage-page--interior-status-container net-metering">
      <flow-error v-if="loadState === 'error'" name="Net Metering" state="error" />
      <flow-error
        v-if="loadState === 'unavailable'"
        name="Net Metering"
        state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/net-metering-not-supported.svg"
      />
    </div>
    <div v-if="loadState === 'complete'">
      <div v-if="meteringStatus">
        <div class="gds-space-stack-l">
          Net metering credits are applied towards charges on your bill each month. Vermont law limits which charges can be paid for by your net metering credits. We are prohibited from applying them to loan payments, equipment rentals, and some program fees.
          <a
            href="/help-category/net-metering/#credits"
          >Learn what net metering credits can cover</a>.
        </div>
        <div class="gds-display-00 gds-space-stack-s">Total Credits Available</div>
        <div class="gds-font-book gds-font-size-xl gds-text-green gds-space-stack-l">{{totalCredits | currency}}</div>
        <table v-if="this.meteringStatus.expiringCredits && this.meteringStatus.expiringCredits.length" class="net-meter-table">
          <thead class="net-meter-table__head">
            <tr>
              <th>Date Generated</th>
              <th>Credit Expires</th>
              <th class="gds-align--text-right">Credit Value</th>
            </tr>
          </thead>
          <tbody class="net-meter-table__body">
            <tr v-for="(credit, index) of this.meteringStatus.expiringCredits" :key="index">
              <td class="gds-flex-container gds-flex-container--left">
                <svg class="gds-icon gds-space-inline-s">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-net-meter" />
                </svg>
                {{credit.creditDate | dateFullUTC}}
              </td>
              <td>{{credit.expirationDate | dateFullUTC}}</td>
              <td class="gds-font-book gds-font-size-xl gds-text-green gds-align--text-right">{{-credit.amount | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from '../../../utilities';

export default {
  name: "SubpageNetMetering",
  data() {
    return {
      meteringStatus: undefined,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    totalCredits() {
      if (!this.meteringStatus || !this.meteringStatus.expiringCredits || !this.meteringStatus.expiringCredits.length) return 0;
      return -(this.meteringStatus.expiringCredits.reduce((acc, obj) => acc + obj.amount, 0));
    }
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.loadState = undefined;
      this.meteringStatus = undefined;
      // If account is not net metered, show message
      if (!this.currentAccount.solarNetMeter) {
        this.loadState = "unavailable";
      } else {
        try {
          const status = await GMPAPI.GetNetMetering(this.currentAccount.accountNumber);
          // Have to work around bogus 404 for "no credits"
          if (status && status.length === 0) {
            this.meteringStatus = { accountNumber: this.currentAccount.accountNumber, expiringCredits: [] };
          } else {
            this.meteringStatus = status;
          }
          this.loadState = "complete";
        } catch (err) {
          DumpError("Net metering refresh error", err);
          this.loadState = "error";
        }
      }
    },
  },
  watch: {
    // Refresh billing status whenever the account changes
    async currentAccount() {
      if (this.currentAccount.netMetered) {
        await this.RefreshStatus();
      } else {
        this.$router.push('/dashboard');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>