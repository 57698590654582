var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentAccount
    ? _c(
        "div",
        {
          staticClass:
            "top-card-display__single-card top-card-display__single-card--account-dashboard top-card-display__single-card--logged-in"
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass:
            "top-banner-card gds-card gds-background-forestgreen gds-invert",
          attrs: { href: "/account" }
        },
        [
          _c("div", { staticClass: "gds-card__contents" }, [
            _c(
              "h3",
              { staticClass: "home-card__title--small-margin gds-card__title" },
              [_vm._v("Account Dashboard")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "home-card__body--small-margin gds-card__body" },
              [_vm._v("View and manage your service account.")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }