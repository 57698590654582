<template>
  <div class="usage-dashboard__widget-net-metering">
    <div v-if="loadstate === 'complete'">
      <WidgetNetMeteringDesktop
        v-if="isDesktopView"
        :billingSummary="billingSummary"
        @change-options-event="handleOptionChangeEvent" />
      <WidgetNetMeteringMobile
        v-if="isMobileView || isTabletView"
        :billingSummary="billingSummary"
        @change-options-event="handleOptionChangeEvent" />
    </div>
    <div v-if="!loadstate" class="usage-dashboard__widget-current-usage my-account__usage-loading" />
    <div v-if="loadstate === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadstate === 'error'" class="error-message">An unexpected error occurred, please try again later.</div>
  </div>
</template>

<script>
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDashboardMixin from "../../../mixins/UsageDashboardMixin";
import WidgetNetMeteringDesktop from "./WidgetNetMeteringDesktop.vue";
import WidgetNetMeteringMobile from "./WidgetNetMeteringMobile.vue";

export default {
  name: "WidgetNetMetering",
  components: {
    WidgetNetMeteringDesktop,
    WidgetNetMeteringMobile,
  },
  mixins: [
    MediaQueryMixin,
    UsageDashboardMixin
  ],
  props: {
    billingSummary: Object,
    loadstate: String
  },
  data() {
    return {
      rateData: undefined,
    }
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge
        || this.deviceIsLarge;
    },
    isTabletView() {
      return this.deviceIsMedium;
    },
    isMobileView() {
      return this.deviceIsSmall;
    }
  },
  async mounted() {

  },
  methods: {
    handleOptionChangeEvent(option) {
      this.$emit('change-options-event', option);
    }
  },
};
</script>
