import DataStore from "../../store";
import GMPAPI from "../../services/gmpapi";

import PastDueWizardLogin from "./PastDueWizardLogin.vue";
import PastDueWizardAccount from "./PastDueWizardAccount.vue";
import PastDueWizardFlow from "./PastDueWizardFlow.vue";
import PastDueWizardComplete from "./PastDueWizardComplete.vue"
import PastDueWizardFatalError from "./PastDueWizardFatalError.vue"

export const PastDueWizardRouterOptions = {
  mode: "history",
  base: "/past-due-wizard",
  // When going to a page, scroll to the slat id
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          selector: to.params.page ? ("#slat-" + to.params.page) : "#top-target",
          offset: { x: 0, y: 12 },
        });
      }, 50);
    });
  },
  routes: [
    { path: "/", redirect: "/account" },
    { path: "/login", component: PastDueWizardLogin },
    {
      path: "/account",
      component: PastDueWizardAccount,
      beforeEnter: async (to, from, next) => {

        try {
          DataStore.commit("setRouteLoading", true);
          await GMPAPI.GetCurrentUser();
          // We have auth, but may not have user info
          if (!DataStore.state.user.userinfo) {
            try {
              await DataStore.dispatch("AttemptCookieLogin");
              next();
            } catch (err) {
              next("/login");
            }
          } else if (document.referrer.includes('make-payment')) {
            next("/wizard/1");
          } else {
            next();
          }
        } catch (err) {
          next("/login");
        } finally {
          DataStore.commit("setRouteLoading", false);
        }
      }
    },
    {
      path: "/wizard/:page",
      component: PastDueWizardFlow,
      beforeEnter: (to, from, next) => {
        if (!DataStore.state.user.currentAccount) {
          next("/account");
        } else {
          next();
        }
      },
    },
    {
      path: "/complete",
      name: 'complete',
      component: PastDueWizardComplete,
    },
    { path: "/error/:code?", component: PastDueWizardFatalError },
    { path: "*", redirect: "/" },
  ],
};

export function PastDueWizardRouterInit(router) {
  // No external init needed
}