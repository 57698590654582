<template>
  <span>
    <button v-if="!disabled" @click="jumpToDate()" title="Calendar"
    v-bind:style="{ width: buttonWidth || defaultButtonWidth }"
    class="date-navigation__button date-navigation__button__calendar date-navigation__button__calendar__text-added">
      <svg class="gds-icon calendar">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
      </svg>
      <span class="date-navigation__button__calendar__label">{{buttonLabel || defaultButtonLabel}}</span>
    </button>
    <button v-if="disabled" disabled title="Calendar"
    v-bind:style="{ width: buttonWidth || defaultButtonWidth }"
    class="date-navigation__button date-navigation__button__calendar date-navigation__button__calendar__text-added">
      <svg class="gds-icon calendar">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
      </svg>
      <span class="date-navigation__button__calendar__label">{{buttonLabel || defaultButtonLabel}}</span>
    </button>
  </span>
</template>

<script>

export default {
  name: "DateNavigationButtonJumpToDate",
  data() {
    return {
      defaultButtonWidth: '10rem',
      defaultButtonLabel: 'Jump to Date'
    };
  },
  props: {
    jumpToDate: Function,
    buttonLabel: String,
    buttonWidth: String,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>