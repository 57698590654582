var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l"
    },
    [
      _c("h2", { staticClass: "gds-display-1 gds-space-stack-ml" }, [
        _vm._v("Username")
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "gds-font-size-m gds-space-stack-ml gds-text-grey" },
          [_vm._v(_vm._s(_vm.userInfo.username))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }