<template>
  <div class="site-main">
    <div id="top-target" class="bill-pay-login__main gds-flex-container">
      <div class="bill-pay-login__login-type-container">
        <section class="bill-pay-login__login-section">
          <h2 class="bill-pay-login__main-headline gds-display-2">Log in to Stop Your Service</h2>
          <p class="gds-body-normal">
            Please log in to stop your service. You may also stop service by calling customer service at
            <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
          </p>
          <form class="global-panel__form" @submit.prevent="Login()">
            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Username or Email</span>
                <input
                  v-model="username"
                  v-validate="'required'"
                  name="user name"
                  key="user name"
                  class="gds-input-field__input"
                  type="text"
                  spellcheck="false"
                  autocapitalize="none"
                  autocomplete="username"
                />
                <div v-if="errors.first('user name')" class="validation-error">{{ errors.first("user name")}}</div>
              </label>
            </fieldset>

            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Password</span>
                <widget-password-input v-model="password" autocomplete="current-password" v-validate="'required'" name="password" key="password" />
                <div v-if="errors.first('password')" class="validation-error">{{ errors.first("password")}}</div>
              </label>
            </fieldset>

            <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <div class="global-login-panel__form-helper">
                <label class="gds-checkbox gds-checkbox-thin">
                  <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
                </label>
              </div>

              <div class="global-login-panel__form-helper">
                <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
              </div>
            </div>

            <div class="gds-space-stack-l">
              <div v-if="errorlogin" id="submission_error" class="gds-helper-text-error">{{errorlogin}}</div>
            </div>

            <div class="gds-space-stack-l">
              <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
                <span class="gds-button__text">Log In</span>
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import WidgetPasswordInput from "../WidgetPasswordInput";
import { DumpError } from "../../utilities";

export default {
  name: "StopServiceLogin",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      username: undefined,
      password: undefined,
      stayloggedin: true,

      errorlogin: undefined,
      pendingLogin: false,

      GMPPhoneNumber,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Login() {
      try {
        if (!await this.$validator.validateAll()) return;
        this.errorlogin = false;
        this.pendingLogin = true;
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });
        this.$router.push({ path: "/account" });
      } catch (err) {
        DumpError("Login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>