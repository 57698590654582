<template>
  <div>
    <div :class="usageTableCellClass">
      <div class="daily-usage-history__container--date">
        <span class="daily-usage-history__weekday">{{ getShortWeekdayFromDate(usage.date) }}</span>
        <span class="daily-usage-container__month-day"> {{ getMonthYearFromDate(usage.date) }}</span>
      </div>
    </div>
    <div v-if="hasTou(usage) || hasConsumption(usage)" :class="usageTableCellClass">
      <Usage-Bar :barType="determineBarType(usage)" :consumedAmount="usage.consumedTotal" :offPeakAmount="usage.offPeak"
        :onPeakAmount="usage.onPeak" :showTouDetails="false" :maxUsageValue="maxUsageValue">
      </Usage-Bar>
    </div>
    <div v-if="hasGenerationRecordsPresent && !hasGenerationRecordProperties(usage)"
      class="daily-usage-history__empty-generation-cell"></div>
    <div v-if="hasGenerationRecordsPresent && hasGenerationRecordProperties(usage)" :class="usageTableCellClass">
      <Usage-Bar :barType="'generation'" :generationAmount="usage.generation" :maxUsageValue="maxUsageValue">
      </Usage-Bar>
    </div>
    <div :class="usageTableCellClass">
      <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="daily-usage-history__weather-image"
        :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
    </div>
    <div :class="[usageTableCellClass, 'daily-usage-history__temperature']">
      <span>{{ getTemperature(usage).avg ? getTemperature(usage).avg + '\u00B0F' : "" }} </span>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import UsageBar from "../usageshared/UsageBar";
import usageTableUtil from "../../../mixins/UsageTableUtil";

export default {
  name: "WidgetDailyUsageHistoryTableRow",
  mixins: [usageTableUtil],
  props: {
    usage: Object,
    hasGenerationRecordsPresent: Boolean,
    maxUsageValue: Number
  },
  components: {
    UsageBar,
  },
  data() {
    return {
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined,
      monthSelected: undefined
    }
  },
  watch: {
    currentAccount() {
      this.getDailyUsageData();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    this.setStyles();
  },
  methods: {
    setStyles() {
      if (this.hasGenerationRecordsPresent) {
        this.usageHeadingCellClass = 'daily-usage-history__generation-heading-cell';
        this.usageTableCellClass = 'daily-usage-history__generation-cell';
        this.usageTableEmptyCellClass = 'daily-usage-history__empty-generation-cell'
      } else {
        this.usageTableCellClass = 'daily-usage-history__flat-rate-cell';
        this.usageTableEmptyCellClass = 'daily-usage-history__empty-flat-rate-cell'
      }
    },
    getDayOfWeek(date) {
      return format(date, 'EEEE');
    },
    formatDate(date) {
      return format(date, 'MMMM d, yyyy');
    },
    getShortWeekdayFromDate(date) {
      return date.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
    },
    getMonthYearFromDate(date) {
      const dateObj = new Date(date);
      return dateObj.getMonth() + 1 + "/" + dateObj.getDate();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>