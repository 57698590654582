<template>
  <transition name="global-login-panel__wrappers">
    <div v-if="isExpanded" v-click-outside="Close" class="global-panel global-login-panel__scroller">
      <FocusLock>
        <button @click="Close()" class="close gds-button-circle gds-icon-close-small gds-secondary">
          <span class="gds-visibility--hide-visually">Close</span>
        </button>
        <p class="gds-display-2 gds-space-stack-m">Log In</p>
        <div v-if="loadState === undefined" class="table-loading" />
        <flow-error v-if="loadState === 'error'" name="Logging in" state="error" phonetext="To report an outage or make a payment by phone call us at" />
        <flow-error v-if="loadState === 'maintenance'" name="Logging in" state="maintenance" phonetext="To report an outage or make a payment by phone call us at" />
        <div v-if="loadState === 'complete'">
          <p class="gds-body-normal gds-text--no-hyphens gds-space-stack-l">Sign in to view your Green Mountain Power account.</p>

          <form class="global-panel__form" @submit.prevent="Login()">
            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Username or Email</span>
                <input
                  v-model="username"
                  class="gds-input-field__input"
                  id="username"
                  type="text"
                  spellcheck="false"
                  autocapitalize="none"
                  autocomplete="username"
                  required
                  aria-required="true"
                />
              </label>
            </fieldset>

            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Password</span>
                <widget-password-input v-model="password" autocomplete="current-password" />
              </label>
            </fieldset>

            <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <div class="global-login-panel__form-helper">
                <label class="gds-checkbox gds-checkbox-thin">
                  <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-font-size-m gds-text-grey">Stay Logged In</span>
                </label>
              </div>

              <div class="global-login-panel__form-helper">
                <a href="/account/forgot" class="gds-font-size-m gds-link gds-link--bold">Forgot Password?</a>
              </div>
            </div>

            <div class="gds-space-stack-l">
              <div v-if="errorlogin" id="submission_error" class="gds-helper-text-error">{{errorlogin}}</div>
            </div>

            <div class="gds-space-stack-l">
              <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
                <span class="gds-button__text">Log In</span>
              </button>
            </div>

            <div class="global-panel__form-helpers">
              <p class="gds-align--text-center gds-body-small gds-link gds-link--bold">
                Don't have an account?
                <a href="/account/create">Get Started</a>
              </p>
            </div>
          </form>
        </div>
      </FocusLock>
    </div>
  </transition>
</template>

<script>
import WidgetPasswordInput from "./WidgetPasswordInput";
import { DumpError, HardScroll } from '../utilities';
import { GetComponentStatus } from '../services/statuspage';

export default {
  name: "GlobalLoginPanel",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      username: undefined,
      password: undefined,
      stayloggedin: true,

      errorlogin: undefined,
      pendingLogin: false,

      loadState: undefined,
    };
  },
  computed: {
    isExpanded() {
      return this.$store.state.layout.logindrawervisible;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state.user.userinfo.groups.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  async mounted() {
    this.loadState = undefined;

    const query = window.location.search;
    const params = new URLSearchParams(query);
    let bypass = params.get("bypassLoginStatusCheck");

    // Check for service outages
    const status = await GetComponentStatus("MyAccount");
    if (status === "operational" || bypass) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    Close() {
      this.$store.commit("setLoginDrawerVisible", false);
    },
    async Login() {
      this.errorlogin = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });
        // For some areas, staying on the page after login is not desired
        let path = window.location.pathname;
        if (path.endsWith("/")) {
          path = path.substring(0, path.length - 1);
        }
        if (this.isRestrictedRebateUser) {
          window.location="electric-vehicle-rebate";
        }
        if (path.startsWith("/account/create")) {
          window.location = "/account";
        } else {
          // Otherwise, scroll to top and remove the panel / menu
          await HardScroll(0, 0, this.$nextTick);
          // Close the login panel on success
          this.Close();
          // Remove the global class the wordpress mobile menu uses to stay visible
          document.body.classList.remove("mobile-navigation--expanded");
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
          DumpError("Login error", err);
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>