<template>
  <div class="header-login">
    <div v-if="!loggedInUser">
      <button @click="ToggleLoginDrawer" class="gds-button gds-compact gds-nav-button">Log In</button>
    </div>
    <div v-if="loggedInUser">
      <!-- This should only show above 900px wide -->
      <button
        v-if="!isMobile"
        @click="ToggleUserMenu"
        class="header--user-toggle-button gds-button gds-nav-button gds-primary caret"
        :class="{ 'caret-down' : !userMenuVisible }"
      >{{loggedInUser.fullName}}</button>
      <!-- This should only show below 900px wide -->
      <div v-if="isMobile" class="header-login__mobile--username gds-text-grey gds-space-stack-m">{{loggedInUser.fullName}}</div>

      <div v-if="userMenuVisible || isMobile" v-click-outside="ToggleUserMenu" class="header-login__settings">
        <ul class="header-login__settings__nav-list gds-body-large">
          <li>
            <a href="/user-profile">User Settings</a>
          </li>
          <!-- <li><a href="/user-profile#change-password">Change Password</a></li> -->
          <li v-if="!isAdmin && !isAdminLite">
            <a href="/user-profile/accounts">Linked Accounts ({{numLinkedAccounts}})</a>
          </li>
        </ul>

        <div class="header-login__settings--username">Signed in as {{loggedInUser.fullName}}</div>
        <div class="header-login__settings--email gds-space-stack-m">{{loggedInUser.email}}</div>
        <div>
          <button @click="LogOut()" class="header-login__settings--logout gds-button gds-round">Log Out</button>
        </div>
        <div v-if="isDeveloper" style="margin-top: 30px; border-top: 1px solid #d8d8d8; padding-top: 10px;">
          <div class="gds-space-stack-m">Developer tools</div>
          <button @click="ClearRefresh()" type="button" class="gds-button gds-small-button">
            <span class="gds-button__text">Clear refresh timer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderLoginButton",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    // Start watching window size
    window.addEventListener("resize", this.OnResize)
  },
  beforeDestroy() {
    // We must make sure to remove the listener when the component is destroyed
    window.removeEventListener("resize", this.OnResize);
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user.userinfo;
    },
    userMenuVisible() {
      return this.$store.state.layout.usermenuvisible;
    },
    numLinkedAccounts() {
      if (this.$store.state.user.accounts) {
        return this.$store.state.user.accounts.length;
      } else {
        return 0;
      }
    },
    isMobile() {
      return this.windowWidth <= 1100;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
    isDeveloper() {
      // return false;
      return window.location.hostname.startsWith("localhost");
    }
  },
  methods: {
    ToggleLoginDrawer() {
      this.$store.commit("toggleLoginDrawer");
    },
    ToggleUserMenu() {
      this.$store.commit("toggleUserMenu");
    },
    async LogOut() {
      this.$store.commit("setUserMenuVisible", false);
      await this.$store.dispatch("LogOut");
      window.location = "/";
    },
    OnResize() {
      this.windowWidth = window.innerWidth;
    },
    ClearRefresh() {
      this.$store.commit("clearLastRefresh");
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
