var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "gds-position--relative usage-dashboard__widget-current-usage"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard__widget-current-usage--heading-container"
        },
        [
          _vm.FF_NetMeteredDashboard && _vm.currentAccount.solarNetMeter
            ? _c("widget-switcher", {
                attrs: {
                  currentWidget: "Current Usage",
                  options: _vm.widgetOptions
                },
                on: { "handle-option-click": _vm.handleOptionClick }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.deviceIsSmall
            ? _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-current-usage--text--grey-small"
                },
                [_vm._v("Current usage this billing period")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.deviceIsSmall
            ? _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-current-usage--text--title-mobile"
                },
                [_vm._v("Current usage")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentUsageData && _vm.loadState === "complete"
            ? _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-current-usage--text--largest"
                },
                [_vm._v(_vm._s(_vm.totalCurrentUsage) + " kWh\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("a", {
            staticClass:
              "usage-dashboard__widget-current-usage--circle-arrow-right",
            attrs: { href: "/account/usage-new/detail/performance" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentUsageData && _vm.loadState === "complete"
        ? _c(
            "div",
            { staticClass: "usage-dashboard__widget-chart-legend-container" },
            [
              _c("div", {
                ref: "currentUsageChartRef",
                attrs: { id: "usage-dashboard__widget-current-usage--chart" }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "usage-dashboard__widget-current-usage--kwhDifference-legend-container"
                },
                [
                  _c(
                    "div",
                    { staticClass: "usage-dashboard__legend-item-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "usage-dashboard__legend-item" },
                        [
                          _c("Usage-Detail-Legend-Item", {
                            staticClass: "usage-dashboard__dashboard-legend",
                            attrs: {
                              title: "Current",
                              text: _vm.currentPeriodLegendText,
                              color: "#FE812F"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "usage-dashboard__legend-item" },
                        [
                          _c("Usage-Detail-Legend-Item", {
                            staticClass: "usage-dashboard__dashboard-legend",
                            attrs: {
                              title: "Previous",
                              text: _vm.previousPeriodLegendText,
                              color: "rgba(32,32,32,.1)"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "usage-dashboard__kwhDifference-container" },
                    [
                      _vm.differenceInUsage.language === "less"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "usage-dashboard__widget-current-usage--checkmark--green"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M7.76479 10.5883L6.35303 12.0001L10.2354 16.2354L17.6471 9.17654L16.2354 7.76477L10.2354 13.4118L7.76479 10.5883Z",
                                      fill: "white"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.differenceInUsage.language === "more"
                        ? _c("div", {
                            staticClass:
                              "usage-dashboard__widget-current-usage--arrow--blue-circle-up"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "usage-dashboard__widget-current-usage--kwhDifference--flex-item"
                        },
                        [
                          _vm._v(
                            "\n          You've used " +
                              _vm._s(_vm.differenceInUsage.difference) +
                              " kWh " +
                              _vm._s(_vm.differenceInUsage.language) +
                              " than last billing period.\n        "
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loadState
        ? _c("div", {
            staticClass:
              "usage-dashboard__widget-current-usage my-account__usage-loading"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "unavailable"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("Usage information is not available for this account.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("An unexpected error occurred, please try again later.")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }