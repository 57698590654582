var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--account-profile"
    },
    [
      _vm.currentAccount
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "top-banner-card gds-card gds-data-card top-banner-card--logged-in gds-align--text-center"
              },
              [
                _c(
                  "figure",
                  {
                    staticClass:
                      "card__account-profile--figure gds-align--block-center"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card__profile-image--outer-outer" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card__profile-image--outer-inner gds-position--relative"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card__profile-image--edit-icon-wrap"
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/settings#photo" } },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "card__profile-image--edit-icon gds-icon"
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.currentAccount.photoUrl
                              ? _c("div", {
                                  staticClass:
                                    "large-round-img card__account-profile--image",
                                  style: {
                                    backgroundImage:
                                      "url(" +
                                      _vm.GetResizedAccountPhoto(
                                        _vm.currentAccount.photoUrl
                                      ) +
                                      ")"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card__account-profile--text-details" },
                  [
                    _c(
                      "div",
                      { staticClass: "gds-body-large gds-space-stack-m" },
                      [
                        _vm._v(
                          _vm._s(_vm._f("accountName")(_vm.currentAccount))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-body-small gds-space-stack-s gds-linebreaks"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressFormat")(
                              _vm.currentAccount.address,
                              { separator: "\n", skipFirst: true }
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gds-body-small gds-space-stack-s" },
                      [
                        _vm._v(
                          "Acct. #" + _vm._s(_vm.currentAccount.accountNumber)
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-flex-container" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "gds-card__button gds-button gds-round gds-secondary card__account-profile--settings gds-button-full",
                        attrs: { to: "/settings" }
                      },
                      [_vm._v("Account Settings")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }