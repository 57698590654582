var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "usage-detail__datepicker" },
    [
      _c("datepicker", {
        ref: "datePicker",
        attrs: {
          typeable: false,
          "minimum-view": _vm.minimumView,
          "maximum-view": _vm.maximumView,
          "disabled-dates": _vm.disabledDatesConfig,
          "input-class": "usage-detail__datepicker__date-input"
        },
        on: { selected: _vm.handleSelectedDateChange },
        model: {
          value: _vm.selectedDate,
          callback: function($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }