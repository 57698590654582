var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "usage-dashboard__usage-visualization-and-tools--container"
      },
      [
        _c("div", { staticClass: "usage-dashboard__headings--no-boxes" }, [
          _vm._v("\n    Usage Visualizations and Tools")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--card-container"
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/graph" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg",
                    alt: "Graph chart icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Graph")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [_vm._v("View your usage as a bar graph.")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/table" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg",
                    alt: "Table chart icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Table")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [_vm._v("View your usage as a set of rows.")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/time-of-day" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg",
                    alt: "Sun and moon icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Time of Day")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [
                    _vm._v(
                      "When do you use energy the most throughout the day."
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/seasonal" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg",
                    alt: "Box with snowflake icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Seasonal")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [
                    _vm._v(
                      "How does your usage change based off of the time of year."
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/performance" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg",
                    alt: "Guage icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Performance")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [_vm._v("Compare your usage between billing periods.")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "usage-dashboard__usage-visualization-and-tools--nav-card",
                attrs: { href: "/account/usage-new/detail/compare" }
              },
              [
                _c("img", {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg",
                    alt: "Box with arrows pointing toward each other icon"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon-text"
                  },
                  [_vm._v("Compare")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--description"
                  },
                  [_vm._v("Compare usage over different time periods.")]
                )
              ]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }