<template>
    <div>
      <div
      v-if="isMobbileView || isTabletView"
      class="usage-detail__date-navigation-mobile">
        <div class="usage-detail__date-navigation-mobile__controls-container">
          <div class="usage-detail__date-navigation-mobile__controls-container__row">
            <div class="usage-detail__date-navigation-mobile__controls-container__row__col">
              <div v-if="showComparisonDetails" class="usage-detail__date-navigation__comparison-container-mobile">
                <div class="comparison-sub-header">Comparing</div>
                <div v-if="comparisonDetailsType" class="comparison-details">{{comparisonDetailsType}}</div>
                <div v-if="comparisonDetailsDate" class="comparison-details">{{comparisonDetailsDate}}</div>
              </div>
              <div
              class="usage-detail__date-navigation-mobile__selected-date-container"
              v-if="selectedDate">
                <div v-if="selectedDate" class="usage-detail__date-navigation-mobile__selected-date-container__selected-date">{{selectedDate}}</div>
                <div v-if="selectedDateSubText" v-html="selectedDateSubText" class="usage-detail__date-navigation-mobile__selected-date-container__selected-date-sub-text"></div>
              </div>
            </div>
            <div class="usage-detail__date-navigation-mobile__controls-container__row__col align-right date-back-forward">
              <DateNavigationButtonBack
              v-if="showDateBackwardsButton"
              :goBack="onDateBackwardsClicked"
              class="date-navigation__mobile-button-circle"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonBack" />

              <DateNavigationButtonForward
              v-if="showDateForwardsButton"
              :goForward="onDateForwardsClicked"
              class="date-navigation__mobile-button-circle"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonForward" />
            </div>
          </div>
          <div class="usage-detail__date-navigation-mobile__controls-container__wrapper">
            <div class="usage-detail__date-navigation-mobile__controls-container__row">
              <div
              @click="toggleMobileViewOptionsVisible()"
              class="usage-detail__date-navigation-item-mobile__view-options">
                {{mobileViewOptionsVisible ? 'Hide Options' : 'View Options'}}
                <i class="usage-detail__date-navigation-item-mobile__view-options__caret" :class="{'caret-up': mobileViewOptionsVisible, 'caret-down': !mobileViewOptionsVisible}"></i>
              </div>
            </div>
            <div
            v-if="mobileViewOptionsVisible"
            class="usage-detail__date-navigation-mobile__controls-container__row block">
              <DateNavigationButtonMobile
              v-if="showJumpToDateButton"
              buttonText="Go To Date"
              :buttonClicked="showDatePicker"
              class="usage-detail__date-navigation-item"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonJumpToDate"/>

              <DateNavigationButtonMobile
              v-if="showComparisonDateButton"
              buttonText="Comparison Date"
              :buttonClicked="showDatePickerSecondary"
              class="usage-detail__date-navigation-item"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonCalendar"/>

              <DateNavigationButtonMobile
              v-if="showSimpleDatePickerButton"
              buttonText="Go To Date"
              :buttonClicked="showDatePicker"
              class="usage-detail__date-navigation-item"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonCalendar"/>

              <DateNavigationButtonMobile
              v-if="showGoToTodayButton"
              buttonText="Go To Today"
              :buttonClicked="onGoToTodayClicked"
              class="usage-detail__date-navigation-item"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonGoToToday"/>

              <DateNavigationButtonMobile
              v-if="showViewOptionsButton"
              :buttonText="viewOptionsLabel"
              :buttonClicked="onViewOptionsClicked"
              class="usage-detail__date-navigation-item"
              :disabled="disableAllDateNavControls || disableDateNavigationButtonGoToToday"/>

              <UsageDetailSelectMobile
              v-if="selectConfigMonthlyDailyHourly"
              :addBackground="true"
              modalTitle="Compare Granularity"
              :config="selectConfigMonthlyDailyHourly"
              :disabled="disableAllDateNavControls"
              :optionSelected="onOptionSelectedMonthlyDailyHourly"/>

              <UsageDetailSelectMobile
              v-if="selectConfigUsageType"
              :addBackground="true"
              modalTitle="Data Series"
              :config="selectConfigUsageType"
              :disabled="disableAllDateNavControls"
              :optionSelected="onOptionSelectedUsageType"/>

              <UsageDetailSelectMobile
              v-if="selectConfigTableSort"
              :addBackground="true"
              modalTitle="Sort Order"
              :config="selectConfigTableSort"
              :disabled="disableAllDateNavControls"
              :optionSelected="onOptionSelectedTableSort"/>

              <UsageDetailSelectMobile
              v-if="selectConfigCalendarMonthsBillingPeriod"
              :addBackground="true"
              modalTitle="Time Grouping"
              :disabled="disableAllDateNavControls"
              :config="selectConfigCalendarMonthsBillingPeriod"
              :optionSelected="onOptionSelectedCalendarMonthsBillingPeriod"/>

              <UsageDetailCheckboxes
              v-if="selectConfigCheckboxes"
              :addBackground="true"
              :buttonText="selectConfigCheckboxes.buttonText || 'Options'"
              :modalTitle="selectConfigCheckboxes.modalTitle || 'Please Select'"
              :disabled="disableAllDateNavControls"
              :config="selectConfigCheckboxes"
              :checkboxChanged="onCheckboxesModalChange"/>

              <UsageDetailDatePickerMobile
              v-if="(showSimpleDatePickerButton || showJumpToDateButton ) && datePickerVisible"
              modalTitle="Go To Date"
              :closeModal="closeDatePickers"
              :datePickerDefaultDate="datePickerDefaultDate"
              :datePickerVisible="datePickerVisible"
              :datePickerDateType="datePickerDateType"
              :disabled="disableAllDateNavControls"
              :disabledDates="datePickerDisabledDates"
              :selectedDateChanged="onSelectedDateChanged"/>

              <UsageDetailDatePickerMobile
              v-if="(showSimpleDatePickerButton || showJumpToDateButton ) && datePickerVisibleSecondary"
              modalTitle="Comparison Date"
              :closeModal="closeDatePickers"
              :datePickerDefaultDate="datePickerDefaultDateSecondary"
              :datePickerVisible="datePickerVisibleSecondary"
              :datePickerDateType="datePickerDateType"
              :disabled="disableAllDateNavControls"
              :disabledDates="datePickerDisabledDatesSecondary"
              :selectedDateChanged="onSelectedDateChangedSecondary"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isDesktopView">
        <div class="usage-detail-controls-container" v-click-outside="closeDatePickers">
          <div class="usage-detail-controls-container__left-side">
            <span v-if="selectedDate">
              <div
              v-bind:style="{ width: selectedDateContainerWidth }"
              class="usage-detail__selected-date-container">
                <div v-if="selectedDate" class="usage-detail__selected-date-container__selected-date">{{selectedDate}}</div>
                <div v-if="selectedDateSubText" v-html="selectedDateSubText" class="usage-detail__selected-date-container__selected-date-sub-text"></div>
              </div>
            </span>

            <div v-if="isCompareRoute">
              <div
              v-if="showComparisonDetails"
              v-bind:style="{ width: comparisonDetailsContainerWidth }"
              class="usage-detail__date-navigation__comparison-container-desktop">
                <div class="comparison-sub-header">Comparing</div>
                <div v-if="comparisonDetailsType" class="comparison-details">{{comparisonDetailsType}}</div>
                <div v-if="comparisonDetailsDate" class="comparison-details">{{comparisonDetailsDate}}</div>
              </div>
              <div
              class="usage-detail__date-navigation-mobile__selected-date-container"
              v-if="selectedDate">
                <div v-if="selectedDate" class="usage-detail__date-navigation-mobile__selected-date-container__selected-date">{{selectedDate}}</div>
                <div v-if="selectedDateSubText" v-html="selectedDateSubText" class="usage-detail__date-navigation-mobile__selected-date-container__selected-date-sub-text"></div>
              </div>
            </div>

            <DateNavigationButtonBack
            v-if="showDateBackwardsButton"
            :goBack="onDateBackwardsClickedHandler"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonBack" />

            <DateNavigationButtonForward
            v-if="showDateForwardsButton"
            :goForward="onDateForwardsClickedHandler"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonForward" />

            <div>
              <UsageDetailDatePicker
              v-if="showSimpleDatePickerButton || showJumpToDateButton"
              :datePickerDefaultDate="datePickerDefaultDate"
              :datePickerVisible="datePickerVisible"
              :datePickerDateType="datePickerDateType"
              :disabled="disableAllDateNavControls"
              :disabledDates="datePickerDisabledDates"
              :selectedDateChanged="onSelectedDateChanged"/>
            </div>

            <DateNavigationButtonCalendar
            v-if="showSimpleDatePickerButton"
            :showCalendar="showDatePicker"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonCalendar"/>

            <DateNavigationButtonJumpToDate
            v-if="showJumpToDateButton"
            :jumpToDate="showDatePicker"
            :buttonLabel="jumpToDateButtonLabel"
            :buttonWidth="jumpToDateButtonWidth"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonJumpToDate"/>

            <DateNavigationButtonJumpToDate
            v-if="showComparisonDateButton"
            :jumpToDate="showDatePickerSecondary"
            buttonLabel="Comparison Date"
            buttonWidth="11rem"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonJumpToDate"/>

            <DateNavigationButtonGoToToday
            v-if="showGoToTodayButton"
            :goToToday="onGoToTodayClickedHandler"
            :buttonWidth="goToTodayButtonWidth"
            class="usage-detail__date-navigation-item"
            :disabled="disableAllDateNavControls || disableDateNavigationButtonGoToToday"/>

            <div>
              <UsageDetailDatePicker
              v-if="showComparisonDateButton"
              :datePickerDefaultDate="datePickerDefaultDateSecondary"
              :datePickerVisible="datePickerVisibleSecondary"
              :datePickerDateType="datePickerDateType"
              :disabled="disableAllDateNavControls"
              :disabledDates="datePickerDisabledDatesSecondary"
              :selectedDateChanged="onSelectedDateChangedSecondary"/>
            </div>

            <span v-if="summaryText" class="usage-detail__performance-summary" v-html="summaryText"></span>
          </div>
          <div class="usage-detail-controls-container__right-side">
            <UsageDetailSelect
            v-if="selectConfigTableSort"
            :config="selectConfigTableSort"
            :optionSelected="onOptionSelectedTableSort"/>

            <UsageDetailSelect
            v-if="selectConfigConsumptionOrGeneration"
            :config="selectConfigConsumptionOrGeneration"
            :optionSelected="onOptionSelectedConsumptionOrGeneration"/>

            <UsageDetailSelect
            v-if="selectConfigCalendarMonthsBillingPeriod"
            :config="selectConfigCalendarMonthsBillingPeriod"
            :optionSelected="onOptionSelectedCalendarMonthsBillingPeriod"/>

            <span v-if="isCompareRoute" class="comparison-select-label">Comparing:</span>

            <UsageDetailSelect
            v-if="selectConfigMonthlyDailyHourly"
            :config="selectConfigMonthlyDailyHourly"
            :optionSelected="onOptionSelectedMonthlyDailyHourlyHandler"/>

            <UsageDetailSelect
            v-if="selectConfigUsageType"
            :config="selectConfigUsageType"
            :optionSelected="onOptionSelectedUsageType"/>

            <UsageDetailCheckboxes
            v-if="selectConfigCheckboxes"
            :addBackground="true"
            :buttonText="selectConfigCheckboxes.buttonText || 'Options'"
            :modalTitle="selectConfigCheckboxes.modalTitle || 'Please Select'"
            :disabled="disableAllDateNavControls"
            :config="selectConfigCheckboxes"
            :checkboxChanged="onCheckboxesModalChange"/>

            <ButtonViewOptions
            v-if="showViewOptionsButton"
            :viewOptionsLabel="viewOptionsLabel ? viewOptionsLabel : 'View Options'"
            :viewOptions="onViewOptionsClicked"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDetailSelect from "../usagedetailselect/UsageDetailSelect";
import ButtonViewOptions from "../../../../components/generic/ButtonViewOptions";
import UsageDetailSelectMobile from "../usagedetailselect/UsageDetailSelectMobile";
import UsageDetailDatePicker from "../usagedetaildatepicker/UsageDetailDatePicker";
import UsageDetailCheckboxes from "../usagedetailcheckboxes/UsageDetailCheckboxes";
import DateNavigationButtonBack from "../../../../components/generic/DateNavigationButtonBack";
import UsageDetailDatePickerMobile from "../usagedetaildatepicker/UsageDetailDatePickerMobile";
import DateNavigationButtonMobile from "../../../../components/generic/DateNavigationButtonMobile";
import DateNavigationButtonForward from "../../../../components/generic/DateNavigationButtonForward";
import DateNavigationButtonCalendar from "../../../../components/generic/DateNavigationButtonCalendar";
import DateNavigationButtonGoToToday from "../../../../components/generic/DateNavigationButtonGoToToday";
import DateNavigationButtonJumpToDate from "../../../../components/generic/DateNavigationButtonJumpToDate";

const defaultWidthSelectedDate = '300px';
const defaultWidthComparisonDetails = '400px';

export default {
  name: "UsageDetailControls",
  data() {
    return {
      escapeKey: 'Escape',
      isCompareRoute: false,
      mobileViewOptionsVisible: false,
      datePickerVisible: false,
      datePickerVisibleSecondary: false,
      selectedDateContainerWidth: this.selectedDateWidth || defaultWidthSelectedDate,
      comparisonDetailsContainerWidth: this.comparisonDetailsWidth || defaultWidthComparisonDetails
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydownEvent);
    this.isCompareRoute = this.$route.path && this.$route.path.startsWith('/compare');
  },
  mixins: [
    MediaQueryMixin
  ],
  watch: {
    comparisonDetailsWidth(val) {
      this.comparisonDetailsContainerWidth = val;
    },
    selectedDateWidth(val) {
      this.selectedDateContainerWidth = val;
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    }
  },
  methods: {
    onGoToTodayClickedHandler() {
      this.onGoToTodayClicked();
      this.closeDatePickers();
    },
    onDateBackwardsClickedHandler() {
      this.onDateBackwardsClicked();
      this.closeDatePickers();
    },
    onDateForwardsClickedHandler() {
      this.onDateForwardsClicked();
      this.closeDatePickers();
    },
    onOptionSelectedMonthlyDailyHourlyHandler(event) {
      this.onOptionSelectedMonthlyDailyHourly(event);
      this.closeDatePickers();
    },
    toggleMobileViewOptionsVisible() {
      this.mobileViewOptionsVisible = !this.mobileViewOptionsVisible;
    },
    showDatePicker() {
      this.datePickerVisible = false;
      this.$nextTick(() => this.datePickerVisible = true);
    },
    showDatePickerSecondary() {
      this.datePickerVisibleSecondary = false;
      this.$nextTick(() => this.datePickerVisibleSecondary = true);
    },
    handleKeydownEvent(event) {
      if (event.key === this.escapeKey) {
          this.datePickerVisible = false;
          this.datePickerVisibleSecondary = false;
      }
    },
    closeDatePickers() {
      this.datePickerVisible = false;
      this.datePickerVisibleSecondary = false;
    }
  },
  components: {
    UsageDetailSelect,
    UsageDetailSelectMobile,
    UsageDetailCheckboxes,
    ButtonViewOptions,
    UsageDetailDatePicker,
    DateNavigationButtonBack,
    DateNavigationButtonForward,
    DateNavigationButtonCalendar,
    DateNavigationButtonGoToToday,
    DateNavigationButtonJumpToDate,
    UsageDetailDatePickerMobile,
    DateNavigationButtonMobile
  },
  props: {
    summaryText: String,
    selectedDate: String,
    selectedDateWidth: String,
    selectedDateSubText: String,
    jumpToDateButtonLabel: String,
    jumpToDateButtonWidth: {
      type: String,
      default: "10rem"
    },
    goToTodayButtonWidth: {
      type: String,
      default: "10rem"
    },
    comparisonDetailsWidth: String,
    showComparisonDetails: Boolean,
    comparisonDetailsType: String,
    comparisonDetailsDate: String,
    onGoToTodayClicked: Function,
    onJumpToDateClicked: Function,
    onViewOptionsClicked: Function,
    onDateBackwardsClicked: Function,
    onDateForwardsClicked: Function,
    showViewOptionsButton: Boolean,
    viewOptionsLabel: {
      type: String,
      default: 'View options'
    },
    showGoToTodayButton: Boolean,
    showJumpToDateButton: Boolean,
    showComparisonDateButton: Boolean,
    showSimpleDatePickerButton: Boolean,
    showDateBackwardsButton: Boolean,
    showDateForwardsButton: Boolean,
    datePickerDateType: String,
    datePickerDefaultDate: Date,
    datePickerDefaultDateSecondary: Date,
    datePickerDisabledDates: Object,
    datePickerDisabledDatesSecondary: Object,
    onSelectedDateChanged: Function,
    onSelectedDateChangedSecondary: Function,
    selectConfigCheckboxes: Object,
    selectConfigUsageType: Object,
    selectConfigTableSort: Object,
    selectConfigMonthlyDailyHourly: Object,
    selectConfigConsumptionOrGeneration: Object,
    selectConfigCalendarMonthsBillingPeriod: Object,
    onCheckboxesModalChange: Function,
    onOptionSelectedTableSort: Function,
    onOptionSelectedUsageType: Function,
    onOptionSelectedMonthlyDailyHourly: Function,
    onOptionSelectedCalendarMonthsBillingPeriod: Function,
    onOptionSelectedConsumptionOrGeneration: Function,
    disableAllDateNavControls: Boolean,
    disableDateNavigationButtonForward: Boolean,
    disableDateNavigationButtonBack: Boolean,
    disableDateNavigationButtonGoToToday: Boolean,
    disableDateNavigationButtonJumpToDate: Boolean,
    disableDateNavigationButtonCalendar: Boolean
  }
};
</script>

<style scoped>
</style>