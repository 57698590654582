<template>
  <section class="my-account__full-width-section my-account__section-with-bottom-border">
    <!-- Loading and error display -->
    <div v-if="loadState !== 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 class="gds-display-1 gds-space-inline-m">Paperless Billing</h2>
          <div v-if="loadState === undefined" class="editor-loading" />
        </div>
        <div class="fake-edit-link">Edit</div>
      </div>
      <div v-if="loadState === 'error'" class="editor-error">Unable to load this section. Please try again later.</div>
    </div>
    <!-- Main display when loading complete -->
    <div v-if="loadState === 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 ref="scrolltarget" class="gds-display-1 gds-space-inline-m">Paperless Billing</h2>
          <div v-if="!editing && billingStatus" class="gds-flex-container gds-flex-container--left">
            <div class="my-account__on-off-text gds-flex-container gds-flex-container--left">
              <IndicatorOnOff :value="billingStatus.billRouteInformation.isEBill"></IndicatorOnOff>
            </div>
          </div>
        </div>

        <div class="edit-close-button-combo">
          <button v-if="!editing" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Edit</span>
          </button>
          <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Close</span>
          </button>
        </div>
      </div>
      <!-- Paperless Billing Display -->
      <div v-if="!editing && billingStatus">
        <div v-if="!billingStatus.billRouteInformation.isEBill" class="my-account__sub-section-bottom gds-text-grey">You are receiving paper bills in the mail.</div>
        <div v-if="billingStatus.billRouteInformation.isEBill" class="my-account__sub-section-bottom">Sending your bill to {{billingStatus.billRouteInformation.emailAddress}}</div>
      </div>
      <!-- Paperless Billing Edit -->
      <form v-if="editing && paperlessSettings" @submit.prevent="Save()">
        <div class="my-account__header-button-pairing gds-flex-container gds-flex-container--left">
          <fieldset class="gds-fieldset gds-space-inline-m">
            <label class="gds-switch">
              <input v-model="paperlessSettings.billRouteInformation.isEBill" @change="touched = true" type="checkbox" />
              <span class="gds-switch__faux"></span>
            </label>
          </fieldset>
          <div>
            <div v-if="paperlessSettings.billRouteInformation.isEBill" class="gds-space-stack-m">Paperless billing is turned on.</div>
            <div v-if="!paperlessSettings.billRouteInformation.isEBill" class="gds-space-stack-m">Paperless billing is turned off.</div>
          </div>
        </div>
        <fieldset v-if="paperlessSettings.billRouteInformation.isEBill" class="gds-fieldset gds-space-stack-m">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Where to send your paper bill</span>
            <input
              v-model="paperlessSettings.billRouteInformation.emailAddress"
              v-validate="'required|email|max:74'"
              maxlength="74"
              name="email"
              key="email"
              @input="touched = true"
              class="gds-input-field__input"
              type="email"
              placeholder="johndoe@example.com"
            />
          </label>
          <div v-if="errors.first('email')" class="validation-error">{{ errors.first("email")}}</div>
        </fieldset>
        <button type="submit" :disabled="pendingSave || !touched || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
          <span class="gds-button__text">Save</span>
        </button>
        <div v-if="errorSave" class="form-message-box gds-space-stack-m">There was an error updating your paperless billing.</div>
      </form>
    </div>
  </section>
</template>

<script>
import IndicatorOnOff from "../../generic/IndicatorOnOff";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import { AnalyticsLogEvent } from "../../../services/analytics";

export default {
  name: "EditorPaperless",
  props: ["billingStatus"],
  data() {
    return {
      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: false,

      loadState: undefined,

      paperlessSettings: undefined,
    };
  },
  components: {
    IndicatorOnOff,
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      if (this.billingStatus) {
        this.paperlessSettings = {
          billRouteInformation: {
            isEBill: this.billingStatus.billRouteInformation.isEBill,
            emailAddress: this.billingStatus.billRouteInformation.emailAddress,
          },
        };
        this.loadState = "complete";
      } else {
        if (this.billingStatus === null) {
          this.loadState = "error";
        } else {
          this.loadState = undefined;
        }
      }
      // Allow "deep-ish" links
      if (this.$route.hash === "#paperlessbilling") {
        this.editing = true;
        await this.$nextTick();
        if (this.$refs.scrolltarget) {
          this.$refs.scrolltarget.scrollIntoView(true);
        }
      }
    },
    async Save() {
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        this.errorSave = undefined;
        try {
          await GMPAPI.UpdateBillingStatus(this.currentAccount.accountNumber, this.paperlessSettings);
          // If it's enabled, log an analytics event
          if (this.billingStatus && this.billingStatus.billRouteInformation && this.billingStatus.billRouteInformation.isEBill) {
            AnalyticsLogEvent("enabled_paperless");
          }
          this.$emit("update");
        } catch (err) {
          DumpError("Update paperless error", err);
          this.errorSave = true;
        } finally {
          this.pendingSave = false;
        }
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async billingStatus() {
      this.editing = false;
      this.touched = false;
      this.errorSave = false;
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>