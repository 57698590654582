// Polyfills for IE11
import "es6-promise/auto";
import "url-search-params-polyfill";
import "svgxuse";

import Vue from "vue";
import VueRouter from "vue-router";
import VeeValidate from "vee-validate";
import VueTheMask from "vue-the-mask";
import VMoney from "v-money";
import FocusLock from "vue-focus-lock";
import Highcharts from "highcharts";
import VueGoodTablePlugin from "vue-good-table";
import vClickOutside from "v-click-outside";

import { HighchartsRoundedCorners } from "./highcharts-plugins";

import Filters from "./filters";
import DataStore from "./store";
import CustomValidation from "./customvalidation";
import FlowError from "./components/generic/FlowError";
import { RefreshAccountIfNeeded, BeginRefreshTimer, LogoutIfNeeded, SwitchAccountFromURL } from "./userrefresh";

import GlobalLoginPanel from "./components/GlobalLoginPanel.vue";
import GlobalAccountPanel from "./components/GlobalAccountPanel.vue";
import GlobalBodyClassWatcher from "./components/GlobalBodyClassWatcher.vue";

import HeaderLoginButton from "./components/HeaderLoginButton.vue";
import WidgetAccountSwitcher from "./components/WidgetAccountSwitcher.vue";
import WidgetWelcomeMessage from "./components/WidgetWelcomeMessage.vue";
import WidgetWebchatButton from "./components/WidgetWebchatButton.vue";
import WidgetFloatingHelper from "./components/WidgetFloatingHelper.vue";
import WidgetDisconnectBanner from "./components/WidgetDisconnectBanner.vue";
import WidgetPowerwallInstallers from "./components/WidgetPowerwallInstallers.vue";

import CardPayBill from "./components/CardPayBill.vue";
import CardReportIssue from "./components/CardReportIssue.vue";
import CardAccountDashboard from "./components/CardAccountDashboard.vue";
import CardMarketing from "./components/CardMarketing.vue";

import PageUsageDetail from "./components/myaccount/usage/PageUsageDetail";
import { UsageDetailRouterOptions, UsageDetailRouterInit } from "./components/myaccount/usage/UsageDetailRouter";

import PagePastDueWizard from "./components/pastduewizard/PagePastDueWizard.vue";
import { PastDueWizardRouterOptions, PastDueWizardRouterInit } from "./components/pastduewizard/PastDueWizardRouter";

import PageMakePayment from "./components/billpay/PageMakePayment.vue";
import { BillPayRouterOptions, BillPayRouterInit } from "./components/billpay/BillPayRouter";

import PageEbikeRebate from "./components/eicproducts/eBike-rebate/PageEbikeRebate.vue";
import { eBikeRebateRouterOptions, eBikeRebateRouterInit } from "./components/eicproducts/eBike-rebate/eBikeRebateRouter";

import PageYardCareRebate from "./components/eicproducts/yardcare/PageYardCareRebate.vue";
import { yardCareRebateRouterOptions, yardCareRebateRouterInit } from "./components/eicproducts/yardcare/yardCareRebateRouter";

import PageEVRebate from "./components/eicproducts/electric-vehicle-rebate/PageEVRebate.vue";
import { EVRebateRouterOptions, EVRebateRouterInit } from "./components/eicproducts/electric-vehicle-rebate/EVRebateRouter";

import PageEVCharger from "./components/eicproducts/evcharger/PageEVCharger.vue";
import { EVChargerRouterOptions, EVChargerRouterInit } from "./components/eicproducts/evcharger/EVChargerRouter";

import PagePayArrange from "./components/payarrange/PagePayArrange.vue";
import { PayArrangeRouterOptions, PayArrangeRouterInit } from "./components/payarrange/PayArrangeRouter";

import PageMyAccount from "./components/myaccount/PageMyAccount.vue";
import { MyAccountRouterOptions, MyAccountRouterInit } from "./components/myaccount/MyAccountRouter";

import PageStopService from "./components/stopservice/PageStopService.vue";
import { StopServiceRouterOptions, StopServiceRouterInit } from "./components/stopservice/StopServiceRouter";

import PageUserProfile from "./components/userprofile/PageUserProfile.vue";
import { UserProfileRouterOptions, UserProfileRouterInit } from "./components/userprofile/UserProfileRouter";

import PageOutageCenter from "./components/outagecenter/PageOutageCenter.vue";
import { OutageCenterRouterOptions, OutageCenterRouterInit } from "./components/outagecenter/OutageCenterRouter";

import PageReportOutage from "./components/reportoutage/PageReportOutage.vue";
import { ReportOutageRouterOptions, ReportOutageRouterInit } from "./components/reportoutage/ReportOutageRouter";

import PageInductionCooktopIncentive from "./components/eicproducts/induction-cooktop/PageInductionCooktopIncentive.vue";
import { InductionCooktopIncentiveRouterOptions, InductionCooktopIncentiveRouterInit } from "./components/eicproducts/induction-cooktop/InductionCooktopIncentiveRouter";


import PageTransitLogin from "./components/transitlogin/PageTransitLogin.vue";

import PageNotAuthorized from "./components/notauthorized/PageNotAuthorized.vue";

import PageForgotPassword from "./components/forgotpassword/PageForgotPassword.vue";
import PageResetPassword from "./components/forgotpassword/PageResetPassword.vue";
import PageForgotUsername from "./components/forgotpassword/PageForgotUsername.vue";
import PageCreateAccount from "./components/createaccount/PageCreateAccount.vue";
import PagePaperlessEnroll from "./components/paperlessenroll/PagePaperlessEnroll.vue";
import PageVerifyContact from "./components/verifycontact/PageVerifyContact.vue";
import PageGhostingSearch from "./components/admin/PageGhostingSearch.vue";
import { DumpError } from "./utilities";
import { AnalyticsSetUserInfo } from "./services/analytics";
import { InitializeChat } from "./services/webchat";


Vue.config.productionTip = false

VeeValidate.Validator.localize("en", CustomValidation);
VeeValidate.Validator.extend("hasupperandlower", {
  getMessage: _field => "The password must contain at least 1 uppercase letter and 1 lowercase letter.",
  validate: value => {
    if (!value) return false;
    return value.toUpperCase() !== value && value.toLowerCase() !== value;
  },
});
VeeValidate.Validator.extend("hasnumber", {
  getMessage: _field => "The password must contain at least 1 number.",
  validate: value => /\d/.test(value),
});

Vue.use(VueRouter);
Vue.use(VeeValidate, {
  events: "change",
});
Vue.use(VueTheMask);
Vue.use(VMoney);
Vue.use(Filters);
Vue.component("FocusLock", FocusLock);
Vue.component("FlowError", FlowError);
Vue.use(VueGoodTablePlugin);
Vue.use(vClickOutside);

HighchartsRoundedCorners(Highcharts);

// Note that now that webchat.min.js is loaded async, this will probably fail because it's not loaded yet.
InitializeChat();

// Initialize in async for simplicity
(async () => {
  // If we have an OTP login and are not in billpay, logout
  try {
    await LogoutIfNeeded();
  } catch (err) {
    DumpError("OTP logout error", err);
  }

  // Verify we're logged in and refresh account info if needed
  try {
    await BeginRefreshTimer();
  } catch (err) {
    DumpError("Refresh timer start error", err);
  }
  try {
    await RefreshAccountIfNeeded();
  } catch (err) {
    DumpError("Refresh account error", err);
  }
  try {
    await SwitchAccountFromURL();
  } catch (err) {
    DumpError("Switch account error", err);
  }

  AnalyticsSetUserInfo();

  // Look for any relevant divs, and convert them to vue components

  // Global
  if (document.getElementById("global-login-panel")) {
    new Vue({
      store: DataStore,
      render: h => h(GlobalLoginPanel),
    }).$mount("#global-login-panel");
  }
  if (document.getElementById("global-account-panel")) {
    new Vue({
      store: DataStore,
      render: h => h(GlobalAccountPanel),
    }).$mount("#global-account-panel");
  }
  if (document.getElementById("global-body-class-watcher")) {
    new Vue({
      store: DataStore,
      render: h => h(GlobalBodyClassWatcher),
    }).$mount("#global-body-class-watcher");
  }

  // Widgets
  if (document.getElementById("widget-header-login-button")) {
    new Vue({
      store: DataStore,
      render: h => h(HeaderLoginButton),
    }).$mount("#widget-header-login-button");
  }
  if (document.getElementById("widget-account-switcher")) {
    new Vue({
      store: DataStore,
      render: h => h(WidgetAccountSwitcher),
    }).$mount("#widget-account-switcher");
  }
  if (document.getElementById("widget-welcome-message")) {
    new Vue({
      store: DataStore,
      render: h => h(WidgetWelcomeMessage),
    }).$mount("#widget-welcome-message");
  }
  if (document.getElementById("widget-webchat-button")) {
    const element = document.getElementById("widget-webchat-button");
    let queueName;
    if (element.attributes && element.attributes["data-queue-name"] && element.attributes["data-queue-name"].value) {
      queueName = element.attributes["data-queue-name"].value;
    }
    new Vue({
      store: DataStore,
      render: h => h(WidgetWebchatButton, { props: { queueName } }),
    }).$mount("#widget-webchat-button");
  }
  if (document.getElementById("widget-powerwall-installers")) {
    new Vue({
      store: DataStore,
      render: h => h(WidgetPowerwallInstallers),
    }).$mount("#widget-powerwall-installers");
  }
  if (document.getElementById("widget-floating-helper")) {
    new Vue({
      store: DataStore,
      render: h => h(WidgetFloatingHelper),
    }).$mount("#widget-floating-helper");
  }

  // Cards
  if (document.getElementById("card-pay-bill")) {
    new Vue({
      store: DataStore,
      render: h => h(CardPayBill),
    }).$mount("#card-pay-bill");
  }
  if (document.getElementById("card-report-issue")) {
    new Vue({
      store: DataStore,
      render: h => h(CardReportIssue),
    }).$mount("#card-report-issue");
  }
  if (document.getElementById("card-account-dashboard")) {
    new Vue({
      store: DataStore,
      render: h => h(CardAccountDashboard),
    }).$mount("#card-account-dashboard");
  }
  // Unfortunately these have to be mounted individually for wrap etc to work
  if (document.getElementById("card-marketing-0")) {
    new Vue({
      store: DataStore,
      render: h => h(CardMarketing, { props: { cardIndex: 0 } }),
    }).$mount("#card-marketing-0");
  }
  if (document.getElementById("card-marketing-1")) {
    new Vue({
      store: DataStore,
      render: h => h(CardMarketing, { props: { cardIndex: 1 } }),
    }).$mount("#card-marketing-1");
  }
  if (document.getElementById("card-marketing-2")) {
    new Vue({
      store: DataStore,
      render: h => h(CardMarketing, { props: { cardIndex: 2 } }),
    }).$mount("#card-marketing-2");
  }

  // Pages
  if (document.getElementById("page-usage-detail")) {
    const router = new VueRouter(UsageDetailRouterOptions);
    UsageDetailRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageUsageDetail),
    }).$mount("#page-usage-detail");
  }
  if (document.getElementById("page-induction-cooktop-incentive")) {
    const router = new VueRouter(InductionCooktopIncentiveRouterOptions);
    InductionCooktopIncentiveRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageInductionCooktopIncentive),
    }).$mount("#page-induction-cooktop-incentive");
  }
  if (document.getElementById("page-eBike-rebate")) {
    const router = new VueRouter(eBikeRebateRouterOptions);
    eBikeRebateRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageEbikeRebate),
    }).$mount("#page-eBike-rebate");
  }
  if (document.getElementById("page-yard-care-rebate")) {
    const router = new VueRouter(yardCareRebateRouterOptions);
    yardCareRebateRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageYardCareRebate),
    }).$mount("#page-yard-care-rebate");
  }
  if (document.getElementById("page-ev-rebate")) {
    const router = new VueRouter(EVRebateRouterOptions);
    EVRebateRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageEVRebate),
    }).$mount("#page-ev-rebate");
  }
  if (document.getElementById("page-ev-charger")) {
    const router = new VueRouter(EVChargerRouterOptions);
    EVChargerRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageEVCharger),
    }).$mount("#page-ev-charger");
  }
  if (document.getElementById("page-past-due-wizard")) {
    const router = new VueRouter(PastDueWizardRouterOptions);
    PastDueWizardRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PagePastDueWizard),
    }).$mount("#page-past-due-wizard");
  }
  if (document.getElementById("page-make-payment")) {
    const router = new VueRouter(BillPayRouterOptions);
    BillPayRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageMakePayment),
    }).$mount("#page-make-payment");
  }
  if (document.getElementById("page-arrange-payment")) {
    const router = new VueRouter(PayArrangeRouterOptions);
    PayArrangeRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PagePayArrange),
    }).$mount("#page-arrange-payment");
  }
  if (document.getElementById("page-my-account")) {
    const router = new VueRouter(MyAccountRouterOptions);
    MyAccountRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageMyAccount),
    }).$mount("#page-my-account");
  }
  if (document.getElementById("page-user-profile")) {
    const router = new VueRouter(UserProfileRouterOptions);
    UserProfileRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageUserProfile),
    }).$mount("#page-user-profile");
  }
  if (document.getElementById("page-stop-service")) {
    const router = new VueRouter(StopServiceRouterOptions);
    StopServiceRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageStopService),
    }).$mount("#page-stop-service");
  }
  if (document.getElementById("page-transit-login")) {
    new Vue({
      store: DataStore,
      render: h => h(PageTransitLogin),
    }).$mount("#page-transit-login");
  }
  if (document.getElementById("page-not-authorized")) {
    new Vue({
      store: DataStore,
      render: h => h(PageNotAuthorized),
    }).$mount("#page-not-authorized");
  }
  if (document.getElementById("page-forgot-password")) {
    new Vue({
      store: DataStore,
      render: h => h(PageForgotPassword),
    }).$mount("#page-forgot-password");
  }
  if (document.getElementById("page-reset-password")) {
    new Vue({
      store: DataStore,
      render: h => h(PageResetPassword),
    }).$mount("#page-reset-password");
  }
  if (document.getElementById("page-forgot-username")) {
    new Vue({
      store: DataStore,
      render: h => h(PageForgotUsername),
    }).$mount("#page-forgot-username");
  }
  if (document.getElementById("page-create-account")) {
    new Vue({
      store: DataStore,
      render: h => h(PageCreateAccount),
    }).$mount("#page-create-account");
  }
  if (document.getElementById("page-paperless-enroll")) {
    new Vue({
      store: DataStore,
      render: h => h(PagePaperlessEnroll),
    }).$mount("#page-paperless-enroll");
  }
  if (document.getElementById("page-verify-contact")) {
    new Vue({
      store: DataStore,
      render: h => h(PageVerifyContact),
    }).$mount("#page-verify-contact");
  }
  if (document.getElementById("page-outage-center")) {
    const router = new VueRouter(OutageCenterRouterOptions);
    OutageCenterRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageOutageCenter),
    }).$mount("#page-outage-center");
  }
  if (document.getElementById("page-report-outage")) {
    const router = new VueRouter(ReportOutageRouterOptions);
    ReportOutageRouterInit(router);
    new Vue({
      router,
      store: DataStore,
      render: h => h(PageReportOutage),
    }).$mount("#page-report-outage");
  }
  if (document.getElementById("widget-disconnect-banner")) {
    new Vue({
      store: DataStore,
      render: h => h(WidgetDisconnectBanner),
    }).$mount("#widget-disconnect-banner");
  }
  if (document.getElementById("page-ghosting-search")) {
    new Vue({
      store: DataStore,
      render: h => h(PageGhostingSearch),
    }).$mount("#page-ghosting-search");
  }

})().catch(err => {
  DumpError("Vue initialization error", err);
});
