<template>
  <div class="usage-detail__table-container">
    <div v-if="loadState === undefined" class="table-loading"></div>
    <div v-if="loadState === 'empty'" class="usage-detail__error-container">No daily historical usage data available.
    </div>
    <div v-if="loadState == 'error'" class="usage-detail__error-container">An unexpected error occurred trying to read
      daily historical usage data, please try again
      later.</div>
    <div v-if="loadState === 'complete'" class="usage-detail-table__hourly-container">
      <div class="usage-detail__hourly-heading-row">
        <div :class="usageHeadingCellClass">Time</div>
        <div v-if="hasGenerationRecordsPresent" :class="usageHeadingCellClass">Net (kWh)</div>
        <div :class="usageHeadingCellClass">Usage (kWh)</div>
        <div v-if="hasGenerationRecordsPresent" :class="usageHeadingCellClass">Generation (kWh)</div>
        <div :class="usageHeadingCellClass">Temperature</div>
        <div :class="usageHeadingCellClass">Conditions</div>
      </div>
      <div v-for="usage of hourlyUsageData" :key="usage.date.getTime()">
        <div class="usage-detail__hourly-table-row">
          <span :class="[usageTableCellClass, 'usage-detail__hourly-time']"> {{ convertToTimeRange(usage.date)}}</span>
          <div v-if="(hasGenerationRecordsPresent && !hasGenerationReturned(usage))"
            class="usage-detail__empty-net-generation-cell">
          </div>
          <div v-if="hasGenerationRecordsPresent && hasGenerationReturned(usage)" :class=usageTableCellClass> 
            <span :class="[returnedGenerationClass, 'usage-detail__returned-amount']">{{ generationReturned(usage.returnedGeneration) }} </span>
          </div>
          <div v-if="hasTou(usage) || hasConsumption(usage)" :class=usageTableCellClass>
            <usage-bar 
              :barType="determineBarType(usage)" 
              :consumedAmount="roundAmountSingleDecimal(usage.consumedTotal)"
              :offPeakAmount="roundAmountSingleDecimal(usage.offPeak)" 
              :onPeakAmount="roundAmountSingleDecimal(usage.onPeak)" 
              :showTouDetails="false"
              :parentContainerSize="maxContainerSize"
              :usageDate="usage.date"
              :maxUsageValue="maxUsageValue()">
            </usage-bar>
          </div>
          <div v-if="hasGenerationRecordsPresent && !hasGeneration(usage)"
            class="usage-detail__empty-hourly-generation-cell"></div>
          <div v-if="hasGeneration(usage)" :class=usageTableCellClass>
            <usage-bar 
              :barType="'generation'" 
              :generationAmount="usage.generation"
              :parentContainerSize="maxContainerSize" 
              :maxUsageValue="maxUsageValue()">
            </usage-bar>
          </div>
          <span :class="usageTableCellClass">{{ getTemperature(usage).avg ? getTemperature(usage).avg + '\u00B0F' : "" }}</span>
          <div :class="usageTableCellClass">
            <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="usage-detail__weather-image"
              :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, getHours, parseISO, addMinutes } from "date-fns";
import { ToServerDateTruncate, DumpError } from "../../../../utilities";
import GMPAPI from "../../../../services/gmpapi";
import UsageBar from "../usageshared/UsageBar";
import usageTableUtil from "../../../mixins/UsageTableUtil";

export default {
  name: "UsageDetailHourlyTable",
  mixins: [usageTableUtil],
  components: {
    UsageBar
  },
  props: {
    barType: String,
    usageDate: Date,
    parentBarType: String,
    maxContainerSize: Number,
    hasGenerationRecordsPresent: Boolean,
  },
  data() {
    return {
      hourlyUsageData: undefined,
      loadState: undefined,
      usageContainerSizePercent: .3,
      usageHeadingCellClass: undefined,
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined,
      returnedGenerationClass: undefined
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    const accountNumber = this.currentAccount.accountNumber;
    const endDate = this.usageDate;
    const startDate = this.usageDate;
    await this.getData(accountNumber, startDate, endDate);
    this.setStyles();
  },
  methods: {
    async getData(accountNumber, startDate, endDate) {
      try {
        this.loadState = undefined;
        this.$emit('daily-usage-read', 'loading');
        this.hourlyUsageData = await this.getHourlyData(accountNumber, ToServerDateTruncate(startDate), ToServerDateTruncate(startDate));
        if (!this.hourlyUsageData || !this.hourlyUsageData.length) {
          return;
        }
        this.loadState = 'complete';
        this.$emit('daily-usage-read', 'complete');
      } catch (err) {
        this.loadState = 'error';
        DumpError("Error getting hourly usage data.", err);
      }
    },
    async getHourlyData(accountNumber, startDate, endDate) {
      const parsedItemDateUTCStart = parseISO(startDate);
      const parsedItemDateUTCEnd = parseISO(endDate);

      const response = await GMPAPI.GetPeriodUsage(accountNumber, 'hourly', ToServerDateTruncate(parsedItemDateUTCStart), ToServerDateTruncate(parsedItemDateUTCEnd));
      if (!response || !response.intervals || !response.intervals.length || !response.intervals[0].values || !response.intervals[0].values.length) {
        this.loadState = 'empty';
        return [];
      }

      const hourlyUsageData = response.intervals[0].values;
      const hourlyUsageWithWeatherIcon = hourlyUsageData.map(item => {
        if (item && (item.temperature || item.darkskyTemperature)) {
          return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon) };
        }
        return { ...item, weatherIconImageUrl: "" };
      });
      return hourlyUsageWithWeatherIcon;
    },
    maxUsageValue() {
      const usageValues = this.hourlyUsageData.map(data => data.consumedTotal);
      return Math.max(...usageValues);
    },
    setStyles() {
      if (this.hasGenerationRecordsPresent) {
        this.usageHeadingCellClass = 'usage-detail__hourly-heading-generation';
        this.usageTableCellClass = 'usage-detail__hourly-generation-table-cell';
        this.usageTableEmptyCellClass = 'usage-detail__empty-generation-cell'
      } else {
        this.usageHeadingCellClass = 'usage-detail__hourly-heading-flat-rate';
        this.usageTableCellClass = 'usage-detail__hourly-table-cell';
        this.usageTableEmptyCellClass = 'usage-detail__empty-flat-rate-cell'
      }

      if (this.hourlyUsageData.returnedGeneration < 0) {
        this.returnedGenerationClass = 'usage-detail__negativeReturnedGenerationClass';
      } else {
        this.returnedGenerationClass = 'usage-detail__positiveReturnedGenerationClass';
      }
    },
    getDayOfWeek(date) {
      return format(date, 'EEEE');
    },
    convertToTimeRange(date) {
      const militaryHour = getHours(date);
      const hourStart = militaryHour;
      const hourEnd = (militaryHour + 1) % 24;

      const startDate = new Date();
      startDate.setHours(hourStart, 0, 0, 0);

      const endDate = new Date();
      endDate.setHours(hourEnd, 0, 0, 0);

      const timeRange = format(startDate, 'haaa') + '-' + format(endDate, 'haaa');
      return timeRange;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>