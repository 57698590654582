<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail">
      <div class="usage-detail-mobile__modal-nav-select__header--date-picker">
        <span class="usage-detail-mobile__modal-nav-select__title">{{modalTitle}}</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="usage-detail-mobile__modal__body">
          <div>
            <fieldset class="gds-fieldset">
              <label
              :key="i"
              v-for="(option, i) in config.options"
              class="radio-label-flex gds-radio"
              :class="{'selected': optionIsSelected(option.value, selectedOption)}">
                <input @change="handleOptionClick" v-model="selectedOption" type="radio" :value="option.value" />
                <span class="gds-radio__faux"></span>
                <span class="gds-radio__label radio-label">
                  {{option.label}}
                </span>
              </label>
            </fieldset>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageDetailSelectMobileModal",
  props: {
    config: Object,
    modalTitle: String,
    datePickerDateType: String,
    closeModal: Function,
    selectOption: Function,
    datePickerDefaultDate: Date
  },
  data() {
    return {
      selectedOption: this.config.selectedValue || 'daily'
    };
  },
  methods: {
    handleOptionClick() {
      this.closeModal();
      this.selectOption(this.selectedOption);
    },
    optionIsSelected(optionValue, selectedOption) {
      return optionValue === selectedOption;
    }
  }
};
</script>

<style scoped>
</style>