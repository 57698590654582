<template>
  <span>
    <button :disabled="disabled"  @click="goToToday()" title="Go to today"
    v-bind:style="{ width: buttonWidth || defaultButtonWidth }"
    class="date-navigation__button date-navigation__button__calendar__text-added">
      <img class="back-to-today-icon enabled" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-back-to-today.svg" />
      <img class="back-to-today-icon disabled" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-back-to-today-disabled.svg" />
      <span class="date-navigation__button__calendar__label">Go To Today</span>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonGoToToday",
  props: {
    goToToday: Function,
    disabled: Boolean,
    buttonWidth: String,
  },
  data() {
    return {
      defaultButtonWidth: '10rem',
    };
  },
};
</script>

<style scoped>
</style>