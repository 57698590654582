var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "usageHistoryContainer", staticClass: "daily-usage-history" },
    [
      _c("div", { staticClass: "usage-dashboard__headings--in-boxes" }, [
        _vm._v("Past 7 Days")
      ]),
      _vm._v(" "),
      _c("a", {
        staticClass: "usage-dashboard__circle-arrow-right",
        attrs: { href: "/account/usage-new/detail/table" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "daily-usage-history__data-container" }, [
        _vm.loadState === "complete"
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "daily-usage-history__table-heading-row" },
                  [
                    _c("div", { class: _vm.usageHeadingCellClass }, [
                      _vm._v("Date")
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.usageHeadingCellClass }, [
                      _vm._v("Usage")
                    ]),
                    _vm._v(" "),
                    _vm.hasGenerationRecordsPresent
                      ? _c("div", { class: _vm.usageHeadingCellClass }, [
                          _vm._v("Generation")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { class: _vm.usageHeadingCellClass }, [
                      _vm._v("Conditions")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "daily-usage-history__horizontal-line"
                }),
                _vm._v(" "),
                _vm._l(_vm.dailyUsageData, function(usage, index) {
                  return _c(
                    "div",
                    { key: usage.date.getTime() },
                    [
                      _c("Widget-Daily-Usage-History-Table-Row", {
                        staticClass: "daily-usage-history__table-row",
                        attrs: {
                          usage: usage,
                          hasGenerationRecordsPresent:
                            _vm.hasGenerationRecordsPresent,
                          maxUsageValue: _vm.maxUsageValue
                        }
                      }),
                      _vm._v(" "),
                      index != _vm.dailyUsageData.length - 1
                        ? _c("div", {
                            staticClass: "daily-usage-history__horizontal-line"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.loadState
        ? _c("div", { staticClass: "daily-usage-history__spinner" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "unavailable"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("Usage information is not available for this account.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("An unexpected error occurred, please try again later.")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }