<template>
  <div class="usage-dashboard__widget-net-metering" ref="container" style="position: relative;">
    <div class="usage-dashboard__widget-net-metering--heading-container">
      <div class="usage-dashboard__widget-current-usage--text--grey-small">Current Net Usage this billing period</div>
      <div class="usage-dashboard__widget-net-metering--text--largest">{{ currentNetUsage }} kWh</div>
      <a href="/account/usage-new/detail/performance"
        class="usage-dashboard__widget-net-metering--circle-arrow-right"></a>
    </div>

    <img class="usage-dashboard__widget-net-metering-gmp-logo"
      src="/wp-content/themes/gmptwentynineteen/assets/images/net-metering/gmp-logo.svg" />
    <div class="usage-dashboard__widget-net-metering--totals-row">
      <div class="usage-dashboard__widget-net-metering--total-generation">
        <div class="usage-dashboard__widget-net-metering--text--large green">{{ totalGeneration }} kWh</div>
        <div class="usage-dashboard__widget-net-metering--text--grey-small">Total Generation</div>
      </div>
      <div class="usage-dashboard__widget-net-metering--total-usage">
        <div class="usage-dashboard__widget-net-metering--text--large orange">{{ totalUsage }} kWh</div>
        <div class="usage-dashboard__widget-net-metering--text--grey-small">Total Usage</div>
      </div>
    </div>
    <div class="usage-dashboard__widget-net-metering--totals-column">
      <div class="usage-dashboard__widget-net-metering--generation-returned">
        <div class="usage-dashboard__widget-net-metering--text--small">{{ generationReturnedToGrid }} kWh</div>
        <div class="usage-dashboard__widget-net-metering--text--grey-small">Generation Returned to Grid</div>
      </div>
      <div class="usage-dashboard__widget-net-metering--total-usage">
        <div class="usage-dashboard__widget-net-metering--text--small">{{ energyFromGrid }} kWh</div>
        <div class="usage-dashboard__widget-net-metering--text--grey-small">Energy from Grid</div>
      </div>
    </div>

    <div class="usage-dashboard__widget-net-metering--generation-to-home">
      <div class="usage-dashboard__widget-net-metering--text--small">{{ generationToHome }} kWh</div>
      <div class="usage-dashboard__widget-net-metering--text--grey-small">Generation To Home</div>
    </div>

    <div class="usage-dashboard__widget-net-metering--battery-saver-note-desktop">* Your usage data may be slightly off if you have a connected battery back-up.</div>

    <!-- HOUSE SVG -->
    <svg id="house" class="usage-dashboard__widget-net-metering--desktop-resizable-svg" :width="svgWidth"
      :height="svgHeight" viewBox="0 0 1125 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M752.387 336.192H809.843V302.057H752.387V336.192Z" fill="white"
        stroke="#202020" stroke-opacity="0.1" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M782.382 314.004C778.882 314.004 776.045 316.87 776.045 320.405C776.045 320.559 776.057 320.71 776.068 320.862H776.045V336.192H788.719V320.862H788.697C788.707 320.71 788.719 320.559 788.719 320.405C788.719 316.87 785.882 314.004 782.382 314.004Z"
        fill="#FD872E" />
      <rect x="759.146" y="271.335" width="9.29441" height="8.53391" fill="#22395B" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M830.122 303.764H747.317L750.697 279.015H830.122V303.764Z"
        fill="#22395B" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M834.347 271.761L805.196 301.203L807.308 303.764L834.347 276.455L861.385 303.764L863.497 301.63L834.347 271.761Z"
        fill="#22395B" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M834.347 276.455L808.153 303.764H808.998V336.192H859.695V303.764H860.54L834.347 276.455Z" fill="white"
        stroke="#202020" stroke-opacity="0.1" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M824.207 327.659H844.486V325.098H824.207V327.659Z"
        fill="#FD872E" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M802.661 303.764L800.53 310.096L797.749 319.211L793.789 336.192H808.153V303.764L802.661 303.764Z"
        fill="#231F20" fill-opacity="0.1" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M833.924 300.777C836.491 300.777 838.571 298.675 838.571 296.083C838.571 293.491 836.491 291.389 833.924 291.389C831.358 291.389 829.277 293.491 829.277 296.083C829.277 298.675 831.358 300.777 833.924 300.777Z"
        fill="#BAE0F5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M825.897 325.098H842.796V308.031H825.897V325.098Z"
        fill="#BAE0F5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M759.146 319.125H768.441V309.737H759.146V319.125Z"
        fill="#BAE0F5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M792.944 319.125H802.239V309.737H792.944V319.125Z"
        fill="#BAE0F5" />
    </svg>

    <!-- Solar Panel SVG -->
    <svg id="solarPanel" class="usage-dashboard__widget-net-metering--desktop-resizable-svg" :width="svgWidth"
      :height="svgHeight" viewBox="0 0 1125 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M449.674 269L447.895 273.643L444.461 272.478L443.421 275.837L438.473 274.493L439.87 279.254L436.381 280.255L437.591 283.561L432.767 285.272L436.805 288.334L434.593 291.118L437.591 293.108L434.733 297.221L439.87 297.414L439.781 300.918L443.421 300.832L443.622 305.776L447.895 303.026L449.963 305.911L452.855 303.782L456.037 307.669L457.815 303.026L461.25 304.191L462.289 300.832L467.237 302.176L465.84 297.414L469.33 296.413L468.12 293.108L472.944 291.397L468.906 288.334L471.118 285.551L468.12 283.561L470.977 279.447L465.84 279.254L465.93 275.751L462.289 275.837L462.089 270.893L457.815 273.643L455.748 270.758L452.855 272.887L449.674 269Z"
        fill="#F8BF97" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M462.717 288.334C462.717 293.576 458.301 297.825 452.855 297.825C447.409 297.825 442.994 293.576 442.994 288.334C442.994 283.092 447.409 278.843 452.855 278.843C458.301 278.843 462.717 283.092 462.717 288.334Z"
        fill="#FD872E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M511.122 331.883H430.226L454.6 292.351H535.497L511.122 331.883Z"
        fill="#2C60B3" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M481.121 292.351H479.112L456.087 331.808L456.227 331.883H458.052L481.121 292.351Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M509.832 292.351H507.824L484.813 331.808L484.952 331.883H486.777L509.832 292.351Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M511.122 331.883H430.226L454.6 292.351C454.6 292.351 463.419 299.01 479.254 309.594C487.343 315.001 511.122 331.883 511.122 331.883Z"
        fill="white" fill-opacity="0.1" />
      <path d="M429 336.353H513.076L539 294.207" stroke="#B8BBBF" stroke-width="1.5" />
    </svg>

    <!-- Curved Line SVG -->
    <svg id="curvedLine" class="usage-dashboard__widget-net-metering--desktop-resizable-svg" :width="svgWidth"
      :height="svgHeight" viewBox="0 0 1125 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M285 400H787C804.673 400 819 385.673 819 368V368" stroke="#A7A7A7" stroke-width="3" />
      <path d="M287.5 400L277.5 409L277.5 391L287.5 400Z" fill="#A7A7A7" />
      <path d="M819 360L828 372L810 372L819 360Z" fill="#A7A7A7" />
    </svg>

    <!-- Left Line SVG -->
    <svg id="leftPointingLine" class="usage-dashboard__widget-net-metering--desktop-resizable-svg" :width="svgWidth"
      :height="svgHeight" viewBox="0 0 1125 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m285 319H407C407.552 319 408 319.448 408 320V320" stroke="#A7A7A7" stroke-width="3" />
      <path d="m277.5 318 L287.5 309 L287.5 327 L277.5 318Z" fill="#A7A7A7" />
      <path d="m402 319 L412 310 L412 328 L402 319Z" fill="#A7A7A7" />
    </svg>

    <!-- Right Line SVG -->
    <svg id="rightPointingLine" class="usage-dashboard__widget-net-metering--desktop-resizable-svg" :width="svgWidth"
      :height="svgHeight" viewBox="0 0 1125 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M544 318H711C711.552 318 712 318.448 712 319V319" stroke="#A7A7A7" stroke-width="3" />
      <path d="M554 318L544 327L544 309L554 318Z" fill="#A7A7A7" />
      <path d="M722 318L710 327L710 309L722 318Z" fill="#A7A7A7" />
    </svg>

    <widget-switcher
      v-if="FF_NetMeteredDashboard && currentAccount.solarNetMeter"
      currentWidget="Net Metering"
      :options="widgetOptions"
      @handle-option-click="handleOptionClick" />
  </div>
</template>

<script>
import WidgetNetMeteringMixin from "./WidgetNetMeteringMixin";
import NetMeteringMixin from "./NetMeteringMixin";
import WidgetSwitcher from "./WidgetSwitcher.vue";

export default {
  name: "WidgetNetMeteringDesktop",
  components: { WidgetSwitcher },
  mixins: [
    WidgetNetMeteringMixin,
    NetMeteringMixin
  ],
  async mounted() {
    this.updateSvgWidth();
    window.addEventListener('resize', this.updateSvgWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSvgWidth);
  },
  methods: {
    updateSvgWidth() {
      if (this.containerElement) {
        this.svgWidth = (this.containerElement.clientWidth / 100) * 150;
        this.svgHeight = (this.containerElement.clientHeight / 100) * 150;
      }
    },
  },
};
</script>
