var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--latest-bill"
    },
    [
      _vm.loadState === "complete" && _vm.latestBill && !_vm.isAdminLite
        ? _c(
            "a",
            {
              staticClass:
                "top-banner-card gds-card gds-background-blue gds-invert top-banner-card--logged-in",
              attrs: { href: _vm.latestBill.url, target: "_blank" }
            },
            [
              _c("div", { staticClass: "gds-card__contents" }, [
                _c("p", { staticClass: "gds-card__caption" }, [
                  _vm._v("Latest Bill")
                ]),
                _vm._v(" "),
                _vm.latestBill.date
                  ? _c("h3", { staticClass: "gds-card__title" }, [
                      _vm._v(
                        "View your last bill from " +
                          _vm._s(_vm._f("dateFull")(_vm.latestBill.date))
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.latestBill.date
                  ? _c("h3", { staticClass: "gds-card__title" }, [
                      _vm._v("View your last bill")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"
                })
              ])
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "top-banner-card gds-card gds-background-blue gds-invert top-banner-card--logged-in",
              class: { "gds-loading": _vm.loadState === undefined }
            },
            [
              _c("div", { staticClass: "gds-card__contents" }, [
                _c("p", { staticClass: "gds-card__caption" }, [
                  _vm._v("Latest Bill")
                ]),
                _vm._v(" "),
                _vm.loadState === "complete" && !_vm.latestBill
                  ? _c("h3", { staticClass: "gds-card__title" }, [
                      _vm._v("No bill has been recorded yet.")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c(
                      "div",
                      { staticClass: "card-error card-error--trans-white" },
                      [
                        _vm._v("\n        Unable to load,\n        "),
                        _c("br"),
                        _vm._v("please try again later.\n      ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c("div", { staticClass: "card-error-layout-blank" }, [
                      _vm._v(" ")
                    ])
                  : _vm._e()
              ])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }