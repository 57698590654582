<template>
  <div class="usage-detail__container">
    <banner-dual-action
      class="gds-flex-container"
      v-if="testDriveUsage" bannerColor="blue"
      message='You are trying out our new usage section. We would love to hear what you think: '
      messageTwo="or you can: "
      linkOneText="Provide Feedback"
      @linkOneClicked="showProvideFeedBackModal"
      linkTwoText="Switch Back to the Old Usage View"
      @linkTwoClicked="goToLegacyUsage"
    />
    <UsageDetailMobileNav v-if="isMobbileView || isTabletView" />
    <div v-if="isDesktopView" class="usage-detail__nav-container">
      <a href="/account/usage-new" class="usage-detail__usage-detail-nav-back-button"></a>
      <div class="usage-detail__router-link-container">
        <router-link to="/graph" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg" />
            <span class="usage-detail__nav-item__text">Graph</span>
          </nav>
        </router-link>
        <router-link to="/table" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg" />
            <span class="usage-detail__nav-item__text">Table</span>
          </nav>
        </router-link>
        <router-link to="/time-of-day" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg" />
            <span class="usage-detail__nav-item__text">Time Of Day</span>
          </nav>
        </router-link>
        <router-link to="/seasonal" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg" />
            <span class="usage-detail__nav-item__text">Seasonal</span>
          </nav>
        </router-link>
        <!--
        <router-link to="/heating-cooling" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling.svg" />
            Heating/Cooling
          </div>
        -->
        <!-- </router-link> -->
        <router-link to="/performance" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg" />
            <span class="usage-detail__nav-item__text">Performance</span>
          </nav>
        </router-link>
        <router-link to="/compare" class="usage-detail__nav-item">
          <nav class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg" />
            <span class="usage-detail__nav-item__text">Compare</span>
          </nav>
        </router-link>
      </div>
    </div>
    <router-view />
    <modal-floating-feedback :visible="showFeedback" @close="showFeedback = false" />
  </div>
</template>

<script>
import MediaQueryMixin from "../../mixins/MediaQueryMixin";
import UsageDetailMobileNav from "./usagedetailmobile/UsageDetailMobileNav";
import BannerDualAction from "../../BannerDualAction.vue";
import ModalFloatingFeedback from "../../ModalFloatingFeedback.vue";
import GMPAPI from '../../../services/gmpapi';

export default {
  name: "UsageDetail",
  components: {
    UsageDetailMobileNav,
    BannerDualAction,
    ModalFloatingFeedback
  },
  mixins: [
    MediaQueryMixin
  ],
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    testDriveUsage() {
      return this.$store.state.user.testDriveUsage
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    }
  },
  data() {
    return {
      showFeedback: false
    }
  },
  methods: {
    async goToLegacyUsage() {
      this.$store.commit("setTestDriveUsage", false);
      window.location.href = '/account/usage';

      await GMPAPI.SubmitCSSLogging(this.currentAccount.accountNumber, this.$store.state.user.userinfo.username, "SWITCH_TO_LEGACY_USAGE", "Switched back to legacy usage.", true);
    },
    showProvideFeedBackModal() {
      this.showFeedback = true;
    },
  }
};
</script>
