<template>
  <section class="my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l">
    <h2 class="gds-display-1 gds-space-stack-ml">Username</h2>
    <div>
      <div class="gds-font-size-m gds-space-stack-ml gds-text-grey">{{userInfo.username}}</div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WidgetProfileUsername",
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>