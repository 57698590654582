<template>
  <div>
    <div v-if="loadState === undefined" class="table-loading" />
    <div v-if="loadState === 'error'">
      <flow-error name="My Account" phonetext="To report an outage or make a payment by phone call us at"
        state="error" />
    </div>
    <div v-if="loadState === 'maintenance'">
      <flow-error name="My Account" phonetext="To report an outage or make a payment by phone call us at"
        state="maintenance" />
    </div>
    <div v-if="loadState === 'complete'" class="my-account__container gds-flex-container gds-flex-container--left">
      <div class="my-account__sidebar-plus-account-panel">
        <global-account-panel></global-account-panel>
        <widget-my-account-sidebar></widget-my-account-sidebar>
      </div>

      <div id="my-account__dashboard" class="my-account__dashboard">
        <div class="my-account__sidebar__nav--mobile gds-flex-container gds-flex-container--space-between">
          <div class="my-account__mobile-floating-title">My Account</div>
          <button @click="ToggleAccountMenu" class="my-account__sidebar__trigger--mobile caret caret-down">
            <span v-if="subPage === ''">Dashboard</span>
            <span v-if="subPage === '/billing'">Billing &amp; Payment</span>
            <span v-if="subPage === '/netmetering'">Net Metering</span>
            <span v-if="subPage === '/usage' || subPage === '/usage-new'">Usage</span>
            <span v-if="subPage === '/notifications'">Notifications</span>
            <span v-if="subPage === '/settings'">Account Settings</span>
          </button>
          <div v-if="showMenu" v-click-outside="CloseAccountMenu" class="my-account__sidebar__nav--mobile-inner-wrap">
            <widget-my-account-nav></widget-my-account-nav>
          </div>
        </div>
        <!-- The router-view is where the current subpage is displayed -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalAccountPanel from "../GlobalAccountPanel";
import WidgetMyAccountSidebar from "./WidgetMyAccountSidebar";
import WidgetMyAccountNav from "./WidgetMyAccountNav";
import { GetComponentStatus } from '../../services/statuspage';

export default {
  name: "PageMyAccount",
  components: {
    GlobalAccountPanel,
    WidgetMyAccountSidebar,
    WidgetMyAccountNav,
  },
  data() {
    return {
      showMenu: false,

      loadState: undefined,
    };
  },
  watch: {
    $route(to, from) {
      this.showMenu = false;
    }
  },
  computed: {
    subPage() {
      if (this.$route.matched.length) {
        return this.$route.matched[0].path;
      }
      return "";
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    this.loadState = undefined;
    // Check for service outages
    const status = await GetComponentStatus("MyAccount");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    ToggleAccountMenu() {
      this.showMenu = !this.showMenu;
    },
    CloseAccountMenu() {
      this.showMenu = false;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>