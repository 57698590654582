var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--outages-near-you"
    },
    [
      _c("div", { staticClass: "top-banner-card gds-card " }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "gds-card__contents" }, [
          _c("p", { staticClass: "gds-display-0 gds-space-stack-s" }, [
            _vm._v(
              "Need help? Chat with our team to get answers to your questions."
            )
          ]),
          _vm._v(" "),
          _vm.queueOnline
            ? _c(
                "button",
                {
                  staticClass:
                    "gds-button gds-card__button gds-align--block-center gds-round gds-invert gds-button-full",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.StartWebchat()
                    }
                  }
                },
                [_vm._v("Start Chat")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.queueOnline
            ? _c(
                "button",
                {
                  staticClass:
                    "gds-button gds-card__button gds-align--block-center gds-round gds-secondary gds-button-full",
                  attrs: { disabled: "", type: "button" }
                },
                [_vm._v("Chat is offline")]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "figure",
      { staticClass: "gds-card__image-container gds-aspect-silver" },
      [
        _c("div", { staticClass: "gds-card__image" }, [
          _c("img", {
            staticClass: "gds-card__image",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/webchat-card-header.jpg",
              alt: "Web Chat"
            }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }