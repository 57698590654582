<template>
  <form class="mailing_address_form" @submit.prevent v-if="editedAddress">
    <fieldset class="gds-fieldset gds-space-stack-s">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Country</span>
        <select v-model="editedAddress.country" @change="ChangeCountry()" class="bill-pay-input-field__select gds-input-field">
          <option value="USA">United States</option>
          <option value="CAN">Canada</option>
          <option value="FOR">Foreign</option>
        </select>
      </label>
    </fieldset>
    <fieldset class="gds-fieldset gds-space-stack-s">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Street Address</span>
        <input
          v-model="editedAddress.addressLine1"
          @input="$emit('touched', true)"
          v-validate="'required'"
          name="street address"
          key="street address"
          class="gds-input-field__input"
          type="text"
          placeholder="Type Here..."
        />
      </label>
      <div v-if="errors.first('street address')" class="validation-error">{{ errors.first("street address")}}</div>
    </fieldset>
    <fieldset class="gds-fieldset gds-space-stack-s">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Apt / Suite</span>
        <input v-model="editedAddress.addressLine2" @input="$emit('touched', true)" class="gds-input-field__input" type="text" placeholder="Type Here..." />
      </label>
    </fieldset>
    <fieldset v-if="editedAddress.country === 'FOR'" class="gds-fieldset gds-space-stack-s">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Line 3</span>
        <input v-model="editedAddress.addressLine3" @input="$emit('touched', true)" class="gds-input-field__input" type="text" placeholder="Type Here..." />
      </label>
    </fieldset>
    <fieldset v-if="editedAddress.country === 'FOR'" class="gds-fieldset gds-space-stack-s">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Line 4</span>
        <input v-model="editedAddress.addressLine4" @input="$emit('touched', true)" class="gds-input-field__input" type="text" placeholder="Type Here..." />
      </label>
    </fieldset>

    <div v-if="editedAddress.country !== 'FOR'" class="gds-flex-container gds-flex-container--left gds-space-stack-l">
      <fieldset class="gds-fieldset gds-space-inline-default">
        <label class="gds-input-field">
          <span class="gds-input-field__label">City</span>
          <input
            v-model="editedAddress.city"
            @input="$emit('touched', true)"
            v-validate="'required'"
            name="city"
            key="city"
            class="gds-input-field__input"
            type="text"
            placeholder="Type Here..."
          />
        </label>
      </fieldset>
      <fieldset v-if="editedAddress.country === 'USA'" class="my-account__settings--detail-state gds-fieldset gds-space-stack-s gds-space-inline-default">
        <label class="gds-input-field">
          <span class="gds-input-field__label">State</span>
          <select v-model="editedAddress.state" @change="$emit('touched', true)" class="bill-pay-input-field__select gds-input-field">
            <option v-for="state of stateList" :key="state" :value="state">{{state}}</option>
          </select>
        </label>
      </fieldset>
      <fieldset v-if="editedAddress.country === 'CAN'" class="my-account__settings--detail-state gds-fieldset gds-space-stack-s gds-space-inline-default">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Province</span>
          <select v-model="editedAddress.state" @change="$emit('touched', true)" class="bill-pay-input-field__select gds-input-field">
            <option v-for="province of provinceList" :key="province" :value="province">{{province}}</option>
          </select>
        </label>
      </fieldset>
      <fieldset v-if="editedAddress.country === 'USA'" class="my-account__settings--detail-zip gds-fieldset gds-space-stack-s gds-space-inline-default">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Zip Code</span>
          <the-mask
            v-model="editedAddress.zip"
            :mask="'#####-####'"
            @input.native="$emit('touched', true)"
            v-validate="'required|min:5'"
            data-vv-validate-on="input"
            name="zip code"
            key="zip code"
            class="gds-input-field__input"
            type="tel"
          />
        </label>
      </fieldset>
      <fieldset v-if="editedAddress.country === 'CAN'" class="my-account__settings--detail-zip gds-fieldset gds-space-stack-s gds-space-inline-default">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Postal Code</span>
          <the-mask
            v-model="editedAddress.zip"
            :mask="'S#S #S#'"
            @input.native="$emit('touched', true)"
            v-validate="'required|min:6'"
            data-vv-validate-on="input"
            name="postal code"
            key="postal code"
            class="gds-input-field__input"
            type="text"
          />
        </label>
      </fieldset>
    </div>
    <div v-if="errors.first('city')" class="validation-error">{{ errors.first("city")}}</div>
    <div v-if="errors.first('zip code')" class="validation-error">{{ errors.first("zip code")}}</div>
    <div v-if="errors.first('postal code')" class="validation-error">{{ errors.first("postal code")}}</div>
    <modal-address-verify ref="modal" @save="SelectAddress" />
  </form>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { GetMailingAddressFromVerified } from "../../../utilities";
import ModalAddressVerify from "./ModalAddressVerify";

export default {
  name: "InlineMailingAddress",
  props: ["address"],
  data() {
    return {
      editedAddress: undefined,

      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"],
    };
  },
  components: {
    ModalAddressVerify,
  },
  computed: {
  },
  async mounted() {
    this.BeginEdit();
  },
  methods: {
    BeginEdit() {
      if (this.address) {
        // Copy current value to editor
        this.editedAddress = {
          addressLine1: this.address.addressLine1,
          addressLine2: this.address.addressLine2,
          addressLine3: this.address.addressLine3,
          addressLine4: this.address.addressLine4,
          city: this.address.city,
          country: this.address.country,
          state: this.address.state,
          zip: this.address.zip,
        };
      }
      this.$validator.resume();
      this.$emit("touched", false);
    },
    ChangeCountry() {
      // During the field switches, the validator gets confused. Turn it off for a moment.
      this.$validator.pause();
      this.$emit("touched", true);
      // If we switch countries, don't leave the state/province picker blank
      if (this.editedAddress && this.editedAddress.country === "USA" && !this.stateList.includes(this.editedAddress.state)) {
        this.editedAddress.state = this.stateList[0];
      }
      if (this.editedAddress && this.editedAddress.country === "CAN" && !this.provinceList.includes(this.editedAddress.state)) {
        this.editedAddress.state = this.provinceList[0];
      }
      // Resume validating
      setTimeout(() => {
        this.$validator.resume();
      }, 100);
    },
    /**
     * This gets called from the parent component, generally when save button is clicked
     * Note that errors are not trapped, responsibility is to the parent
     */
    async Verify() {
      if (await this.$validator.validateAll()) {
        if (this.editedAddress.country === "USA") {
          // For US addresses, validate through experian
          const addressString = this.$options.filters.addressFormat(this.editedAddress, { separator: ", ", mailing: true });
          const result = await GMPAPI.VerifyUSMailingAddress(addressString);
          if (result.verifylevel === "Verified") {
            // OK to save without prompting
            const address = GetMailingAddressFromVerified(result.fields)
            await this.SelectAddress(address);
          } else if (result.verifylevel === "None") {
            this.$refs.modal.Show(undefined, this.editedAddress);
          } else if (result.verifylevel === "InteractionRequired") {
            this.$refs.modal.Show([result], this.editedAddress);
          } else {
            this.$refs.modal.Show(result.results, this.editedAddress);
          }
        } else {
          // CAN or foreign addresses, just use as is
          await this.SelectAddress(this.editedAddress);
        }
      }
    },
    /** This should take a mailing-type address (addressLine1) not an experian type (fields) */
    async SelectAddress(address) {
      this.$emit("save", address);
    },
  },
  watch: {
    address() {
      this.BeginEdit();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>