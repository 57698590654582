<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail">
      <div class="modal-usage-pick-a-view__header">
        <span class="modal-usage-pick-a-view__title">Pick a View</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="modal-usage-pick-a-view__modal-body">
          <div class="modal-usage-pick-a-view__options-body">
              <div class="modal-usage-pick-a-view__options-body__button-options">
                <a href="/account/usage-new/detail/graph" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Graph</span>
                </a>
                <a href="/account/usage-new/detail/table" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Table</span>
                </a>
                <a href="/account/usage-new/detail/time-of-day" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Time / Day</span>
                </a>
                <a href="/account/usage-new/detail/seasonal" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Seasonal</span>
                </a>
                <!--
                <a href="/account/usage-new/detail/heating-cooling" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Heat / Cool</span>
                </a>
                -->
                <a href="/account/usage-new/detail/performance" class="modal-usage-pick-a-view__nav-select-button">
                    <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg">
                    <span class="modal-usage-pick-a-view__nav-select-button__label">Performance</span>
                </a>
                <a href="/account/usage-new/detail/compare" class="modal-usage-pick-a-view__nav-select-button">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg">
                  <span class="modal-usage-pick-a-view__nav-select-button__label">Compare</span>
                </a>
              </div>
              <a v-if="!isDashboard" 
                href="/account/usage-new"
                class="usage-detail-mobile__modal-nav-select-button__back-to-dashboard-link">
                Return to Usage Dashboard
              </a>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalUsagePickAView",
  props: {
    closeModal: Function,
    isDashboard: Boolean
  },
};
</script>

<style scoped>
</style>