var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--payment-plan"
    },
    [
      _vm.currentAccount
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "top-card-display__single-card top-card-display__single-card--pay-bill-loggedin"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "top-banner-card gds-card gds-data-card",
                    class: { "gds-loading": _vm.loadState === undefined }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "my-account__payment-plan-card" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm.loadState === "complete"
                          ? [
                              _vm.arrangementStatus &&
                              _vm.arrangementStatus.isEligible &&
                              !_vm.arrangementStatus.isEnrolled
                                ? _c(
                                    "p",
                                    { staticClass: "gds-card__caption" },
                                    [_vm._v("Pay off your balance over time.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-flex-container gds-space-stack-s"
                                },
                                [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _vm.arrangementStatus &&
                                  _vm.arrangementStatus.isEnrolled &&
                                  _vm.arrangementStatus.paymentsRemaining > 0 &&
                                    _vm.arrangementStatus.monthlyPayment > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "my-account__payment-plan--status"
                                        },
                                        [
                                          _vm._v(
                                            "You have " +
                                              _vm._s(
                                                _vm.arrangementStatus
                                                  .paymentsRemaining
                                              ) +
                                              " installments of " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.arrangementStatus
                                                    .monthlyPayment
                                                )
                                              ) +
                                              " remaining."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "gds-align--text-center" },
                                [
                                  _vm.arrangementStatus &&
                                  _vm.arrangementStatus.isEligible &&
                                  !_vm.arrangementStatus.isEnrolled
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "gds-card__button gds-button gds-round gds-secondary",
                                          attrs: { href: "/arrange-payment" }
                                        },
                                        [_vm._v("Set Up Payment Arrangement")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.arrangementStatus &&
                                  !_vm.arrangementStatus.isEligible &&
                                  !_vm.arrangementStatus.isEnrolled &&
                                  !_vm.arrangementStatus.ineligibleReason
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gds-text-grey gds-font-size-m"
                                        },
                                        [
                                          _vm._v(
                                            "You are not eligible for payment arrangements at this time."
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.arrangementStatus &&
                                  !_vm.arrangementStatus.isEligible &&
                                  !_vm.arrangementStatus.isEnrolled &&
                                  _vm.arrangementStatus.ineligibleReason
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gds-text-grey gds-font-size-m"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.arrangementStatus
                                                .ineligibleReason
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.arrangementStatus &&
                                  _vm.arrangementStatus.isEnrolled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gds-text-grey gds-font-size-m"
                                        },
                                        [
                                          _vm._v(
                                            "Your payment arrangement installments are included in your monthly bill."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadState === "error"
                          ? _c("div", { staticClass: "card-error" }, [
                              _vm._v(
                                "\n            Unable to load,\n            "
                              ),
                              _c("br"),
                              _vm._v("please try again later.\n          ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadState === "error"
                          ? _c(
                              "div",
                              { staticClass: "card-error-layout-blank" },
                              [_vm._v(" ")]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", { staticClass: "gds-card__title" }, [_vm._v("Payment Plan")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__data-container--large-text" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg",
          alt: " "
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }