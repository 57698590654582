var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "subpage-dashboards subpage-overview--dashboard subpage-dashboards--left-spacer"
    },
    [
      _c("widget-scheduled-payments"),
      _vm._v(" "),
      _c("section", { staticClass: "my-account__full-width-section" }, [
        _c(
          "div",
          {
            staticClass:
              "my-account__overview-double-card-wrap gds-flex-container"
          },
          [
            _c(
              "div",
              { staticClass: "my-account__blue-card-wrap" },
              [_c("card-pay-bill")],
              1
            ),
            _vm._v(" "),
            _c("card-payment-plan")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          ref: "history",
          staticClass: "my-account__full-width-section",
          attrs: { id: "history" }
        },
        [
          _c("h2", { staticClass: "gds-display-1 gds-space-stack-l" }, [
            _vm._v("Bill & Payment History")
          ]),
          _vm._v(" "),
          _c("widget-payment-history")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }