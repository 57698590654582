var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isDesktopView
      ? _c("div", [
          _c(
            "div",
            {
              class:
                _vm.expandedRow != -1
                  ? "usage-detail__table-row-expanded"
                  : "usage-detail__table-row"
            },
            [
              _c(
                "div",
                {
                  class: _vm.usageTableCellClass,
                  on: {
                    click: function($event) {
                      return _vm.toggleHourlyUsage(_vm.usage.date)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "usage-detail__usage-date" }, [
                    _vm._v(_vm._s(_vm.formatDate(_vm.usage.date, "long")))
                  ]),
                  _vm._v(" "),
                  _vm.expandedRow != -1 && _vm.hourlyUsageLoadState != "loading"
                    ? _c(
                        "div",
                        { staticClass: "usage-detail__hourly-collapse" },
                        [
                          _vm._v("\n          Showing Hourly\n          "),
                          _c(
                            "span",
                            { staticClass: "usage-detail__collapse-link" },
                            [_vm._v("Collapse")]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("span", { class: _vm.usageTableCellClass }, [
                _vm._v(_vm._s(_vm.getDayOfWeek(_vm.usage.date, "long")))
              ]),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", {
                    staticClass: "usage-detail__empty-net-generation-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", { class: _vm.usageTableCellClass }, [
                    _c(
                      "span",
                      {
                        class: [
                          _vm.returnedGenerationClass,
                          "usage-detail__returned-amount"
                        ]
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.generationReturned(
                                _vm.usage.returnedGeneration
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasTou(_vm.usage) || _vm.hasConsumption(_vm.usage)
                ? _c(
                    "div",
                    { class: _vm.usageTableCellClass },
                    [
                      _c("Usage-Bar", {
                        attrs: {
                          barType: _vm.determineBarType(_vm.usage),
                          consumedAmount: _vm.usage.consumedTotal,
                          offPeakAmount: _vm.usage.offPeak,
                          onPeakAmount: _vm.usage.onPeak,
                          showTouDetails: true,
                          maxUsageValue: _vm.maxUsageValue
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", {
                    staticClass: "usage-detail__empty-generation-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage)
                ? _c(
                    "div",
                    { class: _vm.usageTableCellClass },
                    [
                      _c("Usage-Bar", {
                        attrs: {
                          barType: "generation",
                          generationAmount: _vm.usage.generation,
                          maxUsageValue: _vm.maxUsageValue
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dailyUsagePeriodValues &&
              _vm.dailyUsagePeriodValues.length > 0
                ? _c("div", { class: _vm.usageTableCellClass }, [
                    _vm.usage.date && !_vm.isTodayOrYesterday(_vm.usage.date)
                      ? _c(
                          "div",
                          {
                            staticClass: "usage-detail__spark-chart-container"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "usage-detail__highest-usage-period"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.usage.highestUsagePeriodOfDay)
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("highest-usage-micro-sparkbar", {
                              staticClass: "usage-detail__spark-chart",
                              attrs: { data: _vm.dailyUsagePeriodValues }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", { staticClass: "usage-detail__flat-rate-cell" })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", {
                    staticClass: "usage-detail__empty-generation-tou-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", { staticClass: "usage-detail__generation-cell" })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { class: _vm.usageTableCellClass }, [
                _vm._v(
                  _vm._s(
                    _vm.getTemperature(_vm.usage).low
                      ? _vm.getTemperature(_vm.usage).low + "\u00B0F"
                      : ""
                  )
                )
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.usageTableCellClass }, [
                _vm._v(
                  _vm._s(
                    _vm.getTemperature(_vm.usage).high
                      ? _vm.getTemperature(_vm.usage).high + "\u00B0F"
                      : ""
                  )
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.usageTableCellClass }, [
                _vm.usage.weatherIcon && _vm.usage.weatherIcon != ""
                  ? _c("img", {
                      staticClass: "usage-detail__weather-image",
                      attrs: {
                        src:
                          _vm.weatherImagePath + _vm.usage.weatherIconImageUrl,
                        alt: "Weather Icon"
                      }
                    })
                  : _vm._e()
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isMobileView || _vm.isTabletView
      ? _c("div", [
          _c(
            "div",
            {
              class:
                _vm.expandedRow != -1
                  ? "usage-detail__table-row-expanded"
                  : "usage-detail__table-row"
            },
            [
              _c(
                "div",
                {
                  class: _vm.usageTableCellClass,
                  on: {
                    click: function($event) {
                      return _vm.toggleHourlyUsage(_vm.usage.date)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "usage-detail__usage-date--mobile" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.formatDate(_vm.usage.date, "short")))
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.getDayOfWeek(_vm.usage.date, "short"))
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.expandedRow != -1 && _vm.hourlyUsageLoadState != "loading"
                    ? _c(
                        "div",
                        { staticClass: "usage-detail__hourly-collapse" },
                        [
                          _vm._v("\n          Showing Hourly\n          "),
                          _c(
                            "span",
                            { staticClass: "usage-detail__collapse-link" },
                            [_vm._v("Collapse")]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", {
                    staticClass: "usage-detail__empty-net-generation-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", { class: _vm.usageTableCellClass }, [
                    _c(
                      "span",
                      {
                        class: [
                          _vm.returnedGenerationClass,
                          "usage-detail__returned-amount"
                        ]
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.generationReturned(
                                _vm.usage.returnedGeneration
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasTou(_vm.usage) || _vm.hasConsumption(_vm.usage)
                ? _c(
                    "div",
                    { class: _vm.usageTableCellClass },
                    [
                      _c("usage-bar", {
                        attrs: {
                          barType: _vm.determineBarType(_vm.usage),
                          consumedAmount: _vm.usage.consumedTotal,
                          offPeakAmount: _vm.usage.offPeak,
                          onPeakAmount: _vm.usage.onPeak,
                          showTouDetails: true,
                          maxUsageValue: _vm.maxUsageValue
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage)
                ? _c("div", {
                    staticClass: "usage-detail__empty-generation-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage)
                ? _c(
                    "div",
                    { class: _vm.usageTableCellClass },
                    [
                      _c("usage-bar", {
                        attrs: {
                          barType: "generation",
                          generationAmount: _vm.usage.generation,
                          maxUsageValue: _vm.maxUsageValue
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dailyUsagePeriodValues &&
              _vm.dailyUsagePeriodValues.length > 0
                ? _c("div", { class: _vm.usageTableCellClass }, [
                    _vm.usage.date && !_vm.isTodayOrYesterday(_vm.usage.date)
                      ? _c(
                          "div",
                          {
                            staticClass: "usage-detail__spark-chart-container"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "usage-detail__highest-usage-period"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.usage.highestUsagePeriodOfDay)
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("highest-usage-micro-sparkbar", {
                              staticClass: "usage-detail__spark-chart",
                              attrs: { data: _vm.dailyUsagePeriodValues }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", { staticClass: "usage-detail__flat-rate-cell" })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              _vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", {
                    staticClass: "usage-detail__empty-generation-tou-cell"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGenerationRecordsPresent &&
              !_vm.hasGenerationRecordProperties(_vm.usage) &&
              (!_vm.dailyUsagePeriodValues ||
                !_vm.dailyUsagePeriodValues.length)
                ? _c("span", { staticClass: "usage-detail__generation-cell" })
                : _vm._e(),
              _vm._v(" "),
              _vm.isDesktopView
                ? _c("span", { class: _vm.usageTableCellClass }, [
                    _vm._v(
                      _vm._s(
                        _vm.getTemperature(_vm.usage).low
                          ? _vm.getTemperature(_vm.usage).low + "\u00B0F"
                          : ""
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isDesktopView
                ? _c("span", { class: _vm.usageTableCellClass }, [
                    _vm._v(
                      _vm._s(
                        _vm.getTemperature(_vm.usage).high
                          ? _vm.getTemperature(_vm.usage).high + "\u00B0F"
                          : ""
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isDesktopView
                ? _c("div", { class: _vm.usageTableCellClass }, [
                    _vm.usage.weatherIcon && _vm.usage.weatherIcon != ""
                      ? _c("img", {
                          staticClass: "usage-detail__weather-image",
                          attrs: {
                            src:
                              _vm.weatherImagePath +
                              _vm.usage.weatherIconImageUrl,
                            alt: "Weather Icon"
                          }
                        })
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isDesktopView
                ? _c("div", { staticClass: "gds-flex-container" }, [
                    _c(
                      "span",
                      { staticClass: "usage-detail__mobile-conditions-cell" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getTemperature(_vm.usage).low
                              ? _vm.getTemperature(_vm.usage).low + "\u00B0F"
                              : ""
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "usage-detail__mobile-conditions-cell" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getTemperature(_vm.usage).high
                              ? _vm.getTemperature(_vm.usage).high + "\u00B0F"
                              : ""
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm.usage.weatherIcon && _vm.usage.weatherIcon != ""
                        ? _c("img", {
                            staticClass: "usage-detail__weather-image",
                            attrs: {
                              src:
                                _vm.weatherImagePath +
                                _vm.usage.weatherIconImageUrl,
                              alt: "Weather Icon"
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }