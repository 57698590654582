<template>
  <div class="top-card-display__single-card top-card-display__single-card--report-issue">
    <div v-if="!currentAccount" class="top-banner-card--logged-out">
      <a href="/report-outage"
        class="top-banner-card gds-card gds-background-forestgreen gds-invert top-banner-card--logged-out">
        <div class="gds-card__contents">
          <h3 class="home-card__title--small-margin gds-card__title">Report an Issue</h3>
          <p class="home-card__body--small-margin gds-card__body">Outage, downed wire, tree on line.</p>

          <div class="gds-card__button gds-button-circle gds-secondary gds-icon-arrow-right"></div>
        </div>
      </a>
    </div>
    <div v-if="currentAccount">
      <div class="top-banner-card gds-card gds-data-card top-banner-card--logged-in"
        :class="{ 'gds-loading': loadState === undefined }">
        <h3 class="gds-card__title">Power Status</h3>
        <template v-if="loadState === 'complete'">
          <div v-if="activeIncident">
            <p>{{ formatEtr(activeIncident) }}</p>
          </div>
          <div v-if="!activeIncident && powerSONP">
            <p class="gds-card__caption">Shut off for non&#8209;payment</p>
          </div>
          <div v-if="plannedOutage && FF_PlannedOutage">
            <p class="gds-card__caption">Planned Outage {{ plannedOutage.occursAt | dateTimeFullAt }}.</p>
          </div>
          <figure class="card__pay-bill-figure gds-card__data-container gds-flex-container">
            <div class="card__data-container--flex-container gds-flex-container">
              <div class="card__house-icon--outer-outer">
                <div class="card__house-icon--outer-inner"
                  v-bind:class="{ 'power-out': activeIncident, 'power-off': !activeIncident && (powerSONP || powerOff) }">
                  <svg class="gds-icon card__house-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-house" />
                  </svg>
                </div>
              </div>
              <div class="gds-card__data-container--status">{{ activeIncident ? "Out" : ((powerSONP || powerOff) ? "Off"
                  : "On")
              }}</div>
            </div>
          </figure>
          <a v-if="activeIncident && activeIncident.id" :href="'/outages/incident/' + activeIncident.id"
            class="gds-card__button gds-button gds-round gds-orange">
            <span class="gds-button__text">View Details</span>
          </a>
          <a v-if="(!activeIncident && !(powerSONP || powerOff)) || (activeIncident && !activeIncident.id)"
            href="/report-outage" class="account_report_outage gds-card__button gds-button gds-round gds-orange">
            <span class="gds-button__text">Report Outage</span>
          </a>
          <a v-if="!activeIncident && (powerSONP || powerOff)" href="/contact"
            class="gds-card__button gds-button gds-round gds-secondary">
            <span class="gds-button__text">Contact Us</span>
          </a>
        </template>
        <div v-if="loadState === 'error'" class="card-error card-error--smaller">
          <div class="gds-space-stack-l">
            Unable to load,
            <br />please try again later.
          </div>
          <div class="error-detail">
            To report an outage text OUT to GMPVT or call
            <span class="gds-nobreak">{{ GMPPhoneNumber }}.</span>
          </div>
        </div>
        <div v-if="loadState === 'error'" class="card-error-layout-blank card-error-layout-blank--short">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../services/gmpapi";
import { EnvironmentConstants, GMPPhoneNumber } from "../environment";
import { FormatEstimatedRestoreTime } from "../utilities";
import { AnalyticsLogEvent } from "../services/analytics";
import { DumpError } from '../utilities';
import { isFeatureEnabled } from "../services/featureflags";

export default {
  name: "CardReportIssue",
  data() {
    return {
      // Undefined while not loaded, null when not found, object when incident found
      activeIncident: undefined,
      powerSONP: undefined,
      powerOff: undefined,
      plannedOutage: undefined,

      loadState: undefined,

      EnvironmentConstants,
      GMPPhoneNumber,

      FF_PlannedOutage: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshBoth();
  },
  methods: {
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
    },
    async RefreshBoth() {
      this.loadState = undefined;
      // Don't check for currentuser in this case, it's handled in the refreshers
      try {
        await Promise.all([
          this.getConfigs(),
          this.RefreshIncidents(),
          this.RefreshShutoff(),
          this.RefreshPlanned(),
        ]);
        this.loadState = "complete";
      } catch (err) {
        // Firefox logs error for aborted request from previous load. Let's hide it.
        if (!(err && err.message === "Request aborted")) {
          DumpError("Power status card refresh error", err);
        }
        this.loadState = "error";
      }
      const status = this.activeIncident ? "out" : ((this.powerSONP || this.powerOff) ? "off" : "on");
      AnalyticsLogEvent("powerLoaded", { powerStatus: status });
    },
    async RefreshIncidents() {
      this.activeIncident = undefined;
      if (this.currentAccount) {
        const incidents = await GMPAPI.GetAccountIncidents(this.currentAccount.accountNumber);
        let activeIncident = incidents.find(item => item.isActive === true);
        if (!activeIncident) {
          const pending = await GMPAPI.GetReportedIncident(this.currentAccount.accountNumber);
          if (pending.reported) {
            activeIncident = {
              pending: true,
            };
          }
        }
        this.activeIncident = activeIncident || null;
      }
    },
    async RefreshShutoff() {
      this.powerSONP = undefined;
      this.powerOff = undefined;
      if (this.currentAccount) {
        const status = await GMPAPI.GetAccountStatus(this.currentAccount.accountNumber);
        this.powerSONP = status.sonp;
        this.powerOff = status.meterOff;
      }
    },
    async RefreshPlanned() {
      if (this.FF_PlannedOutage) {
        this.plannedOutage = undefined;
        if (this.currentAccount) {
          const personalData = await GMPAPI.GetPersonalOutageData();
          const account = personalData.find(a => a.accountNumber === this.currentAccount.accountNumber);
          if (account && account.plannedOutages && account.plannedOutages.length > 0) {
            this.plannedOutage = account.plannedOutages[0];
          }
        }
      } else {
        return;
      }
    },
    formatEtr(activeIncident) {
      if(activeIncident.pending) {
        return "Outage Reported";
      }

      if(activeIncident.estimatedRestoredAt || activeIncident.etrEarliest || activeIncident.etrLatest) {
        return FormatEstimatedRestoreTime(activeIncident.estimatedRestoredAt, activeIncident.etrEarliest, activeIncident.etrLatest);
      }

      return "Assessing restoration...";
    }
  },
  watch: {
    // Refresh whenever the account changes
    async currentAccount() {
      await this.RefreshBoth();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>